import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getCities = async ({ state }) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/states/cities?state=${state}`
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const myError = {
      errors: err?.errors ?? [],
      message: err?.message ?? "Error en la petición"
    };

    return Promise.reject(myError);
  }
};
