//PROPS DEL COMPONENTE: property, location
const PropertyInfo = (props) => {
  return (
    <div
      className="info-extend text-start ms-2"
      style={{ fontSize: props.location ? 23 : 14 }}
    >
      {!props.location && (
        <label className="property-title">
          Tipo:{" "}
          <label className="value-title">
            {props?.property?.type ?? "N/A"}
          </label>
        </label>
      )}
      <label className="property-title">
        Estado: <label className="value-title">{props?.property?.state}</label>
      </label>
      <p className="m-0 value-title">
        <span className="m-0 property-title">Ubicacion: </span>
        {props?.property?.address?.street ?? ""}
        {props?.property?.address?.street && " "}
        {props?.property?.address?.numInt ?? ""}
        {props?.property?.address?.numInt && ", "}
        {props?.property?.address?.numExt ?? ""}
        {props?.property?.address?.numExt && ", "}
        {props?.property?.settlement ?? ""}
        {props?.property?.settlement && ", "}
        {props?.property?.city ?? ""}
        {props?.property?.city && ", "}
        {props?.property?.state ?? ""}
        {props?.property?.state && ", "}
        {props?.property?.postalCode && "C.P.: "}
        {props?.property?.postalCode ?? ""}
      </p>
      {!props.location && (
        <p className="m-0 value-title">
          <span className="property-title">Asesor de compra: </span>
          {props?.property?.adviserRef?.name ?? ""}
        </p>
      )}
    </div>
  );
};

export default PropertyInfo;
