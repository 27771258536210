import "./styles.css";

const MetricsMenu = ({ setDisplayComponent }) => {
  return (
    <section className="nav">
      <button className="btn" onClick={() => setDisplayComponent("general")}>
        General
      </button>
      <button className="btn" onClick={() => setDisplayComponent("mapa")}>
        Mapa de Operación
      </button>
      <button className="btn" onClick={() => setDisplayComponent("funnel")}>
        Funnel de Conversión
      </button>
      <button className="btn" onClick={() => setDisplayComponent("kpi")}>
        Kpi&aposs
      </button>
      <button className="btn" onClick={() => setDisplayComponent("cotizacion")}>
        Cotización
      </button>
      <button className="btn" onClick={() => setDisplayComponent("demanda")}>
        Demanda
      </button>
      <button className="btn" onClick={() => setDisplayComponent("oferta")}>
        Oferta
      </button>
    </section>
  );
};

export default MetricsMenu;
