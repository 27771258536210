import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { useState } from "react";

import { useModal } from "../../../hooks/useModal";
import OfferDocsButton from "../offerDocs/OfferDocsButton";
import SigningModal from "./SigningModal";

const SigningProcess = ({ property }) => {
  const { open, onOpenModal, onCloseModal } = useModal();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  return (
    <>
      <Box display={"flex"} justifyContent="start">
        <Typography
          variant="h5"
          className="blue-text"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Firma
        </Typography>
        <IconButton
          sx={{ color: "#0053C5" }}
          aria-label="modal-expediente"
          onClick={onOpenModal}
        >
          <PostAddIcon color="inherit" />
        </IconButton>
      </Box>
      {open && (
        <SigningModal
          property={property}
          open={open}
          onCloseModal={onCloseModal}
        />
      )}
      <Box display={"flex"}>
        <OfferDocsButton
          title={"Oferta firmada"}
          type={"signedOffer2"}
          doc={property?.documents.find((d) => d.type === "signedOffer2")}
          property={property}
          setIsUploadingFiles={setIsUploadingFiles}
        />

        <OfferDocsButton
          title={"Cartas poder firmadas"}
          type={"signedLetter2"}
          doc={property?.documents.find((d) => d.type === "signedLetter2")}
          property={property}
          setIsUploadingFiles={setIsUploadingFiles}
        />
        {isUploadingFiles && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(256,256,256,0.5)"
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
};

export default SigningProcess;
