import React from "react";
import "./styles/Sidebar.css";
import {
  MdOutlineSource,
  MdPendingActions,
  MdOutlineAssignmentReturn,
  MdOutlineAssignmentInd
} from "react-icons/md";
import { RiPriceTag2Line } from "react-icons/ri";

import CustomLink from "./CustomLink";

const SellerSidebar = () => {
  return (
    <section className="sidebar-section-component">
      <section className="navigate-section-container">
        <CustomLink to="/ctc/venta/leads-y-perfilados">
          <div className="navigate-icon-link">
            <MdOutlineAssignmentInd className="nav-icon-size" />
            Perfilados
          </div>
        </CustomLink>
        <hr className="divider" />
        <CustomLink to="/ctc/venta/prospectos">
          <div className="navigate-icon-link">
            <RiPriceTag2Line className="nav-icon-size" />
            Prospectos
          </div>
        </CustomLink>
        <hr className="divider" />
        <CustomLink to="/ctc/venta/reconversion">
          <div className="navigate-icon-link">
            <MdOutlineAssignmentReturn className="nav-icon-size" />
            Reconversion
          </div>
        </CustomLink>
        <hr className="divider" />
        <CustomLink to="/ctc/venta/expediente">
          <div className="navigate-icon-link">
            <MdOutlineSource className="nav-icon-size" />
            Expediente
          </div>
        </CustomLink>
        <hr className="divider" />
        <CustomLink to="/ctc/venta/firma">
          <div className="navigate-icon-link">
            <MdPendingActions className="nav-icon-size" />
            Firma
          </div>
        </CustomLink>
      </section>
    </section>
  );
};

export default SellerSidebar;
