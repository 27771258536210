import { Button } from "@mui/material";
import { useContext } from "react";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";

const ValidatePreinventory = (props) => {
  const { token } = useContext(TokenContext);

  const sendData = async (e) => {
    try {
      const response = await updateProperties(token, props.id, {
        preInventory: true
      });

      if (response._id) {
        props.refetchProperties();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        variant="blue"
        color="primary"
        sx={{ width: 180 }}
        onClick={sendData}
      >
        Validar Preinventario
      </Button>
    </>
  );
};

export default ValidatePreinventory;
