import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button, Grid, List, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useModal } from "../../../hooks/useModal";
import NoteItem from "./NoteItem";
import NoteModal from "./NoteModal";

const NotesContainer = ({ propertyId, notes, refetch, status }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={600}
            width={"100%"}
            textAlign="start"
            mb={1}
          >
            Notas:
            <Tooltip
              arrow
              title="Las notas son registros PERMANENTES de información que se deben tomar en cuenta a lo largo de todo el proceso de compra."
              sx={{ fontSize: 18 }}
            >
              <HelpOutlineIcon color="primary" fontSize="inherit" />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            sx={{ width: 150, mt: 0 }}
            onClick={onOpenModal}
          >
            Nueva nota
          </Button>
        </Grid>
        {open && (
          <NoteModal
            open={open}
            onCloseModal={onCloseModal}
            status={status}
            propertyId={propertyId}
            refetch={refetch}
          />
        )}
      </Grid>
      <Box
        sx={{
          mt: 1,
          height: 300,
          width: "97%",
          border: "1px solid rgba(111, 128, 149, 0.6)",
          borderRadius: 2,
          overflowY: "auto",
          "::-webkit-scrollbar": { width: "5px" },
          "::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",

            borderRadius: "10px"
          },
          "::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
            borderRadius: "10px"
          }
        }}
      >
        <List>
          {notes?.map((n) => (
            <NoteItem key={n._id} note={n} />
          ))}
        </List>
      </Box>
    </>
  );
};

export default NotesContainer;
