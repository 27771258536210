import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import {
  Box,
  Button,
  Modal,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import {
  addDays,
  endOfDay,
  endOfMonth,
  format,
  startOfDay,
  startOfMonth
} from "date-fns";
import { es } from "date-fns/locale";
import esLocale from "date-fns/locale/es";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  DateRangePicker,
  defaultInputRanges,
  defaultStaticRanges
} from "react-date-range";

import { getQuotersMetrics } from "../../api/quoters/getQuotersMetrics";
import TokenContext from "../../context/Token";

const QuoteSection = ({ displayComponent }) => {
  const { token } = useContext(TokenContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [order, setOrder] = useState("desc");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: "selection"
    }
  ]);

  const staticRangesLabels = {
    Today: "Hoy",
    Yesterday: "Ayer",
    "This Week": "Esta semana",
    "Last Week": "Semana pasada",
    "This Month": "Este mes",
    "Last Month": "Mes pasado"
  };

  const inputRangesLabels = {
    "days up to today": "días hasta hoy",
    "days starting today": "días a partir de hoy"
  };

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label]
        ? { ...item, label: dictionary[item.label] }
        : item;
  }

  const ruStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  );
  const ruInputRanges = defaultInputRanges.map(
    translateRange(inputRangesLabels)
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getQuotersMetrics(
          {
            order,
            startDate: rangeDate[0].startDate.toISOString(),
            endDate:
              rangeDate[0].startDate.toISOString() ===
              rangeDate[0].endDate.toISOString()
                ? endOfDay(rangeDate[0].endDate).toISOString()
                : rangeDate[0].endDate.toISOString()
          },
          token
        );

        setData(response?.data?.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, token]); // Add any dependencies here that should trigger a re-fetch

  const updateDateRange = async () => {
    setLoading(true);
    try {
      const response = await getQuotersMetrics(
        {
          order,
          startDate: rangeDate[0].startDate.toISOString(),
          endDate:
            rangeDate[0].startDate.toISOString() ===
            rangeDate[0].endDate.toISOString()
              ? endOfDay(rangeDate[0].endDate).toISOString()
              : rangeDate[0].endDate.toISOString()
        },
        token
      );

      setData(response?.data?.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading || !data) return <p>Cargando...</p>;

  if (error) return <p>Error...</p>;

  console.log(rangeDate);
  return (
    <div
      style={{
        flex: 1,
        width: "100%",
        paddingTop: "1em"
      }}
    >
      <div style={{}}>
        <h2>Métricas de cotización</h2>
      </div>
      <div
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingBottom: "2em"
        }}
      >
        <div style={{ marginRight: "1em" }}>
          Periodo:{" "}
          <h6>
            {format(rangeDate[0]?.startDate, "PPPP", { locale: es })}-
            {format(rangeDate[0]?.endDate, "PPPP", { locale: es })}
          </h6>
        </div>
        <ToggleButton aria-label="left aligned" onClick={handleOpen}>
          <DateRangeIcon />
        </ToggleButton>
        <ToggleButtonGroup
          size="small"
          value={order}
          exclusive
          onChange={(_, value) => setOrder(value)}
          aria-label="text alignment"
        >
          <ToggleButton value="desc" aria-label="left aligned">
            <FilterListIcon />
          </ToggleButton>
          <ToggleButton value="asc" aria-label="centered">
            <FilterListOffIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div
        style={{
          marginTop: "2em",
          display: "flex",
          width: "80%",
          margin: "auto"
        }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ paddingBottom: "2.6em" }}>
            <h5>Cotizaciones generadas</h5>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "80%"
              }}
            >
              {data?.countByAdvicerRef
                .map((d) => Object.values(d))
                .map((row, index) => (
                  <div key={index} style={{ display: "flex" }}>
                    <div style={{ textAlign: "left", marginRight: "auto" }}>
                      <h5>{row[0]?.user?.name}</h5>
                    </div>
                    <div key={index} style={{ textAlign: "left" }}>
                      <h5
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "600"
                        }}
                      >
                        {row[0]?.count}
                      </h5>
                    </div>
                  </div>
                ))}
              {data?.countByAdvicerRef.length === 0 && (
                <h5
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "400"
                  }}
                >
                  Sin Datos
                </h5>
              )}
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ paddingBottom: "2.6em" }}>
            <h5>Ofertas generadas</h5>
          </div>
          <div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "80%" }}
            >
              {data?.countQuotesByAdvicerRef
                .map((d) => Object.values(d))
                .map((row, index) => (
                  <div key={index} style={{ display: "flex" }}>
                    <div style={{ textAlign: "left", marginRight: "auto" }}>
                      <h5>{row[0]?.user?.name}</h5>
                    </div>
                    <div key={index} style={{ textAlign: "left" }}>
                      <h5
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "600"
                        }}
                      >
                        {row[0]?.count}
                      </h5>
                    </div>
                  </div>
                ))}
              {data?.countQuotesByAdvicerRef.length === 0 && (
                <h5
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "400"
                  }}
                >
                  Sin Datos
                </h5>
              )}
            </div>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ paddingBottom: "2.6em" }}>
            <h5>Código Postal Sin datos</h5>
          </div>
          <div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "80%" }}
            >
              {data?.countByPostalCodeNull
                .map((d) => Object.values(d))
                .map((row, index) => (
                  <div key={index} style={{ display: "flex" }}>
                    <div
                      style={{
                        textAlign: "left",
                        marginRight: "auto"
                      }}
                    >
                      <h5>{row[0]?.postalCode}</h5>
                    </div>
                    <div key={index} style={{ textAlign: "left" }}>
                      <h5
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "600"
                        }}
                      >
                        {row[0]?.count}
                      </h5>
                    </div>
                  </div>
                ))}
              {data?.countByPostalCodeNull.length === 0 && (
                <h5
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "400"
                  }}
                >
                  Sin Datos
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "2em"
            }}
          >
            <h4>Rango de fechas</h4>
          </Box>
          <Box
            sx={{
              position: "relative"
            }}
          >
            <DateRangePicker
              onChange={(item) => setRangeDate([item.selection])}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={rangeDate}
              direction="horizontal"
              locale={esLocale}
              staticRanges={ruStaticRanges}
              inputRanges={ruInputRanges}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "2em",
              gap: "3em"
            }}
          >
            <Button variant="text">Limpiar</Button>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                updateDateRange();
              }}
            >
              Aplicar
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default QuoteSection;
