export const stringToCurrency = (amount) => {
  const formatter = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN"
  });

  return !isNaN(amount) ? formatter.format(amount) : 0;
};

export const decimalToPercentage = (decimal) => {
  // Multiplicar por 100 para obtener el valor porcentual
  const percentage = decimal * 100;

  // Formatear el número como porcentaje
  return `${percentage.toFixed(4)}%`;
};
