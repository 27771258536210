const { REACT_APP_BASE_URL } = process.env;

export const login = (data) =>
  new Promise((resolve, reject) => {
    const body = JSON.stringify(data);

    const request = new Request(`${REACT_APP_BASE_URL}/admins/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body
    });

    return fetch(request)
      .then((response) => {
        if (!response.ok) throw response;
        else return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });

export const loginAdvisers = (data) =>
  new Promise((resolve, reject) => {
    const body = JSON.stringify(data);

    const request = new Request(`${REACT_APP_BASE_URL}/advisers/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body
    });

    return fetch(request)
      .then((response) => {
        if (!response.ok) throw response;
        else return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });

export const loginLawyers = (data) =>
  new Promise((resolve, reject) => {
    const body = JSON.stringify(data);

    const request = new Request(`${REACT_APP_BASE_URL}/lawyers/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body
    });

    return fetch(request)
      .then((response) => {
        if (!response.ok) throw response;
        else return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
