import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";

const SubCategoryList = ({ subCategories = [], evidence = [] }) => {
  return (
    <Box>
      {subCategories?.map((s, i) => (
        <Typography key={i} variant="body1" color="secondary">
          {s.subcategory} (
          {evidence?.filter((ev) => ev.subCategory === s.subcategory)?.length})
        </Typography>
      ))}
    </Box>
  );
};

export default SubCategoryList;
