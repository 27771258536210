import ClientInfo from "../InfoFields/ClientInfo";
import LastContact from "../InfoFields/LastContact";
import PropertyInfo from "../InfoFields/PropertyInfo";
import ReconvertActions from "../InfoFields/ReconvertActions";
import ReasonData from "../Reconvert/ReasonData";

const TableBodyReconversion = (props) => {
  // console.log(props?.properties ?? "algo");
  return (
    <tbody>
      {props?.properties?.map((item) => (
        <tr key={item?.propertyRef?._id}>
          <td style={{ width: 100 }}>
            <label className="property-title mt-4">
              {" "}
              {item?.propertyRef.status === "Expediente"
                ? "Integración de Expediente"
                : item?.propertyRef.status === "Perfilado"
                ? "Prospecto"
                : item?.propertyRef.status === "Prospectos"
                ? "Cliente"
                : item?.propertyRef.status}
            </label>
          </td>
          <td style={{ width: 200 }}>
            <label className="property-title mt-4">
              {" "}
              {item?.propertyRef?.adviserRef?.name ?? "Desconocido"}
            </label>
          </td>
          <td style={{ width: 180 }}>
            <ClientInfo sellerRef={item?.propertyRef?.sellerRef ?? {}} />
          </td>
          <td style={{ width: 250 }}>
            <PropertyInfo property={item?.propertyRef ?? {}} />
          </td>
          <td style={{ width: 130 }}>
            <LastContact propertyRef={item.propertyRef} />
          </td>
          <td style={{ width: 150 }}>
            <ReasonData
              reason={item.reason ?? ""}
              reasonDetail={item.reasonDetail ?? ""}
            />
          </td>
          {props.tableType === "Reconversion" && (
            <td style={{ width: 200 }}>
              <ReconvertActions
                _id={item._id}
                status={item.status}
                propertyRef={item.propertyRef}
                advisers={props.advisers ?? []}
                refetchReconversion={props.refetchReconversion}
              />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBodyReconversion;
