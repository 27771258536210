import { useContext } from "react";
import { useState } from "react";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";
import ModalComment from "../Modals/ModalComment";

//PROPS DEL COMPONENTE id={props?.property?._id} inspectionText={props.property?.inspectionText??""}
const InspectionData = (props) => {
  const { token } = useContext(TokenContext);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sendData = async (text) => {
    try {
      const response = await updateProperties(token, props.id, {
        inspection: true,
        inspectionText: text
      });

      // console.log(response._id)
      if (response._id) {
        setSuccessMessage("Inspección Actualizada");
        props.refetchProperties();
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <ModalComment
      reason={"Comentario"}
      titleModal={"Inspeccion"}
      id={props.id}
      okText={"Actualizar Inspección"}
      successMessage={successMessage}
      errorMessage={errorMessage}
      inheritedText={props.inspectionText ?? ""}
      sendData={sendData}
    />
  );
};

export default InspectionData;
