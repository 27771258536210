import React, { useState } from "react";
import { BiMessageSquareEdit } from "react-icons/bi";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const ListDates = () => {
  const [approve, setApprove] = useState(false);
  const navigate = useNavigate();
  const onHandleApprove = () => {
    setApprove(true);
  };

  return (
    <section className="flex-center">
      <div className="mt-5 mb-5" style={{ width: "65%" }}>
        <div
          onClick={() => navigate("/ctc/venta/prospectos")}
          className="historial-back p-3 ps-5"
          style={{ cursor: "pointer" }}
        >
          <MdArrowBackIos />
          <label style={{ cursor: "pointer" }}>Regresar</label>
        </div>

        <div className="d-flex flex-column border border-secondary">
          <div
            className="d-flex justify-content-around fw-bold text-gray fs-6 p-2"
            style={{ width: "85%" }}
          >
            <label>ID de vivienda</label>
            <label>Cita</label>
            <label>Acciones</label>
          </div>
          <div className="d-flex border border-secondary p-3 justify-content-between align-items-center">
            <button className="btn-fill-grayblue ms-5">ID de vivienda</button>
            <label>Fecha y hora</label>
            <div className="d-flex align-items-center">
              {!approve && (
                <>
                  <button
                    className="btn-fill-orange me-3"
                    onClick={onHandleApprove}
                  >
                    Asistió
                  </button>
                  <button className="btn-fill-orange me-5">No Asistió</button>
                </>
              )}
              {approve && (
                <button className="btn-fill-orange me-5">
                  Listo para evaluó
                </button>
              )}
              <BiMessageSquareEdit className="fs-2 text-gray align-self-end" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ListDates;
