import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const createCall = async (token, call) => {
  const body = { call: call };

  try {
    const response = await axios.post(`${REACT_APP_BASE_URL}/calls`, body, {
      headers: { Authorization: `Bearer ${token}` }
    });

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err?.errors[0]?.msg
        : err.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
