import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

function parseQueryParams(queryString) {
  const params = new URLSearchParams(queryString);
  const entries = params.entries();
  const parsedParams = [];
  let currentParam = {};

  for (const [key, value] of entries) {
    if (key === "name") {
      if (currentParam.field) {
        parsedParams.push(currentParam);
        currentParam = {};
      }
      currentParam.field = value;
    } else if (key === "reason") {
      currentParam.reason = value;
    } else if (key === "criteria") {
      if (!currentParam.values) {
        currentParam.values = [];
      }
      currentParam.values.push(value);
    } else if (key === "operator") {
      currentParam.operator = value;
    }
  }

  if (currentParam.field) {
    parsedParams.push(currentParam);
  }

  return parsedParams;
}

export const getTotalPropertiesByPriority = async (
  token,
  results = 20,
  page = 1,
  status = "Perfilado",
  lead = "",
  status2 = "",
  filters,
  preInventory,
  advancedFilters
) => {
  // console.log(advancedFilters);
  for (let param in filters) {
    /* You can get copy by spreading {...query} */
    if (
      filters[param] === undefined /* In case of undefined assignment */ ||
      filters[param] === null ||
      filters[param] === ""
    ) {
      delete filters[param];
    }

    if (filters.preSeller === false) {
      delete filters.preSeller;
    }
  }

  let queryParams = new URLSearchParams({
    ...filters
  }).toString();

  if (advancedFilters) {
    queryParams = advancedFilters;
  }

  let url;

  if (advancedFilters) {
    url = `${REACT_APP_BASE_URL}/metrics/priorities?status=${status}&advanced=${JSON.stringify(
      parseQueryParams(queryParams)
    )}`;
  } else {
    url = queryParams
      ? `${REACT_APP_BASE_URL}/metrics/priorities?status=${status}&` +
        queryParams
      : `${REACT_APP_BASE_URL}/metrics/priorities?status=${status}`;
  }

  if (status2 !== "") {
    url = url + `&status=${status2}`;
  }

  if (lead !== "") {
    url = url + `&lead=${lead}`;
  }

  if (preInventory === true) {
    url = url + `&preInventory=true`;
  }

  if (preInventory === false) {
    url = url + `&preInventory=false`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
