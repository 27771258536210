import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grow,
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Switch
} from "@mui/material";
import { debounce } from "@mui/material/utils";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getAllAdvisers } from "../api/advisers/getAllAdvisers";
import { searchPostalCode } from "../api/postalCode/searchPostalCode";
import { asesores } from "../cacheQueries/cacheQueries";
import TokenContext from "../context/Token";
// import { MdOutlineSearch, MdFilterListAlt } from "react-icons/md";

const states = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Chiapas",
  "Chihuahua",
  "Ciudad de México",
  "Coahuila",
  "Colima",
  "Durango",
  "Estado de México",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán"
];

const SearchBar = (props) => {
  const { token } = useContext(TokenContext);
  const [checked, setChecked] = useState(false);
  const { data, isLoading } = useQuery([asesores], () => getAllAdvisers(token));

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setChecked(false);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Button
          variant="contained"
          sx={{ ml: 3 }}
          onClick={(e) => setChecked(!checked)}
          endIcon={<FilterAltIcon />}
        >
          Filtros
        </Button>
        <Box sx={{ position: "absolute", right: -720, top: -120, zIndex: 10 }}>
          {checked && (
            <Grow in={checked}>
              <Card sx={{ width: 700 }}>
                <CardContent>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={4}>
                      {/* <TextField
                        size="small"
                        fullWidth
                        id="name"
                        name="name"
                        label="Nombre del Cliente"
                        value={props.name}
                        onChange={props.onChange}
                      /> */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {/* <TextField
                        type="number"
                        size="small"
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Celular"
                        InputProps={{ inputProps: { min: 10, max: 10 } }}
                        value={props.phoneNumber}
                        onChange={props.onChange}
                      /> */}
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={4}>
                      {isLoading ? (
                        <TextField
                          size="small"
                          fullWidth
                          id="adviserRef"
                          name="adviserRef"
                          label="Cargando lista de asesores..."
                          value={props.adviserRef}
                          onChange={props.onChange}
                        />
                      ) : (
                        <TextField
                          size="small"
                          fullWidth
                          select
                          id="adviserRef"
                          name="adviserRef"
                          label="Nombre del Asesor"
                          value={props.adviserRef}
                          onChange={props.onChange}
                        >
                          {data?.data?.advisers?.map((ad) => (
                            <MenuItem key={ad._id} value={ad._id}>
                              {ad.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      {/* <TextField
                        type="number"
                        size="small"
                        fullWidth
                        id="postalCode"
                        label="C.P."
                        name="postalCode"
                        InputProps={{ inputProps: { min: 5, max: 5 } }}
                        inputProps={{ min: 4, max: 10 }}
                        value={props.postalCode}
                        onChange={props.onChange}
                      /> */}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        id="settlement"
                        name="settlement"
                        label="Colonia"
                        disabled={props.settlements.length === 0}
                        value={props.settlement}
                        onChange={props.onChange}
                      >
                        {props.settlements.map((settlement) => (
                          <MenuItem key={settlement} value={settlement}>
                            {settlement}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        size="small"
                        fullWidth
                        select
                        id="state"
                        name="state"
                        label="Estado"
                        value={props.state}
                        onChange={props.onChange}
                      >
                        {states.map((state) => (
                          <MenuItem key={state} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MobileDatePicker
                        label="Fecha desde"
                        inputFormat="DD/MM/YYYY"
                        slotProps={{
                          textField: { size: "small", fullWidth: true }
                        }}
                        value={props.dateStart}
                        onChange={(value) => {
                          props.onChange({
                            target: {
                              name: "dateStart",
                              value: value
                            }
                          });
                        }}
                        textField={(params) => (
                          <TextField {...params} id="dateStart" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MobileDatePicker
                        label="Fecha hasta"
                        inputFormat="DD/MM/YYYY"
                        slotProps={{
                          textField: { size: "small", fullWidth: true }
                        }}
                        value={props.dateEnd}
                        onChange={(value) =>
                          props.onChange({
                            target: {
                              name: "dateEnd",
                              value: value
                            }
                          })
                        }
                        textField={(params) => (
                          <TextField {...params} id="dateEnd" />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            id="preSeller"
                            name="preSeller"
                            checked={props.preSeller}
                            onChange={props.onChange}
                          />
                        }
                        label="Incompletos"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    <Grid item>
                      <Button fullWidth sx={{ m: 0 }} onClick={props.clean}>
                        Limpiar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        sx={{ m: 0 }}
                        onClick={props.handleFilter}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grow>
          )}
        </Box>
      </Box>
    </>

    // <div className="input-group mb-3 w-25 mt-5 align-self-end me-5">
    //   <div className="input-group-text">
    //     <MdOutlineSearch />
    //   </div>
    //   <input
    //     type="text"
    //     className="form-control"
    //     placeholder={placeholder || "Search Here"}
    //     onChange={(e) => onChange(e.target.value.toLowerCase())}
    //     value={value}
    //   />
    //   <div className="input-group-text px-2">
    //     <MdFilterListAlt />
    //   </div>
    // </div>
  );
};

export default SearchBar;
