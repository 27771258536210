import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography
} from "@mui/material";

import { stringToCurrency } from "../../../../helpers/currencies";
import { categories } from "../../../../helpers/RepairCategories";
import InspectionGallery from "./inspectionGallery/InspectionGallery";
import SubCategoryList from "./SubCategoryList";

const CategoriesList = ({
  evidence = [],
  modalType = "",
  propertyId,
  refetch
}) => {
  return (
    <>
      {categories.map((c) => (
        <Accordion key={c.category}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography color={"secondary"}>
              {c.category} (
              {evidence?.filter((ev) => ev.category === c.category).length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SubCategoryList
              subCategories={c.subCategories ?? []}
              evidence={evidence}
            />
            <InspectionGallery
              evidence={evidence.filter((ev) => ev.category === c.category)}
              modalType={modalType}
              propertyId={propertyId}
              refetch={refetch}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <Divider color="#718195" />
      <Grid container mx={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" color="secondary" fontWeight={"bold"}>
            Total:
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            color="secondary"
            fontWeight={"bold"}
            textAlign={"Center"}
          >
            {stringToCurrency(
              evidence.reduce(
                (subtotal, ev) => subtotal + ev.quantity * ev.price,
                0
              )
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default CategoriesList;
