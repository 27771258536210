import { useContext } from "react";
import { useQuery } from "react-query";

import { getAllAdvisers } from "../../../api/advisers/getAllAdvisers";
import TokenContext from "../../../context/Token";

const AdviserSelect = (props) => {
  const { token } = useContext(TokenContext);
  const { data } = useQuery(["advisers"], () => getAllAdvisers(token));

  // console.log(data?.data??"")
  return (
    <select
      className="form-select"
      name="adviserRef"
      value={props.adviserRef}
      id="adviserRef"
      onChange={props.handleChange}
    >
      <option value="" hidden>
        Seleccionar Asesor
      </option>
      {data?.data?.advisers?.map((a) => (
        <option key={a._id} value={a._id}>
          {a.name}
        </option>
      ))}
    </select>
  );
};

export default AdviserSelect;
