import { useContext } from "react";
import { useState } from "react";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";

const Stage = ({ stage, propertyId }) => {
  const { token } = useContext(TokenContext);
  const [statusLanding, setStatusLanding] = useState(stage);

  const sendData = async (status) => {
    try {
      const response = await updateProperties(token, propertyId, {
        statusLanding: status
      });
      // console.log(response)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex">
      <label htmlFor="stage" className="property-title fs-4 me-1">
        Estado en tienda:{" "}
      </label>
      <select
        className="form-select"
        style={{ width: 150 }}
        name="stage"
        id="stage"
        value={statusLanding}
        onChange={(e) => {
          setStatusLanding(e.target.value);
          sendData(e.target.value);
        }}
      >
        <option value="Publicado">Publicado</option>
        <option value="No publicado">No publicado</option>
        <option value="Proximamente">Próximamente</option>
      </select>
    </div>
  );
};

export default Stage;
