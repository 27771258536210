import React from "react";
import "../styles/Categories.css";
// import { useContext } from "react";

// import TokenContext from "../../context/Token";

const ProfileCategories = (props) => {
  // const { token } = useContext(TokenContext);
  return (
    <section className="categories-section">
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Nuevo")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Nuevo" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Nuevos</label>
        <label style={{ cursor: "pointer" }}>
          {props?.data?.metrics?.data?.find(
            (priority) => priority.lead === "Nuevo"
          )?.total ?? 0}{" "}
          casas
        </label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "")}
        style={{
          cursor: "pointer",
          backgroundColor: props.currentTab === "" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>General</label>
        <label style={{ cursor: "pointer" }}>
          {props?.data?.metrics?.totalLeads ?? "0"} casas
        </label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "ICSE")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "ICSE" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>ICSE</label>
        <label style={{ cursor: "pointer" }}>
          {props?.data?.metrics?.data?.find(
            (priority) => priority.lead === "ICSE"
          )?.total ?? 0}{" "}
          casas
        </label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Apto")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Apto" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Aptos</label>
        <label style={{ cursor: "pointer" }}>
          {props?.data?.metrics?.data?.find(
            (priority) => priority.lead === "Apto"
          )?.total ?? 0}{" "}
          casas
        </label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "No Apto")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "No Apto" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>No Aptos</label>
        <label style={{ cursor: "pointer" }}>
          {props?.data?.metrics?.data?.find(
            (priority) => priority.lead === "No Apto"
          )?.total ?? 0}{" "}
          casas
        </label>
      </div>
    </section>
  );
};

export default ProfileCategories;
