import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import {
  decimalToPercentage,
  stringToCurrency
} from "../../../helpers/currencies";

const locales = { es };

const InfoOffer = ({ quote }) => {
  if (quote?.quoteData?.netOffer) {
    const totalAdeudos = quote?.quoteData?.debts.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      },
      0
    );

    const interesesCredito =
      quote?.quoteData?.creditAmount *
      (quote?.quoteData?.creditAmountRate || 0.3);

    const comisionAdelanto =
      quote?.quoteData?.advancedPaymentAmount *
      quote?.quoteData?.advancedPaymentRate;

    const totalDeducciones =
      quote?.quoteData?.creditAmount +
      interesesCredito +
      quote?.quoteData?.rehabilitation +
      quote?.quoteData?.cfdi +
      quote?.quoteData?.creditTaxes +
      quote?.quoteData?.advancedPaymentAmount +
      comisionAdelanto +
      quote?.quoteData?.debts.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount;
      }, 0) +
      quote?.quoteData?.debts.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.amount * currentValue.rate;
      }, 0);

    const interesesAdeudos =
      totalAdeudos * (quote?.quoteData?.rangeDays > 180 ? 0.3 : 0);

    const utilidadTotal =
      quote?.quoteData?.commercialPrice -
      quote?.quoteData?.grossOffer +
      interesesCredito +
      comisionAdelanto +
      interesesAdeudos;

    const porcentajeComision = Number(
      utilidadTotal / quote?.quoteData?.commercialPrice
    );

    return (
      <>
        <Grid container spacing={3} mb={3} mt={1}>
          <Grid
            item
            sm={4}
            md={4}
            lg={4}
            display="flex"
            flexDirection={"column"}
          >
            <Typography
              variant="h5"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
              className="blue-text"
            >
              Oferta Neta:{" "}
              <Typography
                variant="h5"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
                fontWeight={"bold"}
              >
                {stringToCurrency(quote?.quoteData?.netOffer)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Oferta Máxima a 365:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(
                  Number(
                    quote?.quoteData?.maxOffer ||
                      Number(
                        quote?.averageM2Price * quote?.quoteData?.metersBuild
                      ) * 0.9
                  )
                )}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Oferta Bruta:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(quote?.quoteData?.grossOffer)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Intervalo:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {quote?.quoteData?.rangeDays} días
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              M2:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {quote?.quoteData?.metersBuild}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              M2 Precio:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(quote?.averageM2Price)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Precio comercial:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(quote?.quoteData?.commercialPrice)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Precio Inventario:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(quote?.quoteData?.commercialPrice + 27500)}
              </Typography>
            </Typography>
            {quote?.adviserref?.name || quote?.leaderRef?.name ? (
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                fontWeight={"bold"}
              >
                {quote?.adviserref ? "Asesor" : "Manager"}:{" "}
                <Typography
                  variant="h6"
                  color="secondary"
                  textAlign={"start"}
                  display={"inline-block"}
                >
                  {quote?.adviserref?.name || quote?.leaderRef?.name}
                </Typography>
              </Typography>
            ) : null}
            {quote?.authorizedByRef?.name ? (
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                fontWeight={"bold"}
              >
                Autorizado por:{" "}
                <Typography
                  variant="h6"
                  color="secondary"
                  textAlign={"start"}
                  display={"inline-block"}
                >
                  {quote?.authorizedByRef?.name}
                </Typography>
              </Typography>
            ) : null}

            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Creada:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {format(new Date(quote?.createdAt), "dd-LLL-yyyy h:mm aaa", {
                  locales
                })}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            lg={4}
            display="flex"
            flexDirection={"column"}
          >
            <Typography
              variant="h5"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
              className="blue-text"
            >
              Deducciones:{" "}
              <Typography
                variant="h5"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
                fontWeight={"bold"}
              >
                {stringToCurrency(totalDeducciones)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Intereses Crédito:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(interesesCredito)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Intereses Adelanto:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(comisionAdelanto)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Intereses servicios:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {stringToCurrency(interesesAdeudos)}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            lg={4}
            display="flex"
            flexDirection={"column"}
          >
            <Typography
              variant="h5"
              textAlign={"start"}
              width={"100%"}
              fontWeight={"bold"}
              className="blue-text"
            >
              Utilidad Total:{" "}
              <Typography
                variant="h5"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
                fontWeight={"bold"}
              >
                {stringToCurrency(utilidadTotal)}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              fontWeight={"bold"}
            >
              Margen de ganancia:{" "}
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                display={"inline-block"}
              >
                {decimalToPercentage(porcentajeComision)}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default InfoOffer;
