import { Box, Grid, Typography } from "@mui/material";
import Fade from "@mui/material/Fade";
import { addDays, format, intlFormat } from "date-fns";
import { useEffect } from "react";

import { calcCommission } from "../../helpers/comisionAdelanto";
import {
  decimalToPercentage,
  stringToCurrency
} from "../../helpers/currencies";

const OfferDisplay = ({
  grossOffer,
  netOffer,
  maxOffer,
  rangeDays,
  calculatedPrice,
  debts,
  advancedPayment,
  setAdvancedPayment,
  clientData,
  totalAll,
  setTotalAll,
  setTotalDebts,
  totalDebts,
  stockPrice,
  isValidProfit,
  holdedNetOffer,
  allowedByAdmin,
  authorizedByRef,
  quoteCleaned
}) => {
  console.log(clientData);

  useEffect(() => {
    const calc = () => {
      const total = Object.values({
        taxes: clientData.taxes,
        commission: clientData.commission,
        cfdiCost: clientData.cfdiCost,
        aguaTax: 0,
        luzTax: 0,
        predialTax: 0,
        mantenimientoTax: 0
      })
        .filter((d) => d !== "" && Number(d) !== 0)
        .reduce((suma, val) => suma + Number(val), 0);

      setTotalAll(total);
    };

    calc();
    //eslint-disable-next-line
  }, [
    debts,
    clientData.taxes,
    clientData.commission,
    clientData.debtCredit,
    clientData.cfdiCost,
    netOffer
  ]);

  useEffect(() => {
    const calc = () => {
      const total = Object.values({
        agua: debts.agua,
        /* aguaTax: rangeDays > 180 ? debts.agua * (debts.aguaTax / 100) : 0, */
        luz: debts.luz,
        /* luzTax: rangeDays > 180 ? debts.luz * (debts.luzTax / 100) : 0, */
        predial: debts.predial,
        /* predialTax:
          rangeDays > 180 ? debts.predial * (debts.predialTax / 100) : 0, */
        mantenimiento: debts.mantenimiento
        /* mantenimientoTax:
          rangeDays > 180
            ? debts.mantenimiento * (debts.mantenimientoTax / 100)
            : 0 */
      })
        .filter((d) => d !== "" && Number(d) !== 0)
        .reduce((suma, val) => suma + Number(val), 0);

      setTotalDebts(total);
    };

    calc();
  }, [debts, advancedPayment, clientData, setTotalDebts, rangeDays, netOffer]);

  useEffect(() => {
    console.log("Entro");

    let temp = calcCommission(
      Number(advancedPayment.dayOfRequest),
      Number(advancedPayment.required),
      rangeDays
    );

    setAdvancedPayment({
      ...advancedPayment,
      commissionPercentage: temp.netCommissionPercentage,
      commissionAmount: temp.netCommissionAmount,
      total: temp.total
    });
    //eslint-disable-next-line
  }, [
    advancedPayment.dayOfRequest,
    advancedPayment.required,
    rangeDays,
    netOffer
  ]);

  const precioInventario = stockPrice;
  const interesAdelanto = advancedPayment?.commissionAmount;

  const interesDeudas =
    rangeDays > 180
      ? Object.values({
          aguaTax: (debts.agua * debts.aguaTax) / 100,
          luzTax: (debts.luz * debts.luzTax) / 100,
          predialTax: (debts.predial * debts.predialTax) / 100,
          mantenimientoTax: (debts.mantenimiento * debts.mantenimientoTax) / 100
        })
          .filter((d) => d !== "" && Number(d) !== 0)
          .reduce((suma, val) => suma + Number(val), 0)
      : 0;

  const interesLiquidarCredito =
    Number(clientData.debtCreditAmount) * Number(clientData.debtCreditRate);
  const utilidadTotal =
    interesLiquidarCredito +
    interesDeudas +
    interesAdelanto +
    (precioInventario - calculatedPrice) -
    27500;

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={6}></Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Intervalo:{" "}
            </Typography>
            {rangeDays} Días{"  "}(
            {intlFormat(
              addDays(new Date(), rangeDays),
              {
                month: "short",
                day: "numeric",
                year: "2-digit"
              },
              {
                locale: "es-MX"
              }
            )}
            )
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Oferta Máxima a 365:{" "}
            </Typography>
            {stringToCurrency(maxOffer)}
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Intereses Crédito:{" "}
            </Typography>
            {stringToCurrency(
              Number(clientData.debtCreditAmount) *
                Number(clientData.debtCreditRate)
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Oferta Bruta:{" "}
            </Typography>
            {stringToCurrency(calculatedPrice)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Intereses Adelanto:{" "}
            </Typography>
            {stringToCurrency(advancedPayment?.commissionAmount)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Porcentaje Comisión Adelanto:{" "}
            </Typography>
            {(advancedPayment.commissionPercentage * 100).toFixed(2)}%
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Intereses servicios:{" "}
            </Typography>
            {stringToCurrency(interesDeudas)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Total Adeudos:{" "}
            </Typography>
            {stringToCurrency(totalDebts)}
          </Typography> */}
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Total Deducciones:{" "}
            </Typography>
            {stringToCurrency(
              Number(totalAll) +
                Number(clientData.debtCreditAmount) +
                Number(totalDebts) +
                Number(
                  advancedPayment.total - advancedPayment?.commissionAmount
                ) +
                Number(debts?.rehabilitation)
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Utilidad Total:{" "}
            </Typography>
            {utilidadTotal > 0 && grossOffer > 0 && rangeDays >= 0
              ? (() => {
                  if (
                    rangeDays === 0 &&
                    parseFloat(utilidadTotal / grossOffer).toFixed(4) < 0.23
                  ) {
                    isValidProfit.current = false;
                    return (
                      <Fade in timeout={500}>
                        <Typography
                          variant="h6"
                          component={"span"}
                          color="error"
                          fontWeight={"bold"}
                        >
                          Invalido inferior al 23%
                        </Typography>
                      </Fade>
                    );
                  } else if (
                    rangeDays === 90 &&
                    parseFloat(utilidadTotal / grossOffer).toFixed(4) < 0.2
                  ) {
                    isValidProfit.current = false;
                    return (
                      <Fade in timeout={500}>
                        <Typography
                          variant="h6"
                          component={"span"}
                          color="error"
                          fontWeight={"bold"}
                        >
                          Invalido inferior al 20%
                        </Typography>
                      </Fade>
                    );
                  } else if (
                    rangeDays === 180 &&
                    parseFloat(utilidadTotal / grossOffer).toFixed(4) < 0.18
                  ) {
                    isValidProfit.current = false;
                    return (
                      <Fade in timeout={500}>
                        <Typography
                          variant="h6"
                          component={"span"}
                          color="error"
                          fontWeight={"bold"}
                        >
                          Invalido inferior al 18%
                        </Typography>
                      </Fade>
                    );
                  } else if (
                    rangeDays === 270 &&
                    parseFloat(utilidadTotal / grossOffer).toFixed(4) < 0.14
                  ) {
                    isValidProfit.current = false;
                    return (
                      <Fade in timeout={500}>
                        <Typography
                          variant="h6"
                          component={"span"}
                          color="error"
                          fontWeight={"bold"}
                        >
                          Invalido inferior al 14%
                        </Typography>
                      </Fade>
                    );
                  } else if (
                    rangeDays === 365 &&
                    Number(
                      (utilidadTotal -
                        (precioInventario - calculatedPrice) +
                        27500) /
                        grossOffer
                    ) < 0.02
                  ) {
                    console.log(
                      Boolean(
                        Math.abs(
                          parseFloat(utilidadTotal / grossOffer).toFixed(4)
                        ).toString() !== "0.1"
                      )
                    );

                    if (!allowedByAdmin) {
                      /* if (quoteCleaned && !authorizedByRef) { */
                      isValidProfit.current = false;

                      if (quoteCleaned.current) {
                        return (
                          <Fade in timeout={500}>
                            <Typography
                              variant="h6"
                              component={"span"}
                              color="error"
                              fontWeight={"bold"}
                            >
                              Invalido inferior al 12%
                              {/* {(utilidadTotal -
                              (precioInventario - calculatedPrice) +
                              27500) /
                              grossOffer +
                              "-" +
                              Number(utilidadTotal / grossOffer).toFixed(2) +
                              "-" +
                              Boolean(
                                Math.abs(
                                  parseFloat(utilidadTotal / grossOffer).toFixed(
                                    4
                                  )
                                ).toString() !== String(parseFloat("0.10"))
                              )} */}
                            </Typography>
                          </Fade>
                        );
                      } else {
                        isValidProfit.current = true;
                        return stringToCurrency(utilidadTotal);
                      }
                    } else {
                      isValidProfit.current = true;
                      return stringToCurrency(utilidadTotal);
                    }
                  } else {
                    console.log(
                      Boolean(
                        Math.abs(
                          parseFloat(utilidadTotal / grossOffer).toFixed(4)
                        ).toString() !== "0.1"
                      )
                    );
                    isValidProfit.current = true;
                    return stringToCurrency(utilidadTotal) /* +
                      "-" +
                      Number(
                        (utilidadTotal -
                          (precioInventario - calculatedPrice) +
                          27500) /
                          grossOffer
                      )
                        .toFixed(6)
                        .toString() +
                      "%" */;
                  }
                })()
              : "$0"}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Rehabilitación:{" "}
            </Typography>
            {stringToCurrency(debts?.rehabilitation)}
          </Typography> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h6"
            color="secondary"
            textAlign={"start"}
            width={"100%"}
          >
            <Typography
              variant="h6"
              fontSize={17}
              component={"span"}
              color="secondary"
              fontWeight={"bold"}
            >
              Margen de ganancia:
            </Typography>
            {calculatedPrice && utilidadTotal && grossOffer
              ? Number.parseFloat(utilidadTotal / grossOffer).toFixed(4) >= 0.1
                ? decimalToPercentage(Number(utilidadTotal / grossOffer))
                : "Invalido"
              : "0%"}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        {calculatedPrice !== "" && Number(calculatedPrice) !== 0 && (
          <Grid item xs={12}>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              color="secondary"
              textAlign={"center"}
              width={"100%"}
              mt={3}
            >
              <Typography
                variant="h5"
                component={"span"}
                color="primary"
                fontWeight={"bold"}
              >
                Saldo final:{" "}
              </Typography>
              {netOffer >= 0 ? (
                Number.parseFloat(utilidadTotal / grossOffer).toFixed(4) <
                  0.1 || Number(utilidadTotal) < 0 ? (
                  "Invalido"
                ) : isValidProfit.current ? (
                  stringToCurrency(netOffer)
                ) : (
                  <Fade in timeout={500}>
                    <Typography
                      variant="h6"
                      component={"span"}
                      color="error"
                      fontWeight={"bold"}
                    >
                      {stringToCurrency(netOffer)}
                    </Typography>
                  </Fade>
                )
              ) : (
                <Fade in timeout={500}>
                  <Typography
                    variant="h6"
                    component={"span"}
                    color="error"
                    fontWeight={"bold"}
                  >
                    {stringToCurrency(netOffer)}
                  </Typography>
                </Fade>
              )}
            </Typography>
            {/* {advancedPayment?.required > 0 && rangeDays > 0 && (
              <Typography
                variant="h6"
                fontWeight={"bold"}
                color="secondary"
                textAlign={"center"}
                width={"100%"}
                mt={3}
              >
                <Typography
                  variant="h6"
                  component={"span"}
                  color="primary"
                  fontWeight={"bold"}
                >
                  Saldo retenido:{" "}
                </Typography>
                {stringToCurrency(holdedNetOffer)}
              </Typography>
            )} */}
          </Grid>
        )}
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </Box>
  );
};

export default OfferDisplay;
