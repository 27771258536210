import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const updateDocument = async (token, documentId, data) => {
  const body = { documentations: data };

  try {
    const response = axios.put(
      `${REACT_APP_BASE_URL}/documentations/${documentId}`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
