import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import { getPropertyById } from "../../../api/properties/getPropertyById";
import TokenContext from "../../../context/Token";
import EditPropertyContainer from "./EditPropertyContainer";

const EditPropertyModal = ({
  open,
  onCloseModal,
  showFeedbackMessage,
  refetchInvetoryProperties
}) => {
  const { token } = useContext(TokenContext);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get("propertyId");
  const { data, isLoading, refetch } = useQuery(
    ["getPropertyById", propertyId],
    () => getPropertyById(token, propertyId),
    { enabled: !!propertyId, cacheTime: 0 }
  );

  console.log(data?.data?.property);
  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      maxWidth="xl"
      sx={{
        borderRadius: ".5rem",
        width: "100%",
        height: "100%"
      }}
      fullWidth
    >
      {isLoadingDialog && (
        <Box
          style={{
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "#00000034"
          }}
        >
          <CircularProgress size={64} />
        </Box>
      )}
      <DialogTitle id={"error-success-title"} p={1}>
        Editar propiedad
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!isLoading && (
          <EditPropertyContainer
            onCloseModal={onCloseModal}
            setIsLoadingDialog={setIsLoadingDialog}
            isLoadingDialog={isLoadingDialog}
            selectedProperty={data?.data?.property}
            isLoadingSelectedProperty={isLoading}
            showFeedbackMessage={showFeedbackMessage}
            refetchInvetoryProperties={refetchInvetoryProperties}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EditPropertyModal;
