import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useState } from "react";

import InventoryCategories from "../../components/Inventory/InventoryCategories";
import { useModal } from "../../hooks/useModal";
import InventoryContainer from "./InventoryContainer";
import PreInventoryContainer from "./PreInventoryContainer";

const Inventory = () => {
  const { open, onCloseModal, onOpenModal } = useModal();
  const [value, setValue] = useState({ selectTab: "" });
  const [isUpdating, setIsUpdating] = useState(false);

  const handleSelect = (type, target, id) => {
    setValue({ ...value, [type]: target, id: id });
  };

  // console.log(value.selectTab)
  return (
    <section className="flex-center px-5">
      <InventoryCategories
        // totalLength={data?.properties?.length}
        onSelect={handleSelect}
        currentTab={value.selectTab}
      />
      <Grid container justifyContent={"flex-end"}>
        <Button
          variant="contained"
          color="primary"
          sx={{ margin: 0 }}
          onClick={onOpenModal}
        >
          Registrar propiedad
        </Button>
      </Grid>
      {value.selectTab !== "Pre-inventario" ? (
        <InventoryContainer
          selectTab={value.selectTab}
          isUpdating={isUpdating}
          openCreate={open}
          onCloseCreateModal={onCloseModal}
          onOpenCreateModal={onOpenModal}
        />
      ) : (
        <PreInventoryContainer />
      )}
    </section>
  );
};

export default Inventory;
