import { Button } from "@mui/material";

import ModalNewVisit from "../../components/Modals/modalNewVisit/ModalNewVisit";

const NewVisit = (props) => {
  return (
    <>
      <Button
        variant="contained"
        sx={{ mt: 0, ml: 5 }}
        data-bs-toggle="modal"
        data-bs-target={"#newVisitModal"}
      >
        Nueva Visita
      </Button>

      <ModalNewVisit refetchVisits={props.refetchVisits} />
    </>
  );
};

export default NewVisit;
