import { Outlet } from "react-router-dom";

import Sidebar from "../../components/BuySidebar";

const ComprarTemplate = () => {
  return (
    <section className="d-flex">
      <Sidebar />
      <Outlet />
    </section>
  );
};

export default ComprarTemplate;
