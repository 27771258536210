import { Button, Typography } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

import { stringToDMY } from "../../helpers/dates";
import ClientInfo from "../InfoFields/ClientInfo";
import DatesInfo from "../InfoFields/DatesInfo";
import GestionActions from "../InfoFields/GestionActions";
import OfferInfo from "../InfoFields/OfferInfo";
import ProceedingActions from "../InfoFields/ProceedingActions";
import ProfileActions from "../InfoFields/ProfileActions";
import PropertyInfo from "../InfoFields/PropertyInfo";
// import ProspectsActions from "../InfoFields/ProspectsActions";
// import SignatureActions from "../InfoFields/SignatureActions";
// import VisitDateAndMedia from "../InfoFields/VisitDateAndMedia";
import Observations from "../Observations";
import PriorityButton from "../Profile/PriorityButton";

const TableBody = (props) => {
  return (
    <tbody>
      {props?.properties &&
        props.properties?.map((property) => {
          // console.log(property)
          return (
            <tr key={property._id}>
              {/* PRIMERA COLUMNA DE LA TABLA */}

              {
                //PERFILADOS - PRIORIDAD
                props?.tableType === "Perfilado" && (
                  <>
                    <td>
                      <Typography
                        variant="body1"
                        color="secondary"
                        fontWeight={"bold"}
                      >
                        {property?.lead}
                      </Typography>
                      {/* <PriorityButton
                      priority={property?.priority ?? ""}
                      propertyId={property?._id}
                      refetchProperties={props?.refetchProperties}
                      counterRefetch={props?.counterRefetch}
                    /> */}
                    </td>
                    <td>
                      <Typography
                        variant="body1"
                        color="secondary"
                        fontWeight={"bold"}
                      >
                        {property?.preSeller ? "Incompleto" : "Completo"}
                      </Typography>
                    </td>
                  </>
                )
              }
              {
                //PROSPECTOS - TIPO DE CREDITO
                props?.tableType === "Prospectos" && (
                  <td style={{ width: 100 }}>
                    <label className="property-title mt-4">
                      {" "}
                      {property?.creditType ?? " No Especificado"}
                    </label>
                  </td>
                )
              }
              {
                //EXPEDIENTE/GESTION - ID DE LA PROPIEDAD
                props?.tableType === "Expediente" ||
                props?.tableType === "Gestion" ||
                props?.tableType === "Firma" ||
                props?.tableType === "Cita" ? (
                  <td style={{ width: 155 }}>
                    <label
                      className="value-title mt-4 text-uppercase"
                      style={{ fontSize: 14 }}
                    >
                      {" "}
                      {property?._id ?? "N/A"}
                    </label>
                  </td>
                ) : null
              }

              {/* SEGUNDA COLUMNA DE LA TABLA */}
              {props?.tableType === "Cita" ? (
                <td style={{ width: 130 }}>
                  <Typography variant="body1" color="secondary">
                    {property?.appointments.length > 0
                      ? moment
                          .utc(
                            property?.appointments[
                              property?.appointments?.length - 1
                            ]?.date
                          )
                          .format("DD/MM/YYYY hh:mm a")
                      : "N/A"}
                  </Typography>
                </td>
              ) : null}
              {
                //FECHA Y HORA DE CREACION
                props?.tableType === "Perfilado" ||
                props?.tableType === "Prospectos" ? (
                  <td style={{ width: 130 }}>
                    <DatesInfo createdAt={property?.createdAt} />
                  </td>
                ) : null
              }
              {
                //PRECIO DE LA PROPIEDAD
                props?.tableType === "Expediente" ||
                props?.tableType === "Gestion" ||
                props?.tableType === "Firma" ? (
                  <td style={{ width: 300 }}>
                    <OfferInfo property={property} />
                  </td>
                ) : null
              }

              {/* TERCERA COLUMNA DE LA TABLA  */}

              <td style={{ width: 200 }}>
                <ClientInfo sellerRef={property?.sellerRef} />
              </td>

              {/* CUARTA COLUMNA DE LA TABLA */}

              <td style={{ width: "300px" }}>
                <PropertyInfo property={property} />
              </td>

              {/* QUINTA COLUMNA DE LA TABLA */}

              <td style={{ width: 100 }}>
                <label className="property-title mt-4">
                  {" "}
                  {property?.sellerRef?.source ?? ""}{" "}
                </label>
              </td>

              {/* SEXTA COLUMNA DE LA TABLA */}
              {props?.tableType === "Cita" ? (
                <td style={{ width: 130 }}>
                  <Typography
                    variant="body1"
                    color="secondary"
                    mt={3}
                    fontWeight={"bold"}
                  >
                    {property?.appointments.length > 0 &&
                      property?.appointments[property?.appointments?.length - 1]
                        ?.status}
                  </Typography>
                </td>
              ) : null}
              {props?.tableType === "Gestion" && (
                <td style={{ width: 250 }}>
                  <Observations
                    property={property}
                    editable={false}
                    refetchProperties={props?.refetchProperties}
                  />
                </td>
              )}
              {props?.tableType === "Prospectos" && (
                <td style={{ width: 300 }}>
                  <OfferInfo property={property} />
                </td>
              )}

              {/* SEPTIMA COLUMNA DE LA TABLA */}
              <td>
                {props?.tableType === "Perfilado" && (
                  <ProfileActions
                    property={property}
                    refetchProperties={props?.refetchProperties}
                  />
                )}
                {
                  //botones de acciones para prospectos (generar oferta, aceptar oferta, reconversion)
                  props?.tableType === "Prospectos" && (
                    <Link to={`/ctc/compra/propiedad/${property?._id}/cliente`}>
                      <Button variant="contained">Ver Más</Button>
                    </Link>
                    // <ProspectsActions
                    //   property={property}
                    //   refetchProperties={props.refetchProperties}
                    // />
                  )
                }
                {props?.tableType === "Expediente" && (
                  <ProceedingActions
                    status={"Expediente"}
                    property={property}
                    refetchProperties={props?.refetchProperties}
                  />
                )}
                {props?.tableType === "Gestion" && (
                  <GestionActions
                    property={property}
                    refetchProperties={props?.refetchProperties}
                  />
                )}
                {props?.tableType === "Firma" && (
                  <Link
                    to={`/ctc/compra/propiedad/${property?._id}/firma#firma`}
                  >
                    <Button variant="contained">Ver Más</Button>
                  </Link>
                )}
                {props?.tableType === "Cita" && (
                  <Link to={`/ctc/compra/propiedad/${property?._id}/cita`}>
                    <Button variant="contained">Ver Más</Button>
                  </Link>
                )}
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default TableBody;
