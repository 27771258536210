import { calcOffer } from "../../helpers/calcOffer";
import { stringToCurrency } from "../../helpers/currencies";
import ToHistoryButton from "../ToHistoryButton";

const OfferInfo = (props) => {
  return (
    <div className="info-extend text-start">
      <label className="property-title">
        Valor Cotizador:
        <label className="value-title ms-2">
          {props?.property?.quote
            ? stringToCurrency(props?.property?.quote)
            : " N/A"}
        </label>
      </label>
      <label className="property-title">
        Última Oferta:
        <label className="value-title ms-2">
          {props?.property?.offer
            ? stringToCurrency(props?.property?.offer)
            : " N/A"}
        </label>
      </label>
      <label className="property-title">
        Oferta Máxima (365 días):
        <label className="value-title ms-2">
          {stringToCurrency(props?.property?.offer ?? props?.property?.quote) ??
            " N/A"}
        </label>
      </label>
      {props?.property?.interval && (
        <label className="property-title">
          Oferta con Intervalo ({props?.property?.interval} días):
          <label className="value-title ms-2">
            {stringToCurrency(
              calcOffer(
                props?.property?.interval,
                props?.property?.offer ?? props?.property?.quote
              )
            ) ?? " N/A"}
          </label>
        </label>
      )}
      {props.property?.debtCredit && (
        <label className="property-title">
          Deuda Crédito:
          <label className="value-title ms-2">
            {stringToCurrency(props.property.debtCredit) ?? " N/A"}
          </label>
        </label>
      )}

      {props.property?.offerPercentage && (
        <label className="property-title">
          Aumento de oferta (reconversion):
          <label className="value-title ms-2">
            {Math.round((props.property.offerPercentage - 1) * 100) + "%" ??
              " N/A"}
          </label>
        </label>
      )}

      {props.property?.netOffer && (
        <label className="property-title">
          Oferta Neta:
          <label className="value-title ms-2">
            {stringToCurrency(props.property.netOffer) ?? " N/A"}
          </label>
        </label>
      )}
      <ToHistoryButton
        id={props?.property?._id}
        property={props.property ?? {}}
      />
    </div>
  );
};

export default OfferInfo;
