import { Alert, Box, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { useQuery } from "react-query";

import { assignRehabilitator } from "../../../../api/Rehabilitations/assingRehabilitator";
import { createRehabilitator } from "../../../../api/Rehabilitations/createRehabilitator";
import { getRehabilitatorsList } from "../../../../api/Rehabilitations/getRehabilitatorsList";
import { rehabilitatorsList } from "../../../../cacheQueries/cacheQueries";
import Loading from "../../../../components/loading/Loading";
import TokenContext from "../../../../context/Token";
import AddWorker from "./modals/AddWorker";

const RehabilitatorList = ({ rehabilitator = {}, propertyId }) => {
  const { token, userType } = useContext(TokenContext);
  const [message, setMessage] = useState({ severity: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [inspectorState, setInspectorState] = useState(rehabilitator._id ?? "");
  const { data, isLoading, refetch } = useQuery([rehabilitatorsList], () =>
    getRehabilitatorsList(token)
  );

  const assignRehab = async (e) => {
    setLoading(true);
    try {
      await assignRehabilitator(token, propertyId, e.target.value);
      setLoading(false);
      setMessage({ severity: "success", text: "Actualizado" });
      refetch();
      setInspectorState(e.target.value);
      setTimeout(() => {
        setMessage({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      setLoading(false);
      setMessage({ severity: "error", text: error });
      refetch();
      setTimeout(() => {
        setMessage({ severity: "", text: "" });
      }, 3000);
    }
  };

  if (isLoading) return <Loading />;
  return (
    <Box display={"flex"} gap={1}>
      <Box display={"flex"} width={200} flexDirection={"column"}>
        <TextField
          disabled={loading}
          fullWidth
          size="small"
          sx={{ width: 200 }}
          name="rehabilitator"
          label={loading ? "Actualizando..." : "Rehabilitador"}
          select
          value={inspectorState}
          onChange={assignRehab}
        >
          {data?.data?.rehabilitators?.map((i) => (
            <MenuItem key={i._id} value={i._id}>
              {i?.name}
              {i.fLastName ? ` ${i.fLastName}` : ""}
              {i.mLastName ? ` ${i.mLastName}` : ""}
            </MenuItem>
          ))}
        </TextField>
        {message.severity !== "" && (
          <Alert severity={message.severity}>{message.text}</Alert>
        )}
      </Box>
      {userType === "Admin" && (
        <AddWorker
          workerType={"rehabilitador"}
          creationFunction={createRehabilitator}
          refetch={refetch}
        />
      )}
    </Box>
  );
};

export default RehabilitatorList;
