import { setDate, setMonth } from "date-fns";

export const defaultFilter = {
  /* name: "",
  phoneNumber: "", */
  adviserRef: "",
  postalCode: "",
  settlement: "",
  state: "",
  dateStart: setMonth(setDate(new Date(), 1), 0),
  dateEnd: new Date(),
  preSeller: false,
  multiSearch: ""
};
