import { Button } from "@mui/material";
import { Link } from "react-router-dom";

// import { updateProperties } from "../../api/properties/updateProperties";
// import TokenContext from "../../context/Token";
// import { useModal } from "../../hooks/useModal";
// import FileIntegration from "../Modals/FileIntegration/FileIntegration";
// import CommentSection from "../Proceeding/CommentSection";
// import NoPerfiladoButton from "../Profile/NoPerfiladoButton";

//PROPS DEL COMPONENTE: property
const ProceedingActions = (props) => {
  // const { token } = useContext(TokenContext);
  // const navigate = useNavigate();
  // const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <div>
      <Link
        to={`/ctc/compra/propiedad/${props?.property?._id}/expediente#expediente`}
      >
        <Button variant="contained">Ver Más</Button>
      </Link>
    </div>
  );
};

export default ProceedingActions;
