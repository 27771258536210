import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Alert, Box, Button, IconButton, Snackbar } from "@mui/material";
import { useContext, useRef, useState } from "react";

import { updateDocuments } from "../../../api/properties/updateDocuments";
import {
  getDownloadFileUrl,
  getUploadFileUrl,
  uploadFile
} from "../../../api/utilsApi";
import TokenContext from "../../../context/Token";

function toNormalForm(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const OfferDocsButton = ({
  refetch,
  type, //el valor se mandará por la funcion sendData para saber qué valor enviar por la api
  title, //texto que sale en el botón
  doc = null, //si ya hay un documento muestra el icono para visualizarlo
  property,
  setIsUploadingFiles
}) => {
  const style = {
    button: {
      fontWeight: 600,
      fontSize: 18,
      color: "gray",
      textDecoration: "underline"
    }
  };
  const { token } = useContext(TokenContext);
  const [inputDoc, setInputDoc] = useState(doc ?? null);
  const inputRef = useRef(null);
  const [success, setSuccess] = useState({ severity: "", text: "" });

  const handleOpenFile = () => inputRef.current.click();
  const handleFileChange = async (e) => {
    setIsUploadingFiles(true);
    try {
      if (e?.target?.files && e?.target?.files[0]) {
        const { url, signedUrl } = await getUploadFileUrl(
          token,
          `tmp/properties/${property._id}/docs`,
          toNormalForm(
            e.target.files[0]?.name?.trim()?.replaceAll(" ", "-").toLowerCase()
          )
        );

        await uploadFile(signedUrl, e.target.files[0]);

        const response = await updateDocuments(token, property._id, {
          type,
          path: url
        });

        setSuccess({ severity: "success", text: "Documento actualizado" });
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
        setInputDoc(e.target.files[0]);
        console.log(response);
        setIsUploadingFiles(false);
        refetch();
      }
    } catch (error) {
      console.log(error);
      setIsUploadingFiles(false);
      setSuccess({ severity: "error", text: "Error al actualizar documento" });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  const downloadFile = async (key, fileName) => {
    setIsUploadingFiles(true);
    console.log(key);

    const downloadUrl = await fetch(key, { mode: "cors" })
      .then((res) => res.blob())
      .then((data) => {
        var a = document.createElement("a");

        a.href = window.URL.createObjectURL(data);
        a.target = "_blank";
        a.download = fileName;
        a.click();
        setIsUploadingFiles(false);
      });

    console.log(downloadUrl);
  };

  return (
    <Box display={"flex"} flexDirection="column">
      <Button sx={style.button} color="secondary" onClick={handleOpenFile}>
        {title ?? "texto Default"}
      </Button>
      <input
        type="file"
        hidden
        ref={inputRef}
        onChange={handleFileChange}
        accept=".pdf, image/*"
      />
      {inputDoc && (
        <Box>
          <IconButton
            color="primary"
            onClick={() => {
              downloadFile(
                property?.documents.find((d) => d.type === type)?.path,
                "cumplimiento-" +
                  toNormalForm(
                    property?.documents
                      .find((d) => d.type === type)
                      .type.replace(" ", "-")
                      ?.toLowerCase()
                  )
              );
            }}
          >
            <RemoveRedEyeIcon color="inherit" />
          </IconButton>
        </Box>
      )}
      {success.severity !== "" && (
        <Snackbar
          open={success.severity !== ""}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={success.severity}>{success.text}</Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default OfferDocsButton;
