export const calcCommission = (
  dayOfRequest, //se cuenta desde el primer día del intervalo hasta el día de solicitud
  required, //cantidad solicitada por el cliente
  rangeDays
) => {
  const netCommissionPercentage = rangeDays > 180 ? 0.3 : 0.15;
  const netCommissionAmount = netCommissionPercentage * required;
  const total = netCommissionAmount + required;

  return {
    netCommissionAmount,
    netCommissionPercentage,
    total
  };
};
