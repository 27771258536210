import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const deleteDebt = async (token, debtId) => {
  try {
    const response = await axios.delete(
      `${REACT_APP_BASE_URL}/debts/${debtId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err?.errors[0]?.msg
        : err.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
