import { Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";

import UploadVideo from "../../../components/Signature/UploadVideo";
import VisitDate from "../../../components/Signature/VisitDate";
import Gallery from "./gallery/Gallery";
import InspeccionModal from "./inspeccionModal/InspeccionModal";
import InspectorList from "./Lists/InspectorList";
import MonitorsList from "./Lists/MonitorList";
import RehabilitatorList from "./Lists/RehabilitatorList";
import UploadImageV2 from "./UploadImageV2";

const { REACT_APP_IMAGES_URL } = process.env;
const InfoInspection = ({
  propertyId,
  inspection = {},
  refetch,
  path_video,
  rehabilitationApproved,
  changeRehabilitationApproved,
  visitDate
}) => {
  return (
    <>
      <Box display={"flex"} my={5}>
        <Typography
          className="blue-text"
          variant="h5"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Inspección
        </Typography>
        <InspeccionModal
          evidence={inspection.evidence ?? []}
          generalComment={inspection?.comments ?? ""}
          modalType="inspection"
          title={"Evidencias de Inspección"}
          rehabilitationApproved={rehabilitationApproved}
          changeRehabilitationApproved={changeRehabilitationApproved}
          propertyId={propertyId}
          refetch={refetch}
        />
        <Box display={"flex"} ml={15}>
          <VisitDate
            visitDate={visitDate ?? ""}
            id={propertyId}
            refetchProperties={refetch}
          />
        </Box>
      </Box>
      <Box display={"flex"} gap={5} height={110}>
        <InspectorList
          inspector={inspection.inspector ?? {}}
          propertyId={propertyId}
          refetch={refetch}
        />
        <MonitorsList
          inspector={inspection?.monitoring?.inspector ?? ""}
          propertyId={propertyId}
          refetch={refetch}
        />
        <RehabilitatorList
          rehabilitator={inspection.rehabilitator ?? {}}
          propertyId={propertyId}
          refetch={refetch}
        />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"}>
          <UploadImageV2 propertyId={propertyId} refetch={refetch} />
          <UploadVideo id={propertyId} refetch={refetch} />
          <Box display={"flex"} flexDirection={"column"} ml={2}>
            <Typography variant="body1" color="secondary" fontWeight="bold">
              El archivo debe pesar menos de 50MB
            </Typography>
            {path_video && (
              <>
                <a
                  href={`${REACT_APP_IMAGES_URL}/${path_video}`}
                  target="_blank"
                  rel="noreferrer"
                  className="my-auto ms-2"
                >
                  <button className="btn-fill-orange"> Ver Video</button>
                </a>
              </>
            )}
          </Box>
        </Box>
        <Box mr={{ md: 18 }}>
          <Rating
            name={"rating"}
            value={inspection?.score ?? 0}
            size="large"
            readOnly
          />
        </Box>
      </Box>
      <Gallery evidence={inspection?.evidence ?? []} />
    </>
  );
};

export default InfoInspection;
