import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  Select,
  Button,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Autocomplete,
  InputAdornment
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useMutation } from "react-query";

import { deleteMarketplaceProperty } from "../../../api/properties/deleteMarketplaceProperty";
import { updateMarketplaceProperty } from "../../../api/properties/updateMarketplaceProperty";
import {
  convertMarketplaceImages,
  getUploadFileUrl,
  uploadFile
} from "../../../api/utilsApi";
import TokenContext from "../../../context/Token";
import {
  AMENITIES,
  MEXICO_STATES,
  NumericFormatCustom,
  getRandomId,
  toNormalForm,
  useCities,
  useSettlements
} from "./CreatePropertyContainer";
import ImageUpload from "./DropDownImage";
import NumberInputIncrement from "./NumberInputIncrement";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const EditPropertyContainer = ({
  setIsLoadingDialog,
  selectedProperty,
  showFeedbackMessage,
  onCloseModal,
  refetchInvetoryProperties
}) => {
  const { token } = useContext(TokenContext);
  const [files, setFiles] = useState([]);
  const imagesFetched = useRef(false);
  const [property, setProperty] = useState({
    state: selectedProperty?.state || "",
    city: selectedProperty?.city || "",
    settlement: selectedProperty?.settlement || "",
    postalCode: selectedProperty?.postalCode || "",
    type: selectedProperty?.type || "",
    street: selectedProperty?.address?.street || "",
    numExt: selectedProperty?.address?.numExt || "",
    numInt: selectedProperty?.address?.numInt || "",
    mLand: selectedProperty?.address?.mLand || "",
    mBuilding: selectedProperty?.address?.mBuilding || "",
    parkingLot: selectedProperty?.address?.parkingLot || 0,
    bedrooms: selectedProperty?.address?.bedrooms || 0,
    washroom: selectedProperty?.address?.washroom || 0,
    halfBaths: selectedProperty?.address?.halfBaths || 0,
    levels: selectedProperty?.address?.levels || 0,
    floor: selectedProperty?.address?.floor || 0,
    lat: selectedProperty?.address?.coordinates?.lat || "",
    lng: selectedProperty?.address?.coordinates?.lng || "",
    cost: selectedProperty?.quote || "",
    description: selectedProperty?.description || "",
    invetoryStatus: selectedProperty?.invetoryStatus || "",
    amenities: selectedProperty?.address?.amenities || []
  });
  const citiesList = useCities(property.state);
  const settlementsList = useSettlements(property.state, property.city);

  const mutation = useMutation(updateMarketplaceProperty, {
    onSuccess: (data) => {
      // Handle success (e.g., show a success message or redirect)
      console.log("Property updated:", data);
      refetchInvetoryProperties();
      showFeedbackMessage({
        message: "Propiedad actualizada",
        type: "success"
      });
      setIsLoadingDialog(false);
    },
    onError: (error) => {
      // Handle error (e.g., show an error message)
      console.error("Error updating property:", error);
      console.log(error);
      showFeedbackMessage({ message: "Ocurrió un error", type: "error" });
      setIsLoadingDialog(false);
    }
  });

  const mutationDelete = useMutation(deleteMarketplaceProperty, {
    onSuccess: async (data) => {
      // Handle success (e.g., show a success message or redirect)
      console.log("Property deleted:", data);
      showFeedbackMessage({
        message: "Propiedad Eliminada",
        type: "success"
      });
      await refetchInvetoryProperties();
      setTimeout(() => {
        onCloseModal(true);
        setIsLoadingDialog(false);
      }, 2000);
    },
    onError: (error) => {
      // Handle error (e.g., show an error message)
      console.error("Error deleting property:", error);
      console.log(error);
      showFeedbackMessage({ message: "Ocurrió un error", type: "error" });
      setIsLoadingDialog(false);
    }
  });

  useEffect(() => {
    const fetchRemoteImages = async () => {
      if (selectedProperty?.images?.length && !imagesFetched.current) {
        console.log("Fetching remote images...");
        imagesFetched.current = true;

        const filePromises = selectedProperty.images.map((image, index) => {
          return fetch(image.path)
            .then((res) => res.blob())
            .then(
              (blob) =>
                new File([blob], image.path, {
                  type: `image/${image.path.split(".").pop()}`
                })
            );
        });

        Promise.all(filePromises).then((fileObjects) => {
          setFiles(fileObjects);
        });
      }
    };

    fetchRemoteImages();
  }, [selectedProperty?.images, imagesFetched]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingDialog(true);
    try {
      console.log("files");
      console.log(files);

      const { imagesPath, newImages, conservedImages } = await handleImages();

      console.log(imagesPath);
      await mutation.mutate({
        token,
        property: { ...property, imagesPath, newImages, conservedImages },
        propertyId: selectedProperty?._id
      });
    } catch (error) {
      console.log(error);
      showFeedbackMessage({ message: "Ocurrió un error", type: "error" });
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    setIsLoadingDialog(true);
    try {
      await mutationDelete.mutate({
        token,
        propertyId: selectedProperty?._id
      });
    } catch (error) {
      console.log(error);
      showFeedbackMessage({ message: "Ocurrió un error", type: "error" });
      setIsLoadingDialog(false);
    }
  };

  const handlePropertyData = (e) => {
    const { name, value } = e.target;

    if (name === "state") {
      setProperty((prev) => ({
        ...prev,
        state: value,
        city: "",
        settlement: ""
      }));
    } else if (name === "city") {
      setProperty((prev) => ({
        ...prev,
        city: value,
        settlement: ""
      }));
    } else {
      setProperty((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleChangeAmenities = (event) => {
    const {
      target: { value }
    } = event;

    setProperty((prev) => ({
      ...prev,
      amenities: typeof value === "string" ? value.split(",") : value
    }));
  };

  const handleImages = async () => {
    const folderName = getRandomId();
    const path = "cdn/convertImages/" + folderName;
    const newImages = [];
    const conservedImages = [];

    for (const { file } of files) {
      console.log(file);

      if (!file?.name?.includes("marketplace")) {
        const fileName = toNormalForm(file?.name);
        const { url, signedUrl } = await getUploadFileUrl({
          token,
          path,
          fileName
        });

        await uploadFile(signedUrl, file);
        console.log(url, signedUrl);
        newImages.push(path + file?.name);
      } else {
        conservedImages.push(file?.name);
      }
    }
    await convertMarketplaceImages(token, path);
    return { imagesPath: path, newImages, conservedImages };
  };

  console.log(settlementsList);

  return (
    <Grid
      container
      item
      xs={12}
      maxWidth="xl"
      spacing={2}
      sx={{
        mt: 1,
        border: "1px solid rgba(111, 128, 149, 0.6)",
        borderRadius: 2,
        py: 2,
        px: 2
      }}
    >
      <Grid container ml={2}>
        <Grid item md={12}>
          <Typography textAlign={"left"} variant="h5" color="secondary">
            Datos del inmueble:
          </Typography>
        </Grid>
      </Grid>
      {mutation.isLoading && <h5>Loading</h5>}
      <Grid container item xs={12} flexDirection={"row"} spacing={2}>
        <Grid container item xs={6} spacing={2}>
          <FormFields
            property={property}
            setProperty={setProperty}
            handlePropertyData={handlePropertyData}
            citiesList={citiesList}
            settlementsList={settlementsList}
            handleChangeAmenities={handleChangeAmenities}
          />
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Typography variant="h5" mb={1}>
              Imágenes
            </Typography>
            <ImageUpload files={files} setFiles={setFiles} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            gap={4}
          >
            <Button variant="contained" onClick={handleDelete} color="error">
              Eliminar
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Actualizar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FormFields = ({
  property,
  handlePropertyData,
  citiesList,
  settlementsList,
  handleChangeAmenities,
  setProperty
}) => (
  <>
    <Grid item xs={12} md={6}>
      <TextField
        id="cost"
        fullWidth
        size="medium"
        name="cost"
        value={property.cost}
        onChange={handlePropertyData}
        style={{ marginTop: "24px" }}
        label={
          <Typography variant="headline" component="h3">
            Precio
          </Typography>
        }
        InputProps={{
          inputComponent: NumericFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="start">.00</InputAdornment>,
          style: { fontSize: "1.4rem" }
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <InputLabel id="property-type-label">Estatus</InputLabel>
      <Select
        labelId="invetoryStatus"
        id="invetoryStatus"
        fullWidth
        name="invetoryStatus"
        defaultValue=""
        value={property.invetoryStatus}
        onChange={handlePropertyData}
        placeholder="Tipo de Casa"
        style={{ fontSize: "1.4rem" }}
      >
        <MenuItem value="Publicada">Publicada</MenuItem>
        <MenuItem value="No Públicada">No Públicada</MenuItem>
        <MenuItem value="Vendida">Vendida</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={12} md={6}>
      <Autocomplete
        id="state"
        value={property.state}
        onChange={(event, newValue) =>
          handlePropertyData({ target: { name: "state", value: newValue } })
        }
        options={Object.values(MEXICO_STATES)}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            id="state"
            label="Estado"
            variant="outlined"
            placeholder="Seleccionar..."
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Autocomplete
        id="city"
        value={property.city}
        onChange={(event, newValue) =>
          handlePropertyData({ target: { name: "city", value: newValue } })
        }
        options={citiesList}
        disabled={citiesList.length === 0}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            id="city"
            label="Municipio"
            variant="outlined"
            placeholder="Seleccionar..."
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Autocomplete
        value={property.settlement}
        onChange={(event, newValue) =>
          handlePropertyData({
            target: { name: "settlement", value: newValue }
          })
        }
        options={settlementsList}
        disabled={settlementsList.length === 0}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label="Colonia"
            variant="outlined"
            placeholder="Seleccionar..."
          />
        )}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="lat"
        label="Latitud"
        type="number"
        value={property.lat}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="lng"
        label="Longitud"
        type="number"
        value={property.lng}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        size="medium"
        name="street"
        label="Calle"
        value={property.street}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="numInt"
        label="Número Interior"
        value={property.numInt}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="numExt"
        label="Número Exterior"
        value={property.numExt}
        onChange={handlePropertyData}
      />
    </Grid>

    <Grid item xs={12} md={6}>
      <InputLabel id="property-type-label">Tipo de Casa</InputLabel>
      <Select
        labelId="property-type-label"
        id="property-type"
        fullWidth
        name="type"
        defaultValue=""
        value={property.type}
        onChange={handlePropertyData}
        placeholder="Tipo de Casa"
      >
        <MenuItem value="Casa">Casa</MenuItem>
        <MenuItem value="Departamento">Departamento</MenuItem>
        <MenuItem value="Duplex">Dúplex</MenuItem>
        <MenuItem value="pie de casa">Pie de Casa</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={6} md={3} mt={2}>
      <TextField
        fullWidth
        size="medium"
        name="mBuilding"
        label="Construcción"
        type="number"
        value={property.mBuilding}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3} mt={2}>
      <TextField
        fullWidth
        size="medium"
        name="mLand"
        label="Terreno"
        type="number"
        value={property.mLand}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="rooms-input" style={{ textAlign: "center" }}>
        Cuartos
      </InputLabel>
      <NumberInputIncrement
        id="bedrooms"
        name="bedrooms"
        aria-label="Rooms Input"
        min={0}
        max={10}
        value={property.bedrooms}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, bedrooms: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="bathrooms-input" style={{ textAlign: "center" }}>
        Baños
      </InputLabel>
      <NumberInputIncrement
        id="washroom"
        name="washroom"
        aria-label="Bathrooms Input"
        min={0}
        max={10}
        value={property.washroom}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, washroom: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel
        htmlFor="half-bathrooms-input"
        style={{ textAlign: "center" }}
      >
        Medios Baños
      </InputLabel>
      <NumberInputIncrement
        id="halfBaths"
        name="halfBaths"
        aria-label="Half Bathrooms Input"
        min={0}
        max={5}
        value={property.halfBaths}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, halfBaths: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="floors-input" style={{ textAlign: "center" }}>
        Nivel
      </InputLabel>
      <NumberInputIncrement
        id="levels"
        name="levels"
        aria-label="Floors Input"
        min={0}
        max={10}
        value={property.levels}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, levels: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="floors-input" style={{ textAlign: "center" }}>
        Piso
      </InputLabel>
      <NumberInputIncrement
        id="floor"
        name="floor"
        aria-label="Floors Input"
        min={0}
        max={10}
        value={property.floor}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, floor: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="parking-input" style={{ textAlign: "center" }}>
        Estacionamientos
      </InputLabel>
      <NumberInputIncrement
        id="parkingLot"
        name="parkingLot"
        aria-label="Parking Input"
        min={0}
        max={10}
        value={property.parkingLot}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, parkingLot: value }))
        }
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Amenidades</InputLabel>
        <Select
          fullWidth
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={property?.amenities}
          onChange={handleChangeAmenities}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {AMENITIES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={property.amenities.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        size="medium"
        name="description"
        label="Descripción"
        multiline
        rows={4}
        value={property.description}
        onChange={handlePropertyData}
      />
    </Grid>
  </>
);

export default EditPropertyContainer;
