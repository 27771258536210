import { useContext } from "react";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";

//PROPS DEL COMPONENTE: id={props?.data?.propertyRef?._id}
const DeleteLead = (props) => {
  const { token } = useContext(TokenContext);

  const sendData = async () => {
    try {
      const response = await updateProperties(token, props.id, {
        showLead: false
      });

      // console.log(response)
      if (response.showLead === false) {
        // console.log(response.showLead)
        props.refetchReconversion();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <button
      className="btn-disabled lh-sm rounded-pill py-0 px-2"
      style={{ height: 24 }}
      onClick={sendData}
    >
      Eliminar lead
    </button>
  );
};

export default DeleteLead;
