import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField
} from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import { useQuery } from "react-query";

import { getStates } from "../../../api/states/getStates";
import { states } from "../../../cacheQueries/cacheQueries";
import MultiCheckStates from "../../../components/SellerProperty/selects/MultiCheckStates";
import MultiCheckStatus from "../../../components/SellerProperty/selects/MultiCheckStatus";
import TokenContext from "../../../context/Token";

const DrawerOptions = ({
  currentParam,
  setCurrentParam,
  setShownSection,
  paramsArray,
  setParamsArray
}) => {
  const [reason, setReason] = useState("equals");
  const { token } = useContext(TokenContext);
  const statesQuery = useQuery([states], () => getStates(token));

  const handleClick = () => {
    setParamsArray([...paramsArray, currentParam]);
    setCurrentParam({ ...currentParam, criteria: [] });
    setShownSection("cards");
  };
  const handleChange = (e) => {
    setCurrentParam({ ...currentParam, reason: e.target.value });
    setReason(e.target.value);
  };

  const handleBack = () => {
    setShownSection("list");
    setCurrentParam({
      ...currentParam,
      criteria: [],
      reason: "equals",
      operator: ""
    });
  };
  const handleDateChange = (e) => {
    setCurrentParam({
      ...currentParam,
      criteria: [e.target.value]
    });
  };

  return (
    <Box width={"100%"} p={2}>
      <Button sx={{ my: 0 }} onClick={handleBack}>
        Volver
      </Button>
      <Typography variant="h5" color="initial" textAlign={"center"} mb={2}>
        Razón:
      </Typography>
      <RadioGroup
        aria-labelledby="radio-group-reason"
        value={reason}
        onChange={handleChange}
        name="radio-buttons-group"
      >
        <FormControlLabel value="equals" control={<Radio />} label="igual a" />
        {statesQuery.isLoading && (
          <Typography variant="body1" color="primary">
            Cargando...
          </Typography>
        )}
        {reason === "equals" && currentParam.name === "state" && (
          <MultiCheckStates
            list={statesQuery.data?.data?.states ?? []}
            setCurrentParam={setCurrentParam}
            currentParam={currentParam}
          />
        )}
        {reason === "equals" && currentParam.name === "status" && (
          <MultiCheckStatus
            setCurrentParam={setCurrentParam}
            currentParam={currentParam}
          />
        )}
        {reason === "equals" && currentParam.name === "createdAt" && (
          <TextField
            id=""
            label=""
            type="date"
            value={
              currentParam.criteria[0]
                ? currentParam.criteria[0]
                : moment().format("YYYY/MM/DD")
            }
            onChange={handleDateChange}
          />
        )}

        <FormControlLabel
          value="different"
          control={<Radio />}
          label="diferente de"
        />
        {!statesQuery.isLoading ? (
          reason === "different" &&
          currentParam.name === "state" && (
            <MultiCheckStates
              list={statesQuery.data?.data?.states ?? []}
              setCurrentParam={setCurrentParam}
              currentParam={currentParam}
            />
          )
        ) : (
          <Typography variant="body1" color="primary">
            Cargando...
          </Typography>
        )}
        {reason === "different" && currentParam.name === "status" && (
          <MultiCheckStatus
            setCurrentParam={setCurrentParam}
            currentParam={currentParam}
          />
        )}
        {reason === "different" && currentParam.name === "createdAt" && (
          <TextField
            id=""
            label=""
            type="date"
            value={
              currentParam.criteria[0]
                ? currentParam.criteria[0]
                : moment().format("YYYY/MM/DD")
            }
            onChange={handleDateChange}
          />
        )}
        <FormControlLabel
          value="defined"
          control={<Radio />}
          label="valor conocido"
        />
        <FormControlLabel
          value="undefined"
          control={<Radio />}
          label="valor desconocido"
        />
      </RadioGroup>
      <Button onClick={handleClick}>Continuar</Button>
    </Box>
  );
};

export default DrawerOptions;
