import { Alert, Button, Grid } from "@mui/material";
import { useState } from "react";

import Loading from "../../../../components/loading/Loading";

const AuthRepairmentButtons = ({
  evidenceId,
  evidenceStatus,
  changeEvidenceStatus
}) => {
  const [success, setSuccess] = useState({ severity: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleEvent = async (status, evidenceId) => {
    try {
      setIsLoading(true);
      await changeEvidenceStatus(status, evidenceId);

      setIsLoading(false);
      setSuccess({ severity: "success", text: "Actualizado" });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      setIsLoading(false);
      setSuccess({ severity: "error", text: String(error) });
      console.log(error);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <>
      <Grid container columnSpacing={5}>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            fullWidth
            disabled={isLoading || evidenceStatus === "APPROVED"}
            onClick={() => handleEvent("APPROVED", evidenceId)}
          >
            Aprobar reparación
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="blue"
            fullWidth
            disabled={isLoading || evidenceStatus === "DENIED"}
            onClick={() => handleEvent("DENIED", evidenceId)}
          >
            Rechazar reparación
          </Button>
        </Grid>
      </Grid>
      {isLoading && <Loading />}
      {success.severity !== "" && (
        <Alert severity={success.severity}>{success.text}</Alert>
      )}
    </>
  );
};

export default AuthRepairmentButtons;
