import { Button } from "@mui/material";

import { useModal } from "../../hooks/useModal";
import CreateProspectForm from "./createProspectForm/CreateProspectForm";

const CreateProspect = ({ refetchPreSellers, refetchProperties }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Button
        onClick={onOpenModal}
        variant="contained"
        sx={{ ml: 3, mt: "auto" }}
      >
        Nuevo Prospecto
      </Button>
      {open && (
        <CreateProspectForm
          refetchPreSellers={refetchPreSellers}
          refetchProperties={refetchProperties}
          open={open}
          onCloseModal={onCloseModal}
        />
      )}
    </>
  );
};

export default CreateProspect;
