import { Box } from "@mui/material";
import { useContext } from "react";

import { updateInspectionEvidence } from "../../../../../api/inspection/updateInspectionEvidence";
import TokenContext from "../../../../../context/Token";
import EvidenceImg from "./EvidenceImg";

const InspectionGallery = ({ evidence, modalType, propertyId, refetch }) => {
  const { token } = useContext(TokenContext);

  const changeEvidenceStatus = async (status, evidenceId) => {
    console.log("status");
    console.log(status);
    try {
      await updateInspectionEvidence(token, propertyId, evidenceId, {
        status
      });

      /* setSuccess(true); */
      refetch();
      /* setTimeout(() => {
        setSuccess(false);
      }, 3000); */
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {evidence.length > 0 && (
        <Box
          display={"flex"}
          width={"80%"}
          height={150}
          sx={{ overflowX: "auto" }}
        >
          {evidence?.map((ev) => (
            <EvidenceImg
              key={ev?._id}
              evidenceItem={ev}
              modalType={modalType}
              changeEvidenceStatus={changeEvidenceStatus}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default InspectionGallery;
