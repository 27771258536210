import { useState, useEffect, useContext } from "react";
import { MdUploadFile } from "react-icons/md";

import { createDocsSeller } from "../../../api/documentations/createDocsSeller";
import { getDocListSeller } from "../../../api/documentations/getDocListSeller";
import { getDocsSeller } from "../../../api/documentations/getDocsSeller";
import { uploadDocumentationFile } from "../../../api/documentations/uploadDocumentationsFile";
import TokenContext from "../../../context/Token";
import DocTypeSelect from "./DocTypeSelect";
import NewUploadedDocsSection from "./NewUploadedDocsSection";
import UploadedDocsSection from "./UploadedDocsSection";

//PROPS DEL COMPONENTE: id (propiedad), sellerId, MUDisActive, setMUDisActive, setDocsNum
const ModalUploadDocumentation = (props) => {
  const { token } = useContext(TokenContext);
  const [response, setResponse] = useState({});
  const [docType, setDocType] = useState("");
  const [DocList, setDocList] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [file, setFile] = useState({});

  const getDocs = async () => {
    if (props.MUDisActive) {
      try {
        const aux = await getDocsSeller(token, props.sellerId);
        const sellerDocList = await getDocListSeller(
          token,
          props.isMarried,
          props.creditType
        );

        setResponse(aux.data);
        setDocList(sellerDocList);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const sendData = async (file) => {
    if (!file.name) {
      // console.log(file.name)
      setErrorMessage("Debes Seleccionar un Archivo");

      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    try {
      const CDSResponse = await createDocsSeller(
        token,
        props.sellerId,
        docType
      );
      // console.log(CDSResponse)

      if (CDSResponse?.data?.document_id) {
        const UDFResponse = await uploadDocumentationFile(
          token,
          CDSResponse.data.document_id,
          file
        );
        // console.log(UDFResponse)

        setSuccessMessage("Documento agregado exitosamente");
        getDocs();

        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(error);

      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    getDocs();
  }, [props.MUDisActive]);

  // props.MUDisActive && console.log(response)
  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      id={"modalUploadDocumentation" + props?.id}
      aria-labelledby={`modalUploadDocumentationLabel`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content" style={{ height: 600 }}>
          <div className="modal-header">
            <h5 className="modal-title">{props.reason}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.setMUDisActive(false)}
            ></button>
          </div>
          <div className="modal-body">
            <section className="upload-file-modal">
              <div className="modal-upload">
                <DocTypeSelect
                  MUDisActive={props.MUDisActive}
                  docType={docType ?? ""}
                  setDocType={setDocType}
                  isMarried={props.isMarried ?? false}
                  communityProperty={props.communityProperty ?? false}
                  creditType={props.creditType ?? ""}
                />
                {docType && (
                  <>
                    <label
                      htmlFor="file"
                      style={{
                        cursor: "pointer",
                        width: "fit-content",
                        margin: "auto"
                      }}
                    >
                      <div className="select-file-box m-auto">
                        <MdUploadFile className="select-icons" />
                        <input
                          hidden
                          name="file"
                          id="file"
                          type="file"
                          // accept='.doc, .docx, .pdf'
                          onChange={(e) => {
                            setFile(e.target.files[0]);
                          }}
                        />
                        <span>
                          {file?.name
                            ? file?.name?.length <= 13
                              ? file?.name
                              : `${file?.name?.substr(0, 12)}...`
                            : "Examinar"}
                        </span>
                      </div>
                    </label>
                    <button
                      className="btn-fill-orange mt-3"
                      onClick={() => sendData(file)}
                    >
                      Enviar
                    </button>
                  </>
                )}
              </div>

              <div>
                <label className="d-block mb-3">Documentos cargados:</label>

                <div style={{ height: "380px", overflowY: "auto" }}>
                  <NewUploadedDocsSection
                    MUDisActive={props.MUDisActive}
                    documentations={response?.documentations ?? []}
                    token={token}
                    getDocs={getDocs}
                    canDelete={true}
                  />
                </div>

                {/* <div style={{height:"380px", overflowY:"auto"}}>
                  <UploadedDocsSection
                    MUDisActive={props.MUDisActive}
                    documentations={response?.documentations ?? []}
                    token={token}
                    getDocs={getDocs}
                    canDelete={true}
                  />
                </div> */}
              </div>
            </section>
          </div>
          <div className="modal-footer">
            {successMessage && (
              <p className="me-3 py-1 px-3 rounded bg-success text-white fw-bold">
                {successMessage}{" "}
              </p>
            )}
            {errorMessage && (
              <p className="me-3 py-1 px-3 rounded bg-danger text-white fw-bold">
                {errorMessage}{" "}
              </p>
            )}
            <button
              type="button"
              className="btn-fill-orange"
              data-bs-dismiss="modal"
              onClick={() => {
                props.setMUDisActive(false);
                setFile({});
                setDocType("");
              }}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUploadDocumentation;
