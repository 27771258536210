import React from "react";

const Calendar = (props) => {
  return (
    <div
      className="modal fade"
      id="ModalCalendar"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-gray fw-bold">Agendar</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
              type="datetime-local"
              className="w-75 fs-5"
              onChange={props.onChange}
              pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}T[0-9]{2}:[0-9]{2}"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={props.onHandleOk}
              data-bs-target={props.onTarget}
              data-bs-toggle="modal"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
