import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { useState } from "react";

import CreatePropertyContainer from "./CreatePropertyContainer";

const CreatePropertyModal = ({
  open,
  onCloseModal,
  showFeedbackMessage,
  refetchInvetoryProperties
}) => {
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      maxWidth="xl"
      sx={{
        borderRadius: ".5rem",
        width: "100%",
        height: "100%"
      }}
      fullWidth
    >
      {isLoadingDialog && (
        <Box
          style={{
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "#00000034"
          }}
        >
          <CircularProgress size={64} />
        </Box>
      )}
      <DialogTitle id={"error-success-title"} p={1}>
        Registar propiedad
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CreatePropertyContainer
          onCloseModal={onCloseModal}
          setIsLoadingDialog={setIsLoadingDialog}
          isLoadingDialog={isLoadingDialog}
          showFeedbackMessage={showFeedbackMessage}
          refetchInvetoryProperties={refetchInvetoryProperties}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CreatePropertyModal;
