import { Grid, MenuItem, TextField, Button, Box } from "@mui/material";

const SigningConfirmation = ({
  confirmationData,
  setConfirmationData,
  sendData
}) => {
  const handleChange = (e) => {
    setConfirmationData({
      ...confirmationData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextField
            size="small"
            select
            fullWidth
            name="isSigned"
            label="¿Firmó?"
            value={confirmationData.isSigned ?? ""}
            onChange={handleChange}
          >
            <MenuItem value={""} hidden></MenuItem>
            <MenuItem value={true}>Sí</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            size="small"
            fullWidth
            InputLabelProps={{ shrink: true }}
            name="reason"
            label="Razón por la que no firmó"
            value={confirmationData.reason ?? ""}
            onChange={handleChange}
          />
        </Grid>
        <Box width={"100%"} display={"flex"} justifyContent={"end"}>
          <Button variant="contained" color="primary" onClick={sendData}>
            Actualizar
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default SigningConfirmation;
