import { Button } from "@mui/material";
import { useState } from "react";

const RejectReason = ({
  isValid,
  rejectFile,
  setErrorMessage,
  setSuccessMessage,
  documentId,
  doc,
  ...props
}) => {
  const [reason, setReason] = useState("");

  return (
    <>
      <td>
        <input
          disabled={isValid !== false}
          type="text"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </td>
      <td>
        <Button
          sx={{ my: 0, width: 120 }}
          variant="reasonButton"
          disabled={isValid !== false}
          onClick={() => rejectFile(doc._id, reason)}
        >
          Enviar Razón
        </Button>
      </td>
    </>
  );
};

export default RejectReason;
