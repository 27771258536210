import { Box, Button, IconButton } from "@mui/material";
import { blue } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { MdDelete, MdModeEditOutline } from "react-icons/md";

import { deleteDebt } from "../../../api/debts/deleteDebt";
import TokenContext from "../../../context/Token";
import { stringToCurrency } from "../../../helpers/currencies";
// import { getDateAndTime } from "../../../helpers/dates";
import ModalEditDebts from "./ModalEditDebts";

const { REACT_APP_DEBTS_URL } = process.env;
//PROPS DEL COMPONENTE: debtsRef={props.property.debtsRef} refetchProperties={refetchProperties}
const DebtsTable = (props) => {
  const { token } = useContext(TokenContext);

  const handleDelete = async (token, debtId) => {
    const response = await deleteDebt(token, debtId);

    if (response?.status === 200) {
      props.refetchProperties();
    }
  };

  return (
    <>
      {props.debtsRef
        ?.map((debt) => (
          <article
            key={debt._id}
            className="py-2 d-flex"
            style={{ borderBottom: "1px solid #b3abab" }}
          >
            <Box
              sx={{
                px: "10px",
                borderRight: "1px solid #b3abab",
                flex: 1,
                textAlign: "left"
              }}
            >
              <Box display={"flex"}>
                <Box sx={{ maxWidth: "70%" }}>
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight="bold"
                  >
                    Tipo:
                    <Typography
                      variant="body1"
                      color="secondary"
                      component={"span"}
                    >
                      {" "}
                      {debt.type}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight="bold"
                  >
                    Descripción:
                    <Typography
                      variant="body1"
                      color="secondary"
                      component={"span"}
                    >
                      {" "}
                      {debt.detail}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontWeight="bold"
                  >
                    Cantidad:
                    <Typography
                      variant="body1"
                      color="secondary"
                      component={"span"}
                    >
                      {" "}
                      {stringToCurrency(debt.amount ?? 0)}
                    </Typography>
                  </Typography>
                </Box>
                <Box margin={"auto"}>
                  {debt.path && (
                    <a href={`${debt.path}`} target="_blank" rel="noreferrer">
                      <Button sx={{ my: 0 }}>Ver Documento</Button>
                    </a>
                  )}
                </Box>
              </Box>
              <small style={{ fontSize: "12px" }}>
                {/* <label className="property-title">
                Creado:
                <label className="ms-2 value-title">
                  {getDateAndTime(debt.createdAt) ?? ""}
                </label>
              </label>

              <label className="property-title">
                Actualizado:
                <label className="ms-2 value-title">
                  {getDateAndTime(debt.updatedAt) ?? ""}
                </label>
              </label> */}
              </small>
            </Box>
            <div className="d-flex" style={{ flex: 0 }}>
              <h4 className="m-auto text-orange px-3">
                <IconButton color="primary">
                  <MdModeEditOutline
                    data-bs-target={"#modalEditDebts" + debt._id}
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    style={{ cursor: "pointer" }}
                  />
                </IconButton>
              </h4>
              <h4 className="m-auto text-danger pe-3">
                <IconButton
                  sx={{ color: blue[600] }}
                  onClick={() => handleDelete(token, debt._id)}
                >
                  <MdDelete cursor={"pointer"} />
                </IconButton>
              </h4>
            </div>
            <ModalEditDebts
              id={debt._id}
              debtRef={debt._id}
              detail={debt.detail ?? ""}
              amount={debt.amount}
              refetchProperties={props.refetchProperties}
            />
          </article>
        ))
        .reverse()}
    </>
  );
};

export default DebtsTable;
