import { useContext } from "react";
import { useState } from "react";

import { updateDebt } from "../../../api/debts/updateDebt";
import { uploadDebt } from "../../../api/debts/uploadDebt";
import { getUploadFileUrl, uploadFile } from "../../../api/utilsApi";
import TokenContext from "../../../context/Token";
import { toNormalForm } from "../../../helpers/common";
import DebtsForm from "./DebtsForm";

// PROPS DEL COMPONENTE: id={props.debt._id} debtsRef={props.debt.debtsRef} detail={debt.detail ?? ""} amount={debt.amount} refetchProperties

const ModalEditDebts = (props) => {
  const { token } = useContext(TokenContext);

  const [text, setText] = useState(props.detail ?? "");
  const [amount, setAmount] = useState(props.amount ?? "");
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const clearInputs = () => {
    setText("");
    setAmount("");
  };

  const handleAction = async (e) => {
    e.preventDefault();

    if (!amount) {
      setErrorMessage("Cantidad Obligatoria");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    try {
      const response = await updateDebt(token, props.id, {
        text,
        amount,
        type
      });

      if (file) {
        const { url, signedUrl } = await getUploadFileUrl(
          token,
          `tmp/properties/${props.id}/docs/expediente/adeudos`,
          toNormalForm(file?.name?.trim()?.replaceAll(" ", "-").toLowerCase())
        );

        await uploadFile(signedUrl, file);
        await uploadDebt(token, response?.debt_id, url);
        setSuccessMessage("Registro Actualizado");
        props.refetchProperties();
        clearInputs();
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id={"modalEditDebts" + props.id}
      aria-labelledby={`modalEditDebtsLabel`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Editar Adeudo</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column pe-3">
              <DebtsForm
                edit={true}
                text={text}
                setText={setText}
                type={type}
                setType={setType}
                file={file}
                setFile={setFile}
                amount={amount}
                setAmount={setAmount}
                handleAction={handleAction}
              />
            </div>
          </div>
          <div className="modal-footer">
            {successMessage && (
              <p className="me-3 py-1 px-3 rounded bg-success text-white fw-bold">
                {successMessage}{" "}
              </p>
            )}
            {errorMessage && (
              <p className="me-3 py-1 px-3 rounded bg-danger text-white fw-bold">
                {errorMessage}{" "}
              </p>
            )}
            <button
              type="button"
              className="btn btn-disabled"
              data-bs-dismiss="modal"
              onClick={clearInputs}
              data-bs-toggle="modal"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn-fill-orange"
              onClick={handleAction}
            >
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditDebts;
