import React, { useState } from "react";
import { BiMessageSquareEdit } from "react-icons/bi";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import bathroom from "../../../assets/baño.svg";
import image from "../../../assets/ctc.jpg";
import parking from "../../../assets/estacionamiento.svg";
import bedroom from "../../../assets/recamara.svg";
import Calendar from "../../Modals/Calendar";

const ProspectList = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState("");

  const saveDate = () => {
    localStorage.setItem("date", JSON.stringify(date));
    navigate("/ctc/venta/prospectos");
  };

  const onChange = (e) => {
    setDate(e.target.value);
  };

  return (
    <section className="flex-center">
      <div
        className="border border-secondary mt-5 mb-5"
        style={{ width: "65%" }}
      >
        <div className="d-flex text-orange p-3 ps-5 fs-3 fw-bold align-items-center border border-secondary">
          <MdArrowBackIos
            onClick={() => navigate("/ctc/venta/prospectos")}
            style={{ cursor: "pointer" }}
          />
          <label
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/ctc/venta/prospectos")}
          >
            Regresar
          </label>
        </div>
        <div className="d-flex p-5 justify-content-around border border-secondary">
          <div className="d-flex">
            <div className="d-flex flex-column">
              <button className="btn-fill-grayblue mb-3">ID de vivienda</button>
              <img src={image} alt="" width={130} height={200} />
            </div>
            <div className="d-flex flex-column text-gray text-start justify-content-end ms-5">
              <label className="fw-bold fs-5">El Marques</label>
              <label className="fs-5">
                <label className="fw-bold">$2,400,000</label>MXN
              </label>
              <label className="fs-6">
                <label className="fw-bold">Nombre:</label> Miguel Ramirez
              </label>
              <label className="fs-6 w-75">
                <label className="fw-bold">Dirección:</label>Avenida Paseos del
                Marques #86. Col. Paseos del Marquez. II. Qro Condominio
                Andalucia
              </label>
              <div
                className="d-flex align-items-center"
                style={{ height: "45px" }}
              >
                <label>
                  38.99m<sup>2</sup>
                </label>
                <img src={bedroom} alt="" width={80} height={80} />
                <label>2</label>
                <img src={bathroom} alt="" width={80} height={80} />
                <label>1</label>
                <img src={parking} alt="" width={80} height={80} />
                <label>1</label>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <BiMessageSquareEdit className="fs-2 text-gray align-self-end" />
            <button
              className="btn-fill-orange"
              data-bs-target="#ModalCalendar"
              data-bs-toggle="modal"
            >
              Agendar
            </button>
            <Calendar onChange={onChange} onHandleOk={saveDate} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProspectList;
