const SignatureCategories = (props) => {
  return (
    <section className="categories-section">
      <div
        className="category-item text-gray "
        onClick={() => props.onSelect("selectTab", "")}
        style={{
          cursor: "pointer",
          backgroundColor: props.currentTab === "" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>General</label>
        <label style={{ cursor: "pointer" }}>{props.totalLength} casas</label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "hasFirmDate")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Alta" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Con cita para firmar</label>
        <label style={{ cursor: "pointer" }}>{props.withDate} casas</label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "hasNotFirmDate")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Media" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Sin cita para firmar</label>
        <label style={{ cursor: "pointer" }}>{props.noDate} casas</label>
      </div>
    </section>
  );
};

export default SignatureCategories;
