import React from "react";

const SignatureCard = (props) => {
  return (
    <div
      className="d-flex border border-secondary p-4 rounded-3 mt-5"
      style={{ width: "60%" }}
    >
      <div className="d-flex flex-column text-start text-gray">
        <button className="btn-fill-grayblue mb-4 w-50">
          <label className="fw-normal me-1">Solicitud:</label>ID de vivienda
        </button>

        <label className="fw-bold">
          Información proporcionada por el cliente
        </label>
        <label>
          <label className="fw-bold">Nombre:</label> {props.name}
        </label>
        <label>
          <label className="fw-bold">Correo electrónico:</label> {props.email}
        </label>
        <label>
          <label className="fw-bold">Teléfono:</label> {props.phone}
        </label>

        <label className="fw-bold mt-3">
          Información de inmueble en inventario
        </label>
        <label>
          <label className="fw-bold">Estado:</label> Querétaro
        </label>
        <label className="w-75">
          <label className="fw-bold">Ubicación:</label> Avenida Paseos del
          Marques #86. Col. Paseos del Marquez. II. Qro Condominio Andalucia
        </label>
        <label>
          <label className="fw-bold">Solución adquirida:</label> Solución CTC
        </label>
        <label>
          <label className="fw-bold">Asesor de compra asignado:</label> Martin
          Morales
        </label>
      </div>
      <div className="d-flex flex-column justify-content-end w-50 text-start">
        <label className="mb-2">Fecha establecida</label>
        <input type="date" className="mb-3" />
        <div className="d-flex justify-content-between">
          <button className="btn-fill-orange">Firmado</button>
          <button className="btn-fill-gray w-50">No Firmado</button>
        </div>
        <button className="btn-fill-orange mb-2">Titulado</button>
      </div>
    </div>
  );
};

export default SignatureCard;
