import { Button } from "@mui/material";
import { Link } from "react-router-dom";

// import EditDataClient from "../Modals/EditDataClient";
// import ModalDataHouse from "../Modals/modalDataHouse/ModalDataHouse";
// import CreditTypeSelect from "../Profile/CreditTypeSelect";
// import LeadStatusSelect from "../Profile/LeadStatusSelect";
// import NoPerfiladoButton from "../Profile/NoPerfiladoButton";
// import PerfiladoButton from "../Profile/PerfiladoButton";

const ProfileActions = (props) => {
  return (
    <>
      <div className="row m-auto" style={{ width: 260 }}>
        {/* <LeadStatusSelect
          lead={props.property?.lead ?? ""}
          propertyId={props.property?._id}
        /> */}
        {/* <CreditTypeSelect
          credit={props.property?.sellerRef?.credit ?? ""}
          creditType={props.property?.creditType ?? ""}
          debtCredit={props.property?.debtCredit ?? ""}
          hasBacklogCredit={props.property?.hasBacklogCredit ?? ""}
          monthBacklogs={props.property?.monthBacklogs ?? ""}
          propertyId={props.property?._id}
          sellerId={props.property?.sellerRef?._id}
        /> */}
      </div>

      <div className="row m-auto" style={{ width: 260 }}>
        {/* <button
          type="button"
          className="btn-fill-orange col py-0 me-2"
          style={{ fontSize: "14px" }}
          data-bs-toggle="modal"
          data-bs-target={"#modalDataHouse" + props?.property?._id}
        >
          Datos Casa
        </button>
        <ModalDataHouse
          address={props?.property?.address}
          postalCode={props.property?.postalCode ?? ""}
          state={props.property?.state ?? ""}
          city={props.property?.city ?? ""}
          settlement={props?.property?.settlement ?? ""}
          propertyId={props?.property?._id}
          reason={"Datos De La Casa"}
          refetchProperties={props.refetchProperties}
        />
        <button
          style={{ fontSize: "14px" }}
          className="btn-fill-orange col py-0"
          data-bs-target={"#EditDataClient" + props.property._id}
          data-bs-toggle="modal"
        >
          Datos Cliente
        </button>
        <EditDataClient
          id={props.property._id}
          sellerRef={props.property.sellerRef}
          refetch={props.refetchProperties}
        /> */}

        <Link to={`/ctc/compra/propiedad/${props?.property?._id}/prospecto`}>
          <Button variant="contained">Ver Más</Button>
        </Link>
      </div>

      {/* <div className="row m-auto mt-2" style={{ width: 260 }}>
        <PerfiladoButton propertyId={props.property?._id} />
        <NoPerfiladoButton
          propertyId={props.property?._id}
          buttonText={"No Perfilado"}
          type={"rec"}
          refetchProperties={props.refetchProperties}
        />
      </div> */}
    </>
  );
};

export default ProfileActions;
