import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getPropertyById } from "../../api/properties/getPropertyById";
import { getAllvisits } from "../../api/visits/getAllVisits";
import { propertyInfo } from "../../cacheQueries/cacheQueries";
// import PropertyInfo from "../../components/InfoFields/PropertyInfo";
import Loading from "../../components/loading/Loading";
import MapContainer from "../../components/map/MapContainer";
// import ModalDataHouseV2 from "../../components/Modals/modalDataHouse/ModalDataHouseV2";
import ModalUploadMultimedia from "../../components/Modals/modalUploadMultimedia/ModalUploadMultimedia";
import TokenContext from "../../context/Token";
import Documentation from "../propertyData/documentation/Documentation";
import InfoBuilding from "../propertyData/infoBuilding/InfoBuilding";
import InfoClient from "../propertyData/InfoClient";
import NotesContainer from "../propertyData/notes/NotesContainer";
import ObservationsContainer from "../propertyData/observations/ObservationsContainer";
import ButtonSection from "./ButtonSection";
// import BuyerDocs from "./BuyerDocs";
// import ExpedienteFiles from "./ExpedienteFiles";
import Gallery from "./Gallery";
import LayawayInfo from "./layaway/LayawayInfo";
import NewVisit from "./NewVisit";
import Prices from "./Prices";
// import PropertyData from "./PropertyData";
import Staff from "./Staff";
import Stage from "./Stage";
import Visits from "./Visits";

const HomeInfo = () => {
  const { token } = useContext(TokenContext);
  const { propertyId } = useParams();
  const { data, isLoading, refetch } = useQuery(
    [propertyInfo, propertyId],
    () => getPropertyById(token, propertyId)
  );
  const visits = useQuery(["visits", propertyId], () =>
    getAllvisits(token, propertyId)
  );

  if (isLoading) {
    return (
      <section className="flex-center">
        <Loading />
      </section>
    );
  }
  return (
    <section className="flex-center px-5">
      <div className="d-flex w-100 mt-4">
        <div onClick={() => window.history.back()} className="historial-back">
          <MdArrowBackIos />
          <label>Regresar</label>
        </div>
      </div>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              color={"initial"}
              textAlign={"start"}
              width={"100%"}
            >
              {data?.data?.property?.sellerRef?.name ?? ""}
              {data?.data?.property?.sellerRef?.name && " "}
              {data?.data?.property?.sellerRef?.fLastName ?? ""}
              {data?.data?.property?.sellerRef?.fLastName && " "}
              {data?.data?.property?.sellerRef?.mLastName ?? ""}
            </Typography>
          </Grid>
        </Grid>
        <Box mb={10}>
          <LayawayInfo />
        </Box>
        <Box
          mb={10}
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 10
          }}
        >
          <InfoClient
            sellerRef={data?.data?.property?.sellerRef ?? {}}
            refetch={refetch}
          />
        </Box>
        <Box mb={10}>
          <InfoBuilding
            property={data?.data?.property ?? {}}
            refetch={refetch}
          />
        </Box>
        <Grid container columnSpacing={3} mb={10}>
          <Grid item xs={12} md={6}>
            <ObservationsContainer
              property={data?.data?.property}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NotesContainer
              status={data?.data?.property?.status ?? ""}
              propertyId={data?.data?.property?._id}
              notes={data?.data?.property?.descriptionsRef ?? []}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt={5}></Box>
            <Documentation
              property={data?.data?.property ?? {}}
              refetch={refetch}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mt={5}></Box>
            <Stage
              stage={data?.data?.property?.statusLanding ?? ""}
              propertyId={data?.data?.property?._id}
            />
          </Grid>
        </Grid>
        <Box mb={10} id="expediente"></Box>
        <Box mt={5}></Box>
        <Grid container columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <Prices
              interval={data?.data?.property?.interval ?? 365}
              offer={data?.data?.property?.offer ?? 0}
              quote={data?.data?.property?.quote ?? 0}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ButtonSection property={data?.data?.property ?? {}} />
          </Grid>
        </Grid>
        <Box display={"flex"}>
          <Staff
            adviserRef={data?.data?.property?.adviserRef ?? {}}
            saleAdviserRef={data?.data?.property?.saleAdviserRef ?? {}}
            buyLawyerRef={data?.data?.property?.buyLawyerRef ?? {}}
            saleLawyerRef={data?.data?.property?.saleLawyerRef ?? {}}
          />
        </Box>
        <Grid container columnSpacing={5}>
          <Grid item xs={12} md={6}>
            <Box display={"flex"} justifyContent={"space-between"} mt={3}>
              <label className="property-title fs-3">Visitas</label>
              <NewVisit refetchVisits={visits.refetch} />
            </Box>
            <div
              className="p-2"
              style={{
                height: 404,
                width: "100%",
                border: "1px solid gray",
                overflowY: "auto"
              }}
            >
              <Visits
                data={visits.data}
                isLoading={visits.isLoading}
                error={visits.error}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <h3 className="property-title mt-4 ms-1 w-100 text-start">
                Galería
              </h3>
              <Button
                variant="contained"
                data-bs-toggle="modal"
                data-bs-target={`#multimedia${data?.data?.property?._id}`}
              >
                Subir fotos
              </Button>
            </Box>
            <Gallery images={data?.data?.property?.images} />
            <ModalUploadMultimedia
              id={data?.data?.property?._id}
              photos={data?.data?.property?.images}
              refetchProperties={refetch}
              storegallery={true}
            />
          </Grid>
        </Grid>
        <div
          className=""
          style={{
            height: "500px",
            width: "100%",
            border: "1px solid gray",
            marginTop: 20
          }}
        >
          <MapContainer
            latitude={data?.data?.property?.location?.coordinates[1] ?? 0}
            longitude={data?.data?.property?.location?.coordinates[0] ?? 0}
            postalCode={data?.data?.property?.postalCode ?? ""}
          />
        </div>
      </Container>
    </section>
  );
};

export default HomeInfo;
