const NewUploadedDocsSection = ({
  MUDisActive,
  documentations,
  token,
  getDocs,
  canDelete
}) => {
  console.log(documentations);
  return (
    <div>
      {documentations?.map((d) => (
        <p key={d._id}>{d.name}</p>
      ))}
    </div>
  );
};

export default NewUploadedDocsSection;
