import React from "react";
import "../styles/FrameLayout.css";
import { useContext } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getAllHistories } from "../../api/histories/getAllHistories";
import Loading from "../../components/loading/Loading";
import TokenContext from "../../context/Token";
import { getDateAndTime } from "../../helpers/dates";

const { REACT_APP_BASE_URL } = process.env;

const Historial = () => {
  const { token } = useContext(TokenContext);
  const { propertyId } = useParams();
  // console.log(propertyId)
  const { isLoading, error, data } = useQuery(
    ["propertyHistory", propertyId],
    () => getAllHistories(token, "venta", propertyId)
  );

  if (isLoading) {
    return (
      <section className="historial-layout">
        <Loading />
      </section>
    );
  }

  if (error) {
    console.log(error);
    return (
      <section className="historial-layout">
        <h3>Error al cargar datos</h3>
      </section>
    );
  }

  // data && console.log(data);
  return (
    <section className="historial-layout">
      <div className="left-historial">
        <div onClick={() => window.history.back()} className="historial-back">
          <MdArrowBackIos />
          <label>Regresar</label>
        </div>

        <h2 className="margin-element fs-5">Historial:</h2>

        <h2 className="margin-element fs-5 text-capitalize mb-5">
          {localStorage.getItem("propertyName")}
        </h2>
        <a
          href={`${REACT_APP_BASE_URL}/pdf/history-property?reference=${propertyId}`}
        >
          <button className="btn-fill-orange">Descargar Pdf</button>
        </a>

        {data?.history?.map((entry) => {
          return (
            <div key={entry._id}>
              <label className="text-orange fw-bold margin-element mt-3">
                {entry.date && getDateAndTime(entry.date)}.
                <span className="property-title">
                  {" "}
                  Usuario: {entry?.user?.name ?? "Desconocido"} (
                  {entry?.userType ?? ""}){" "}
                </span>
                <br />
                <label className="value-title ms-3">
                  {entry.description ?? ""}
                </label>
              </label>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Historial;
