import { DeedProvider } from "../../context/Deed/DeedProvider";
import DeedTable from "./DeedTable";
import "./deed.css";

const Deed = () => {
  return (
    <DeedProvider>
      <DeedTable />
    </DeedProvider>
  );
};

export default Deed;
