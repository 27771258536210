import { useContext, useState } from "react";

import { updateReconversion } from "../../api/reconversion/updateReconversion";
import TokenContext from "../../context/Token";

const ReconvertStatus = (props) => {
  // console.log(props.reconversionId)
  const { token } = useContext(TokenContext);
  const [status, setStatus] = useState(props.status ?? "");

  const sendData = async (e) => {
    setStatus(e.target.value);

    const response = await updateReconversion(token, props.reconversionId, {
      status: e.target.value
    });

    // console.log(response)
  };

  return (
    <select className="margin-element" value={status} onChange={sendData}>
      <option hidden value={""}>
        Marcar como
      </option>
      <option value="Pendiente">Pendiente</option>
      <option value="Cancelado">Cancelado</option>
      <option value="Acciones en revision">Acciones en revision</option>
    </select>
  );
};

export default ReconvertStatus;
