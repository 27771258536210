import { Box, TextField } from "@mui/material";
import moment from "moment";
import { useContext } from "react";
import { useState } from "react";
import { FcCheckmark } from "react-icons/fc";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";

//PROPS DEL COMPONENTE visitDate, id, refetchProperties
const VisitDate = (props) => {
  const { token } = useContext(TokenContext);

  const [visitDate, setVisitDate] = useState(
    moment(props.visitDate).format("YYYY-MM-DDTHH:mm") ?? ""
  );
  const [success, setSuccess] = useState(false);

  const handleVisitDateUpdate = async (e) => {
    setVisitDate(e.target.value);
    console.log(e.target.value);
    try {
      const response = await updateProperties(token, props.id, {
        visitDate: e.target.value,
        inspection: false,
        inspectionText: ""
      });
      // console.log(response)

      if (response?._id) {
        setSuccess(true);
        props.refetchProperties();
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"start"}
      alignContent={"center"}
      alignItems={"center"}
      gap={2}
    >
      <p className="property-title mb-0 mt-1">Fecha de Inspección:</p>
      <TextField
        variant="standard"
        type={"datetime-local"}
        size="small"
        style={{ width: "190px" }}
        value={visitDate}
        onChange={handleVisitDateUpdate}
      />
      {success && (
        <Box style={{ fontSize: "20px", marginLeft: "10px" }}>
          <FcCheckmark className="mb-2" />
        </Box>
      )}
    </Box>
  );
};

export default VisitDate;
