import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Grid
} from "@mui/material";
import moment from "moment";

import errorImg from "../../../assets/error-image.png";
import { useModal } from "../../../hooks/useModal";

const MonitoringItem = ({ event }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={10}>
          <Box display={"flex"}>
            <Box width={500}>
              <Typography
                display={"block"}
                textAlign={"start"}
                component="span"
                variant="body2"
                color="secondary"
                fontSize={18}
                mt={1}
                ml={5}
              >
                {event?.comments}
              </Typography>
              <Box mb={2} textAlign={"start"}>
                <Typography
                  ml={5}
                  variant="body1"
                  component={"span"}
                  color="primary"
                  fontWeight={600}
                  fontSize={13}
                >
                  {moment(event?.date).format("L LT")}{" "}
                  <Typography
                    component={"span"}
                    variant="body1"
                    color="secondary"
                    fontWeight={500}
                    fontSize={13}
                  >
                    Creado por: Inspector {event?.inspector?.fLastName}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box
              height={100}
              width={100}
              borderRadius={4}
              overflow={"hidden"}
              backgroundColor="red"
              ml={3}
              mb={1}
            >
              <Box
                component={"img"}
                src={event.evidence[0]?.path ?? errorImg}
                width={"100%"}
                height={"100%"}
                onClick={onOpenModal}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          aria-labelledby={"error-success-dialogue"}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            <Typography variant="h4" color="initial">
              Imágen
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box width={"70vw"} height={"80vh"}>
              <Box
                component={"img"}
                src={event.evidence[0]?.path ?? errorImg}
                width={"100%"}
                height={"100%"}
                sx={{ objectFit: "contain" }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display={"flex"}></Box>
          </DialogActions>
        </Dialog>
      )}
      <Divider component="li" />
    </Box>
  );
};

export default MonitoringItem;
