import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getQuoters = async (data, token) => {
  console.log(data);

  if (data?.rooms === "") {
    delete data.rooms;
  }

  if (data?.baths === "") {
    delete data.baths;
  }
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/landing/quote`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
