import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateProperties } from "../../../api/properties/updateProperties";
import Pdf from "../../../components/InfoFields/Pdf";
import TokenContext from "../../../context/Token";
import OfferDocsButton from "./OfferDocsButton";

const OfferDocs = ({ property, debtsRef, refetch }) => {
  const { token } = useContext(TokenContext);
  const navigate = useNavigate();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [requiredDocsUploaded, setRequiredDocsUploaded] = useState(false);

  const sendData = async () => {
    setIsUploadingFiles(true);
    try {
      await updateProperties(token, property?._id, {
        status: "Expediente"
      });
      refetch();
      navigate(`/ctc/compra/propiedad/${property._id}/expediente`, {
        replace: true
      });
      setIsUploadingFiles(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const VerifyDocsRequired = () => {
      if (
        property?.documents.some((d) => d.type === "signedOffer") &&
        property?.documents.some((d) => d.type === "signedLetter") &&
        property?.documents.some((d) => d.type === "buyPromise")
      ) {
        setRequiredDocsUploaded(true);
      } else {
        setRequiredDocsUploaded(false);
      }
    };

    VerifyDocsRequired();
  }, [property]);

  return (
    <>
      <Box display={"flex"} justifyContent="space-between">
        <Typography
          variant="h5"
          className="blue-text"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Documentos de la Oferta
        </Typography>
        <Button variant="contained" color="primary" sx={{ mt: 0 }}>
          <PDFDownloadLink
            style={{ textDecoration: "none", color: "#fff" }}
            document={<Pdf property={property} debtsRef={debtsRef} />}
            fileName={`Oferta ${property?.sellerRef?.name} ${
              property?.sellerRef?.fLastName ?? ""
            } ${moment().format("DD-MM-YYYY")}`}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Cargando..." : "Descargar PDF"
            }
          </PDFDownloadLink>
        </Button>
      </Box>
      <Grid container>
        <Grid item md={3}>
          <OfferDocsButton
            refetch={refetch}
            title={"Oferta firmada*"}
            type={"signedOffer"}
            doc={property?.documents.find((d) => d.type === "signedOffer")}
            property={property}
            setIsUploadingFiles={setIsUploadingFiles}
          />
        </Grid>
        <Grid item md={3}>
          <OfferDocsButton
            refetch={refetch}
            title={"Cartas poder firmadas*"}
            type={"signedLetter"}
            doc={property?.documents.find((d) => d.type === "signedLetter")}
            property={property}
            setIsUploadingFiles={setIsUploadingFiles}
          />
        </Grid>
        <Grid item md={3}>
          <OfferDocsButton
            refetch={refetch}
            title={"Solicitud de Adeudos"}
            type={"debitRequest"}
            property={property}
            doc={property?.documents.find((d) => d.type === "debitRequest")}
            setIsUploadingFiles={setIsUploadingFiles}
          />
        </Grid>
        <Grid item md={3}>
          <OfferDocsButton
            refetch={refetch}
            title={"Solicitud de Inspección"}
            type={"inspectionRequest"}
            property={property}
            doc={property?.documents.find(
              (d) => d.type === "inspectionRequest"
            )}
            setIsUploadingFiles={setIsUploadingFiles}
          />
        </Grid>
        <Grid item md={3}>
          <OfferDocsButton
            refetch={refetch}
            title={"Promesa de compra-venta*"}
            type={"buyPromise"}
            property={property}
            doc={property?.documents.find((d) => d.type === "buyPromise")}
            setIsUploadingFiles={setIsUploadingFiles}
          />
        </Grid>
        <Grid item md={3}></Grid>
        <Grid item md={3}></Grid>
        <Grid item md={3} display={"flex"} justifyContent={"end"}>
          <Button
            sx={{ my: "auto" }}
            disabled={!requiredDocsUploaded}
            variant="blue"
            onClick={sendData}
          >
            Enviar a Integración de Exp.
          </Button>
        </Grid>
        {isUploadingFiles && (
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(256,256,256,0.5)"
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </>
  );
};

export default OfferDocs;
