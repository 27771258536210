import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import React from "react";

import background from "../../assets/fondoPagina1.png";
import poppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../assets/fonts/Poppins-Medium.ttf";
import poppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import insta from "../../assets/insta.png";
import logo from "../../assets/Logo_CTC.png";
import mail from "../../assets/mail.png";
import red from "../../assets/red.png";
import tel from "../../assets/tel.png";
import { stringToCurrency } from "../../helpers/currencies";
import getOffer from "../../helpers/offerCalculation";

// Create styles
Font.register({
  family: "poppins",
  fonts: [
    { src: poppinsRegular },
    { src: poppinsMedium, fontWeight: "medium" },
    { src: poppinsBold, fontWeight: "bold" }
  ]
});

const styles = StyleSheet.create({
  /* page: {
    flexDirection: "column",
    fontFamily: "poppins",
    fontSize: 14
  },
  folio: {
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: "16px",
    fontSize: "12px"
  },
  header: {
    marginVertical: "16px",
    flexDirection: "row",
    height: "70px"
  },
  headerLeft: {
    width: "40%",
    marginLeft: 30
  },
  headerRight: {
    width: "60%",
    backgroundColor: "#EE7833",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    padding: "15px"
  },
  title1: {
    fontSize: "20px",
    color: "#fff",
    fontWeight: "bold"
  },
  title2: {
    fontSize: "16px"
  },
  container: { paddingHorizontal: "30px" } */
});

// Create Document Component
const PdfCtc = ({
  data: {
    updatedAt = new Date(),
    fileNumber = "folio",
    clientData,
    rangeDays,
    grossOffer,
    debtCreditAmount,
    rehabilitation,
    advancedPaymentAmount,
    advancedPaymentInterests,
    debts,
    netOffer,
    taxes,
    debtsInterests,
    creditInterests,
    commercialPrice,
    streetAndNumber,
    settlement,
    postalCode,
    city,
    state,
    rooms,
    baths,
    mBuilding,
    mLand,
    parkingLot,
    levels,
    floor,
    notes = "",
    driveUrl = "",
    locationUrl = "",
    inspectionUrl = "",
    adviser,
    leader
  }
}) => {
  return (
    <Document>
      <Page
        size="letter"
        style={{
          flexDirection: "column",
          fontFamily: "poppins",
          fontSize: 14
        }}
      >
        <View
          style={{
            flexDirection: "row",
            height: "64px",
            width: "100%",
            backgroundColor: "#002060",
            paddingVertical: "16px"
          }}
        >
          <View
            style={{
              width: "50%",
              marginLeft: 30
            }}
          >
            <Image
              style={{
                width: "32%",
                marginLeft: 2
              }}
              src={logo}
            />
          </View>
          <View
            style={{
              width: "50%"
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                color: "#fff"
              }}
            >
              Expediente: {fileNumber}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                color: "#fff"
              }}
            >
              Asesor: {adviser?.name || "-"}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                color: "#fff"
              }}
            >
              Líder: {leader?.name || "-"}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: "16px", paddingLeft: "16px" }}>
          <View style={{ flexDirection: "row", fontSize: "10px" }}>
            <Text
              style={{ marginRight: "32px", fontWeight: "400", color: "grey" }}
            >
              Nombre del cliente:
            </Text>
            <Text>
              {clientData?.name} {clientData?.fLastName} {clientData?.mLastName}
            </Text>
          </View>
          <View style={{ flexDirection: "row", fontSize: "10px" }}>
            <Text
              style={{ marginRight: "32px", fontWeight: "400", color: "grey" }}
            >
              Método de pago
            </Text>
            <Text> {rangeDays === 0 ? "Inmediato" : rangeDays + " Días"}</Text>
          </View>
          <View style={{ flexDirection: "row", fontSize: "10px" }}>
            <Text
              style={{ marginRight: "32px", fontWeight: "400", color: "grey" }}
            >
              Fecha de oferta:
            </Text>
            <Text>
              {format(new Date(updatedAt), "PPPPpppp", { locale: es })}
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "160px",
            marginTop: "16px",
            backgroundColor: "#002060",
            fontSize: "10px",
            marginBottom: "12px"
          }}
        >
          <Text style={{ color: "#fff" }}>DETALLE DE COMPRA</Text>
        </View>
        <View style={{ flexDirection: "row", fontSize: "10px" }}>
          <View style={{ width: "66%", alignItems: "center" }}>
            <View
              style={{
                color: "#FF6B01",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "12px"
              }}
            >
              <Text>Precio de compra</Text>
            </View>
            <View
              style={{
                border: "1px solid black",
                marginTop: "8px",
                width: "90%"
              }}
            >
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Crédito hipotecario</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Rehabilitación</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Cuota condominal</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Agua</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Luz</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Predial</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Adelanto</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Interés liquidación crédito</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Interés liquidación servicios</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>Comisión por adelanto</Text>
              </View>
              <View style={{ alignItems: "center" }}>
                <Text>
                  Impuestos{" "}
                  {clientData?.debtCredit === "false"
                    ? ""
                    : clientData?.debtCredit}
                </Text>
              </View>
            </View>
            <View
              style={{
                color: "#002060",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "12px",
                marginTop: "12px"
              }}
            >
              <Text>Saldo</Text>
            </View>
          </View>
          <View style={{ width: "33%" }}>
            <View
              style={{
                color: "#FF6B01",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "12px"
              }}
            >
              <Text>{stringToCurrency(grossOffer || 0)}</Text>
            </View>
            <View
              style={{
                border: "1px solid black",
                marginTop: "8px",
                width: "90%"
              }}
            >
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(debtCreditAmount || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(rehabilitation || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(debts?.mantenimiento || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(debts?.agua || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(debts?.luz || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(debts?.predial || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(advancedPaymentAmount || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(creditInterests || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(debtsInterests || 0)}</Text>
              </View>
              <View
                style={{
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>{stringToCurrency(advancedPaymentInterests || 0)}</Text>
              </View>
              <View style={{ alignItems: "center" }}>
                <Text>{stringToCurrency(taxes || 0)}</Text>
              </View>
            </View>
            <View
              style={{
                color: "#002060",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "12px",
                marginTop: "12px"
              }}
            >
              <Text>{stringToCurrency(netOffer)}</Text>
            </View>
          </View>
        </View>

        {/* <View
          style={{
            border: "1.5px solid black",
            flexDirection: "row",
            fontSize: "10px",
            marginLeft: "16px",
            marginRight: "16px",
            paddingHorizontal: "12px",
            paddingVertical: "12px"
          }}
        >
          <View style={{ width: "60%" }}>
            <Text>
              Se autoriza aumento en el uso de porcentaje para negociar oferta
              de compara
            </Text>
          </View>
          <View style={{ width: "6%", alignItems: "flex-end" }}>
            <Text>0%</Text>
          </View>
          <View style={{ width: "33%", alignItems: "center" }}>
            <Text>{stringToCurrency("0")}</Text>
          </View>
        </View> */}

        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "66%", alignItems: "center" }}>
            <View
              style={{
                color: "#FF6B01",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "12px"
              }}
            >
              <Text>Precio Comercial</Text>
            </View>
            <View
              style={{
                color: "#FF6B01",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "4px"
              }}
            >
              <Text>Precio Inventario</Text>
            </View>
          </View>
          <View style={{ width: "33%" }}>
            <View
              style={{
                color: "#002060",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "12px"
              }}
            >
              <Text>{stringToCurrency(commercialPrice)}</Text>
            </View>
            <View
              style={{
                color: "#002060",
                alignItems: "center",
                width: "90%",
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "4px"
              }}
            >
              <Text>{stringToCurrency(commercialPrice + 27500)}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            width: "160px",
            marginTop: "12px",
            backgroundColor: "#002060",
            fontSize: "10px"
          }}
        >
          <Text style={{ color: "#fff" }}>DETALLES DE PROPIEDAD</Text>
        </View>

        <View
          style={{
            border: "1px solid black",
            fontSize: "10px",
            marginHorizontal: "16px",
            marginTop: "8px",
            flexDirection: "row"
          }}
        >
          <View style={{ width: "40%", borderRight: "1px solid black" }}>
            <View
              style={{
                borderBottom: "1px solid black",
                alignItems: "center",
                height: "32px",
                justifyContent: "center"
              }}
            >
              <Text>DIRECCIÓN COMERCIAL</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>NIVELES / PISO</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>RECAMARAS</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>SANITARIOS</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>M2 DE CONSTRUCCIÓN</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>M2 DE TERRENO</Text>
            </View>
            <View style={{ alignItems: "center" }}>
              <Text>ESTACIONAMIENTOS</Text>
            </View>
          </View>
          <View style={{ width: "60%" }}>
            <View
              style={{
                borderBottom: "1px solid black",
                alignItems: "center",
                height: "32px",
                justifyContent: "center"
              }}
            >
              <Text>
                {streetAndNumber} {postalCode} {settlement} {city}, {state}
              </Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>{floor}</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>{rooms || 0}</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>{baths || 0}</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>{mBuilding || 0}</Text>
            </View>
            <View
              style={{ borderBottom: "1px solid black", alignItems: "center" }}
            >
              <Text>{mLand || 0}</Text>
            </View>
            <View style={{ alignItems: "center" }}>
              <Text>{parkingLot || 0}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            border: "1px solid black",
            fontSize: "8px",
            marginHorizontal: "16px",
            marginTop: "16px",
            flexDirection: "row"
          }}
        >
          <View style={{ width: "100%" }}>
            <View
              style={{
                borderBottom: "1px solid black",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#002060",
                color: "#fff"
              }}
            >
              <Text>Notas</Text>
            </View>
            <View style={{ textAlign: "justify", padding: "4px" }}>
              <Text style={{ fontSize: "10px" }}>{notes}</Text>
            </View>
          </View>
        </View>

        <View
          style={{
            border: "1px solid black",
            fontSize: "8px",
            marginHorizontal: "16px",
            marginTop: "16px",
            flexDirection: "row"
          }}
        >
          <View style={{ width: "100%", borderRight: "1px solid black" }}>
            <View
              style={{
                borderBottom: "1px solid black",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#002060",
                color: "#fff"
              }}
            >
              <Text>HIPERVINCULOS</Text>
            </View>
            <View
              style={{
                borderBottom: "1px solid black",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                width: "100%"
              }}
            >
              <View
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>INSPECCIÓN</Text>
              </View>
              <View
                style={{
                  width: "70%",
                  alignItems: "center"
                }}
              >
                <Link src={inspectionUrl}>{inspectionUrl}</Link>
              </View>
            </View>
            <View
              style={{
                borderBottom: "1px solid black",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <View
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>UBICACIÓN</Text>
              </View>
              <View
                style={{
                  width: "70%",
                  alignItems: "center"
                }}
              >
                <Link src={locationUrl}>{locationUrl}</Link>
              </View>
            </View>
            <View
              style={{
                alignItems: "center",
                flexGrow: 0,
                flexDirection: "row"
              }}
            >
              <View
                style={{
                  width: "30%",
                  borderRight: "1px solid black",
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Text>DRIVE</Text>
              </View>
              <View
                style={{
                  width: "70%",
                  borderBottom: "1px solid black",
                  alignItems: "center"
                }}
              >
                <Link src={driveUrl}>{driveUrl}</Link>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfCtc;
