import { Box, Container, Typography } from "@mui/material";
import { useState } from "react";

import ctcLogo from "../../assets/Logo_CTC.svg";
import CodeVerification from "./CodeVerification";
import PasswordForm from "./PasswordForm";

const ChangePassword = () => {
  const [showComponent, setShowComponent] = useState("form"); // form o code

  return (
    <Container maxWidth="md">
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        pt={5}
      >
        <Box
          component={"img"}
          src={ctcLogo}
          alt="logo"
          className="w-25"
          m={"auto"}
          mb={4}
        />
        {showComponent === "form" && (
          <PasswordForm setShowComponent={setShowComponent} />
        )}
        {showComponent === "code" && (
          <CodeVerification setShowComponent={setShowComponent} />
        )}
      </Box>
    </Container>
  );
};

export default ChangePassword;
