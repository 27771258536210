import { Height } from "@mui/icons-material";
import { Button } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import zIndex from "@mui/material/styles/zIndex";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useContext } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { updateInspection } from "../../api/inspection/updateInspection";
import { getPropertyById } from "../../api/properties/getPropertyById";
import { deleteFullSeller } from "../../api/seller/deleteFullSeller";
import { getMe } from "../../api/session/getMe";
import { currentUser, propertyInfo } from "../../cacheQueries/cacheQueries";
import Loading from "../../components/loading/Loading";
import LeadStatusSelect from "../../components/Profile/LeadStatusSelect";
import NoPerfiladoButton from "../../components/Profile/NoPerfiladoButton";
import PerfiladoButton from "../../components/Profile/PerfiladoButton";
import LoadingContext from "../../context/Loading";
import TokenContext from "../../context/Token";
import AdviserInfo from "./adviserInfo/AdviserInfo";
import DebtsInfo from "./debtsInfo/DebtsInfo";
import Documentation from "./documentation/Documentation";
import FirstMeeting from "./firstMeeting/FirstMeeting";
import InfoBuilding from "./infoBuilding/InfoBuilding";
import InfoClient from "./InfoClient";
import InfoCredit from "./InfoCredit";
import InfoOffers from "./infoOffers/InfoOffers";
import InfoInspection from "./Inspection/InfoInspection";
import Monitoring from "./monitoring/Monitoring";
import NotesContainer from "./notes/NotesContainer";
import ObservationsContainer from "./observations/ObservationsContainer";
import OfferDocs from "./offerDocs/OfferDocs";
import Qr from "./Qr";
import SigningProcess from "./signingProcess/SigningProcess";
import StartBuyProcess from "./startBuyProcess/StartBuyProcess";

const PropertyData = () => {
  const { appLoading } = useContext(LoadingContext);
  const { token } = useContext(TokenContext);
  const { propertyId, processStatus } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery(
    [propertyInfo, token, propertyId],
    () => getPropertyById(token, propertyId)
  );

  const { data: me } = useQuery([currentUser, token], () => getMe(token));

  const {
    mutate,
    isLoading: isLoadingDeleteSeller,
    error
  } = useMutation(deleteFullSeller, {
    onSuccess: () => {
      // Invalidate and refetch
      if (window.location.pathname?.includes("prospectos")) {
        navigate("/ctc/compra/prospectos", { replace: true });
      } else {
        navigate("/ctc/compra/clientes", { replace: true });
      }
    }
  });

  if (isLoading || isLoadingDeleteSeller)
    return (
      <section className="flex-center">
        <Loading />
      </section>
    );

  const handleDelete = () => {
    console.log({ propertyId: data?.data?.property?._id });
    mutate({ propertyId: data?.data?.property?._id });
  };

  const changeRehabilitationApproved = async (rehabilitationApproved) => {
    try {
      await updateInspection(token, propertyId, {
        rehabilitationApproved
      });
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="flex-center" style={{ position: "relative" }}>
      {appLoading && (
        <Box
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
            backgroundColor: "rgba(0,0,0,0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loading />
        </Box>
      )}

      <div className="d-flex w-100 mt-4 ms-5 mb-4">
        <div onClick={() => navigate(-1)} className="historial-back ms-4">
          <MdArrowBackIos />
          <label>Regresar</label>
        </div>
      </div>
      <Container maxWidth="lg">
        <Grid container columnSpacing={2} mb={2}>
          <Grid item xs={12} md={5} display={"flex"}>
            <Typography
              variant="h5"
              color="primary"
              textAlign={"left"}
              fontWeight={600}
              mr={3}
            >
              Estatus:{" "}
              <Typography variant="h5" color="secondary" component={"span"}>
                {data?.data?.property?.reconversion === true
                  ? "Reconversión"
                  : data?.data?.property?.status === "Expediente"
                  ? "Integración de Expediente"
                  : data?.data?.property?.status === "Perfilado"
                  ? "Prospecto"
                  : data?.data?.property?.status === "Prospectos"
                  ? "Cliente"
                  : data?.data?.property?.status}
              </Typography>
            </Typography>
            {/* <Box>
              {!data?.data?.property?.reconversion === true ? (
                <NoPerfiladoButton
                  propertyId={data?.data?.property?._id}
                  buttonText={"Enviar a reconversión"}
                  type={"Rec"}
                />
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ width: 250, mt: 0 }}
                  onClick={() => navigate("/ctc/compra/reconversion")}
                >
                  Ir a reconversión
                </Button>
              )}
            </Box> */}
          </Grid>
          {/* <Grid item xs={12} md={5} display="flex" justifyContent={"center"}>
            {processStatus === "prospecto" && (
              <LeadStatusSelect
                lead={data?.data?.property?.lead ?? ""}
                propertyId={data?.data?.property?._id}
              />
            )}
          </Grid> */}
          {/* <Grid
            item
            xs={12}
            md={2}
            gap={2}
            display="flex"
            justifyContent={"end"}
          >
            {processStatus === "expediente" && (
              <Qr property={data?.data?.property ?? {}} refetch={refetch} />
            )}

            <DebtsInfo
              property={data?.data?.property ?? {}}
              refetch={refetch}
            />
          </Grid> */}
        </Grid>
        <Grid container gap={2}>
          {/* <Button
            variant="contained"
            color="error"
            size="small"
            onClick={handleDelete}
            disabled={data?.data?.property?.lastQuoteRef}
          >
            Eliminar prospecto
          </Button> */}
          {/* {data?.data?.property?.status === "Perfilado" && (
            <PerfiladoButton
              property={data?.data?.property ?? {}}
              propertyId={data?.data?.property?._id}
              refetch={refetch}
            />
          )} */}
        </Grid>

        <>
          <Box
            mb={2}
            sx={{
              backgroundColor: "#fff",
              position: "sticky",
              top: 0,
              zIndex: 10
            }}
          >
            <InfoClient
              sellerRef={data?.data?.property?.sellerRef ?? {}}
              refetch={refetch}
            />
          </Box>
          <Box mb={2}>
            <InfoBuilding
              property={data?.data?.property ?? {}}
              refetch={refetch}
            />
          </Box>
          {/* <Box mb={2}>
              <AdviserInfo
                role={me?.data?.admin?.role}
                propertyId={data?.data?.property?._id}
                adviserRef={data?.data?.property?.adviserRef ?? {}}
                refetch={refetch}
              />
            </Box>

            <InfoCredit
              credit={data?.data?.property?.sellerRef?.credit ?? ""}
              creditType={data?.data?.property?.creditType ?? ""}
              debtCredit={data?.data?.property?.debtCredit ?? ""}
              hasBacklogCredit={data?.data?.property?.hasBacklogCredit ?? ""}
              monthBacklogs={data?.data?.property?.monthBacklogs ?? ""}
              propertyId={data?.data?.property?._id}
              sellerId={data?.data?.property?.sellerRef?._id}
              refetch={refetch}
            /> */}
        </>

        {/* {(processStatus === "cliente" || processStatus === "cita") && (
          <Box mb={6}>
            <FirstMeeting
              appointment={
                data?.data.property?.appointments[
                  data?.data.property?.appointments?.length - 1
                ] ?? {}
              }
              propertyId={data?.data.property?._id}
              refetch={refetch}
            />
          </Box>
        )} */}
        <Box mb={10}>
          <InfoOffers
            refetch={refetch}
            propertyId={data?.data?.property?._id}
            mBuilding={data?.data?.property?.address?.mBuilding ?? 0}
            offer={data?.data?.property?.offer}
            quote={data?.data?.property?.quote}
            interval={data?.data?.property?.interval}
            debtCredit={data?.data?.property?.debtCredit}
            netOffer={data?.data?.property?.netOffer}
            offerAccepted={data?.data?.property?.offer_accepted ?? null}
            status={data?.data?.property?.status}
            debtsRef={data?.data?.property?.debtsRef ?? []}
            lastQuote={data?.data?.property?.lastQuoteRef}
            refetchCurrentProperty={refetch}
            property={data?.data?.property}
          />
        </Box>
        {(processStatus === "cita" || processStatus === "prospecto") && (
          <>
            {processStatus === "cita" && (
              <Box mb={10}>
                <OfferDocs
                  property={data?.data?.property}
                  debtsRef={data?.data?.property?.debtsRef ?? []}
                  refetch={refetch}
                />
              </Box>
            )}
          </>
        )}
        {processStatus === "expediente" && (
          <>
            <Box mb={10}>
              <InfoInspection
                visitDate={data?.data?.property?.visitDate ?? ""}
                path_video={data?.data?.property?.path_video}
                propertyId={data?.data?.property?._id}
                inspection={data?.data?.property?.inspection ?? []}
                rehabilitationApproved={
                  data?.data?.property?.inspection?.rehabilitationApproved
                }
                changeRehabilitationApproved={changeRehabilitationApproved}
                refetch={refetch}
              />
            </Box>
          </>
        )}
        {(processStatus === "expediente" || processStatus === "firma") && (
          <Box mb={10} id="expediente">
            <Documentation
              property={data?.data?.property ?? {}}
              refetch={refetch}
            />
          </Box>
        )}
        {processStatus === "inicio-proceso-de-compra" && (
          <>
            <Box mb={10}>
              <Documentation
                property={data?.data?.property ?? {}}
                refetch={refetch}
              />
            </Box>
            <Box mb={10}>
              <StartBuyProcess
                property={data?.data?.property}
                sellerId={data?.data?.property?.sellerRef?._id}
                legal={data?.data?.property?.legal ?? false}
                refetchProperties={refetch}
              />
            </Box>
          </>
        )}
        {processStatus === "firma" && (
          <Box mb={10} id="firma">
            <SigningProcess property={data?.data?.property} />
          </Box>
        )}
        {(processStatus === "expediente" ||
          processStatus === "inicio-proceso-de-compra" ||
          processStatus === "firma") && (
          <Box mb={10}>
            <Monitoring
              monitoringEvents={
                data?.data?.property?.inspection?.monitoring?.events
              }
            />
          </Box>
        )}
        <Grid container columnSpacing={3} mb={10}>
          <Grid item xs={12} md={6}>
            <ObservationsContainer
              property={data?.data?.property}
              refetch={refetch}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <NotesContainer
              status={data?.data?.property?.status ?? ""}
              propertyId={data?.data?.property?._id}
              notes={data?.data?.property?.descriptionsRef ?? []}
              refetch={refetch}
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default PropertyData;
