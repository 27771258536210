import React from "react";

import ProfileCard from "../../components/SellerProperty/Profiles/ProfilesCard";

const Profiles = () => {
  return (
    <section className="flex-center pt-5 pb-5">
      {/* {data.map(profile => (
        <ProfileCard name={profile.name} email={profile.email} phone={profile.phone} />
      ))} */}
    </section>
  );
};

export default Profiles;
