import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from "@mui/material";
import moment from "moment";
import { useState } from "react";

import { useModal } from "../../../hooks/useModal";
import ModalPreviousLayaways from "./ModalPreviousLayaways";

const LayawayInfo = () => {
  const [layAway, setLayAway] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState("Tarjeta");
  const [paymentDone, setPaymentDone] = useState(false);
  const [requestDate, setrequestDate] = useState(Date.now());
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Box display="flex">
        <Typography
          variant="h5"
          color="primary"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Información de Apartado
        </Typography>
        <FormControlLabel
          sx={{
            ".MuiFormControlLabel-label": {
              fontSize: 20,
              fontWeight: "bold",
              color: "#F1592A"
            }
          }}
          labelPlacement="start"
          control={
            <Switch value={layAway} onChange={() => setLayAway(!layAway)} />
          }
          label="Apartada"
        />
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="info-building-content"
          id="info-building-header"
        ></AccordionSummary>
        <AccordionDetails>
          <Grid container columnSpacing={3} rowSpacing={2} mb={2}>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Nombre*
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                Nombre Apellido1 Apellido2
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Correo electrónico*
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                test@test.com
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Teléfono*
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                0000000000
              </Typography>
            </Grid>

            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Origen
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                Landing page
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                Método de pago
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {paymentMethod}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              <Typography
                variant="h6"
                color="secondary"
                textAlign={"start"}
                width={"100%"}
                fontWeight={"bold"}
              >
                {paymentMethod === "Oxxo"
                  ? "Fecha límite de pago"
                  : "Fecha de pago"}
              </Typography>
              <Typography variant="h6" color="secondary" textAlign={"start"}>
                {paymentMethod === "Oxxo"
                  ? moment(requestDate).add(1, "days").format("DD/MM/YYYY")
                  : moment(requestDate).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              md={4}
              lg={3}
              display="flex"
              flexDirection={"column"}
            >
              {paymentMethod === "Oxxo" && (
                <>
                  <Typography
                    variant="h6"
                    color="secondary"
                    textAlign={"start"}
                    width={"100%"}
                    fontWeight={"bold"}
                  >
                    Pagado
                  </Typography>
                  <Typography
                    variant="h6"
                    color="secondary"
                    textAlign={"start"}
                  >
                    {paymentDone === true ? "Sí" : "Pendiente"}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={6} md={4} lg={3} display="flex">
              <Button variant="contained" onClick={onOpenModal}>
                Historial
              </Button>
            </Grid>
            {layAway && (
              <Grid item sm={6} md={4} lg={3} display="flex">
                <Button variant="contained">Reembolsar</Button>
              </Grid>
            )}
            {open && (
              <ModalPreviousLayaways open={open} onCloseModal={onCloseModal} />
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default LayawayInfo;
