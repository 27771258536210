const Staff = (props) => {
  return (
    <div className="my-3 d-flex flex-column">
      <label className="fs-4 property-title w-100 text-start">Asesores:</label>

      <label className="value-title">
        <span className="fw-bold me-2">
          {props.adviserRef?.name && "Compra:"}
        </span>{" "}
        {props.adviserRef?.name ?? ""}
      </label>
      <label className="value-title">
        <span className="fw-bold me-2">
          {props.saleAdviserRef?.name && "Venta:"}
        </span>
        {props.saleAdviserRef?.name ?? ""}
      </label>

      <label className="fs-4 property-title w-100 mt-2 text-start">
        Abogados:
      </label>

      <label className="value-title">
        <span className="fw-bold me-2">
          {props.buyLawyerRef?.name && "Compra:"}
        </span>
        {props.buyLawyerRef?.name ?? ""}
        {props.buyLawyerRef?.fLastName && " "}
        {props.buyLawyerRef?.fLastName ?? ""}
        {props.buyLawyerRef?.mLastName && " "}
        {props.buyLawyerRef?.mLastName ?? ""}
      </label>
      <label className="value-title">
        <span className="fw-bold me-2">
          {props.saleLawyerRef?.name && "Venta:"}
        </span>
        {props.saleLawyerRef?.name ?? ""}
        {props.saleLawyerRef?.fLastName && " "}
        {props.saleLawyerRef?.fLastName ?? ""}
        {props.saleLawyerRef?.mLastName && " "}
        {props.saleLawyerRef?.mLastName ?? ""}
      </label>
    </div>
  );
};

export default Staff;
