const TableHeader = ({ columnTitles }) => {
  return (
    <thead style={{ backgroundColor: "#F2F2F2", color: "#718195" }}>
      <tr>
        {columnTitles.length > 0 &&
          columnTitles.map((title, i) => <th key={i}>{title}</th>)}
      </tr>
    </thead>
  );
};

export default TableHeader;
