const tasaMin = 0.1; // %
const tasaMax = 0.3; // %
const diasAlAño = 365;
const tasaDiaria = (tasaMax - tasaMin) / diasAlAño; // %

function getPorcentage(days) {
  return Number(tasaMin + (diasAlAño - days) * tasaDiaria);
}

export default function getOffer(propertyValue, days) {
  return Number(propertyValue - getPorcentage(days).toFixed(4) * propertyValue);
}
