import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: "#F1592A",
      dark: "#F69477",
      light: "#AA553B",
      contrastText: "#fff" // Add contrastText to ensure it's defined
    },
    secondary: {
      main: "#718195",
      dark: "#8D9AAA",
      light: "#4F5A68",
      contrastText: "#fff" // Add contrastText to ensure it's defined
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff"
    },
    white: {
      main: "#FFF",
      dark: "#FFF",
      light: "#FFF",
      contrastText: "#000"
    },
    orange: {
      main: "#FFA500",
      dark: "#FFB733",
      light: "#B27300",
      contrastText: "#fff"
    },
    error: {
      main: "#E51A1A",
      dark: "#C81D1D",
      contrastText: "#fff" // Add contrastText to ensure it's defined
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(1),
          marginBottom: defaultTheme.spacing(1)
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "blue" },
          style: {
            backgroundColor: "#0053C5",
            ":hover": { backgroundColor: "#247fff" }
          }
        },
        {
          props: { variant: "reasonButton" },
          style: {
            backgroundColor: "#EE7833",
            ":hover": { backgroundColor: "#F69477" }
          }
        }
      ],
      defaultProps: {
        variant: "contained",
        color: "primary" // Use primary color name instead of hex value
      },
      styleOverrides: {
        blue: {
          boxShadow: "2px 2px 4px 1px rgba(128,128,128,0.8)",
          minWidth: 180,
          "&.Mui-disabled": {
            backgroundColor: grey[500],
            color: grey[200],
            boxShadow: "none"
          }
        },
        contained: {
          boxShadow: "2px 2px 4px 1px rgba(128,128,128,0.8)",
          minWidth: 180
        },
        reasonButton: {
          boxShadow: "2px 2px 4px 1px rgba(128,128,128,0.8)",
          minWidth: 130,
          "&.Mui-disabled": {
            backgroundColor: grey[500],
            color: grey[200],
            boxShadow: "none"
          }
        },
        text: {
          color: "#EE7833"
        },
        root: {
          marginTop: defaultTheme.spacing(1.5),
          marginBottom: defaultTheme.spacing(1.5),
          textTransform: "initial",
          color: "#fff",
          borderRadius: 30
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: grey[200]
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: "#718195",
          fontWeight: "bold"
        },
        body: {
          color: "#718195"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: defaultTheme.palette.primary.main,
          color: defaultTheme.palette.primary.contrastText,
          textAlign: "center"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "0.5rem"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          paddingBottom: defaultTheme.spacing(2),
          paddingTop: defaultTheme.spacing(1)
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none" // Eliminate the shadow from Accordion
        }
      }
    }
  }
});

export default theme;
