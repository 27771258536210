import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { AiFillEye } from "react-icons/ai";

import { updateCurrentDoc } from "../../api/documentations/buyer/updateDocBuyer";
import { ButtonLoading } from "../../components/Deed/ButtonLoading";
import TokenContext from "../../context/Token";

const { REACT_APP_DOCS_URL } = process.env;

export const DeedFileLink = (props) => {
  const { token } = useContext(TokenContext);
  const [valid, setValid] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setloading] = useState(false);
  const [comment, setComment] = useState(
    props.valid === false ? props?.comment : ""
  );

  const handlevalid = async (e) => {
    if (e.target.value === "si") {
      updateCurrentDoc(token, props._id, { valid: true, reUploaded: false })
        .then((doc) => {
          props.setValidDoc(doc);
          setValid(doc.valid);
        })
        .catch((error) => console.log(error));
    }

    if (e.target.value === "no") {
      setIsDisabled(false);
    }
  };

  const handleInvalid = () => {
    setloading(true);

    updateCurrentDoc(token, props._id, { valid: false, comment })
      .then((doc) => props.setValidDoc(doc))
      .finally(() => setloading(false));
  };

  useEffect(() => {
    setIsDisabled(props?.valid === false ? false : true);

    setValid(props?.valid ? props.valid : false);
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-4">
        <p className="deed-text text-center">
          {props.reUploaded && (
            <span className="text-primary">Corregido - </span>
          )}{" "}
          {" " + props.type}
        </p>
      </div>
      <div className="col-1">
        <a
          href={`${REACT_APP_DOCS_URL}/${props.path}`}
          target="_blank"
          rel="noreferrer"
        >
          <AiFillEye fontSize={"20px"} />
        </a>
      </div>

      {valid === true ? (
        <div className="col-7 fs-4 mb-1">
          <span className="badge rounded-pill bg-success">Valido</span>
        </div>
      ) : (
        <>
          <div className="col-2">
            <select
              onChange={handlevalid}
              className="form-select"
              defaultValue={props.valid === false ? "no" : ""}
            >
              <option value={""} disabled>
                Selecciona
              </option>
              <option value={"si"}>Si</option>
              <option value={"no"}>No</option>
            </select>
          </div>
          <div className="col-3">
            <input
              type="text"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              className="form-control"
              placeholder="Razón del rechazo"
              disabled={isDisabled}
            />
          </div>
          <div className="col-2">
            {loading ? (
              <ButtonLoading />
            ) : (
              <button
                className="deed-button"
                disabled={isDisabled}
                onClick={handleInvalid}
              >
                Enviar
              </button>
            )}
          </div>
        </>
      )}

      <hr />
    </div>
  );
};
