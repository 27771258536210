import React from "react";

import ProceedingCard from "../../components/SellerProperty/Proceeding/ProceedingCard";

const Proceeding = () => {
  return (
    <section className="flex-center">
      <ProceedingCard
        name="Miguel Ramirez"
        email="miguel_ram@gmail.com"
        phone="55 5555 5555"
      />
    </section>
  );
};

export default Proceeding;
