import { Button } from "@mui/material";
import { Link } from "react-router-dom";

//PROPS DEL COMPONENTE: id, property
const ToHistoryButton = (props) => {
  // GUARDA LA DIRECCION DEL INMUEBLE PARA USARLA COMO NOMBRE EN LA PAGINA DE HISTORIAL
  const savePropertyName = () => {
    const propertyName = `${props?.property?.address?.street ?? ""} ${
      props?.property?.address?.numExt ?? ""
    } ${props?.property?.address?.numInt ?? ""}`;

    localStorage.setItem("propertyName", propertyName);
  };

  return (
    <Link
      to={`/ctc/compra/${props?.id}/historial`}
      className="text-decoration-none"
    >
      <Button
        variant="contained"
        sx={{ mt: 0 }}
        fullWidth
        onClick={savePropertyName}
      >
        Historial
      </Button>
    </Link>
  );
};

export default ToHistoryButton;
