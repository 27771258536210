import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const uploadVideoProperty = async (
  token,
  propertyId,
  video,
  setProgress
) => {
  const formData = new FormData();

  formData.append("files", video);

  try {
    const response = await axios.put(
      `${REACT_APP_BASE_URL}/properties/${propertyId}/video`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted =
            Math.round(progressEvent.loaded / progressEvent.total) * 100;

          setProgress(percentCompleted);
        }
      }
    );

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err.type === "VALIDATION_ERROR"
        ? err?.errors[0]?.msg
        : err.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
