import { MdOutlineAddAPhoto } from "react-icons/md";

import { useModal } from "../../../hooks/useModal";
import UploadImageModal from "./UploadImageModal";

const UploadImageV2 = ({ propertyId, refetch }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <div className="me-3">
      <div
        className="select-file-box"
        style={{ cursor: "pointer" }}
        onClick={onOpenModal}
      >
        <MdOutlineAddAPhoto className="select-icons" />
        <label style={{ fontSize: 12 }}>Imagenes</label>
      </div>
      {open && (
        <UploadImageModal
          open={open}
          onCloseModal={onCloseModal}
          propertyId={propertyId}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default UploadImageV2;
