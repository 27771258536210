import { useContext } from "react";
import { useState } from "react";

import { updateDocument } from "../../../api/documentations/updateDocumentation";
import TokenContext from "../../../context/Token";

//PROPS DEL COMPONENTE: isValid, token, documentId, setSuccessMessage, setErrorMessage, getDocs
const ValidateDocument = (props) => {
  const { token } = useContext(TokenContext);

  const [isValid, setIsValid] = useState(props.isValid);

  const sendData = async (e) => {
    setIsValid(e.target.value);

    try {
      const response = await updateDocument(token, props.documentId, {
        valid: e.target.value
      });

      if (response.status === 200) {
        props.setSuccessMessage("Documento Actualizado");
        props.getDocs();
        setTimeout(() => {
          props.setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      props.setErrorMessage(error);
      setTimeout(() => {
        props.setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <select value={isValid ?? ""} onChange={sendData}>
      <option value="" hidden>
        Seleccionar
      </option>
      <option value={true}>Si</option>
      <option value={false}>No</option>
    </select>
  );
};

export default ValidateDocument;
