import React, { useState } from "react";
import "../styles/CardLayout.css";
import { BiMessageSquareEdit } from "react-icons/bi";
import { MdOutlineAddAPhoto } from "react-icons/md";

import { stringToCurrency } from "../../helpers/currencies";
import { stringToDMY } from "../../helpers/dates";
import DebtsTable from "../Modals/ModalDebts/DebtsTable";
import ModalDebts from "../Modals/ModalDebts/ModalDebts";
import ModalFirma from "../Modals/ModalFirma/ModalFirma";
import ModalLegal from "../Modals/ModalLegal/ModalLegal";
import ModalUploadMultimedia from "../Modals/modalUploadMultimedia/ModalUploadMultimedia";

import moment from "moment";

import Observations from "../Observations";
import InspectionData from "./InspectionData";
import UploadVideo from "./UploadVideo";
import ValidatePreinventory from "./ValidatePreinventory";
import VisitDate from "./VisitDate";

const { REACT_APP_IMAGES_URL } = process.env;

//PROPS DEL COMPONENTE: property, refetchProperties
const SignatureCard = (props) => {
  const [MLisActive, setMLisActive] = useState(false);

  return (
    <section className="card-component" style={{ width: 1000 }}>
      <section className="left-section-card">
        <h2 className="fs-5 text-capitalize">
          {props?.property?.address?.street ?? ""}
          {props?.property?.address?.street && " "}
          {props?.property?.address?.numInt ?? ""}
          {props?.property?.address?.numInt && ", "}
          {props?.property?.address?.numExt ?? ""}
          {props?.property?.address?.numExt && ", "}
          {props?.property?.settlement ?? ""}
          {props?.property?.settlement && ""}
        </h2>

        <label className="property-title">
          {props?.property?.offer
            ? stringToCurrency(props?.property?.offer)
            : props?.property?.quote
            ? stringToCurrency(props?.property?.quote)
            : " N/A"}
        </label>

        <label className="property-title">
          Nombre:
          <label className="value-title">
            {props?.property?.sellerRef?.name ?? ""}{" "}
            {props?.property?.sellerRef?.fLastName ?? ""}{" "}
            {props?.property?.sellerRef?.mLastName ?? ""}
          </label>
        </label>
        <label className="property-title">
          Correo electronico:
          <label className="value-title">
            {props?.property?.sellerRef?.email ?? ""}
          </label>
        </label>
        <label className="property-title">
          Telefono:
          <label className="value-title ms-2">
            {props?.property?.sellerRef?.phoneNumber ?? ""}
          </label>
        </label>

        <label className="property-title me-1" style={{ marginTop: 15 }}>
          Tipo de credito:
          <label className="value-title ms-2">
            {props?.property?.creditType ?? " No Especificado"}
          </label>
        </label>

        <label className="info-extend source">
          Fuente:
          {props?.property?.sellerRef?.source ?? "Desconocido"}
        </label>

        <div className="info-extend margin-element">
          <label className="property-title">
            Fecha de Creación:
            <label className="value-title ms-2">
              {moment(props?.property?.createdAt).format("DD/MM/Y") ?? ""}
              {" a las: "} {moment(props?.property?.createdAt).format("h:mm A")}
            </label>
          </label>
          <label className="property-title">
            Último Cambio:
            <label className="value-title ms-2">
              {moment(props?.property?.updatedAt).format("DD/MM/Y") ?? ""}
              {" a las: "} {moment(props?.property?.updatedAt).format("h:mm A")}
            </label>
          </label>
        </div>

        <VisitDate
          visitDate={props.property?.visitDate ?? ""}
          id={props.property?._id}
          refetchProperties={props.refetchProperties}
        />

        <div className="contain-selects align-items-start">
          <div className="ms-3">
            <div
              className="select-file-box"
              data-bs-toggle="modal"
              data-bs-target={`#multimedia${props.property?._id}`}
              style={{ cursor: "pointer" }}
            >
              <MdOutlineAddAPhoto className="select-icons" />
              <label style={{ fontSize: 12 }}>Imagenes</label>
            </div>
            <ModalUploadMultimedia
              id={props.property?._id}
              photos={props.property?.images ?? []}
              refetchProperties={props.refetchProperties}
            />
          </div>
          <div className="ms-3 d-flex">
            <UploadVideo id={props.property?._id} />
            {props?.property?.path_video && (
              <a
                href={`${REACT_APP_IMAGES_URL}/${props?.property?.path_video}`}
                target="_blank"
                rel="noreferrer"
                className="my-auto ms-5"
              >
                <button className="btn-fill-orange"> Ver Video</button>
              </a>
            )}
          </div>
          {props?.property?.legal &&
            props?.property?.legal === true &&
            props?.property?.inspection &&
            props?.property?.inspection === true &&
            !props?.property?.preInventory && (
              <ValidatePreinventory
                id={props?.property?._id}
                refetchProperties={props?.refetchProperties}
              />
            )}
        </div>
      </section>

      <section
        className="right-section-card"
        style={{ width: 500, textAlign: "left" }}
      >
        <BiMessageSquareEdit className="edit-element-icon" />

        <h2 className="fs-5">Informacion del inmueble</h2>

        <label className="property-title width-text">
          Estado:
          <label className="value-title">{props?.property?.state ?? ""}</label>
        </label>

        <label className="property-title width-text margin-element">
          Ubicacion:
          <label className="value-title">
            {props?.property?.address?.street ?? ""}
            {props?.property?.address?.street && " "}
            {props?.property?.address?.numInt ?? ""}
            {props?.property?.address?.numInt && ", "}
            {props?.property?.address?.numExt ?? ""}
            {props?.property?.address?.numExt && ", "}
            {props?.property?.settlement ?? ""}
            {props?.property?.settlement && ", "}
            {props?.property?.city ?? ""}
            {props?.property?.city && ", "}
            {props?.property?.state ?? ""}
            {props?.property?.state && ", "}
            {props?.property?.postalCode && "C.P.: "}
            {props?.property?.postalCode ?? ""}
          </label>
        </label>

        <h2 className="fs-5">Descripcion:</h2>

        <p>{props?.property?.description ?? "Sin Descripción"}</p>

        <h2 className="fs-5">Observaciones:</h2>
        <Observations
          property={props.property ?? {}}
          editable={false}
          refetchProperties={props.refetchProperties}
          lockBadges={true}
        />

        <h2 className="fs-5">Adeudos:</h2>
        <DebtsTable
          debtsRef={props.property.debtsRef}
          refetchProperties={props.refetchProperties}
        />

        <div className="contain-btn-signa margin-element">
          <button
            type="button"
            className="btn-fill-orange margin-element mt-0"
            style={{
              backgroundColor: !props.property?.visitDate ? "gray" : null
            }}
            data-bs-toggle="modal"
            data-bs-target={"#modalComment" + props.property?._id}
          >
            Inspección
          </button>
          <InspectionData
            id={props?.property?._id}
            inspectionText={props.property?.inspectionText ?? ""}
            refetchProperties={props.refetchProperties}
          />

          <button
            type="button"
            className="btn-fill-orange margin-element mt-0"
            data-bs-toggle="modal"
            data-bs-target={"#ModalLegal" + props.property?._id}
            onClick={() => setMLisActive(true)}
          >
            Legal
          </button>
          <ModalLegal
            id={props?.property?._id}
            MLisActive={MLisActive}
            setMLisActive={setMLisActive}
            sellerId={props?.property?.sellerRef?._id}
            legal={props.property?.legal ?? false}
            refetchProperties={props.refetchProperties}
          />

          <button
            type="button"
            className="btn-fill-orange margin-element mt-0"
            data-bs-toggle="modal"
            data-bs-target={`#modalDebts${props.property?._id}`}
          >
            Adeudos
          </button>
          <ModalDebts
            id={props?.property?._id}
            debtsRef={props?.property?.debtsRef}
            debts={props?.property?.debts ?? false}
            refetchProperties={props.refetchProperties}
          />
        </div>

        {props?.property?.legal &&
          props?.property?.legal === true &&
          props?.property?.inspection &&
          props?.property?.inspection === true &&
          props?.property?.preInventory === true && (
            <>
              <button
                type="button"
                className="btn-fill-orange"
                data-bs-toggle="modal"
                data-bs-target={"#modalFirma" + props?.property?._id}
              >
                Firma
              </button>
              <ModalFirma
                id={props?.property?._id}
                firmDate={props?.property?.firmDate ?? ""}
                refetchProperties={props.refetchProperties}
              />
            </>
          )}
      </section>
    </section>
  );
};

export default SignatureCard;
