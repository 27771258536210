import { Button } from "@mui/material";

import ModalReconversion from "./ModalReconversion";

const NoPerfiladoButton = (props) => {
  return (
    <>
      <Button
        sx={{ mt: 0, width: 200 }}
        variant="contained"
        color="secondary"
        data-bs-toggle="modal"
        data-bs-target={"#modalComment" + (props.type ?? "") + props.propertyId}
      >
        {props.buttonText}
      </Button>
      <ModalReconversion
        id={props.propertyId}
        titleModal="No perfilado"
        reason="Comentario"
        select={true}
        type={props.type ?? ""} //rec para reconversion, vacío para observacion en compra/expediente
      />
    </>
  );
};

export default NoPerfiladoButton;
