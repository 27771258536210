import PostAddIcon from "@mui/icons-material/PostAdd";
import { Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";

import FileIntegration from "../../../components/Modals/FileIntegration/FileIntegration";
import { useModal } from "../../../hooks/useModal";

const Documentation = ({ property, refetch, readOnly }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Box display={"flex"} justifyContent="start">
        <Typography
          variant="h5"
          className="blue-text"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Integración de expediente
        </Typography>
        <IconButton
          sx={{ color: "#0053C5" }}
          aria-label="modal-expediente"
          onClick={onOpenModal}
        >
          <PostAddIcon color="inherit" />
        </IconButton>

        {open && (
          <FileIntegration
            onClose={onCloseModal}
            onOpen={onOpenModal}
            open={open}
            property={property}
            refetch={refetch}
            readOnly={readOnly}
          />
        )}
      </Box>
    </>
  );
};

export default Documentation;
