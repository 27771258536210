import { createContext, useState } from "react";

const TokenContext = createContext();

const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token_CTC") ?? "");
  const [userType, setUserType] = useState(localStorage.getItem("UT") ?? "");

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken,
        userType,
        setUserType
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export { TokenProvider };

export default TokenContext;
