import { Box } from "@mui/material";
import { useState } from "react";

import errorImg from "../../../../../assets/error-image.png";
import { useModal } from "../../../../../hooks/useModal";
import ImgModal from "../../gallery/imgModal";

const EvidenceImg = ({ evidenceItem, modalType, changeEvidenceStatus }) => {
  const [imageSrc, setImageSrc] = useState(evidenceItem.photograph);
  const { open, onOpenModal, onCloseModal } = useModal();

  const handleImageError = () => {
    setImageSrc(errorImg);
  };

  return (
    <Box
      height={130}
      width={130}
      borderRadius={2}
      mr={2}
      mt={2}
      overflow="hidden"
      sx={{ cursor: "pointer" }}
    >
      <img
        height={"100%"}
        width={"100%"}
        src={imageSrc}
        alt={"img"}
        onError={handleImageError}
        onClick={onOpenModal}
      />
      <ImgModal
        open={open}
        onCloseModal={onCloseModal}
        imageSrc={imageSrc}
        evidenceItem={evidenceItem}
        evidenceModal={true}
        modalType={modalType}
        changeEvidenceStatus={changeEvidenceStatus}
      />
    </Box>
  );
};

export default EvidenceImg;
