import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Grow,
  MenuItem,
  Pagination,
  Snackbar,
  TextField
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { getProperties } from "../../api/properties/getProperties";
import Loading from "../../components/loading/Loading";
import {
  useCities,
  useSettlements
} from "../../components/Modals/PropertyModal/CreatePropertyContainer";
import CreatePropertyModal from "../../components/Modals/PropertyModal/CreatePropertyModal";
import EditPropertyModal from "../../components/Modals/PropertyModal/EditPropertyModal";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";
import { useModal } from "../../hooks/useModal";

const MEXICO_STATES = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Chiapas",
  "Chihuahua",
  "Ciudad de México",
  "Coahuila",
  "Colima",
  "Durango",
  "Estado de México",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "Michoacán",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz",
  "Yucatán"
];

const defaultFilters = {
  state: null,
  city: "",
  settlement: ""
};

const SearchBar = (props) => {
  console.log(props);

  const [checked, setChecked] = useState(false);
  const citiesList = useCities(props?.filters?.state);
  const settlementsList = useSettlements(
    props?.filters?.state,
    props?.filters?.city
  );

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setChecked(false);
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Button
          variant="contained"
          sx={{ ml: 3 }}
          onClick={(e) => setChecked(!checked)}
          endIcon={<FilterAltIcon />}
        >
          Filtros
        </Button>
        <Box sx={{ position: "absolute", right: -720, top: -120, zIndex: 10 }}>
          {checked && (
            <Grow in={checked}>
              <Card sx={{ width: 700 }}>
                <CardContent>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        id="state"
                        value={props?.filters?.state}
                        onChange={(event, newValue) =>
                          props.onChange({
                            target: { name: "state", value: newValue }
                          })
                        }
                        options={MEXICO_STATES}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="state"
                            label="Estado"
                            variant="outlined"
                            placeholder="Seleccionar..."
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        id="city"
                        value={props?.filters?.city}
                        onChange={(event, newValue) =>
                          props.onChange({
                            target: { name: "city", value: newValue }
                          })
                        }
                        options={citiesList}
                        disabled={citiesList.length === 0}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="city"
                            label="Municipio"
                            variant="outlined"
                            placeholder="Seleccionar..."
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Autocomplete
                        id="settlement"
                        value={props?.filters?.settlement}
                        onChange={(event, newValue) =>
                          props.onChange({
                            target: { name: "settlement", value: newValue }
                          })
                        }
                        options={settlementsList}
                        disabled={settlementsList.length === 0}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="settlement"
                            label="Colonia"
                            variant="outlined"
                            placeholder="Seleccionar..."
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={"center"}
                  >
                    <Grid item>
                      <Button fullWidth sx={{ m: 0 }} onClick={props.clean}>
                        Limpiar
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        fullWidth
                        sx={{ m: 0 }}
                        onClick={props.handleFilter}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grow>
          )}
        </Box>
      </Box>
    </>
  );
};

const GeneralFilter = ({ refetch, filters, setFilters }) => {
  const handleChangeFilters = (e) => {
    if (e?.target?.name === "state") {
      setFilters({
        ...filters,
        state: String(e?.target?.value || ""),
        city: "",
        settlement: ""
      });
    } else if (e?.target?.name === "city") {
      setFilters({
        ...filters,
        city: String(e?.target?.value || ""),
        settlement: ""
      });
    } else if (e?.target?.name === "settlement") {
      setFilters({
        ...filters,
        settlement: String(e?.target?.value || "")
      });
    }
  };

  const handleCleanFilters = () => {
    console.log("Cleaned filters");
    setFilters(defaultFilters);
  };

  const handleFilter = () => {
    refetch();
  };

  return (
    <>
      <SearchBar
        filters={filters}
        onChange={handleChangeFilters}
        clean={handleCleanFilters}
        handleFilter={handleFilter}
      />
    </>
  );
};

const InventoryContainer = ({
  openCreate,
  onCloseCreateModal,
  onOpenCreateModal,
  selectTab,
  isUpdating
}) => {
  const {
    open: openEdit,
    onCloseModal: onCloseEditModal,
    onOpenModal: onOpenEditModal
  } = useModal();
  const [results] = useState(20);
  const [openNotificationFeedback, setOpenNotificationFeedback] =
    useState(false);
  const [notificationFeedbackMessage, setNotificationFeedbackMessage] =
    useState({ message: "", type: "" });
  const [page, setPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(1);
  const [status] = useState("Inventario");
  const { token } = useContext(TokenContext);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const propertyId = queryParams.get("propertyId");
  const [filters, setFilters] = useState(defaultFilters);
  const {
    data,
    isLoading,
    isRefetching,
    refetch: refetchInvetoryProperties
  } = useQuery(
    ["Inventory-properties", token, results, page, status],
    () => getProperties(token, results, page, status, "", "", { ...filters }),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  useEffect(() => {
    if (propertyId) {
      onOpenEditModal();
    }
  }, [onOpenEditModal, propertyId]);

  useEffect(() => {
    const getPaginationValue = () => {
      setPaginationValue(Math.ceil(data?.properties?.totalDocs / results));
    };

    getPaginationValue();
  }, [data?.properties?.totalDocs, results]);

  const housesFilter = data?.properties?.docs
    ?.filter((house) => house.statusSale === selectTab)
    .map((house) => house);

  const closeCreateModal = () => {
    onCloseCreateModal();
    navigate("/ctc/dashboard/inventario", { replace: true });
  };

  const closeEditModal = () => {
    onCloseEditModal();
    navigate("/ctc/dashboard/inventario", { replace: true });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenNotificationFeedback(false);
  };

  const showFeedbackMessage = ({ message, type }) => {
    setNotificationFeedbackMessage({ message, type });
    setOpenNotificationFeedback(true);
  };

  return (
    <>
      <Box mt={2} height={32}>
        {paginationValue > 0 && (
          <Pagination
            color="primary"
            count={paginationValue}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        )}
      </Box>
      <Box style={{ width: "100%", paddingLeft: "8px" }} display="flex">
        <GeneralFilter
          refetch={refetchInvetoryProperties}
          filters={filters}
          setFilters={setFilters}
        />
      </Box>
      {!isLoading && !isRefetching && !isUpdating ? (
        <Table
          columnTitles={[
            "Id de la Casa",
            "Status",
            "Precio Venta",
            "Estado",
            "Municipio-Colonia",
            "Más"
          ]}
          properties={selectTab ? housesFilter : data?.properties ?? []}
          tableType={"Inventario"}
        />
      ) : (
        <Loading />
      )}
      <CreatePropertyModal
        open={openCreate}
        onCloseModal={closeCreateModal}
        showFeedbackMessage={showFeedbackMessage}
        refetchInvetoryProperties={refetchInvetoryProperties}
      />
      <EditPropertyModal
        open={openEdit}
        onCloseModal={closeEditModal}
        showFeedbackMessage={showFeedbackMessage}
        refetchInvetoryProperties={refetchInvetoryProperties}
      />
      <Snackbar
        open={openNotificationFeedback}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={notificationFeedbackMessage.type}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notificationFeedbackMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InventoryContainer;
