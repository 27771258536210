import React, { useState } from "react";

import MetricsMenu from "../../components/Metrics/MetricsMenu";
import DisplaySection from "./DisplaySection";

const Metrics = () => {
  const [displayComponent, setDisplayComponent] = useState("cotizacion");

  return (
    <section className="flex-center">
      <MetricsMenu setDisplayComponent={setDisplayComponent} />
      <DisplaySection displayComponent={displayComponent} />
    </section>
  );
};

export default Metrics;
