import axios from "axios";

export const geocode = async (apikey, postalCode) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?components=country:mexico%7Cpostal_code:${postalCode}&key=${apikey}`
    );

    return response;
  } catch (error) {
    return error;
  }
};
