import { MdUploadFile } from "react-icons/md";

const ModalUploadDoc = (props) => {
  return (
    <div
      className="modal fade"
      id="ModalUploadDoc"
      tabIndex="-1"
      aria-labelledby="ModalUploadDocLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-gray fw-bold"
              id="ModalUploadDocLabel"
            >
              Documentación
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <section className="modal-documentacion">
              <section className="modal-upload align-items-center">
                <div className="select-file-box">
                  <MdUploadFile className="select-icons" />
                  <label>Examinar</label>
                </div>
                <button
                  style={{ marginTop: "1rem" }}
                  className="btn-fill-gray w-75"
                >
                  Cargar
                </button>
              </section>
              <select className="select-documentacion w-25">
                <option hidden selected>
                  Revisar lista
                </option>
                <option>INE Ratificada con Validación</option>
                <option>Acta de nacimiento con validación</option>
                <option>CURP</option>
                <option>RFC</option>
                <option>Comprobante de domicilio no mayor a 3 meses</option>
                <option>Precalificación</option>
                <option>Solicitud Inscripción de crédito</option>
                <option>Solicitud Avaluo</option>
                <option>Bansefi</option>
                <option>Taller</option>
                <option>SICs</option>
                <option>Hoja de datos generales</option>
                <option>Contrato</option>
              </select>
              <label>Documentos cargados</label>
              <div className="d-flex flex-column text-start">
                <label className="mb-2">Monto del evaluo</label>
                <input type="text" placeholder="$XXXXXX" />

                {props.date && (
                  <>
                    <label className="mt-4 fs-6">Fecha establecida</label>
                    <label className="mt-1">{props.date}</label>
                  </>
                )}

                {props.isDeed && (
                  <>
                    <button
                      className="btn-fill-orange mt-4"
                      data-bs-toggle="modal"
                      data-bs-target="#ModalCalendar"
                    >
                      Agendar firma
                    </button>
                  </>
                )}
              </div>
            </section>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={props.onHandleOk}
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUploadDoc;
