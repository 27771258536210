import { Delete } from "@mui/icons-material";
import { Chip, Grid, IconButton } from "@mui/material";
import { useContext } from "react";

import { deleteDocs } from "../../../api/documentations/deleteDocs";
import TokenContext from "../../../context/Token";

export const FileLinkDoc = (props) => {
  const { token } = useContext(TokenContext);

  const handleDelete = async () => {
    try {
      await deleteDocs(token, props?.doc?._id);

      props.refetchClientUploadedDocs();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <a
            className="link"
            href={`${props.doc.path}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.doc.type} - Subido{" "}
            {new Date(props.doc.createdAt).toLocaleDateString()}
          </a>
        </Grid>
        <Grid item xs={12} md={12}>
          {props.doc?.valid === false && (
            <Chip variant="outlined" color="error" label={props.doc?.comment} />
          )}
        </Grid>
      </Grid>
      {!props.readOnly && (
        <IconButton onClick={handleDelete}>
          <Delete />
        </IconButton>
      )}
    </>
  );
};
