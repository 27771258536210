import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

const DrawerList = ({ currentParam, setCurrentParam, setShownSection }) => {
  const handleClick = (name, title) => {
    setCurrentParam({ ...currentParam, name, title });
    setShownSection("options");
  };

  return (
    <List>
      {[
        { name: "status", title: "Estado del Lead" },
        { name: "state", title: "Estado donde quiere vender" },
        { name: "createdAt", title: "Fecha de creación" },
        { name: "source", title: "Fuente" }
      ].map((param) => (
        <ListItem key={param.name} disablePadding>
          <ListItemButton onClick={() => handleClick(param.name, param.title)}>
            <ListItemText primary={param.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default DrawerList;
