import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const deleteImageProperty = async (token, propertyId, imgId) => {
  let url = `${REACT_APP_BASE_URL}/properties/${propertyId}/images/${imgId}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
