import { Alert, Box, MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { useQuery } from "react-query";

import { assignInspectorMonitor } from "../../../../api/inspection/assingInspectorMonitor";
import { createInspector } from "../../../../api/inspectors/createInspector";
import { getInspectorList } from "../../../../api/inspectors/getInspectorList";
import { inspectorList } from "../../../../cacheQueries/cacheQueries";
import Loading from "../../../../components/loading/Loading";
import TokenContext from "../../../../context/Token";
import AddWorker from "./modals/AddWorker";

const InspectorList = ({ inspector = {}, propertyId }) => {
  const { token, userType } = useContext(TokenContext);
  const [message, setMessage] = useState({ severity: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [inspectorState, setInspectorState] = useState(inspector._id ?? "");
  const { data, isLoading, refetch } = useQuery([inspectorList], () =>
    getInspectorList(token)
  );

  const assignInspector = async (e) => {
    setLoading(true);
    try {
      await assignInspectorMonitor(token, propertyId, {
        inspector: e.target.value
      });
      setLoading(false);
      setMessage({ severity: "success", text: "Actualizado" });
      refetch();
      setTimeout(() => {
        setMessage({ severity: "", text: "" });
      }, 3000);
      setInspectorState(e.target.value);
    } catch (error) {
      setLoading(false);
      setMessage({ severity: "error", text: error });
      refetch();
      setTimeout(() => {
        setMessage({ severity: "", text: "" });
      }, 3000);
    }
  };

  if (isLoading) return <Loading />;
  return (
    <Box display={"flex"} gap={1}>
      <Box display={"flex"} width={200} flexDirection={"column"}>
        <TextField
          disabled={loading}
          fullWidth
          size="small"
          name="inspector"
          label={loading ? "Actualizando..." : "Inspector"}
          select
          value={inspectorState}
          onChange={assignInspector}
        >
          {data?.data?.inspectors?.map((i) => (
            <MenuItem key={i._id} value={i._id}>
              {i?.name}
              {i.fLastName ? ` ${i.fLastName}` : ""}
              {i.mLastName ? ` ${i.mLastName}` : ""}
            </MenuItem>
          ))}
        </TextField>
        {message.severity !== "" && (
          <Alert severity={message.severity}>{message.text}</Alert>
        )}
      </Box>
      {userType === "Admin" && (
        <AddWorker
          workerType="inspector"
          creationFunction={createInspector}
          refetch={refetch}
        />
      )}
    </Box>
  );
};

export default InspectorList;
