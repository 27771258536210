import { Button } from "@mui/material";
import { useState } from "react";

import ModalLegal from "../../components/Modals/ModalLegal/ModalLegal";

const ValidateButton = ({ propertyId, sellerId, legal, refetchProperties }) => {
  const [MLisActive, setMLisActive] = useState(false);

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        sx={{ mt: 0 }}
        data-bs-toggle="modal"
        data-bs-target={"#ModalLegal" + propertyId}
        onClick={() => setMLisActive(true)}
      >
        Validación
      </Button>
      <ModalLegal
        id={propertyId}
        MLisActive={MLisActive}
        setMLisActive={setMLisActive}
        sellerId={sellerId}
        legal={legal ?? false}
        refetchProperties={refetchProperties}
      />
    </>
  );
};

export default ValidateButton;
