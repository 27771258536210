import moment from "moment";

import Loading from "../../components/loading/Loading";

const Visits = ({ data, isLoading, error }) => {
  // console.log(data?.data ?? "")

  if (isLoading) return <Loading />;

  if (error) return <p>{error}</p>;

  return (
    <>
      {data?.data?.visits?.map((v) => (
        <div key={v._id} className="text-start d-flex flex-column mb-3">
          <div>
            <label className="property-title">Asesor:</label>
            <label className="value-title">{v.adviserRef?.name}</label>
          </div>
          <div>
            <label className="property-title me-1">Razon de visita:</label>
            <label className="value-title">{v.reason}</label>
          </div>
          {v.companion && (
            <div>
              <label className="property-title me-1">Acompañante(s):</label>
              <label className="value-title">{v.companion}</label>
            </div>
          )}
          <div>
            <label className="property-title me-1">Fecha de visita:</label>
            <label className="value-title">
              {moment(v?.date ?? "").format("DD/MMM/yyyy") ?? "N/A"}
            </label>
          </div>
          <div>
            <label className="property-title me-1">Hora de visita:</label>
            <label className="value-title">
              {moment(v?.date ?? "").format("hh:mm A") ?? "N/A"}
            </label>
          </div>
        </div>
      ))}
    </>
  );
};

export default Visits;
