import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Visibility, Search, CloseOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { el } from "date-fns/locale";
import mapboxgl from "mapbox-gl"; // eslint-disable-line
import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

import { getAppraisalsPostalCodes } from "../../api/quoters/getAppraisalsPostalCodes";
import Map from "./Map";
import mapInstance, { renderPopUpMuseum } from "./mapUtils";
import statesGeojson from "./states.geojson";
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const initial_coordinate = [-99.0715511, 19.3511648];

const mapboxSourceLayers = [
  {
    layerName: "prodshapefilemx-layer",
    sourceName: "prodshapefilemx-source",
    sourceUrl: "yamir-ctc.81psjbkc",
    sourceLayer: "prodshapefilemx"
  }
];

function MapQuoter() {
  const [apiResults, setApiResults] = useState(null);
  const [selectedCP, setSelectedCP] = useState(0);
  const mapContainer = useRef(null);
  const popUp = useRef(null);
  const [currentLocation, setCurrentLocation] = useState(
    "-99.0715511,19.3511648"
  );
  const [zoom /* , setZoom */] = useState(8);
  const map = React.useRef(null);
  const [type, setType] = useState("all");
  const [origin, setOrigin] = useState("all");
  const [version, setVersion] = useState("v2");
  const [desviationVectorEnabled, setDesviationVectorEnabled] = useState(false);
  const [loadingMap, setLoadingMap] = useState(true);
  const [rooms, setRooms] = useState(0);
  const [baths, setBaths] = useState(0);

  const updateMapFilterBathandRooms = async (e) => {
    setLoadingMap(true);
    console.log(e.target);

    const data = await getAppraisalsPostalCodes({
      type,
      version,
      rooms: e.target.name === "rooms" ? e.target.value : rooms,
      baths: e.target.name === "baths" ? e.target.value : baths
    });

    console.log(data);

    const results = data?.data?.data;

    const existingCp = results.map((result) => String(result.cp));

    console.log(existingCp);

    for (const data of mapboxSourceLayers) {
      if (map.current.getLayer(data.layerName)) {
        map.current.setFilter(
          data.layerName,
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      if (map.current.getLayer(data.layerName + "-outline")) {
        map.current.setFilter(
          data.layerName + "-outline",
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      if (map.current.getLayer(data.layerName + "-raster")) {
        map.current.setFilter(data.layerName + "-raster", [
          "all",
          ["has", "desviation"], // Filter to include features where desviation exists
          ["in", "d_codigo"].concat(existingCp)
        ]);
      }
    }

    if (e.target.name === "rooms") {
      setRooms(e.target.value);
    } else {
      setBaths(e.target.value);
    }

    setApiResults(results);
    setLoadingMap(false);
  };

  const updateMapFilter = async (e) => {
    setLoadingMap(true);

    const data = await getAppraisalsPostalCodes({
      type: e.target.value,
      version,
      rooms,
      baths
    });

    console.log(data);

    const results = data?.data?.data;

    const existingCp = results.map((result) => String(result.cp));

    console.log(existingCp);

    for (const data of mapboxSourceLayers) {
      if (map.current.getLayer(data.layerName)) {
        map.current.setFilter(
          data.layerName,
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      if (map.current.getLayer(data.layerName + "-outline")) {
        map.current.setFilter(
          data.layerName + "-outline",
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      if (map.current.getLayer(data.layerName + "-raster")) {
        map.current.setFilter(data.layerName + "-raster", [
          "all",
          ["has", "desviation"], // Filter to include features where desviation exists
          ["in", "d_codigo"].concat(existingCp)
        ]);
      }
    }

    setType(e.target.value);

    setApiResults(results);
    setLoadingMap(false);
  };

  const updateMapFilterVersion = async (e) => {
    setLoadingMap(true);

    const data = await getAppraisalsPostalCodes({
      type,
      version: e.target.value,
      rooms,
      baths
    });

    console.log(data);

    const results = data?.data?.data;

    const existingCp = results.map((result) => String(result.cp));

    console.log(existingCp);

    for (const data of mapboxSourceLayers) {
      if (map.current.getLayer(data.layerName)) {
        map.current.setFilter(
          data.layerName,
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      if (map.current.getLayer(data.layerName + "-outline")) {
        map.current.setFilter(
          data.layerName + "-outline",
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      /* if (map.current.getLayer(data.layerName + "-raster")) {
        map.current.setFilter(data.layerName + "-raster", [
          "all",
          ["has", "desviation"],
          ["in", "d_codigo"].concat(existingCp)
        ]);
      } */
    }

    setVersion(e.target.value);
    setOrigin("all");
    setApiResults(results);
    setLoadingMap(false);
  };

  const updateMapFilterOrigin = async (e) => {
    setLoadingMap(true);

    const data = await getAppraisalsPostalCodes({
      type,
      version,
      origin: e.target.value,
      rooms,
      baths
    });

    console.log(data);

    const results = data?.data?.data;

    const existingCp = results.map((result) => String(result.cp));

    console.log(existingCp);

    for (const data of mapboxSourceLayers) {
      if (map.current.getLayer(data.layerName)) {
        map.current.setFilter(
          data.layerName,
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      if (map.current.getLayer(data.layerName + "-outline")) {
        map.current.setFilter(
          data.layerName + "-outline",
          ["in", "d_codigo"].concat(existingCp)
        );
      }

      /* if (map.current.getLayer(data.layerName + "-raster")) {
        map.current.setFilter(
          data.layerName + "-raster",
          ["in", "d_codigo"].concat(existingCp)
        );
      } */
    }

    setOrigin(e.target.value);

    setApiResults(results);
    setLoadingMap(false);
  };

  const toggleDesviationVector = (event) => {
    if (event.target.checked) {
      map.current.setPaintProperty(
        "formatedPostalCodesMx-layer",
        "fill-color",
        "#b8b8b8"
      );
      map.current.setLayoutProperty(
        "formatedPostalCodesMx-layer-raster",
        "visibility",
        "visible"
      );
    } else {
      map.current.setPaintProperty(
        "formatedPostalCodesMx-layer",
        "fill-color",
        "#f1592a"
      );
      map.current.setLayoutProperty(
        "formatedPostalCodesMx-layer-raster",
        "visibility",
        "none"
      );
    }

    setDesviationVectorEnabled(!desviationVectorEnabled);
  };

  useEffect(() => {
    console.log("Mount");

    if (map.current) return; // initialize map only once

    mapInstance.initializeMap({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12?optimize=true",
      center: initial_coordinate,
      zoom: zoom,
      trackResize: true
    });

    map.current = mapInstance.getMap();

    popUp.current = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true
    });

    // disable map rotation using right click + drag
    map.current.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    map.current.touchZoomRotate.disableRotation();

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      placeholder: "Buscar",
      proximity: {
        latitude: initial_coordinate[0],
        longitude: initial_coordinate[1]
      },
      countries: "mx",
      marker: true
    });

    map.current.addControl(geocoder);

    map.current.on("load", async () => {
      const data = await getAppraisalsPostalCodes({ type, version });

      const results = data?.data?.data;

      setApiResults(results);

      const existingCp = results.map((result) => String(result.cp));

      for (const data of mapboxSourceLayers) {
        if (!map.current.getSource(data.sourceName)) {
          map.current.addSource(data.sourceName, {
            type: "vector",
            url: "mapbox://" + data.sourceUrl,
            properties: {
              data,
              test: 1
            }
          });
        }
      }

      for (const data of mapboxSourceLayers) {
        if (!map.current.getLayer(data.layerName)) {
          map.current.addLayer(
            {
              id: data.layerName,
              type: "fill",
              source: data.sourceName,
              "source-layer": data.sourceLayer,
              paint: {
                "fill-color": "#f1592a", // blue color fill
                "fill-opacity": 0.8
              },
              filter: ["in", "d_codigo"].concat(existingCp)
            },
            "waterway-label"
          );
        }

        if (!map.current.getLayer(data.layerName + "-outline")) {
          map.current.addLayer(
            {
              id: data.layerName + "-outline",
              type: "line",
              source: data.sourceName,
              "source-layer": data.sourceLayer,
              layout: {},
              paint: {
                "line-color": "#e60404",
                "line-width": 0.5
              },
              filter: ["in", "d_codigo"].concat(existingCp)
            },
            "waterway-label"
          );
        }

        if (!map.current.getLayer(data.layerName + "-raster")) {
          map.current.addLayer(
            {
              id: data.layerName + "-raster",
              type: "fill",
              source: data.sourceName,
              "source-layer": data.sourceLayer,
              paint: {
                "fill-opacity": 0.8,
                "fill-color": [
                  "interpolate",
                  ["linear"],
                  ["get", "desviation"],
                  2000,
                  "#DA9C20",
                  4000,
                  "#CA8323",
                  6000,
                  "#B86B25",
                  8000,
                  "#A25626",
                  10000,
                  "#81432b",
                  12000,
                  "#693225",
                  14000,
                  "#702e1f",
                  16000,
                  "#593020",
                  18000,
                  "#48271d",
                  20000,
                  "#3f1208",
                  22000,
                  "#3a0c05",
                  24000,
                  "#350903",
                  26000,
                  "#2f0502",
                  28000,
                  "#2a0401",
                  30000,
                  "red"
                ]
              },
              filter: [
                "all",
                ["has", "desviation"], // Filter to include features where desviation exists
                ["in", "d_codigo"].concat(existingCp)
              ],
              layout: {
                visibility: desviationVectorEnabled ? "visible" : "none"
              }
            },
            "waterway-label"
          );
        }
      }

      map.current.on(
        "mousemove",
        mapboxSourceLayers.map((layer) => layer.layerName),
        (e) => {
          const postalCode = e.features?.[0]?.properties?.d_codigo;

          if (popUp.current && popUp.current.isOpen()) popUp.current.remove();

          setSelectedCP(postalCode);
          console.log("Features");
          console.log(e.features);

          popUp.current
            .setLngLat(e.lngLat)
            .setHTML(
              renderPopUpMuseum({
                postalCode: postalCode
              })
            )
            .addTo(map.current);
        }
      );

      map.current.addSource("states", {
        type: "geojson",
        data: statesGeojson
      });

      map.current.addLayer({
        id: "states-layer",
        type: "line",
        source: "states",
        paint: {
          "line-color": "#e60404",
          "line-width": 1
        },
        filter: ["in", "state_name"].concat([
          "Distrito Federal",
          "Jalisco",
          "Nuevo León",
          "Quintana Roo",
          "Morelos",
          "México",
          "Hidalgo",
          "Querétaro",
          "Baja California",
          "Sonora"
        ])
      });

      setLoadingMap(false);
    });

    map.current.on(
      "mouseenter",
      mapboxSourceLayers.map((layer) => layer.layerName),
      () => {
        if (!map.current) return;
        map.current.getCanvas().style.cursor = "pointer";
      }
    );
    map.current.on(
      "mouseleave",
      mapboxSourceLayers.map((layer) => layer.layerName),
      () => {
        if (!map.current) return;
        map.current.getCanvas().style.cursor = "";

        popUp.current.remove();
      }
    );

    map.current.once("idle", () => {
      //setLoadingMap(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom]);

  const apiResultsCp = apiResults?.find(
    (cpData) => String(cpData.cp) === String(selectedCP)
  );

  console.log(apiResultsCp);

  const selectedCpData = {
    m2: Number(apiResultsCp?.averagePricePerMeter || 0)?.toFixed(0) || 0,
    total: String(apiResultsCp?.count || 0) || 0,
    desv: Number(apiResultsCp?.standardDeviation || 0)?.toFixed(0) || 0,
    desvPrice:
      Number(apiResultsCp?.standardDeviationPrice || 0)?.toFixed(0) || 0,
    valuationsCouter: apiResultsCp?.origins?.filter(
      (origin) => origin === "VALUATION"
    ).length,
    scrappersCouter: apiResultsCp?.origins?.filter(
      (origin) => origin === "SCRAPER"
    ).length
  };

  return (
    <section className="flex-center" style={{ position: "relative" }}>
      {loadingMap && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(77, 77, 77, 0.801)",
            zIndex: 10,
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress size={56} />
        </div>
      )}
      <aside
        className="menu-desktop"
        style={{
          position: "absolute",
          width: "20em",
          height: "100%",
          zIndex: 9,
          top: 0,
          bottom: 0,
          left: "1em",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
          pointerEvents: "none"
        }}
      >
        <Box
          alignSelf={"initial"}
          sx={{
            width: "20em",
            height: "4em",
            backgroundColor: "#dad5d5",
            marginTop: "2em",
            padding: "1em"
          }}
        >
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h6"
              fontWeight={600}
              color="black"
            >
              Total: {apiResults?.length}
            </Typography>
          </Box>
        </Box>
        <Box
          alignSelf={"initial"}
          sx={{
            width: "20em",
            height: "20em",
            backgroundColor: "#dad5d5",
            marginTop: "2em",
            marginBottom: "2em",
            padding: "1em"
          }}
        >
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h5"
              fontWeight={600}
              color="black"
            >
              CP: {selectedCP}
            </Typography>
          </Box>
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h5"
              fontWeight={600}
              color="black"
            >
              M2: ${" "}
              {!Number.isNaN(selectedCpData?.m2)
                ? Number(selectedCpData?.m2 || 0).toLocaleString()
                : 0}
            </Typography>
          </Box>
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h5"
              fontWeight={600}
              color="black"
            >
              Total: {selectedCpData?.total}
            </Typography>
          </Box>
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h5"
              fontWeight={600}
              color="black"
            >
              DesvM2: ${Number(selectedCpData?.desv).toLocaleString()}
            </Typography>
          </Box>
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h5"
              fontWeight={600}
              color="black"
            >
              DesvPrecio: ${Number(selectedCpData?.desvPrice).toLocaleString()}
            </Typography>
          </Box>
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h5"
              fontWeight={600}
              color="black"
            >
              Avaluos: {Number(selectedCpData?.valuationsCouter)}
            </Typography>
          </Box>
          <Box>
            <Typography
              textAlign={"left"}
              mb={1}
              variant="h5"
              fontWeight={600}
              color="black"
            >
              Scrapper: {Number(selectedCpData?.scrappersCouter)}
            </Typography>
          </Box>
        </Box>
        <Box style={{ overflowY: "scroll", paddingBottom: "2em" }}>
          <FormControl
            fullWidth
            style={{ pointerEvents: "all" }}
            sx={{ backgroundColor: "#b3b3b3" }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              label="Tipo"
              onChange={updateMapFilter}
            >
              <MenuItem value={"all"}>Todos</MenuItem>
              <MenuItem value={"casa"}>Casa</MenuItem>
              <MenuItem value={"depa"}>Departamento</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{ pointerEvents: "all" }}
            sx={{ backgroundColor: "#b3b3b3", marginTop: "1em" }}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={version}
              label="version"
              onChange={updateMapFilterVersion}
            >
              <MenuItem value={"v1"}>V1</MenuItem>
              <MenuItem value={"v2"}>V2</MenuItem>
            </Select>
          </FormControl>

          {version === "v2" && (
            <>
              <FormControl
                fullWidth
                style={{ pointerEvents: "all" }}
                sx={{ backgroundColor: "#b3b3b3", marginTop: "1em" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={origin}
                  label="origin"
                  onChange={updateMapFilterOrigin}
                >
                  <MenuItem value={"all"}>Avaluo/Scraper</MenuItem>
                  <MenuItem value={"VALUATION"}>Avaluo</MenuItem>
                  <MenuItem value={"SCRAPER"}>Scraper</MenuItem>
                </Select>
                <FormControlLabel
                  style={{ marginLeft: "1em" }}
                  control={
                    <Switch
                      checked={desviationVectorEnabled}
                      onChange={toggleDesviationVector}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Desviación"
                />
              </FormControl>

              <FormControl
                fullWidth
                style={{ pointerEvents: "all" }}
                sx={{ backgroundColor: "#b3b3b3", marginTop: "1em" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="rooms"
                  select
                  value={rooms}
                  label="rooms"
                  name="rooms"
                  onChange={updateMapFilterBathandRooms}
                >
                  <MenuItem value={0}>Sin Cuartos</MenuItem>
                  {[1, 2, 3, 4, 5].map((number, index) => {
                    return (
                      <MenuItem value={number} key={index}>
                        {number} Cuartos
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                style={{ pointerEvents: "all" }}
                sx={{ backgroundColor: "#b3b3b3", marginTop: "1em" }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="baths"
                  select
                  value={baths}
                  label="baths"
                  name="baths"
                  onChange={updateMapFilterBathandRooms}
                >
                  <MenuItem value={0}>Sin Baños</MenuItem>
                  {[1, 2, 3, 4, 5].map((number, index) => {
                    return (
                      <MenuItem value={number} key={index}>
                        {number} Baños
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        {/* <Paper
          style={{ pointerEvents: "all" }}
          sx={{
            backgroundColor: "#b3b3b3",
            marginTop: "1em",
            display: "flex",
            flexDirection: "row",
            height: "4em",
            width: "100%"
          }}
        >
          <Input
            value={searchedCP}
            fullWidth
            id="outlined-basic"
            label="CP"
            variant="outlined"
            placeholder="CP"
            onChange={searchCP}
            endAdornment={
              <InputAdornment position="end">
                {searchedCP !== "" && (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleCleanSearchCP}
                  >
                    <CloseOutlined />
                  </IconButton>
                )}
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleSearchCP}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </Paper> */}
      </aside>
      <Map mapContainer={mapContainer} location={currentLocation} />
    </section>
  );
}

export default MapQuoter;
