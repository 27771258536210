import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { createDescriptions } from "../../../api/descriptionsnotes/createDescription";
import { getQuoters } from "../../../api/quoters/getQuoters";
// import { createPropertyPreseller } from "../../../api/properties/createPropertyPreseller";
// import { updateAddressPreseller } from "../../../api/properties/updateAddressPreseller";
// import { updatePropertyPreseller } from "../../../api/properties/updatePropertyPreseller";
// import { createPreSeller } from "../../../api/seller/createPreSeller";
// import { updatePreSellerToSeller } from "../../../api/seller/updatePresellerToSeller";
import { createFullSeller } from "../../../api/seller/createFullSeller";
import CreditInfo from "../../../components/Modals/createHouseSeller/CreditInfo";
import MaritalStatus from "../../../components/Modals/createHouseSeller/MaritalStatus";
import PropertyAddress from "../../../components/Modals/createHouseSeller/PropertyAddress";
import PropertyCharacteristics from "../../../components/Modals/createHouseSeller/PropertyCharacteristics";
import StatusSelect from "../../../components/Modals/createHouseSeller/StatusSelect";
import TokenContext from "../../../context/Token";
import PersonalInfo from "./PersonalInfo";

const CreateProspectForm = ({
  open,
  onCloseModal,
  refetchPreSellers,
  refetchProperties
}) => {
  const { token } = useContext(TokenContext);
  const [success, setSuccess] = useState({ severity: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [renovation, setRenovation] = useState("");
  const [user, setUser] = useState({
    name: "",
    fLastName: "",
    mLastName: "",
    email: "",
    phoneNumber: ""
  });
  const [propertyAddress, setPropertyAddress] = useState({
    numInt: "",
    parkingLot: "",
    levels: "",
    washroom: "",
    street: "",
    mLand: "",
    bedrooms: "",
    floor: "",
    mortgage: "",
    numExt: "",
    mBuilding: "",
    division: "",

    mzn: "",
    lt: ""
  });

  const [propertyData, setPropertyData] = useState({
    // status: "Prospectos",
    postalCode: "",
    state: "", //estado
    city: "", //ciudad
    settlement: "", //colonia
    type: "", //dept o casa
    quote: 0, //oferta de cotizador
    creditType: "", //infonavit, fovissste, bancario, otro
    debtCredit: "", //cantidad restante por pagar
    hasBacklogCredit: "", // si tiene meses atrasados
    monthBacklogs: "", //meses de atraso
    adquisitionYear: "", //fecha de adquisición
    reasonSale: "", //razón de venta
    isInhabited: true, //si está deshabitada
    wantedForSale: "" //cantidad esperada para vender
  });

  const [sellerInfo, setSellerInfo] = useState({
    credit: "", //numero de crédito
    married: "", //casado(true) o soltero(false)
    communityProperty: false //bienes separados(false) o mancomunados(true)
  });

  const [avgPrice, setAvgPrice] = useState(0);

  const handleUserInfo = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const sendData = async (e) => {
    e.preventDefault();

    const propertyAndAddress = { ...propertyData };

    propertyAndAddress.address = { ...propertyAddress };
    try {
      setLoading(true);
      await createFullSeller({
        seller: { ...sellerInfo, ...user },
        property: propertyAndAddress
      });
      // await createDescriptions(token, {
      //   propertyRef: resProperty?.property_id,
      //   description: `Cambios o renovaciones: ${renovation}`,
      //   status: "Perfilado"
      // });

      setSuccess({ severity: "success", message: "Usuario Creado" });
      setLoading(false);
      setTimeout(() => {
        setSuccess({ severity: "", message: "" });
        refetchProperties();
        onCloseModal();
      }, 2000);
    } catch (error) {
      setSuccess({ severity: "error", message: error });
      setLoading(false);
      console.log(error);
      setTimeout(() => {
        setSuccess({ severity: "", message: "" });
      }, 4000);
    }
  };

  useEffect(() => {
    const getHomeValue = async () => {
      // const price = propertyAddress.mBuilding * 10000;

      // setPropertyData({
      //   ...propertyData,
      //   quote: price
      // });
      const price = await getQuoters(
        propertyData.postalCode,
        propertyAddress.mBuilding ?? 1
      );

      if (price?.data?.data[0]?.averageValue) {
        setPropertyData({
          ...propertyData,
          quote: Math.ceil(
            price?.data?.data[0].averageValue * propertyAddress.mBuilding
          )
        });
      } else {
        setPropertyData({
          ...propertyData,
          quote: Math.ceil(10000 * propertyAddress.mBuilding)
        });
      }

      // setPropertyData({
      //   ...propertyData,
      //   quote: parseFloat(price.data?.data[0]?.marketValue.toFixed(2))
      // });
    };

    // if (propertyAddress.mBuilding && propertyData.postalCode) {
    //   getHomeValue();
    // }
    if (propertyAddress.mBuilding && propertyData.postalCode) {
      getHomeValue();
    }
    //eslint-disable-next-line
  }, [propertyAddress.mBuilding, propertyData.quote]);

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      maxWidth="lg"
      aria-labelledby={"error-success-dialogue"}
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"error-success-title"}>
        Crear Cliente Nuevo
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box component={"form"} onSubmit={sendData} mb={4}>
          {/* <StatusSelect
            propertyData={propertyData}
            setPropertyData={setPropertyData}
          /> */}
          <Divider>
            <Typography variant="h6" color="primary" fontWeight={600}>
              Datos Personales
            </Typography>
          </Divider>
          <PersonalInfo user={user} handleUserInfo={handleUserInfo} />
          <Divider>
            <Typography variant="h6" color="primary" fontWeight={600}>
              Datos Del Inmueble
            </Typography>
          </Divider>
          <PropertyAddress
            propertyData={propertyData}
            setPropertyData={setPropertyData}
            propertyAddress={propertyAddress}
            setPropertyAddress={setPropertyAddress}
            setAvgPrice={setAvgPrice}
          />
          <PropertyCharacteristics
            renovation={renovation}
            setRenovation={setRenovation}
            propertyData={propertyData}
            setPropertyData={setPropertyData}
            propertyAddress={propertyAddress}
            setPropertyAddress={setPropertyAddress}
          />

          <CreditInfo
            sellerInfo={sellerInfo}
            setSellerInfo={setSellerInfo}
            propertyData={propertyData}
            setPropertyData={setPropertyData}
            propertyAddress={propertyAddress}
            setPropertyAddress={setPropertyAddress}
          />

          {/* <Debts debts={debts} setDebts={setDebts} /> */}

          <MaritalStatus
            sellerInfo={sellerInfo}
            setSellerInfo={setSellerInfo}
          />

          <Box display={"flex"} justifyContent="end"></Box>
          {success.severity === "success" && (
            <Alert severity={success.severity} sx={{ mr: 2 }}>
              {success.message}
            </Alert>
          )}
          {success.severity === "error" && (
            <Alert severity={success.severity} sx={{ mr: 2 }}>
              {success.message}
            </Alert>
          )}
          <Box display={"flex"}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{ width: 200, mr: 3, ml: "auto" }}
            >
              Crear
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateProspectForm;
