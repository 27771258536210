import { useState } from "react";
import { useContext } from "react";

import { updateObservations } from "../../api/Observations/updateObservations";
import TokenContext from "../../context/Token";
import ModalComment from "../Modals/ModalComment";

//PROPS DEL COMPONENTE: id, propertyId, observation, refetch
const EditObservation = (props) => {
  const { token } = useContext(TokenContext);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sendData = async (data) => {
    if (!data || data.trim() === "") {
      setErrorMessage("La observación no puede estar vacía");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }
    try {
      const response = await updateObservations(token, props.id, {
        propertyRef: props.propertyId,
        observation: data
      });

      if (response?.data?.property) {
        setSuccessMessage("Observación actualizada exitosamente");
        props.refetch();
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <ModalComment
      inheritedText={props.observation ?? ""}
      titleModal="Observaciones"
      reason="Editar observacion"
      okText="Actualizar Observación"
      id={props?.id}
      sendData={sendData}
      successMessage={successMessage}
      errorMessage={errorMessage}
    />
  );
};

export default EditObservation;
