import moment from "moment";
import { useState } from "react";

import { updateProperties } from "../../../api/properties/updateProperties";

//PROPS DEL COMPONENTE: setSuccessMessage, setErrorMessage, id, token, firmDate, refetchProperties
const UpdateFirmDate = (props) => {
  const [firmDate, setFirmDate] = useState(
    moment(props.firmDate).format("YYYY-MM-DDTHH:mm") ?? ""
  );

  // console.log(firmDate)
  const sendData = async (e) => {
    try {
      const response = await updateProperties(props.token, props.id, {
        firmDate
      });

      if (response._id) {
        props.setSuccessMessage("Fecha Para Firma Actualizada");
        props.refetchProperties();
        setTimeout(() => {
          props.setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      props.setErrorMessage(error);
      setTimeout(() => {
        props.setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <div className="d-flex">
      <label className="property-title me-3 mb-0 mt-1">Fecha de Firma:</label>
      <input
        type="datetime-local"
        name=""
        id=""
        value={firmDate}
        onChange={(e) => setFirmDate(e.target.value)}
      />
      <button className="btn-fill-orange ms-3" onClick={sendData}>
        Actualizar
      </button>
    </div>
  );
};

export default UpdateFirmDate;
