import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";

import { stringToCurrency } from "../../../../helpers/currencies";
import AuthRepairmentButtons from "./AuthRepairmentButtons";
import RehabButtons from "./RehabButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4
};
const ImgModal = ({
  open,
  onCloseModal,
  imageSrc,
  description = "sin descripción",
  evidenceModal = false,
  evidenceItem = null,
  modalType = "",
  changeEvidenceStatus
}) => {
  return (
    <Modal
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"modal-img"}
      sx={{ borderRadius: ".5rem" }}
    >
      <Box sx={style}>
        <Box display={"flex"} justifyContent={"space-between"} mb={2}>
          <Typography variant="h5" color="initial">
            {modalType === "inspection"
              ? "Evidencia de la Inspección"
              : modalType === "rehab"
              ? "Información de la Rehabilitación"
              : "Información de la imágen"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseModal}
            sx={{
              position: "absolute",
              right: 15,
              top: 15,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container columnSpacing={3}>
          <Grid item xs={12} md={8}>
            <a href={imageSrc} target="_blank" rel="noreferrer">
              <Box
                component={"img"}
                sx={{
                  height: "80vh",
                  width: "100%",
                  objectFit: "contain",
                  objectPosition: "center center"
                }}
                src={imageSrc}
                alt={"img"}
              />
            </a>
          </Grid>
          <Grid item xs={12} md={4}>
            {evidenceModal === true && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} textAlign={"start"}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    fontWeight={"bold"}
                  >
                    Categoría
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {evidenceItem?.category ?? ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={"start"}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    fontWeight={"bold"}
                  >
                    Sub-Categoría
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {evidenceItem?.subCategory ?? ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={"start"}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    fontWeight={"bold"}
                  >
                    Cantidad
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {evidenceItem?.quantity ?? ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={"start"}>
                  <Typography
                    variant="h6"
                    color="secondary"
                    fontWeight={"bold"}
                  >
                    Monto Total
                  </Typography>
                  <Typography variant="body1" color="secondary">
                    {evidenceItem?.price
                      ? stringToCurrency(evidenceItem?.price)
                      : "N/A"}
                  </Typography>
                </Grid>
                {modalType === "inspection" && (
                  <Grid item xs={12} md={6} textAlign={"start"}>
                    <Typography
                      variant="h6"
                      color="secondary"
                      fontWeight={"bold"}
                    >
                      Status
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {evidenceItem?.status === "APPROVED" && "Aprobado"}
                      {evidenceItem?.status === "PENDING" && "Pendiente"}
                      {evidenceItem?.status === "DENIED" && "Rechazado"}
                    </Typography>
                  </Grid>
                )}

                {modalType === "rehab" && (
                  <Grid item xs={12} md={6} textAlign={"start"}>
                    <Typography
                      variant="h6"
                      color="secondary"
                      fontWeight={"bold"}
                    >
                      Status
                    </Typography>
                    <Typography variant="body1" color="secondary">
                      {evidenceItem?.rehabilitationStatus === "FINISHED" &&
                        "Finalizado"}
                      {evidenceItem?.rehabilitationStatus === "PENDING" &&
                        "Pendiente"}
                      {evidenceItem?.rehabilitationStatus === "BLOCKED" &&
                        "Bloqueado"}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
            <Typography
              variant="h6"
              color="secondary"
              fontWeight={"bold"}
              mt={3}
            >
              Descripción
            </Typography>
            <Box
              sx={{
                mt: 1,
                height: 200,
                width: "100%",
                border: "1px solid rgba(111, 128, 149, 0.6)",
                borderRadius: 2,
                overflowY: "auto",
                "::-webkit-scrollbar": { width: "5px" },
                "::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",

                  borderRadius: "10px"
                },
                "::-webkit-scrollbar-thumb": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
                  borderRadius: "10px"
                }
              }}
            >
              <Typography variant="body1" color="secondary" ml={1}>
                {description}
              </Typography>
            </Box>
            {modalType === "inspection" && (
              <AuthRepairmentButtons
                evidenceId={evidenceItem?._id}
                changeEvidenceStatus={changeEvidenceStatus}
                evidenceStatus={evidenceItem?.status}
              />
            )}
            {modalType === "rehab" && <RehabButtons />}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ImgModal;
