import { useState } from "react";

import GestionContainer from "../../components/Proceeding/GestionContainer";
import ProceedingCategories from "../../components/Proceeding/ProceedingCategories";
import FirmaContainer from "./FirmaContainer";
import LegalContainer from "./LegalContainer";

const Legal = () => {
  const [value, setValue] = useState({
    selectTab: ""
  });

  const handleSelect = (type, target) => {
    setValue({ ...value, [type]: target });
  };

  return (
    <section className="flex-center">
      <ProceedingCategories
        onSelect={handleSelect}
        currentTab={value.selectTab}
      />
      <div
        className="w-100 d-flex justify-content-center px-2"
        style={{ height: "fit-content", overflowX: "auto" }}
      >
        {value.selectTab === "" && <LegalContainer />}
        {value.selectTab === "Firma" && <FirmaContainer />}
        {value.selectTab === "Gestion" && <GestionContainer />}
      </div>
    </section>
  );
};

export default Legal;
