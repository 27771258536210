import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getAppraisalsPostalCodes = async (params) => {
  try {
    const searchParams = new URLSearchParams();

    for (const key in params) {
      if (params[key] !== "all" && params[key] !== 0) {
        searchParams.append(key, params[key]);
      }
    }
    console.log(searchParams.toString());

    const response = await axios.get(
      `${REACT_APP_BASE_URL}/quoters/appraisals-postal-codes?${searchParams.toString()}`
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
