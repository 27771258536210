import { Box, Typography, Button, Grid } from "@mui/material";
import { useState } from "react";

import ModalLegal from "../../../components/Modals/ModalLegal/ModalLegal";
import ValidatePreinventory from "../../../components/Signature/ValidatePreinventory";

const StartBuyProcess = ({ property, sellerId, legal, refetchProperties }) => {
  const [MLisActive, setMLisActive] = useState(false);

  return (
    <>
      <Box display={"flex"} justifyContent="start">
        <Typography
          variant="h5"
          className="blue-text"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Inicio proceso de compra
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} md={6} display="flex" justifyContent={"start"}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginRight: { md: 5 } }}
            data-bs-toggle="modal"
            data-bs-target={"#ModalLegal" + property?._id}
            onClick={() => setMLisActive(true)}
          >
            Validación
          </Button>
          <ModalLegal
            id={property?._id}
            MLisActive={MLisActive}
            setMLisActive={setMLisActive}
            sellerId={sellerId}
            legal={legal ?? false}
            refetchProperties={refetchProperties}
          />
          {/* <Button variant="contained" color="primary">
            Gestión
          </Button> */}
        </Grid>
        <Grid item xs={12} md={6} display="flex" justifyContent={"end"}>
          {property?.legal &&
            property?.legal === true &&
            property?.inspection &&
            property?.inspection === true &&
            !property?.preInventory && (
              <ValidatePreinventory
                id={property?._id}
                refetchProperties={refetchProperties}
              />
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default StartBuyProcess;
