import { useContext } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { createNewVisit } from "../../../api/visits/CreateNewVisit";
import TokenContext from "../../../context/Token";
import AdviserSelect from "./adviserSelect";

const ModalNewVisit = (props) => {
  const { token } = useContext(TokenContext);
  const { propertyId } = useParams();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newVisit, setNewVisit] = useState({
    propertyRef: propertyId,
    adviserRef: "",
    type: "",
    date: "",
    reason: "",
    companion: ""
  });

  const handleChange = (e) => {
    setNewVisit({
      ...newVisit,
      [e.target.name]: e.target.value
    });
  };

  const sendData = async () => {
    if (
      newVisit.adviser === "" ||
      newVisit.type === "" ||
      newVisit.date === "" ||
      newVisit.reason === ""
    ) {
      setErrorMessage("Faltan datos obligatorios por llenar");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
      return;
    }

    try {
      const response = await createNewVisit(token, newVisit);

      if (response.status === 201) {
        setSuccessMessage("Visita registrada");
        props.refetchVisits();
        setTimeout(() => {
          setSuccessMessage("");
        }, 2000);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };

  return (
    <div
      className="modal fade"
      id={"newVisitModal"}
      tabIndex="-1"
      aria-labelledby="newVisitModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="newVisitModalLabel">
              Nueva Visita
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-4">
                <div
                  className="d-flex flex-column text-start"
                  style={{ width: "200px" }}
                >
                  <label className="property-title">Asesor*:</label>

                  <AdviserSelect
                    handleChange={handleChange}
                    adviserRef={newVisit.adviser}
                  />
                </div>
                <div
                  className="d-flex flex-column text-start"
                  style={{ width: "200px" }}
                >
                  <label htmlFor="type" className="property-title">
                    Tipo*:
                  </label>
                  <select
                    className="form-select"
                    name="type"
                    id="type"
                    value={newVisit.type}
                    onChange={handleChange}
                  >
                    <option value="" hidden>
                      Seleccionar Tipo
                    </option>
                    <option value="external">Rehabilitacion</option>
                    <option value="sale">Posible Comprador</option>
                  </select>
                </div>

                <div
                  className="d-flex flex-column text-start"
                  style={{ width: "200px" }}
                >
                  <label htmlFor="date" className="property-title">
                    Fecha y Hora de Visita*:
                  </label>
                  <input
                    className="form-control"
                    type={"datetime-local"}
                    name="date"
                    id="date"
                    value={newVisit.date}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="col-8">
                <div className="d-flex flex-column text-start">
                  <label htmlFor="reason" className="property-title">
                    Razón*:
                  </label>
                  <textarea
                    className="form-control"
                    name="reason"
                    id="reason"
                    value={newVisit.reason}
                    onChange={handleChange}
                    placeholder="Ej.: 'reparacion de lavavo', 'cliente cotiza'"
                  />
                </div>
                {newVisit.type === "external" && (
                  <div className="d-flex flex-column text-start">
                    <label htmlFor="visitDate" className="property-title">
                      Acompañantes (Nombres y puesto/profesión):
                    </label>
                    <input
                      className="form-control"
                      name="companion"
                      type="text"
                      placeholder="ej.: Juan Paz (carpintero), Oscar Perez (supervisor)"
                      value={newVisit.companion}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {successMessage && (
              <p className="bg-success text-white py-1 px-1 rounded mx-1 fw-bold text-center">
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="bg-danger text-white py-1 px-1 rounded mx-1 fw-bold text-center">
                {errorMessage}
              </p>
            )}
            <button
              type="button"
              className="btn btn-secondary rounded-pill py-0"
              data-bs-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              className="btn-fill-orange"
              onClick={sendData}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalNewVisit;
