import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import "./styles/Navbar.css";
import { useContext } from "react";
import { IoCalendarNumber } from "react-icons/io5";
import { MdLogout, MdComputer } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { logout, logoutAdvisers, logoutLawyers } from "../api/session/logout";
import logo from "../assets/Logo_CTC.svg";
import TokenContext from "../context/Token";

import { Box, Button, Grid, Stack } from "@mui/material";

import { useModal } from "../hooks/useModal";
import CotizerModal from "./Modals/CotizerModal/CotizerModal";

const Navbar = () => {
  const { pathname } = useLocation();
  const re = /-/gi;
  const encabezado = pathname.split("/").pop().replace(re, " ");
  const navigate = useNavigate();
  const { open, onCloseModal, onOpenModal } = useModal();

  const { token, setToken, userType, setUserType } = useContext(TokenContext);

  const logOut = async () => {
    try {
      if (userType === "Admin") await logOut(token);

      if (userType === "Adviser") await logoutAdvisers(token);

      if (userType === "Lawyer") await logoutLawyers(token);

      setToken("");
      setUserType("");
      localStorage.clear();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="navbar-section-component">
      <Grid container paddingX={2}>
        <Grid
          item
          xs={4}
          display={"flex"}
          justifyContent={{ xs: "center", sm: "start" }}
        >
          <img
            style={{
              height: 80,
              width: 160
            }}
            src={logo}
            alt="CTC-logo"
            onClick={() =>
              userType === "Adviser"
                ? navigate("/ctc/compra/clientes")
                : navigate("/ctc/dashboard/metricas")
            }
          />
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"center"}>
          <h3
            className="header-title fs-5 fw-bold"
            style={{ margin: "auto 0" }}
          >
            {encabezado}
          </h3>
        </Grid>
        <Grid item xs={4} display={"flex"} justifyContent={"end"}>
          <Stack direction={"row"} gap={"10px"} marginLeft={"auto"}>
            {encabezado === "firma" && (
              <Link
                to={"compra/calendario"}
                style={{
                  textDecoration: "none",
                  color: "black"
                }}
              >
                <Button
                  variant="outlined"
                  style={{ height: "40px", width: "50px", color: "#F1592A" }}
                >
                  <IoCalendarNumber color="inherit" />
                </Button>
              </Link>
            )}
            {(userType === "Admin" || userType === "Adviser") && (
              <Button
                variant="outlined"
                sx={{
                  height: "40px",
                  width: "50px",
                  color: "#F1592A"
                }}
                onClick={onOpenModal}
              >
                <MdComputer color="inherit" />
              </Button>
            )}
            {open && <CotizerModal open={open} onCloseModal={onCloseModal} />}
            <Link to={"dashboard/configuracion"}>
              <Button
                variant="outlined"
                sx={{
                  height: "40px",
                  width: "50px",
                  color: "#F1592A"
                }}
              >
                <SettingsIcon color="inherit" />
              </Button>
            </Link>

            <Button
              variant="outlined"
              sx={{
                height: "40px",
                width: "50px",
                color: "#F1592A"
              }}
              onClick={logOut}
            >
              <MdLogout color="inherit" />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </section>
  );
};

export default Navbar;
