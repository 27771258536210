import moment from "moment";
import { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";

const localizer = momentLocalizer(moment);
const CalendarContainer = (props) => {
  const [firmDates, setFirmDates] = useState(
    props.firmDates.map((property) => {
      const textString = (
        <label className="m-0">
          <strong className="me-1">Cliente:</strong>
          {property?.sellerRef?.name ?? ""}{" "}
          {property?.sellerRef?.fLastName ?? ""}{" "}
          {property?.sellerRef?.mLastName ?? ""}
          <strong className="me-1 ms-2">Dirección:</strong>
          {property?.address?.street ?? ""} {property?.address?.street && ", "}
          {property?.address?.numExt ?? ""} {property?.address?.numExt && ", "}
          {property?.address?.numInt ?? ""}
          <strong className="me-1 ms-2">Asesor:</strong>
          {property?.adviserRef?.name ?? "N/A"}
        </label>
      );

      //   `
      //   <strong>Cliente:</strong> ${property?.sellerRef?.name ?? ""} ${property?.sellerRef?.fLastName ?? ""} ${property?.sellerRef?.mLastName ?? ""},
      //   Direccion: ${property?.address?.street??""} ${property?.address?.numExt??""} ${property?.address?.numInt ? ", "+property?.address?.numInt:""}
      //   Asesor: ${property?.adviserRef?.name ?? "N/A"}
      // `

      return {
        title: textString,
        tooltip: `De ${moment(property?.firmDate).format("LT")} a ${moment(
          property?.firmDate
        )
          .add(1, "h")
          .format("LT")}`,
        start: new Date(property?.firmDate),
        end: new Date(moment(property?.firmDate).add(1, "h"))
      };
    })
  );

  return (
    <>
      <Calendar
        localizer={localizer}
        events={firmDates}
        startAccessor={"start"}
        endAccessor={"end"}
        tooltipAccessor={"tooltip"}
        views={["month", "day", "agenda"]}
        style={{ marginTop: 30, height: "90%", width: "90%" }}
      />
    </>
  );
};

export default CalendarContainer;
