import React from "react";
import "../../styles/Modal.css";
import { useState } from "react";
import { useContext } from "react";
import { useRef } from "react";

import { uploadImageProperty } from "../../../api/properties/uploadImageProperty";
import { getUploadFileUrl, uploadFile } from "../../../api/utilsApi";
import TokenContext from "../../../context/Token";
import { toNormalForm } from "../../../helpers/common";
import AddImages from "./AddImages";
import PreviousImages from "./PreviousImages";

//PROPS DEL COMPONENTE: id={props.property._id} photos={props.property?.images}, refetchProperties, storegallery
const ModalUploadMultimedia = (props) => {
  const { token } = useContext(TokenContext);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const imagesInputRef = useRef(null);

  const sendData = async () => {
    setIsLoading(true);
    console.log(imagesToUpload);

    let good = 0;
    let err = 0;

    for (const image of imagesToUpload) {
      try {
        const { url, signedUrl } = await getUploadFileUrl(
          token,
          `cdn/marketplace/properties/${props.id}`,
          toNormalForm(image?.name?.trim()?.replaceAll(" ", "-").toLowerCase())
        );

        await uploadFile(signedUrl, image);

        await uploadImageProperty(token, props.id, [url]);

        good++;
      } catch (e) {
        console.log(e);
        err++;
      }
    }

    setImagesToDisplay([]);
    setImagesToUpload([]);
    imagesInputRef.current.value = null;
    setIsLoading(false);
    setSuccessMessage(`Subidos: ${good}. Errores: ${err}`);
    props.refetchProperties();
    setSuccessMessage("");
  };

  return (
    <div
      className="modal fade"
      id={`multimedia${props.id}`}
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="fs-5">Fotos</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <PreviousImages
              setIsLoading={setIsLoading}
              photos={props.photos}
              propertyId={props.id}
              refetchProperties={props.refetchProperties}
              setErrorMessage={setErrorMessage}
            />
            <AddImages
              imagesInputRef={imagesInputRef}
              imagesToUpload={imagesToUpload}
              setImagesToUpload={setImagesToUpload}
              imagesToDisplay={imagesToDisplay}
              setImagesToDisplay={setImagesToDisplay}
            />
          </div>
          <div className="modal-footer">
            {successMessage && (
              <p className="bg-success text-white py-1 px-5 rounded mx-4 fw-bold text-center">
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="bg-danger text-white py-1 px-5 rounded mx-4 fw-bold text-center">
                {errorMessage}
              </p>
            )}
            {isLoading && (
              <p className="bg-primary text-white py-1 px-5 rounded mx-4 fw-bold text-center">
                {"Cargando..."}
              </p>
            )}
            <button
              type="button"
              className={`btn-fill-orange ${
                imagesToUpload.length === 0 ? "btn" : ""
              }`}
              disabled={imagesToUpload.length === 0}
              onClick={sendData}
            >
              Actualizar Multimedia
            </button>
            <button
              type="button"
              className="btn-fill-orange"
              data-bs-dismiss="modal"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUploadMultimedia;
