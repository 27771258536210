import { borderColor } from "@mui/system";

const DebtsForm = (props) => {
  return (
    <form onSubmit={props.handleAction}>
      <div className="d-flex flex-column">
        {props.showOptions && (
          <>
            <label htmlFor="property-title" className="text-start">
              Tipo de deuda:
            </label>
            <select
              value={props.type ?? ""}
              name="type"
              id="type"
              className="w-50 mb-4 form-select"
              onChange={(e) => props.setType(e.target.value)}
            >
              <option value="" hidden>
                Seleccionar
              </option>
              {props?.options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </>
        )}
        <input
          type="file"
          name=""
          id=""
          onChange={(e) => props.setFile(e.target.files[0])}
        />
      </div>
      <label className="property-title mt-3 mb-1">
        Descripción (opcional):
      </label>
      <textarea
        className="modal-textarea"
        onChange={(e) => props.setText(e.target.value)}
        value={props.text}
        style={{
          height: "125px",
          resize: "none",
          borderRadius: "10px",
          border: "1px solid rgba(111, 128, 149, 0.6)"
        }}
      />
      <label className="property-title mt-3 mb-1">Cantidad:</label>
      <input
        className="w-100"
        type="number"
        required
        style={{
          border: "1px solid rgba(111, 128, 149, 0.6)",
          borderRadius: "10px"
        }}
        value={props.amount}
        onChange={(e) => props.setAmount(e.target.value)}
      />
    </form>
  );
};

export default DebtsForm;
