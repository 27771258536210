import { Outlet } from "react-router-dom";

import MainSidebar from "../../components/MainSidebar";

const DashboardTemplate = () => {
  return (
    <section className="d-flex">
      <MainSidebar />
      <Outlet />
    </section>
  );
};

export default DashboardTemplate;
