import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Alert
} from "@mui/material";
import React, { useContext, useState } from "react";

import { changePassword } from "../../api/auth/changePassword";
import TokenContext from "../../context/Token";

const Settings = () => {
  const { token } = useContext(TokenContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [message, setMessage] = useState({ severity: "", text: "" });

  const sendData = async (e) => {
    e.preventDefault();

    if (newPassword !== newPassword2) {
      setMessage({ severity: "error", text: "Las contraseñas no coinciden" });
      setTimeout(() => {
        setMessage({ severity: "", text: "" });
      }, 3000);
      return;
    }

    if (newPassword.length < 8) {
      setMessage({
        severity: "error",
        text: "La contraseña debe de tener al menos 8 caracteres"
      });
      setTimeout(() => {
        setMessage({ severity: "", text: "" });
      }, 3000);
      return;
    }
    try {
      await changePassword(token, { currentPassword, newPassword });
    } catch (error) {
      setMessage({
        severity: "error",
        text: error
      });
      setTimeout(() => {
        setMessage({ severity: "", text: "" });
      }, 3000);
    }
    setMessage({ severity: "success", text: "Contraseña actualizada" });
    setTimeout(() => {
      setMessage({ severity: "", text: "" });
    }, 3000);
  };

  return (
    <section className="flex-center">
      <Container maxWidth="lg">
        <Box display="flex" mt={{ xs: 5, md: 10 }}>
          <Typography
            display={"block"}
            sx={{ width: "fit-content" }}
            variant="h5"
            color="primary"
            fontWeight={600}
            textAlign="start"
            my={1}
          >
            Configuración de mi cuenta
          </Typography>
        </Box>
        <Box mt={5}>
          <Typography
            display={"block"}
            sx={{ width: "fit-content" }}
            variant="h5"
            color="secondary"
            fontWeight={600}
            textAlign="start"
            my={1}
          >
            Cambiar contraseña{" "}
            <Typography
              component={"span"}
              variant="body1"
              color="secondary"
              fontSize={13}
            >
              (la contraseña debe de ser de al menos 8 caracteres)
            </Typography>
          </Typography>
          <Box
            component={"form"}
            autoComplete="off"
            width={400}
            display={"flex"}
            flexDirection={"column"}
            onSubmit={sendData}
          >
            <TextField
              required
              autoComplete="new-password"
              type="password"
              fullWidth
              name="currentPassword"
              label="contraseña actual"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <TextField
              required
              autoComplete="new-password"
              type="password"
              fullWidth
              name="newPassword"
              label="Nueva contraseña"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              required
              autoComplete="new-password"
              type="password"
              fullWidth
              name="newPassword2"
              label="Confirmar contraseña"
              value={newPassword2}
              onChange={(e) => setNewPassword2(e.target.value)}
              error={newPassword !== newPassword2}
            />
            <Button variant="contained" type="submit">
              Actualizar contraseña
            </Button>
            {message.severity !== "" && (
              <Alert severity={message.severity}>{message.text}</Alert>
            )}
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Settings;
