import { useState, useEffect, useMemo } from "react";

import { updateCurrentBuyRequest } from "../../api/buyRequest/updateBuyRequest";
import { getFilesList } from "../../api/documentations/buyer/getDocBuyer";
import Loading from "../../components/loading/Loading";
import { BUY_REQUESTS_STATUS } from "../../constants/buyRequestsConstants";
import { useDeedContext } from "../../hooks/useDeedContext";
import { DeedFileLink } from "./DeedFileLink";

export const DeedShowFile = (props) => {
  const { getBuyRequestInDeed, token } = useDeedContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const isValidListDoc = useMemo(
    () => files.length === files.filter((doc) => doc?.valid === true).length,
    [files]
  );
  const existDocInvalid = useMemo(
    () => files.filter((doc) => doc?.valid === false).length >= 1,
    [files]
  );

  useEffect(() => {
    if (props.open) {
      setLoading(true);
      getFilesList(props.token, `buyerRef=${props.buyerRef._id}`)
        .then((docs) => setFiles(docs))
        .catch((error) => showError(error))
        .finally(() => setLoading(false));
    }
  }, [props.open]);

  const showError = (error) => {
    console.log(error);
    setError(error);
    setTimeout(() => setError(""), 5500);
  };

  const updateBuyRequest = async (status) => {
    setLoading(true);

    updateCurrentBuyRequest(token, props._id, { status })
      .then(() => getBuyRequestInDeed())
      .catch((error) => showError(error))
      .finally(() => setLoading(false));
  };

  const handleClose = () => props.onClose();

  const setValidDoc = (doc) =>
    setFiles(files.map((file) => (file._id === doc._id ? doc : file)));

  return (
    props.open && (
      <td
        className={`custom_modal ${props.open && "isOpen"}`}
        onClick={handleClose}
      >
        <div
          className="custom_modal__content w-75"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="custom_modal__header">
            <h2 className="mt-3">Documentacion</h2>
            <div className="col-3 mt-3">
              <button
                className="btn-close custom_modal_close"
                onClick={handleClose}
              ></button>
            </div>
          </div>

          <div className="my-5 px-2">
            {loading ? (
              <Loading />
            ) : (
              files.map((file) => (
                <DeedFileLink
                  key={file._id}
                  setValidDoc={setValidDoc}
                  {...file}
                />
              ))
            )}
          </div>

          <div className="custom_modal__footer">
            {error !== "" && <div className="deed-error">{error}</div>}
            <button
              className="deed-button w-auto px-3 py-1"
              onClick={() => updateBuyRequest(BUY_REQUESTS_STATUS.SIGNING)}
              disabled={!isValidListDoc}
            >
              ENVIAR A FIRMA
            </button>
            <button
              className="deed-button w-auto px-3 py-1"
              disabled={!existDocInvalid}
              onClick={() => updateBuyRequest(BUY_REQUESTS_STATUS.FILE)}
            >
              DEVOLVER A EXPEDIENTE
            </button>
            <button
              className="deed-button w-auto px-3 py-1"
              onClick={handleClose}
            >
              CERRAR
            </button>
          </div>
        </div>
      </td>
    )
  );
};
