import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { createDescriptions } from "../../../api/descriptionsnotes/createDescription";
import { createPropertyPreseller } from "../../../api/properties/createPropertyPreseller";
import { updateAddressPreseller } from "../../../api/properties/updateAddressPreseller";
import { updatePropertyPreseller } from "../../../api/properties/updatePropertyPreseller";
// import { getQuoters } from "../../../api/quoters/getQuoters";
import { updatePreSellerToSeller } from "../../../api/seller/updatePresellerToSeller";
import TokenContext from "../../../context/Token";
import { useModal } from "../../../hooks/useModal";
import CreditInfo from "./CreditInfo";
import MaritalStatus from "./MaritalStatus";
import PropertyAddress from "./PropertyAddress";
import PropertyCharacteristics from "./PropertyCharacteristics";

//no está hecho para recibir datos iniciales ni actualizar (no put), solo para crear una casa nueva(post)
const CreateHouseSeller = ({ preSellerId, refetchPreSellers }) => {
  const { token } = useContext(TokenContext);
  const { open, onOpenModal, onCloseModal } = useModal();
  const [avgPrice, setAvgPrice] = useState(0);
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [renovation, setRenovation] = useState("");

  const [propertyAddress, setPropertyAddress] = useState({
    numInt: "",
    parkingLot: "",
    levels: "",
    washroom: "",
    street: "",
    mLand: "",
    bedrooms: "",
    floor: "",
    mortgage: "",
    numExt: "",
    mBuilding: "",
    division: "",

    mzn: "",
    lt: ""
  });

  const [propertyData, setPropertyData] = useState({
    postalCode: "",
    state: "", //estado
    city: "", //ciudad
    settlement: "", //colonia
    type: "", //dept o casa
    quote: 0, //oferta de cotizador
    creditType: "", //infonavit, fovissste, bancario, otro
    debtCredit: "", //cantidad restante por pagar
    hasBacklogCredit: "", // si tiene meses atrasados
    monthBacklogs: "", //meses de atraso
    adquisitionYear: "", //fecha de adquisición
    reasonSale: "", //razón de venta
    isInhabited: true, //si está deshabitada
    wantedForSale: "" //cantidad esperada para vender
  });

  const [sellerInfo, setSellerInfo] = useState({
    credit: "", //numero de crédito
    married: "", //casado(true) o soltero(false)
    communityProperty: false //bienes separados(false) o mancomunados(true)
  });

  const getHomeValue = async () => {
    const price = propertyAddress.mBuilding * 10000;

    setPropertyData({
      ...propertyData,
      quote: price
    });
    // const price = await getQuoters(
    //   propertyData.postalCode,
    //   propertyAddress.mBuilding
    // );

    // setPropertyData({
    //   ...propertyData,
    //   quote: parseFloat(price.data?.data[0]?.marketValue.toFixed(2))
    // });
  };

  const sendData = async () => {
    try {
      setLoading(true);

      const price = propertyAddress.mBuilding * 10000;
      const response = await createPropertyPreseller(token, preSellerId, {
        ...propertyData,
        quote: price
      });

      if (response.property_id) {
        await updatePropertyPreseller(token, response?.property_id, {
          ...propertyData,
          quote: price
        });
        await updateAddressPreseller(
          token,
          response?.property_id,
          propertyAddress
        );
        await updatePreSellerToSeller(token, preSellerId);
        await createDescriptions(token, {
          propertyRef: response?.property_id,
          description: `Cambios o renovaciones: ${renovation}`,
          status: "Perfilado"
        });

        refetchPreSellers();
        onCloseModal();
        // debts.forEach(async d => {
        //   if (d.val === true) {
        //     const debtResponse = await createDebt(token, response?.data?.property_id, { text: "", amount: 0, type: d.name })
        //   }
        // })
      }
      setSuccess({ severity: "success", text: "Actualizado" });
      setLoading(false);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      console.log(error);
      setSuccess({ severity: "error", text: error });
      setLoading(false);
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  useEffect(() => {
    // if (propertyAddress.mBuilding && propertyData.postalCode) {
    //   getHomeValue();
    // }
    if (propertyAddress.mBuilding) {
      getHomeValue();
    }
    //eslint-disable-next-line
  }, [propertyAddress.mBuilding, propertyData.quote]);

  return (
    <>
      <button onClick={onOpenModal} className="btn-fill-orange mb-2">
        Crear Casa
      </button>
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          aria-labelledby={"error-success-dialogue"}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            Informacion del inmueble
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box component={"form"}>
              <PropertyAddress
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                propertyAddress={propertyAddress}
                setPropertyAddress={setPropertyAddress}
                setAvgPrice={setAvgPrice}
              />
              <PropertyCharacteristics
                renovation={renovation}
                setRenovation={setRenovation}
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                propertyAddress={propertyAddress}
                setPropertyAddress={setPropertyAddress}
              />

              <CreditInfo
                sellerInfo={sellerInfo}
                setSellerInfo={setSellerInfo}
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                propertyAddress={propertyAddress}
                setPropertyAddress={setPropertyAddress}
              />

              {/* <Debts debts={debts} setDebts={setDebts} /> */}

              <MaritalStatus
                sellerInfo={sellerInfo}
                setSellerInfo={setSellerInfo}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            {success.severity === "success" && (
              <Alert severity={success.severity} sx={{ mr: 2 }}>
                {success.text}
              </Alert>
            )}
            {success.severity === "error" && (
              <Alert severity={success.severity} sx={{ mr: 2 }}>
                {success.text}
              </Alert>
            )}
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              onClick={sendData}
            >
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default CreateHouseSeller;
