import React from "react";
import { useState } from "react";
import "../styles/Modal.css";

// PROPS DEL COMPONENTE: titleModal, reason, okText, id, sendData, successMessage, errorMessage, inheritedText
const ModalComment = (props) => {
  const [text, setText] = useState(props.inheritedText ?? "");
  //const [selectReason, setSelectReason] = useState("")

  const handleAction = () => {
    if (!props.select) {
      props.sendData(text).then((a) => setText(""));
    }
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id={"modalComment" + props.id}
      aria-labelledby={`modalCommentLabel`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.reason}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <textarea
              className="modal-textarea"
              onChange={(e) => setText(e.target.value)}
              value={text}
            />
            {props.select && (
              <>
                <label className="text-gray fw-bold my-3">
                  Razon por la que no está perfilado
                </label>
                <select className="form-select" style={{ width: 250 }}>
                  <option hidden selected>
                    {" "}
                    Razones
                  </option>
                  <option value="Adeudo Mayor">Adeudo Mayor</option>
                  <option value="Viviendas sin formalidad">
                    Viviendas sin formalidad
                  </option>
                  <option value="La casa esta invadida">
                    La casa esta invadida
                  </option>
                  <option value="No le interesa vender">
                    No le interesa vender
                  </option>
                  <option value="No se registro">No se registro</option>
                  <option value="No se compra dentro de ese fraccionamiento">
                    No se compra dentro de ese fraccionamiento
                  </option>
                  <option value="Lead sin datos">Lead sin datos</option>
                  <option value="Lead repetido">Lead repetido</option>
                </select>
              </>
            )}
          </div>
          <div className="modal-footer">
            {props.successMessage && (
              <p className="me-3 py-1 px-3 rounded bg-success text-white fw-bold">
                {props.successMessage}{" "}
              </p>
            )}
            {props.errorMessage && (
              <p className="me-3 py-1 px-3 rounded bg-danger text-white fw-bold">
                {props.errorMessage}{" "}
              </p>
            )}
            <button
              type="button"
              className="btn btn-disabled"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn-fill-orange"
              onClick={handleAction}
            >
              {props.okText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalComment;
