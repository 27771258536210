import React from "react";

import { DeedListItem } from "./DeedListItem";

export const DeedListTable = (props) => {
  return (
    <div
      className="w-100 d-flex justify-content-center px-5"
      style={{ height: "fit-content", overflowX: "auto" }}
    >
      <table
        className="table table-bordered mt-3"
        style={{ borderColor: "#707070" }}
      >
        <thead style={{ backgroundColor: "#F2F2F2", color: "#718195" }}>
          <tr>
            <th>Id de la casa</th>
            <th>Precio</th>
            <th>Información del cliente</th>
            <th>Información del inmueble</th>
            <th>Fuente</th>
            <th>Monto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {props.buyRequests.map((buyRequest) => (
            <DeedListItem
              key={buyRequest._id}
              {...buyRequest}
              token={props.token}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
