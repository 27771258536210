import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select
} from "@mui/material";
import { useMemo, useState } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const statusList = [
  "Perfilado",
  "Prospectos",
  "Expediente",
  "Gestion",
  "Firma",
  "Inventario",
  "Escrituracion",
  "Entrega"
];

export default function MultiCheckStatus({ currentParam, setCurrentParam }) {
  const [statesList, setStatesList] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;

    setStatesList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useMemo(() => {
    setCurrentParam({ ...currentParam, criteria: statesList });
  }, [statesList]);

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={statesList}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {statusList.map((item, index) => (
            <MenuItem key={index} value={item}>
              <Checkbox checked={statesList.indexOf(item) > -1} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
