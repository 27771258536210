import React, { useContext } from "react";

import "../styles/Categories.css";
import { useQuery } from "react-query";

import { getMe } from "../../api/session/getMe";
import { currentUser } from "../../cacheQueries/cacheQueries";
import TokenContext from "../../context/Token";

//PROPS DEL COMPONENTE onSelect={handleSelect} currentTab={value.selectTab}
const ProceedingCategories = (props) => {
  const { token } = useContext(TokenContext);
  const { data } = useQuery([currentUser, token], () => getMe(token), {
    refetchOnWindowFocus: false
  });

  return (
    <section className="categories-section mb-3">
      {data?.data?.admin?.type !== "Lawyer" && (
        <div
          className="category-item text-gray"
          onClick={() => props.onSelect("selectTab", "")}
          style={{
            cursor: "pointer",
            backgroundColor:
              props.currentTab === "" ? "rgb(230, 230, 230)" : null
          }}
        >
          <label style={{ cursor: "pointer" }}>Todo</label>
        </div>
      )}
      <div
        className="category-item text-gray"
        onClick={() => props.onSelect("selectTab", "Firma")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Firma" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Validación</label>
      </div>
      {data?.data?.admin?.type !== "Lawyer" && (
        <div
          className="category-item text-gray"
          onClick={() => props.onSelect("selectTab", "Gestion")}
          style={{
            cursor: "pointer",
            backgroundColor:
              props.currentTab === "Gestion" ? "rgb(230, 230, 230)" : null
          }}
        >
          <label style={{ cursor: "pointer" }}>Gestion</label>
        </div>
      )}
    </section>
  );
};

export default ProceedingCategories;
