import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useContext, useEffect, useState } from "react";
import { useMemo } from "react";

import TokenContext from "../../context/Token";
import { geocode } from "../../helpers/geocode";
import Loading from "../loading/Loading";

const { REACT_APP_GOOGLE_API } = process.env;

const MapContainer = ({ latitude, longitude, postalCode }) => {
  const { token } = useContext(TokenContext);
  const [coords, setCoords] = useState({});

  const getCoordsByPostalCode = async () => {
    try {
      const response = await geocode(REACT_APP_GOOGLE_API, postalCode);

      return setCoords(response?.data?.results[0]?.geometry?.location);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!latitude || !longitude) {
      getCoordsByPostalCode();
    }
  }, []);

  const center = useMemo(
    () => ({
      lat: latitude || coords?.lat,
      lng: longitude || coords?.lng
    }),
    [coords]
  );

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_API
  });

  if (!isLoaded) return <Loading />;

  return (
    <>
      {center.lat && center.lng ? (
        <GoogleMap
          zoom={14}
          center={center}
          mapContainerStyle={{ width: "100%", height: "100%" }}
        >
          <MarkerF position={center} />
        </GoogleMap>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MapContainer;
