import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { deletePropertyPreseller } from "../../../api/properties/deletePreseller";
// import { getQuoters } from "../../../api/quoters/getQuoters";
import TokenContext from "../../../context/Token";
import { useModal } from "../../../hooks/useModal";
import PropertyAddress from "./PropertyAddress";

//no está hecho para recibir datos iniciales ni actualizar (no put), solo para crear una casa nueva(post)
const DeleteHousePreSeller = ({ preSellerId, refetchPreSellers }) => {
  const { token } = useContext(TokenContext);

  const { open, onOpenModal, onCloseModal } = useModal();

  const [avgPrice, setAvgPrice] = useState(0);

  const [success, setSuccess] = useState(false);

  const [propertyAddress, setPropertyAddress] = useState({
    numInt: "",
    parkingLot: "",
    levels: "",
    washroom: "",
    street: "",
    mLand: "",
    bedrooms: "",
    floor: "",
    mortgage: "",
    numExt: "",
    mBuilding: "",
    division: "",

    mzn: "",
    lt: ""
  });

  const [propertyData, setPropertyData] = useState({
    postalCode: "",
    state: "", //estado
    city: "", //ciudad
    settlement: "", //colonia
    type: "", //dept o casa
    quote: 0, //oferta de cotizador
    creditType: "", //infonavit, fovissste, bancario, otro
    debtCredit: "", //cantidad restante por pagar
    hasBacklogCredit: "", // si tiene meses atrasados
    monthBacklogs: "" //meses de atraso
  });

  const [sellerInfo, setSellerInfo] = useState({
    credit: "", //numero de crédito
    married: "", //casado(true) o soltero(false)
    communityProperty: false //bienes separados(false) o mancomunados(true)
  });

  const getHomeValue = async () => {
    const price = propertyAddress.mBuilding * 10000;

    setPropertyData({
      ...propertyData,
      quote: price
    });
    // const price = await getQuoters(
    //   propertyData.postalCode,
    //   propertyAddress.mBuilding
    // );

    // setPropertyData({
    //   ...propertyData,
    //   quote: parseFloat(price.data?.data[0]?.marketValue.toFixed(2))
    // });
  };

  const sendData = async () => {
    try {
      const response = await deletePropertyPreseller(
        token,
        preSellerId,
        propertyData
      );

      refetchPreSellers();
      onCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (propertyAddress.mBuilding && propertyData.postalCode) {
    //   getHomeValue();
    // }
    if (propertyAddress.mBuilding) {
      getHomeValue();
    }
  }, [propertyAddress.mBuilding, propertyData.quote]);

  return (
    <>
      <button onClick={onOpenModal} className="btn-fill-red mb-2">
        Eliminar
      </button>
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          aria-labelledby={"error-success-dialogue"}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            Eliminar PreLead
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box component={"form"}>
              <PropertyAddress
                propertyData={propertyData}
                setPropertyData={setPropertyData}
                propertyAddress={propertyAddress}
                setPropertyAddress={setPropertyAddress}
                setAvgPrice={setAvgPrice}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="contained"
              onClick={onCloseModal}
            >
              Cancelar
            </Button>
            <Button variant="contained" onClick={sendData} color="error">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteHousePreSeller;
