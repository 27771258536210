import { useContext } from "react";
import { useQuery } from "react-query";

import { getAllAdvisers } from "../../api/advisers/getAllAdvisers";
import { getAllReconversion } from "../../api/reconversion/getAllReconversion";
import Loading from "../../components/loading/Loading";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";

const NoAptosTableContainer = () => {
  const { token } = useContext(TokenContext);
  const getAdvisers = useQuery(["advisers"], () => getAllAdvisers(token));
  const { data, refetch, isLoading } = useQuery(["No Apto"], () =>
    getAllReconversion(token, "No Apto")
  );

  if (getAdvisers.isLoading || isLoading) return <Loading />;
  console.log(getAdvisers?.data?.data?.advisers);
  return (
    <Table
      columnTitles={[
        "Origen",
        "Enviado por",
        "Informacion del Cliente",
        "Informacion del Inmueble",
        "Contacto",
        "Razón"
      ]}
      properties={data?.data?.properties?.docs ?? []}
      advisers={getAdvisers?.data?.data?.advisers ?? []}
      refetchReconversion={refetch}
      tableType={"No Apto"}
    />
  );
};

export default NoAptosTableContainer;
