import React from "react";
import "../styles/Categories.css";

const InventoryCategories = (props) => {
  return (
    <section className="categories-section mb-2">
      <div
        className="category-item text-gray"
        onClick={() => props.onSelect("selectTab", "")}
        style={{
          cursor: "pointer",
          backgroundColor: props.currentTab === "" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>General</label>
        <label style={{ cursor: "pointer" }}></label>
      </div>
      {/* <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Firma")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Firma" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Firma</label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Titulacion")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Titulacion" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Titulación</label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Rehabilitacion")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Rehabilitacion" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Rehabilitación</label>
      </div>
      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Entrega")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Entrega" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Entrega</label>
      </div>

      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Comprada")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Comprada" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Comprada</label>
      </div> */}

      <div
        className="category-item text-gray margin-category"
        onClick={() => props.onSelect("selectTab", "Pre-inventario")}
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Proximamente" ? "rgb(230, 230, 230)" : null
        }}
      >
        <label style={{ cursor: "pointer" }}>Pre-Inventario</label>
      </div>
    </section>
  );
};

export default InventoryCategories;
