const { REACT_APP_BASE_URL } = process.env;

export const getUploadFileUrl = ({ token, path, fileName }) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({
      path,
      fileName
    }).toString();

    const request = new Request(
      REACT_APP_BASE_URL + "/utils/upload-urls?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const getDownloadFileUrl = (token, key) =>
  new Promise((resolve, reject) => {
    const queryParams = new URLSearchParams({ key }).toString();

    const request = new Request(
      REACT_APP_BASE_URL + "/utils/download-urls?" + queryParams,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const convertMarketplaceImages = (token, folderPath) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_BASE_URL + "/utils/convertMarketplaceImages",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ folderPath })
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const sendDocumentToSign = (token, data) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_BASE_URL + "/utils/sendDocumentToSign",
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ data })
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const getWeeTrustDocument = (token, quoteId) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_BASE_URL + "/documentations/weeTrustDocument/" + quoteId,
      {
        method: "get",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const resendWeeTrustdSignDocumentEmail = (token, documentId) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_BASE_URL +
        "/documentations/resendWeeTrustdSignDocumentEmail/" +
        documentId,
      {
        method: "put",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const regeneratedWeeTrustSignUrl = (token, documentId) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_BASE_URL +
        "/documentations/regeneratedWeeTrustSignUrl/" +
        documentId,
      {
        method: "put",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const deleteWeeTrustDocument = (token, documentId) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_BASE_URL +
        "/documentations/deleteWeeTrustDocument/" +
        documentId,
      {
        method: "delete",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const resetWeeTrustDocument = (token, documentId) =>
  new Promise((resolve, reject) => {
    const request = new Request(
      REACT_APP_BASE_URL +
        "/documentations/resetWeeTrustDocument/" +
        documentId,
      {
        method: "delete",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );

    fetch(request)
      .then((response) => resolve(response.json()))
      .catch((err) => reject(err));
  });

export const uploadFile = (url, data) =>
  new Promise((resolve, reject) => {
    fetch(url, {
      method: "PUT",
      body: data,
      headers: {
        "Content-Length": new Blob([data]).size
      },
      mode: "cors"
    })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
