import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getAllReconversion = async (token, status = "", page = 1) => {
  let url = `${REACT_APP_BASE_URL}/reconversions?results=20&page=${page}`;

  if (status === "No Apto") {
    url = url + `&lead=${encodeURI(status)}`;
  } else if (status !== "") {
    url = url + `&status=${status}`;
  }

  try {
    const response = await axios.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    });

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
