import {
  Grid,
  InputLabel,
  TextField,
  Typography,
  MenuItem,
  Select,
  Button,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControl,
  Autocomplete,
  InputAdornment
} from "@mui/material";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useMutation, useQuery } from "react-query";

import { createMarketplaceProperty } from "../../../api/properties/createMarketplaceProperty";
import { getCities } from "../../../api/properties/getCities";
import { getSettlements } from "../../../api/properties/getSettlements";
import {
  convertMarketplaceImages,
  getUploadFileUrl,
  uploadFile
} from "../../../api/utilsApi";
import TokenContext from "../../../context/Token";
import ImageUpload from "./DropDownImage";
import NumberInputIncrement from "./NumberInputIncrement";

export const MEXICO_STATES = {
  AGUASCALIENTES: "Aguascalientes",
  BAJA_CALIFORNIA_SUR: "Baja California Sur",
  BAJA_CALIFORNIA: "Baja California",
  CAMPECHE: "Campeche",
  CDMX: "Ciudad de México",
  CHIAPAS: "Chiapas",
  CHIHUAHUA: "Chihuahua",
  COAHUILA: "Coahuila",
  COLIMA: "Colima",
  DURANGO: "Durango",
  EDOMEX: "Estado de México",
  GUANAJUATO: "Guanajuato",
  GUERRERO: "Guerrero",
  HIDALGO: "Hidalgo",
  JALISCO: "Jalisco",
  MICHOACAN: "Michoacán",
  MORELOS: "Morelos",
  NAYARIT: "Nayarit",
  NUEVO_LEON: "Nuevo León",
  OAXACA: "Oaxaca",
  PUEBLA: "Puebla",
  QUERETARO: "Querétaro",
  QUINTANA_ROO: "Quintana Roo",
  SAN_LUIS_POTOSI: "San Luis Potosí",
  SINALOA: "Sinaloa",
  SONORA: "Sonora",
  TABASCO: "Tabasco",
  TAMAULIPAS: "Tamaulipas",
  TLAXCALA: "Tlaxcala",
  VERACRUZ: "Veracruz",
  YUCATAN: "Yucatán",
  ZACATECAS: "Zacatecas"
};

export const AMENITIES = [
  "Estacionamiento",
  "Jardín interior",
  "Jardín exterior",
  "Alberca privada",
  "Alberca pública",
  "Gimnasio",
  "Caseta de guardia",
  "Escuelas Cercanas",
  "Portero",
  "Árboles",
  "Área de Juegos Infantiles",
  "Área de basura",
  "Restaurantes cercanos",
  "Cocina integral",
  "Cuartos de servicio",
  "Acceso discapacitados",
  "Cines cercanos",
  "Plazas cercanas"
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export function toNormalForm(str) {
  return str
    .normalize("NFD")
    .replaceAll(/[\u0300-\u036f]/g, "")
    .replaceAll(/[()]/g, "")
    .replaceAll("+", "_")
    .replaceAll(" ", "_")
    .replaceAll("-", "_")
    .toLowerCase();
}

export function getRandomId() {
  const size = 6;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomId = "";

  for (let i = 0; i < size; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);

    randomId += characters[randomIndex];
  }
  return randomId;
}

export const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
    />
  );
});

const InitialState = {
  state: "",
  city: "",
  settlement: "",
  postalCode: "",
  type: "",
  street: "",
  numExt: "",
  numInt: "",
  mLand: "",
  mBuilding: "",
  parkingLot: 0,
  bedrooms: 0,
  washroom: 0,
  halfBaths: 0,
  levels: 0,
  floor: 0,
  lat: "",
  lng: "",
  cost: "",
  invetoryStatus: "",
  description: "",
  amenities: []
};

export const useCities = (state) => {
  const [citiesList, setCitiesList] = useState([]);
  const { refetch: fetchCities } = useQuery(
    ["city", state],
    () => getCities({ state }),
    {
      enabled: false
    }
  );

  useEffect(() => {
    const fetchCitiesData = async () => {
      if (state) {
        const result = await fetchCities();

        if (result?.data?.data?.states[0]?.cities) {
          setCitiesList(result?.data?.data?.states[0]?.cities);
        }
      } else {
        setCitiesList([]);
      }
    };

    fetchCitiesData();
  }, [fetchCities, state]);

  return citiesList;
};

export const useSettlements = (state, city) => {
  const [settlementsList, setSettlementsList] = useState([]);
  const { refetch: fetchSettlements } = useQuery(
    ["settlements", state, city],
    () => getSettlements({ state, city }),
    {
      enabled: false
    }
  );

  useEffect(() => {
    const fetchSettlementsData = async () => {
      if (city) {
        const result = await fetchSettlements();

        if (result?.data?.data?.settlements) {
          setSettlementsList([
            ...new Set(
              result?.data?.data?.settlements
                ?.map((postalCode) => postalCode?.settlements)
                .flat()
            )
          ]);
        }
      } else {
        setSettlementsList([]);
      }
    };

    fetchSettlementsData();
  }, [fetchSettlements, city]);

  return settlementsList;
};

const CreatePropertyContainer = ({
  setIsLoadingDialog,
  showFeedbackMessage,
  refetchInvetoryProperties
}) => {
  const { token } = useContext(TokenContext);
  const [files, setFiles] = useState([]);
  const [property, setProperty] = useState(InitialState);
  const citiesList = useCities(property.state);
  const settlementsList = useSettlements(property.state, property.city);

  const mutation = useMutation(createMarketplaceProperty, {
    onSuccess: (data) => {
      // Handle success (e.g., show a success message or redirect)
      console.log("Post created:", data);
    },
    onError: (error) => {
      // Handle error (e.g., show an error message)
      console.error("Error creating post:", error);
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoadingDialog(true);
    try {
      const imagesPath = await handleImages();

      console.log(imagesPath);
      await mutation.mutate({
        token,
        property: { ...property, imagesPath }
      });
      /* setProperty(InitialState);
      setFiles([]); */
      showFeedbackMessage({
        message: "Propiedad Creada",
        type: "success"
      });
      refetchInvetoryProperties();
    } catch (error) {
      showFeedbackMessage({ message: "Ocurrió un error", type: "error" });
      throw new Error(error);
    } finally {
      setIsLoadingDialog(false);
    }
  };

  const handlePropertyData = (e) => {
    const { name, value } = e.target;

    if (name === "state") {
      setProperty((prev) => ({
        ...prev,
        state: value,
        city: "",
        settlement: ""
      }));
    } else {
      setProperty((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleChangeAmenities = (event) => {
    const {
      target: { value }
    } = event;

    setProperty((prev) => ({
      ...prev,
      amenities: typeof value === "string" ? value.split(",") : value
    }));
  };

  const handleImages = async () => {
    const folderName = getRandomId();
    const path = "cdn/convertImages/" + folderName;

    for (const { file } of files) {
      const { url, signedUrl } = await getUploadFileUrl({
        token,
        path,
        fileName: toNormalForm(file?.name)
      });

      await uploadFile(signedUrl, file);
      console.log(url, signedUrl);
    }
    await convertMarketplaceImages(token, path);
    return path;
  };

  return (
    <Grid
      container
      item
      xs={12}
      maxWidth="xl"
      spacing={2}
      sx={{
        mt: 1,
        border: "1px solid rgba(111, 128, 149, 0.6)",
        borderRadius: 2,
        py: 2,
        px: 2
      }}
    >
      <Grid container ml={2}>
        <Grid item md={12}>
          <Typography textAlign={"left"} variant="h5" color="secondary">
            Datos del inmueble:
          </Typography>
        </Grid>
      </Grid>
      {mutation.isLoading && <h5>Loading</h5>}
      <Grid container item xs={12} flexDirection={"row"} spacing={2}>
        <Grid container item xs={6} spacing={2}>
          <FormFields
            property={property}
            setProperty={setProperty}
            handlePropertyData={handlePropertyData}
            citiesList={citiesList}
            settlementsList={settlementsList}
            handleChangeAmenities={handleChangeAmenities}
          />
        </Grid>
        <Grid container item xs={6}>
          <Grid item xs={12}>
            <Typography variant="h5" mb={1}>
              Imágenes
            </Typography>
            <ImageUpload files={files} setFiles={setFiles} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button variant="contained" onClick={handleSubmit}>
              Registrar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const FormFields = ({
  property,
  handlePropertyData,
  citiesList,
  settlementsList,
  handleChangeAmenities,
  setProperty
}) => (
  <>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        size="medium"
        name="cost"
        value={property.cost}
        onChange={handlePropertyData}
        style={{ marginTop: "24px" }}
        label={
          <Typography variant="headline" component="h3">
            Precio
          </Typography>
        }
        InputProps={{
          inputComponent: NumericFormatCustom,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="start">.00</InputAdornment>,
          style: { fontSize: "1.4rem" }
        }}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <InputLabel id="invetoryStatus">Estatus</InputLabel>
      <Select
        labelId="invetoryStatus"
        id="invetoryStatus"
        fullWidth
        name="invetoryStatus"
        defaultValue=""
        value={property.invetoryStatus}
        onChange={handlePropertyData}
        placeholder="Estatus"
        style={{ fontSize: "1.4rem" }}
      >
        <MenuItem value="Publicada">Publicada</MenuItem>
        <MenuItem value="No Públicada">No Públicada</MenuItem>
        <MenuItem value="Vendida">Vendida</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={12} md={6}>
      <Autocomplete
        id="state"
        value={property.state}
        onChange={(event, newValue) =>
          handlePropertyData({ target: { name: "state", value: newValue } })
        }
        options={Object.values(MEXICO_STATES)}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            id="state"
            label="Estado"
            variant="outlined"
            placeholder="Seleccionar..."
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Autocomplete
        id="city"
        value={property.city}
        onChange={(event, newValue) =>
          handlePropertyData({ target: { name: "city", value: newValue } })
        }
        options={citiesList}
        disabled={citiesList.length === 0}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            id="city"
            label="Municipio"
            variant="outlined"
            placeholder="Seleccionar..."
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <Autocomplete
        id="settlement"
        value={property.settlement}
        onChange={(event, newValue) =>
          handlePropertyData({
            target: { name: "settlement", value: newValue }
          })
        }
        options={settlementsList}
        disabled={settlementsList.length === 0}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            id="settlement"
            label="Colonia"
            variant="outlined"
            placeholder="Seleccionar..."
          />
        )}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="lat"
        label="Latitud"
        type="number"
        value={property.lat}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="lng"
        label="Longitud"
        type="number"
        value={property.lng}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <TextField
        fullWidth
        size="medium"
        name="street"
        label="Calle"
        value={property.street}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="numInt"
        label="Número Interior"
        value={property.numInt}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <TextField
        fullWidth
        size="medium"
        name="numExt"
        label="Número Exterior"
        value={property.numExt}
        onChange={handlePropertyData}
      />
    </Grid>

    <Grid item xs={12} md={6}>
      <InputLabel id="property-type-label">Tipo de Casa</InputLabel>
      <Select
        labelId="property-type-label"
        id="property-type"
        fullWidth
        name="type"
        defaultValue=""
        value={property.type}
        onChange={handlePropertyData}
        placeholder="Tipo de Casa"
      >
        <MenuItem value="casa">Casa</MenuItem>
        <MenuItem value="departamento">Departamento</MenuItem>
        <MenuItem value="duplex">Dúplex</MenuItem>
        <MenuItem value="pie de casa">Pie de Casa</MenuItem>
      </Select>
    </Grid>
    <Grid item xs={6} md={3} mt={2}>
      <TextField
        fullWidth
        size="medium"
        name="mBuilding"
        label="Construcción"
        type="number"
        value={property.mBuilding}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3} mt={2}>
      <TextField
        fullWidth
        size="medium"
        name="mLand"
        label="Terreno"
        type="number"
        value={property.mLand}
        onChange={handlePropertyData}
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="rooms-input" style={{ textAlign: "center" }}>
        Cuartos
      </InputLabel>
      <NumberInputIncrement
        id="bedrooms"
        name="bedrooms"
        aria-label="Rooms Input"
        min={0}
        max={10}
        value={property.bedrooms}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, bedrooms: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="bathrooms-input" style={{ textAlign: "center" }}>
        Baños
      </InputLabel>
      <NumberInputIncrement
        id="washroom"
        name="washroom"
        aria-label="Bathrooms Input"
        min={0}
        max={10}
        value={property.washroom}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, washroom: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel
        htmlFor="half-bathrooms-input"
        style={{ textAlign: "center" }}
      >
        Medios Baños
      </InputLabel>
      <NumberInputIncrement
        id="halfBaths"
        name="halfBaths"
        aria-label="Half Bathrooms Input"
        min={0}
        max={5}
        value={property.halfBaths}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, halfBaths: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="floors-input" style={{ textAlign: "center" }}>
        Nivel
      </InputLabel>
      <NumberInputIncrement
        id="levels"
        name="levels"
        aria-label="Floors Input"
        min={0}
        max={10}
        value={property.levels}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, levels: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="floors-input" style={{ textAlign: "center" }}>
        Piso
      </InputLabel>
      <NumberInputIncrement
        id="floor"
        name="floor"
        aria-label="Floors Input"
        min={0}
        max={10}
        value={property.floor}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, floor: value }))
        }
      />
    </Grid>
    <Grid item xs={6} md={3}>
      <InputLabel htmlFor="parking-input" style={{ textAlign: "center" }}>
        Estacionamientos
      </InputLabel>
      <NumberInputIncrement
        id="parkingLot"
        name="parkingLot"
        aria-label="Parking Input"
        min={0}
        max={10}
        value={property.parkingLot}
        onChange={(e, value) =>
          setProperty((prev) => ({ ...prev, parkingLot: value }))
        }
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl sx={{ m: 1, width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">Amenidades</InputLabel>
        <Select
          fullWidth
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={property.amenities}
          onChange={handleChangeAmenities}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {AMENITIES.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={property.amenities.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        size="medium"
        name="description"
        label="Descripción"
        multiline
        rows={4}
        value={property.description}
        onChange={handlePropertyData}
      />
    </Grid>
  </>
);

export default CreatePropertyContainer;
