import React from "react";

import { AppBuyRequestTile } from "../../components/Deed/AppBuyRequestTile";
import { useModal } from "../../hooks/useModal";
import { DeedEvaluate } from "./DeedEvaluate";
import { DeedReconversion } from "./DeedReconversion";
import { DeedShowFile } from "./DeedShowFile";

export const DeedListItem = (props) => {
  const deedEvaluo = useModal();
  const deedReconversion = useModal();
  const deedShowFile = useModal();

  return (
    <AppBuyRequestTile {...props}>
      <td>
        <button className="deed-button" onClick={deedEvaluo.onOpenModal}>
          AVALÚO
        </button>
      </td>
      <td style={{ width: "13%" }}>
        <button className="deed-button" onClick={deedShowFile.onOpenModal}>
          EXPEDIENTE
        </button>
        <button
          className="deed-button deeb-secondary"
          onClick={deedReconversion.onOpenModal}
        >
          RECONVERSIÓN
        </button>
      </td>

      <DeedShowFile
        open={deedShowFile.open}
        onClose={deedShowFile.onCloseModal}
        {...props}
      />
      <DeedEvaluate
        open={deedEvaluo.open}
        onClose={deedEvaluo.onCloseModal}
        {...props}
      />
      <DeedReconversion
        open={deedReconversion.open}
        onClose={deedReconversion.onCloseModal}
        {...props}
      />
    </AppBuyRequestTile>
  );
};
