import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { getQuotersAdmins } from "../../api/quoters/getQuotersAdmins";
import { postValidateAdmin } from "../../api/quoters/postValidateAdmin";
import Loading from "../../components/loading/Loading";
import TokenContext from "../../context/Token";

const DialogQuoterAdmins = ({
  setAllowedByAdmin,
  open,
  handleClose,
  setSuccess,
  setAllowedByAdminRef
}) => {
  const { token } = useContext(TokenContext);
  const [adminData, setAdminData] = useState({
    adminEmail: "",
    adminPassword: ""
  });
  const { data: { data: { data: adminsList } = {} } = {}, isLoading } =
    useQuery([], () => getQuotersAdmins(token));

  const { mutate: validateAdminMutation, isLoading: isLoadingValidation } =
    useMutation(postValidateAdmin, {
      onSuccess: (result, variables, context) => {
        if (result?._id) {
          setAllowedByAdmin(true);
          setAllowedByAdminRef(result?._id);
          handleClose();
        } else {
          setSuccess({
            severity: "error",
            text: "Contraseña incorrecta"
          });
          setTimeout(() => {
            setSuccess({ severity: "", text: "" });
          }, 3000);
        }
      }
    });

  console.log(adminsList);

  if (isLoading || isLoadingValidation)
    return (
      <Grid
        item
        columnSpacing={2}
        style={{
          position: "absolute",
          width: "100%"
        }}
      >
        <Loading />
      </Grid>
    );

  const onSubmit = async (event) => {
    try {
      await validateAdminMutation({
        token,
        email: adminData.adminEmail,
        password: adminData.adminPassword
      });
    } catch (error) {
      setSuccess({
        severity: "error",
        text: "Ocurrió un error. Inténtalo nuevamente."
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{}}>
      <DialogTitle>Desbloquear</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor, introduce tu contraseña de administrador para continuar:
        </DialogContentText>
        <Autocomplete
          id="combo-box-demo"
          options={adminsList?.map((admin) => admin.email)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Administrador"
              name="adminEmail"
              autoComplete="off"
            />
          )}
          inputValue={adminData.adminEmail}
          onInputChange={(e, newInputValue) =>
            setAdminData({ ...adminData, adminEmail: newInputValue })
          }
        />
        <TextField
          required
          id="adminPassword"
          name="adminPassword"
          label="Contraseña"
          type="password"
          autoComplete="off"
          fullWidth
          value={adminData.adminPassword}
          onChange={(e) =>
            setAdminData({ ...adminData, adminPassword: e.target.value })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button
          onClick={onSubmit}
          disabled={!adminData.adminPassword || !adminData.adminEmail}
        >
          Verificar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogQuoterAdmins;
