import React from "react";
import { useNavigate } from "react-router-dom";

const ModalSearch = () => {
  const navigate = useNavigate();

  return (
    <div
      className="modal fade"
      id="ModalSearch"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-gray fw-bold"
              id="exampleModalLabel"
            >
              Información del cliente
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-around">
              <div className="d-flex flex-column text-gray align-items-start">
                <label className="fw-bold mb-1">¿Qué zona busca</label>
                <input className="mb-4" type="text" />
                <label className="fw-bold mb-1">Ciudad</label>
                <input className="mb-4" type="text" />
                <label className="fw-bold mt-2">
                  Caracteristicas de la casa{" "}
                </label>

                <label className="fw-bold mt-3">
                  Numero de M<sup>2</sup>
                </label>
                <input className="mb-4" type="text" />
              </div>

              <div className="d-flex flex-column text-gray align-items-start">
                <label className="fw-bold mb-1">Colonia</label>
                <input className="mb-4" type="text" />
                <label className="fw-bold mb-1">Municipio</label>
                <input className="mb-4" type="text" />

                <hr className="mt-2" />
                <label className="fw-bold mt-3">Habitaciones</label>
                <input className="mb-4" type="text" />
              </div>

              <div className="d-flex flex-column text-gray align-items-start">
                <label className="fw-bold mb-1">Fraccionamiento</label>
                <input className="mb-4" type="text" />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => navigate("/ctc/venta/seach/prospectos")}
            >
              Buscar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSearch;
