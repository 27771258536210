import { useContext } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { deleteImageProperty } from "../../../api/properties/deleteImageProperty";
import TokenContext from "../../../context/Token";

const { REACT_APP_IMAGES_URL } = process.env;
const PreviousImages = (props) => {
  const { token } = useContext(TokenContext);

  const deleteImage = async (photoId) => {
    try {
      props.setIsLoading(true);
      await deleteImageProperty(token, props.propertyId, photoId);
      props.refetchProperties();
      props.setIsLoading(false);
    } catch (error) {
      props.setIsLoading(false);
      console.log(error);
      props.setErrorMessage(error);
      setTimeout(() => {
        props.setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <>
      <h2 className="fs-5">Fotos del Inmueble</h2>
      <div
        className="mb-4 ps-1 d-flex pt-3 thumbnails"
        style={{ overflowX: "auto" }}
      >
        {props.photos
          ?.map((photo, i) => (
            <div key={i} className="thumbnail-container">
              <AiOutlineCloseCircle
                className="delete-item"
                onClick={() => deleteImage(photo._id)}
              />
              <a href={`${photo.path}`} target="_blank" rel="noreferrer">
                <img
                  src={`${photo.path}`}
                  alt={photo.path}
                  className="thumbnail"
                />
              </a>
            </div>
          ))
          .reverse()}
      </div>
    </>
  );
};

export default PreviousImages;
