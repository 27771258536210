import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const postValidateAdmin = async ({ token, ...rest }) => {
  let url = `${REACT_APP_BASE_URL}/quoters/validateAdmin`;

  try {
    const response = await axios.post(url, rest, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response?.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
