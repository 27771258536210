import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import { useContext, useState } from "react";

import { createDescriptions } from "../../../api/descriptionsnotes/createDescription";
import TokenContext from "../../../context/Token";

const NoteModal = ({ open, onCloseModal, propertyId, refetch, status }) => {
  const { token } = useContext(TokenContext);
  const [success, setSuccess] = useState(false);
  const [note, setNote] = useState("");
  const sendData = async () => {
    try {
      await createDescriptions(token, {
        propertyRef: propertyId,
        description: note,
        status: status
      });

      setSuccess(true);
      refetch();
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      maxWidth="md"
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"error-success-title"}>
        Nueva Nota
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: { xs: 300, md: 500 } }}>
        <TextField
          multiline
          fullWidth
          id="note"
          name="note"
          label="Escribe tu nota"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ px: 4 }}>
        {success === true && (
          <Alert severity="success" sx={{ mr: 2 }}>
            Actualizado
          </Alert>
        )}
        <Button color="primary" variant="contained" onClick={sendData}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoteModal;
