import React, { useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import Slider from "react-slick";

// Register the plugins
registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageCrop
);

const ImageUpload = ({ files, setFiles }) => {
  const pondRef = useRef(null);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: files.length > 1
  };

  console.log(files);
  return (
    <>
      {files.length > 0 && (
        <div
          style={{ marginTop: "20px", width: "80%", margin: "0 auto 1.6em" }}
        >
          <Slider {...sliderSettings}>
            {files.map((fileItem, index) => (
              <div key={index}>
                {fileItem?.file instanceof File ? (
                  <img
                    src={URL.createObjectURL(fileItem.file)}
                    alt={`preview-${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "300px", // Adjust max height here
                      objectFit: "contain"
                    }}
                  />
                ) : (
                  <img
                    src={fileItem.source}
                    alt={`preview-${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "300px", // Adjust max height here
                      objectFit: "contain"
                    }}
                  />
                )}
              </div>
            ))}
          </Slider>
        </div>
      )}
      <FilePond
        ref={pondRef}
        files={files}
        allowMultiple={true}
        allowImageCrop
        allowReorder
        imagePreviewHeight={150}
        maxFiles={10}
        name="files" // Name attribute for the input field
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        onupdatefiles={setFiles}
        onreorderfiles={setFiles}
        acceptedFileTypes={["image/*"]}
        maxFileSize="10MB"
        allowImageResize
        imageResizeTargetWidth={200}
        imageResizeTargetHeight={200}
        imagePreviewMinHeight={200}
        imagePreviewMaxHeight={200}
      />
    </>
  );
};

export default ImageUpload;
