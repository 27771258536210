import { Grid, MenuItem, TextField, Typography } from "@mui/material";

const MaritalStatus = ({ sellerInfo, setSellerInfo }) => {
  return (
    <>
      <Typography
        variant="body1"
        color="primary"
        fontSize={20}
        fontWeight="bold"
        mt={4}
      >
        Estado Civil
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            id="married"
            name="married"
            label="Estado Civil"
            value={sellerInfo.married ?? ""}
            onChange={(e) =>
              setSellerInfo({ ...sellerInfo, married: e.target.value })
            }
          >
            <MenuItem value={true}>Casado(a)</MenuItem>
            <MenuItem value={false}>Soltero(a)</MenuItem>
          </TextField>
        </Grid>
        {sellerInfo.married === true && (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              select
              id="communityProperty"
              name="communityProperty"
              label="Régimen del matrimonio"
              value={sellerInfo.communityProperty ?? ""}
              onChange={(e) =>
                setSellerInfo({
                  ...sellerInfo,
                  communityProperty: e.target.value
                })
              }
            >
              <MenuItem value={true}>Bienes mancomunados</MenuItem>
              <MenuItem value={false}>Bienes separados</MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MaritalStatus;
