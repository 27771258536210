const ReasonData = (props) => {
  return (
    <>
      <label className="property-title">
        {props.reason ?? ""}
        {props.reason && ": "}
      </label>
      <p className="value-title">{props.reasonDetail}</p>
    </>
  );
};

export default ReasonData;
