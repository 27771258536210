import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Rect,
  Svg,
  Defs,
  LinearGradient,
  Stop
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import React from "react";

import galanoBold from "../../assets/fonts/galano/galano-bold.otf";
import galanoExtraBold from "../../assets/fonts/galano/galano-extra-bold.otf";
import galanoLight from "../../assets/fonts/galano/galano-light.otf";
import galanoRegular from "../../assets/fonts/galano/galano-regular.otf";
import insta from "../../assets/insta.png";
import mail from "../../assets/mail.png";
import red from "../../assets/red.png";
import tel from "../../assets/tel.png";
import { stringToCurrency } from "../../helpers/currencies";
import getOffer from "../../helpers/offerCalculation";
import logo from "./logo.png";
import waterMarkLogo from "./water-mark-logo.png";

// Create styles
/* Font.register({
  family: "poppins",
  fonts: [
    { src: poppinsRegular },
    { src: poppinsMedium, fontWeight: "medium" },
    { src: poppinsBold, fontWeight: "bold" }
  ]
});
 */
Font.register({
  family: "galano",
  fonts: [
    { src: galanoRegular, fontWeight: "400" },
    { src: galanoBold, fontWeight: "800" },
    { src: galanoExtraBold, fontWeight: "1000" }
  ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "galano",
    backgroundColor: "#fff",
    position: "relative"
  },
  header: {
    color: "#fff",
    textAlign: "center",
    flexDirection: "row",
    width: "100%",
    marginTop: "16px"
  },
  image: {
    width: "80%",
    marginLeft: 32
  },
  title: {
    fontSize: 34,
    fontWeight: "800",
    textAlign: "left",
    marginLeft: "32px",
    marginTop: "12px"
  },
  subTitle: {
    fontSize: 14,
    textAlign: "left",
    marginLeft: "32px",
    marginBottom: "16px",
    fontWeight: "600"
  },
  title2: {
    fontSize: 24,
    fontWeight: "800",
    textAlign: "left",
    marginLeft: "32px",
    marginTop: "8px"
  },
  subTitle2: {
    fontSize: 12,
    textAlign: "left",
    marginLeft: "32px",
    marginBottom: "16px"
  },
  folio: {
    fontSize: 12,
    textAlign: "left",
    marginLeft: "32px",
    marginTop: "16px",
    color: "blue",
    fontWeight: 600
  },
  box: {
    padding: 10,
    marginVertical: 10
  },
  section: {
    marginBottom: 10
  },
  input: {
    minHeight: 24,
    padding: 8,
    backgroundColor: "#f2f2f2",
    marginBottom: 5,
    color: "black"
  },
  fieldRow: {
    flexDirection: "row",
    marginBottom: 5,
    justifyContent: "space-between"
  },
  fieldLabel: {
    fontSize: 14,
    width: 150,
    color: "black"
  },
  fieldValue: {
    fontSize: 14,
    width: 200,
    color: "#666"
  },
  footer: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 25,
    paddingTop: 10,
    borderTop: 1,
    borderColor: "#eeeeee",
    borderStyle: "solid"
  },
  icon: {
    height: "15px",
    width: "15px"
  },
  gradientBackground: {
    width: "100%",
    height: "100%",
    position: "absolute"
  }
});

const OfferRow = ({ name, value, noBorder, bold }) => {
  return (
    <View
      style={{
        flexDirection: "row",
        height: "24px"
      }}
    >
      <View
        style={{
          width: "50%",
          borderRight: "1px solid #2b44a0",
          alignItems: "flex-end",
          justifyContent: "center"
        }}
      >
        <Text
          style={{
            fontSize: "11px",
            marginRight: "8px",
            color: "#2b44a0",
            fontWeight: bold ? 600 : 400
          }}
        >
          {name}
        </Text>
      </View>
      <View
        style={{
          width: "50%",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: !noBorder ? "1px solid #2b44a0" : null
        }}
      >
        <Text
          style={{
            fontSize: "10px",
            fontWeight: bold ? 600 : 400,
            color: "#2b44a0"
          }}
        >
          {stringToCurrency(String(value))}
        </Text>
      </View>
    </View>
  );
};

// Create Document Component
const OfferPdf = ({
  data: {
    quoteId,
    name,
    firstName,
    lastName,
    streetAndNumber,
    settlement,
    postalCode,
    city,
    state,
    grossOffer,
    netOffer,
    taxes,
    advancedPaymentAmount,
    advancedPaymentInterests,
    debts: { creditAmount, agua, luz, predial, mantenimiento },
    rehabilitation,
    debtsInterests,
    updatedAt = new Date(),
    debtCreditAmount,
    creditInterests,
    rangeDays
  }
}) => {
  console.log("PDF");
  console.log(rehabilitation);
  return (
    <Document>
      <Page size="letter" style={styles.page}>
        <View
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            opacity: "0.1"
          }}
        >
          <Image
            style={{
              width: "88%",
              marginLeft: "348px",
              marginTop: "190px"
            }}
            src={waterMarkLogo}
          />
        </View>
        <View
          style={{
            width: "100%",
            height: "16px"
          }}
        >
          <Svg
            viewBox="0 0 4000 100"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          >
            <Defs>
              <LinearGradient id="myLinearGradient">
                <Stop offset="5%" stopColor="#2b44a0" />
                <Stop offset="95%" stopColor="#18aae9" />
              </LinearGradient>
            </Defs>
            <Rect
              x="0"
              y="0"
              width="4000"
              height="100"
              fill="url('#myLinearGradient')"
            />
          </Svg>
        </View>
        <View style={styles.header}>
          <View style={{ width: "45%", flexDirection: "column" }}>
            <Image style={styles.image} src={logo} />
          </View>
          <View style={{ width: "55%" }}>
            <View
              style={{
                width: "100%",
                borderTopLeftRadius: "100%",
                borderBottomLeftRadius: "100%",
                height: 80,
                position: "relative"
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: 80,
                  borderTopLeftRadius: "100%",
                  borderBottomLeftRadius: "100%",
                  flexDirection: "column",
                  flexGrow: 1,
                  overflow: "hidden",
                  position: "absolute",
                  top: 32,
                  right: 0
                }}
              >
                <Svg
                  viewBox="0 0 600 125"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute"
                  }}
                >
                  <Defs>
                    <LinearGradient id="myLinearGradient">
                      <Stop offset="5%" stopColor="#2b44a0" />
                      <Stop offset="95%" stopColor="#18aae9" />
                    </LinearGradient>
                  </Defs>
                  <Rect
                    x="0"
                    y="0"
                    width="600"
                    height="140"
                    fill="url('#myLinearGradient')"
                  />
                </Svg>
                <View
                  style={{ position: "absolute", bottom: 0, width: "100%" }}
                >
                  <Text
                    style={{
                      width: "100%",
                      fontSize: 12,
                      textAlign: "left",
                      marginLeft: "32px",
                      marginBottom: "18px",
                      color: "white",
                      position: "absolute",
                      bottom: 0,
                      fontWeight: 600
                    }}
                  >
                    {"FOLIO: "}
                    <Text
                      style={{
                        width: "100%",
                        fontSize: 12,
                        textAlign: "left",
                        marginLeft: "32px",
                        marginBottom: "18px",
                        color: "white",
                        position: "absolute",
                        bottom: 0,
                        fontWeight: 400
                      }}
                    >
                      {quoteId}
                    </Text>
                  </Text>
                  <Text
                    style={{
                      width: "100%",
                      fontSize: 12,
                      textAlign: "left",
                      marginLeft: "32px",
                      marginBottom: "4px",
                      color: "white",
                      position: "absolute",
                      bottom: 0,
                      fontWeight: 800
                    }}
                  >
                    {"FECHA: "}
                    <Text
                      style={{
                        width: "100%",
                        fontSize: 12,
                        textAlign: "left",
                        marginLeft: "32px",
                        marginBottom: "18px",
                        color: "white",
                        position: "absolute",
                        bottom: 0,
                        fontWeight: 400
                      }}
                    >
                      {format(new Date(updatedAt), "PPP", { locale: es })}
                    </Text>
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: "100%",
                  height: 80,
                  borderTopLeftRadius: "100%",
                  borderBottomLeftRadius: "100%",
                  flexDirection: "column",
                  flexGrow: 1,
                  position: "absolute",
                  overflow: "hidden",
                  top: -8,
                  right: 0
                }}
              >
                <Svg
                  viewBox="0 0 560 130"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute"
                  }}
                >
                  <Defs>
                    <LinearGradient id="myLinearGradient">
                      <Stop offset="5%" stopColor="#EA592C" />
                      <Stop offset="95%" stopColor="#FBB900" />
                    </LinearGradient>
                  </Defs>
                  <Rect
                    x="0"
                    y="0"
                    width="600"
                    height="128"
                    fill="url('#myLinearGradient')"
                  />
                </Svg>
                <Text style={styles.title}>¡Felicidades!</Text>
                <Text style={styles.subTitle}>
                  Estás muy cerca de vender tu casa.
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: "40px"
          }}
        >
          <View
            style={{
              width: "95%",
              height: "28px",
              borderTopRightRadius: "100%",
              borderBottomRightRadius: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              flexDirection: "row"
            }}
          >
            <Svg
              viewBox="0 0 850 40"
              preserveAspectRatio="slice"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            >
              <Defs width="1200" height="120">
                <LinearGradient id="myLinearGradient" width="100%" height="120">
                  <Stop offset="5%" stopColor="#2b44a0" />
                  <Stop offset="95%" stopColor="#18aae9" />
                </LinearGradient>
              </Defs>
              <Rect
                width="100%"
                height="100%"
                fill="url('#myLinearGradient')"
              />
            </Svg>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                fontWeight: "600",
                marginLeft: "16px",
                marginTop: "8px"
              }}
            >
              {`${name} ${firstName} ${lastName}`}
            </Text>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                fontWeight: "400"
              }}
            >
              {`, la oferta por tu casa ubicada en:`}
            </Text>
          </View>
          {/* <View
            style={{
              width: "49%",
              border: "1px solid blue",
              height: "28px",
              justifyContent: "center",
              paddingLeft: "8px"
            }}
          >
            <Text style={{ color: "black", fontSize: "12px" }}>
              {streetAndNumber
                ? streetAndNumber
                : `${settlement} CP ${postalCode}`}
            </Text>
          </View> */}
          <View
            style={{
              width: "92%",
              border: "1px solid blue",
              height: "28px",
              marginLeft: "4%",
              marginTop: "12px",
              justifyContent: "center",
              paddingLeft: "8px"
            }}
          >
            <Text style={{ color: "black", fontSize: "12px" }}>
              {`${streetAndNumber + " "}${settlement + " "}CP${
                " " + postalCode + " "
              }${city + ", "}${state}`}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: "16px",
            alignItems: "center"
          }}
        >
          <View
            style={{
              width: "64%",
              height: "28px",
              borderTopRightRadius: "100%",
              borderBottomRightRadius: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
              overflow: "hidden",
              position: "relative"
            }}
          >
            <Svg
              viewBox="0 0 850 60"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            >
              <Defs>
                <LinearGradient id="myLinearGradient">
                  <Stop offset="5%" stopColor="#2b44a0" />
                  <Stop offset="95%" stopColor="#18aae9" />
                </LinearGradient>
              </Defs>
              <Rect
                x="0"
                y="0"
                width="1200"
                height="120"
                fill="url('#myLinearGradient')"
              />
            </Svg>
            <Text
              style={{
                color: "white",
                fontSize: 12,
                fontWeight: "400",
                marginLeft: "16px"
              }}
            >
              y vendida en modalidad{" "}
              <Text
                style={{
                  color: "white",
                  fontSize: 12,
                  fontWeight: "600",
                  marginLeft: "16px"
                }}
              >
                {rangeDays === 0 ? "INMEDIATA" : `${rangeDays} días`}
              </Text>{" "}
              es la siguiente:
            </Text>
          </View>
          <View
            style={{ width: "100%", marginTop: "8px", alignItems: "center" }}
          >
            <View
              style={{
                width: "72%",
                borderRadius: "16px",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                overflow: "hidden",
                height: "24px"
              }}
            >
              <Svg
                viewBox="0 0 700 38"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0
                }}
              >
                <Defs>
                  <LinearGradient id="myLinearGradient">
                    <Stop offset="5%" stopColor="#EA592C" />
                    <Stop offset="95%" stopColor="#FBB900" />
                  </LinearGradient>
                </Defs>
                <Rect
                  x="0"
                  y="0"
                  width="1200"
                  height="120"
                  fill="url('#myLinearGradient')"
                />
              </Svg>
              <Text
                style={{
                  color: "white",
                  fontWeight: "600",
                  fontSize: "16px",
                  top: "5px",
                  position: "absolute"
                }}
              >
                Oferta por tu casa: {stringToCurrency(grossOffer)}
              </Text>
            </View>
          </View>
          <View
            style={{
              justifyContent: "center",
              width: "100%",
              height: "218px",
              marginTop: "12px",
              flexDirection: "row"
            }}
          >
            <View
              style={{
                width: "62%",
                border: "1px solid #2b44a0",
                height: "224px",
                borderRadius: "16px",
                overflow: "hidden",
                flexDirection: "row"
              }}
            >
              <View style={{ width: "100%", flexDirection: "column" }}>
                <OfferRow
                  name={"Crédito Hipotecario"}
                  value={debtCreditAmount}
                />
                <OfferRow
                  name={"Intereses liquidación cŕedito:"}
                  value={creditInterests}
                />
                <OfferRow name={"Rehabilitación:"} value={rehabilitation} />
                <OfferRow name={"Cuota condominal:"} value={mantenimiento} />
                <OfferRow name={"Agua:"} value={agua} />
                <OfferRow name={"Luz:"} value={luz} />
                <OfferRow name={"Predial:"} value={predial} />
                <OfferRow
                  name={"Intereses liquidación servicios"}
                  value={debtsInterests}
                />
                <OfferRow name={"Impuestos:"} value={taxes} />
                <OfferRow name={"Adelanto:"} value={advancedPaymentAmount} />
                <OfferRow
                  name={"Comisión por adelanto:"}
                  value={advancedPaymentInterests}
                />
                <OfferRow
                  bold
                  name={"Total de deducción:"}
                  value={
                    Number(debtCreditAmount || 0) +
                    Number(creditInterests || 0) +
                    Number(rehabilitation || 0) +
                    Number(mantenimiento || 0) +
                    Number(agua || 0) +
                    Number(luz || 0) +
                    Number(predial || 0) +
                    Number(debtsInterests || 0) +
                    Number(taxes || 0) +
                    Number(advancedPaymentAmount || 0) +
                    Number(advancedPaymentInterests || 0)
                  }
                  noBorder
                />
                {/* <OfferRow name={"Saldo final:"} value={netOffer} noBorder /> */}
              </View>
            </View>
          </View>
        </View>

        <View style={{ width: "100%", marginTop: "8px", alignItems: "center" }}>
          <View
            style={{
              width: "72%",
              borderRadius: "16px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              height: "32px"
            }}
          >
            <Svg
              viewBox="0 0 1100 70"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            >
              <Defs>
                <LinearGradient id="myLinearGradient">
                  <Stop offset="5%" stopColor="#EA592C" />
                  <Stop offset="95%" stopColor="#FBB900" />
                </LinearGradient>
              </Defs>
              <Rect
                x="0"
                y="0"
                width="1200"
                height="120"
                fill="url('#myLinearGradient')"
              />
            </Svg>
            <Text
              style={{
                color: "white",
                fontWeight: "600",
                fontSize: "20px",
                position: "absolute",
                top: 8
              }}
            >
              Saldo final: {stringToCurrency(netOffer)}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              borderRadius: "16px",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              height: "32px",
              marginTop: "8px"
            }}
          >
            <Svg
              viewBox="0 0 1200 120"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            >
              <Defs>
                <LinearGradient id="myLinearGradient">
                  <Stop offset="5%" stopColor="#2b44a0" />
                  <Stop offset="95%" stopColor="#18aae9" />
                </LinearGradient>
              </Defs>
              <Rect
                x="0"
                y="0"
                width="1200"
                height="120"
                fill="url('#myLinearGradient')"
              />
            </Svg>
            <Text
              style={{
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
                marginTop: "8px"
              }}
            >
              ACEPTO MI OFERTA
            </Text>
          </View>
        </View>

        <View
          style={{
            width: "100%",
            marginTop: "24px",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <View
            style={{
              height: "40px",
              borderBottom: "2px solid blue",
              width: "50%"
            }}
          ></View>
          {/* <View
            style={{
              width: "50%",
              backgroundColor: "#d7f2fa",
              height: "32px",
              alignSelf: "center",
              justifyContent: "center",
              paddingLeft: "8px",
              borderRadius: "4px",
              alignItems: "center",
              marginTop: "8px"
            }}
          >
            <Text
              style={{
                color: "#212427",
                fontSize: "12px",
                fontWeight: "600"
              }}
            >
              {name} {firstName} {lastName}
            </Text>
          </View> */}
          <View>
            <Text style={{ fontWeight: 800, color: "#2b44a0" }}>
              {name} {firstName} {lastName}
            </Text>
          </View>
        </View>

        <View style={{ width: "100%", position: "absolute", bottom: 0 }}>
          <View
            style={{
              width: "88%",
              borderRadius: "16px",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              height: "24px",
              marginBottom: "8px",
              overflow: "hidden",
              position: "relative"
            }}
          >
            <Svg
              viewBox="0 0 1800 80"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0
              }}
            >
              <Defs>
                <LinearGradient id="myLinearGradient">
                  <Stop offset="5%" stopColor="#f14d2e" />
                  <Stop offset="95%" stopColor="#f89a1c" />
                </LinearGradient>
              </Defs>
              <Rect
                x="0"
                y="0"
                width="1800"
                height="100"
                fill="url('#myLinearGradient')"
              />
            </Svg>
            <Text
              style={{
                color: "white",
                fontSize: "12px",
                fontWeight: "600",
                padding: "0 24px",
                marginTop: "5px"
              }}
            >
              !En COMPRAMOSTUCASA.MX tú decides qué tan rápido quieres vender tu
              casa!
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              marginBottom: "8px",
              fontSize: "10px",
              justifyContent: "space-around"
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Image src={tel} style={styles.icon} />
              <Text>(55) 2133 3400</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Image src={red} style={styles.icon} />
              <Text>www.compramostucasa.mx</Text>
            </View>
            {/* <View style={{ flexDirection: "row" }}>
              <Image src={mail} style={styles.icon} />
              <Text>contacto@compramostucasa.mx</Text>
            </View> */}
            <View style={{ flexDirection: "row" }}>
              <Image src={insta} style={styles.icon} />
              <Text>compramostucasamx</Text>
            </View>
          </View>
          <View style={{ padding: "0 32px" }}>
            <Text style={{ fontSize: "8px", color: "#2b44a0" }}>
              <Text style={{ fontWeight: "800" }}>*Aplican T&C</Text> | La
              siguiente oferta tiene una vigencia de 7 días a partir de la fecha
              emitida, misma que puede estar sujeta a cambios a la fecha de
              compra venta. La presente oferta se envía bajo la condición de que
              su contenido es confidencial y no será revelado a ningún tercero
              sin previo aviso.
            </Text>
          </View>
          <View
            style={{ width: "100%", height: "4px", backgroundColor: "#EA592C" }}
          ></View>
          <View
            style={{ width: "100%", height: "4px", backgroundColor: "#2b44a0" }}
          ></View>
        </View>
      </Page>
    </Document>
  );
};

export default OfferPdf;
