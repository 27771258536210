import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const updateQuote = async (data, token) => {
  try {
    const response = await axios.post(
      `${REACT_APP_BASE_URL}/landing/updateQuote`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
