import React from "react";

import SignatureCard from "../../components/SellerProperty/Signature/SignatureCard";

const Signature = () => {
  return (
    <section className="flex-center">
      <SignatureCard
        name="Miguel Ramirez"
        email="miguel_ram@gmail.com"
        phone="55 5555 5555"
      />
    </section>
  );
};

export default Signature;
