import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  CircularProgress
} from "@mui/material";

import InfoOffer from "./InfoOffer";

const QuoterHistoryModal = ({ open, onCloseModal, data, isLoading }) => {
  if (isLoading) {
    return <CircularProgress />;
  }

  console.log(data);

  const quotes = data?.data ? data?.data : data;

  const quotesHistory = quotes?.quotesHistory.reverse();

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      sx={{ borderRadius: ".5rem" }}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id={"meeting"}>
        Historial de Ofertas
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {quotesHistory &&
          quotesHistory.reverse().map((quote) => (
            <>
              <InfoOffer quote={quote} />
              <Divider
                sx={{
                  border: "1.2px solid #F1592A",
                  opacity: 0.6
                }}
              />
            </>
          ))}
      </DialogContent>
      <DialogActions sx={{ pr: 2, pb: 2 }}>
        <Button variant="contained" onClick={onCloseModal}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuoterHistoryModal;
