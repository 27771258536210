import BuyDatesContainer from "./BuyDatesContainer";

const BuyDates = () => {
  return (
    <section className="flex-center">
      <BuyDatesContainer />
    </section>
  );
};

export default BuyDates;
