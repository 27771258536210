import TableBody from "./TableBody";
import TableBodyPreSeller from "./TableBodyPreSeller";
import TableBodyReconversion from "./TableBodyReconversion";
import TableHeader from "./TableHeader";
import TableInventory from "./TableInventory";

//PROPS DEL COMPONENTE: columnTitles:{[]} properties={data?.properties??[]} refetchProperties, tableType={""}
const Table = (props) => {
  return (
    <div
      className="w-100 d-flex justify-content-center px-2"
      style={{ height: "fit-content", overflowX: "auto" }}
    >
      <table
        className="table table-bordered mt-3"
        style={{ borderColor: "#707070" }}
      >
        <TableHeader columnTitles={props.columnTitles} />
        {props.tableType !== "Reconversion" &&
          props.tableType !== "Inventario" &&
          props.tableType !== "No Apto" && (
            <TableBody
              properties={props.properties}
              refetchProperties={props.refetchProperties}
              counterRefetch={props.counterRefetch}
              tableType={props.tableType}
            />
          )}
        {props.tableType === "Reconversion" && (
          <TableBodyReconversion
            properties={props.properties ?? []}
            advisers={props.advisers ?? []}
            refetchReconversion={props.refetchReconversion}
            tableType={props.tableType}
          />
        )}
        {props.tableType === "No Apto" && (
          <TableBodyReconversion
            properties={props.properties ?? []}
            advisers={props.advisers ?? []}
            refetchReconversion={props.refetchReconversion}
            tableType={props.tableType}
          />
        )}
        {props.tableType === "Inventario" && (
          <TableInventory properties={props.properties} />
        )}
        {props.tableType === "preRegistro" && (
          <TableBodyPreSeller
            sellers={props.sellers ?? {}}
            refetchPreSellers={props.refetchPreSellers}
          />
        )}
      </table>
    </div>
  );
};

export default Table;
