import { List } from "@mui/material";

import MonitoringItem from "./MonitoringItem";

const MonitorList = ({ monitoringEvents }) => {
  return (
    <>
      <List>
        {monitoringEvents?.map((e) => (
          <MonitoringItem key={e._id} event={e} />
        ))}
      </List>
    </>
  );
};

export default MonitorList;
