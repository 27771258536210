import { useState, useEffect, useContext } from "react";
import { AiFillEye } from "react-icons/ai";
import { MdDomainVerification } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { getDocsSeller } from "../../../api/documentations/getDocsSeller";
import "../../styles/Modal.css";
import { updateDocument } from "../../../api/documentations/updateDocumentation";
import { updateProperties } from "../../../api/properties/updateProperties";
import TokenContext from "../../../context/Token";
import RejectReason from "./RejectReason";
import SendToGestion from "./SendToGestion";
import SendToProceedings from "./SendToProceedings";
import ValidateDocument from "./ValidateDocument";

import { Alert, Button, Stack } from "@mui/material";

const { REACT_APP_DOCS_URL } = process.env;

const ModalLegal = (props) => {
  const { token } = useContext(TokenContext);
  const [response, setResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const getDocs = async () => {
    if (props.MLisActive) {
      const aux = await getDocsSeller(token, props.sellerId);

      setResponse(aux.data);
    }
  };

  // props.MLisActive &&console.log(response)

  const sendData = async (legal, preInventory) => {
    try {
      const response = await updateProperties(token, props.id, {
        legal: legal,
        preInventory: preInventory
      });

      if (response._id) {
        setSuccessMessage("Estado del documento Actualizado");
        navigate(0);
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  const rejectFile = async (documentId, reason) => {
    try {
      const response = await updateDocument(token, documentId, {
        comment: reason
      });

      if (response.status === 200) {
        setSuccessMessage("Estado del documento Actualizado");
        props.refetchProperties();
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    getDocs();
  }, [props.MLisActive]);

  // props.MLisActive && console.log(response.documentations)

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      id={"ModalLegal" + props.id}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-gray fw-bold">
              Validar Documentación
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => props.setMLisActive(false)}
            ></button>
          </div>
          <div
            className="modal-body"
            style={{ maxHeight: 450, overflowY: "auto" }}
          >
            <table className="table">
              <thead>
                <tr>
                  <th className="ps-2">Tipo</th>
                  <th className="text-center">Vista</th>
                  <th className="text-center">Validez</th>
                  <th className="text-center">Razón de rechazo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {response?.documentations?.map((doc) => {
                  // console.log(doc)
                  return (
                    <tr key={doc._id}>
                      <td style={{ width: 170 }}>{doc.type}</td>
                      <td className="text-center" style={{ width: 50 }}>
                        <a
                          href={`${doc.path}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <AiFillEye fontSize={"20px"} />
                        </a>
                      </td>
                      <td className="text-center">
                        <ValidateDocument
                          isValid={doc.valid ?? ""}
                          token={token}
                          documentId={doc._id}
                          setSuccessMessage={setSuccessMessage}
                          setErrorMessage={setErrorMessage}
                          getDocs={getDocs}
                        />
                      </td>
                      <RejectReason
                        isValid={doc.valid ?? ""}
                        rejectFile={rejectFile}
                        doc={doc}
                        refetchProperties={props.refetchProperties}
                        setSuccessMessage={setSuccessMessage}
                        setErrorMessage={setErrorMessage}
                        documentId={doc._id}
                      />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <div className="d-flex">
              {successMessage && (
                <Alert severity="success">{successMessage}</Alert>
              )}
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
              {!response?.documentations?.some(
                (d) => d.valid === false || !d.valid
              ) &&
                !successMessage &&
                !props.legal && (
                  <Button variant="text" onClick={() => sendData(true, true)}>
                    Documentación Lista
                  </Button>
                )}
              {response?.documentations?.some(
                (d) => d.valid === false || !d.valid
              ) &&
                !successMessage && (
                  <Stack spacing={4} direction={"row"}>
                    <SendToProceedings id={props.id} />{" "}
                    <SendToGestion id={props.id} />{" "}
                  </Stack>
                )}
              {/* <Button
                sx={{ my: 0 }}
                variant="contained"
                data-bs-dismiss="modal"
                onClick={() => props.setMLisActive(false)}
              >
                Aceptar
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalLegal;
