import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { useModal } from "../../../hooks/useModal";
import DrawerCardsContainer from "./DrawerCardsContainer";
import DrawerList from "./DrawerList";
import DrawerOptions from "./DrawerOptions";

const DrawerContainer = (props) => {
  const { open, onOpenModal, onCloseModal } = useModal();
  //puede ser list, options o cards
  const [shownSection, setShownSection] = useState("list");
  /**
   * 1. Elegir queryParam
   * 2. Elegir si se va a filtrar los que coinciden con, los que son diferentes, que tenga valor o no lo tenga
   * 3. en caso de coincidir o ser diferente, meter en un array los valores a comparar
   * 4. mostrar card con los params de búsqueda, opcion para eliminar, agregar otro parámetro que deba cumplirse al mismo tiempo
   *    o que deba cumplirse en lugar de (&&) ó (||)
   */
  const [paramsArray, setParamsArray] = useState([]);
  const [currentParam, setCurrentParam] = useState({
    name: "",
    title: "",
    reason: "equals",
    criteria: [],
    operator: ""
  });

  const sendData = () => {
    props.handleFilter();
  };

  //toma lo guardado en localStorage si existe
  /*  useEffect(() => {
    if (JSON.parse(localStorage.getItem("queryString")?.length > 0)) {
      setParamsArray(JSON.parse(localStorage.getItem("queryString")));

      setShownSection("cards");
    }
  }, []); */

  //guarda en localStorage los cambios hechos a paramsArray
  useEffect(() => {
    /* const setQueryStorage = () => {
      if (paramsArray.length > 0) {
        localStorage.setItem("queryString", JSON.stringify(paramsArray));
      }
    };

    setQueryStorage(); */

    const searchParams = new URLSearchParams();

    paramsArray.forEach((params) => {
      Object.entries(params).forEach(([key, value]) => {
        if (key !== "title" && value !== "") {
          if (Array.isArray(value)) {
            value.forEach((v) => searchParams.append(key, v));
          } else {
            searchParams.append(key, value);
          }
        }
      });
    });

    props.handleAndvancedFilters(searchParams.toString());
  }, [paramsArray, props]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={onOpenModal}
        sx={{ ml: 3 }}
      >
        Filtros Avanzados
      </Button>

      <Drawer anchor="right" open={open} onClose={onCloseModal}>
        <Box role="presentation" sx={{ width: "fit-content", minWidth: 320 }}>
          <Box
            width={"100%"}
            height={"fit-content"}
            p={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              background:
                "linear-gradient(120deg, rgba(241,89,42,1) 59%, rgba(245,170,17,1) 100%)"
            }}
          >
            <Typography variant="h5" sx={{ color: "#fff" }}>
              Todos los filtros
            </Typography>
            <IconButton
              aria-label="close-drawer"
              onClick={onCloseModal}
              sx={{ color: "#fff" }}
            >
              <CloseIcon color="inherit" />
            </IconButton>
          </Box>
          {shownSection === "list" && (
            <DrawerList
              currentParam={currentParam}
              setCurrentParam={setCurrentParam}
              setShownSection={setShownSection}
            />
          )}
          {shownSection === "options" && (
            <DrawerOptions
              currentParam={currentParam}
              setCurrentParam={setCurrentParam}
              setShownSection={setShownSection}
              paramsArray={paramsArray}
              setParamsArray={setParamsArray}
            />
          )}
          {shownSection === "cards" && (
            <DrawerCardsContainer
              paramsArray={paramsArray}
              setParamsArray={setParamsArray}
              setShownSection={setShownSection}
              currentParam={currentParam}
              setCurrentParam={setCurrentParam}
            />
          )}
        </Box>
        {shownSection === "cards" && (
          <Box display={"flex"} width={"100%"} justifyContent={"center"}>
            <Button variant="contained" color="primary" onClick={sendData}>
              Realizar Búsqueda
            </Button>
          </Box>
        )}
        {shownSection === "cards" && paramsArray.length > 0 && (
          <Box display={"flex"} width={"100%"} justifyContent={"center"}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setParamsArray([]);
                setCurrentParam({
                  name: "",
                  title: "",
                  reason: "equals",
                  criteria: [],
                  operator: ""
                });
              }}
            >
              Borrar filtros
            </Button>
          </Box>
        )}
      </Drawer>
    </>
  );
};

export default DrawerContainer;
