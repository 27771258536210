import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Button, Grid, List, Tooltip, Typography } from "@mui/material";

import { useModal } from "../../../hooks/useModal";
import ObservationItem from "./ObservationItem";
import ObservationModal from "./ObservationModal";

const ObservationsContainer = ({ property, refetch }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight={600}
            width={"100%"}
            textAlign="start"
            mb={1}
          >
            Observaciones:
            <Tooltip
              arrow
              title="Las observaciones son registros de cosas que deben hacerse, se pueden editar y marcar como hechas o pendientes."
              sx={{ fontSize: 18 }}
            >
              <HelpOutlineIcon color="primary" fontSize="inherit" />
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" onClick={onOpenModal} sx={{ mt: 0 }}>
            Nueva Observación
          </Button>
        </Grid>
        {open && (
          <ObservationModal
            open={open}
            onCloseModal={onCloseModal}
            propertyId={property?._id}
            refetch={refetch}
          />
        )}
      </Grid>
      <Box
        sx={{
          mt: 1,
          height: 300,
          width: "97%",
          border: "1px solid rgba(111, 128, 149, 0.6)",
          borderRadius: 2,
          overflowY: "auto",
          "::-webkit-scrollbar": { width: "5px" },
          "::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",

            borderRadius: "10px"
          },
          "::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
            borderRadius: "10px"
          }
        }}
      >
        <List>
          {property?.observationsRef?.map((obs) => (
            <ObservationItem
              key={obs._id}
              obs={obs}
              refetch={refetch}
              propertyId={property?._id}
            />
          ))}
        </List>
      </Box>
    </>
  );
};

export default ObservationsContainer;
