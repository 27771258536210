import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const updateCurrentBuyRequest = async (token, id, buyRequest) => {
  try {
    const response = await axios.put(
      REACT_APP_BASE_URL + "/buy-requests/" + id,
      { buyRequest },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    return response.data?.buyRequest ?? [];
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
