import React from "react";
import useLocalStorage from "use-local-storage";

import ProspectCard from "../../components/SellerProperty/Prospects/ProspectCard";

const Prospects = () => {
  const [date] = useLocalStorage("date");

  return (
    <section className="flex-center pb-5">
      <ProspectCard
        name="Miguel Ramirez"
        email="miguel_ram@gmail.com"
        phone="55 5555 5555"
        dates={date}
      />
    </section>
  );
};

export default Prospects;
