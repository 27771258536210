import React from "react";
import "../styles/Categories.css";

const ReconvertCategories = (props) => {
  return (
    <section className="categories-section mb-3">
      <div
        className="category-item text-gray"
        style={{
          cursor: "pointer",
          backgroundColor: props.currentTab === "" ? "rgb(230, 230, 230)" : null
        }}
        onClick={() => props.onSelect("")}
      >
        <label style={{ cursor: "pointer" }}>General</label>
        <label style={{ cursor: "pointer" }}>{props.totalLength} leads</label>
      </div>
      <div
        className="category-item text-gray margin-category"
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Perfilado" ? "rgb(230, 230, 230)" : null
        }}
        onClick={() => props.onSelect("Perfilado")}
      >
        <label style={{ cursor: "pointer" }}>Prospectos</label>
        <label style={{ cursor: "pointer" }}>
          {props.perfiladosLength} leads
        </label>
      </div>
      <div
        className="category-item text-gray margin-category"
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Prospectos" ? "rgb(230, 230, 230)" : null
        }}
        onClick={() => props.onSelect("Prospectos")}
      >
        <label style={{ cursor: "pointer" }}>Clientes</label>
        <label style={{ cursor: "pointer" }}>
          {props.prospectosLength} leads
        </label>
      </div>
      <div
        className="category-item text-gray margin-category"
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Expediente" ? "rgb(230, 230, 230)" : null
        }}
        onClick={() => props.onSelect("Expediente")}
      >
        <label style={{ cursor: "pointer" }}>Expediente</label>
        <label style={{ cursor: "pointer" }}>
          {props.expedienteLength} leads
        </label>
      </div>
      <div
        className="category-item text-gray margin-category"
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "Firma" ? "rgb(230, 230, 230)" : null
        }}
        onClick={() => props.onSelect("Firma")}
      >
        <label style={{ cursor: "pointer" }}>Firma</label>
        <label style={{ cursor: "pointer" }}>{props.firmaLength} leads</label>
      </div>
      {/* <div
        className="category-item text-gray margin-category"
        style={{
          cursor: "pointer",
          backgroundColor:
            props.currentTab === "No Apto" ? "rgb(230, 230, 230)" : null
        }}
        onClick={() => props.onSelect("No Apto")}
      >
        <label style={{ cursor: "pointer" }}>No Aptos</label>
      </div> */}
    </section>
  );
};

export default ReconvertCategories;
