import { useState } from "react";

export const useModal = () => {
  const [modal, setModal] = useState(false);

  const onOpenModal = () => setModal(true);

  const onCloseModal = () => setModal(false);

  return {
    open: modal,
    onOpenModal,
    onCloseModal
  };
};
