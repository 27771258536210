import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const updateReconversion = async (token, reconversionId, data) => {
  try {
    const response = await axios.put(
      `${REACT_APP_BASE_URL}/reconversions/${reconversionId}`,
      { reconversion: data },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
