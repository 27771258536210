import { useState, useEffect } from "react";

import { getAllBuyRequests } from "../../api/buyRequest/getAllBuyRequest";
import { updateCurrentBuyRequest } from "../../api/buyRequest/updateBuyRequest";
import { uploadedBuyRequests } from "../../api/buyRequest/uploadedBuyRequest";
import Loading from "../../components/loading/Loading";
import { BUY_REQUESTS_STATUS } from "../../constants/buyRequestsConstants";
import { useDeedContext } from "../../hooks/useDeedContext";

const { REACT_APP_DOCS_URL } = process.env;

export const DeedEvaluate = (props) => {
  const { token, setBuyRequests } = useDeedContext();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pathEvaletion, setPathEvaletion] = useState(null);
  const [amountEvaletion, setAmountEvaletion] = useState("");
  const [authorizedEvaletion, setAuthorizedEvaletion] = useState("");

  const handleClose = () => props.onClose();

  const showError = (error) => {
    console.log(error);
    setError(error);
    setTimeout(() => setError(""), 5500);
  };

  const onSubmit = () => {
    if (amountEvaletion === "")
      return showError("Todos los campos son obligatorios");

    setLoading(true);

    updateCurrentBuyRequest(token, props._id, {
      amountEvaletion,
      authorizedEvaletion
    })
      .then(() => uploadedBuyRequests(token, props._id, selectedFile))
      .then((buyRequest) => setPathEvaletion(buyRequest.pathEvaletion))
      .then(() =>
        getAllBuyRequests(token, `status=${BUY_REQUESTS_STATUS.DEED}`)
      )
      .then((data) => setBuyRequests(data))
      .catch((error) => showError(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const amountEvaletion =
      props.amountEvaletion > 0 ? props.amountEvaletion : "";
    const authorizedEvaletion = props.authorizedEvaletion
      ? props.authorizedEvaletion
      : "";
    const pathEvaletion = props.pathEvaletion ? props.pathEvaletion : "";

    setAmountEvaletion(amountEvaletion);
    setAuthorizedEvaletion(authorizedEvaletion);
    setPathEvaletion(pathEvaletion);
  }, []);

  return (
    props.open && (
      <td
        className={`custom_modal ${props.open && "isOpen"}`}
        onClick={handleClose}
      >
        <div
          className="custom_modal__content w-75"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="custom_modal__header">
            <h2 className="mt-3">Monto del Avalúo</h2>
            <div className="col-3 mt-3">
              <button
                className="btn-close custom_modal_close"
                onClick={handleClose}
              ></button>
            </div>
          </div>

          <div className="my-5 px-5">
            {loading ? (
              <Loading />
            ) : (
              <>
                <div className="mt-3">
                  <label htmlFor="" className="form-label text-start w-100">
                    Monto
                  </label>
                  <input
                    type="number"
                    min={0}
                    className="form-control w-100"
                    onChange={(e) => setAmountEvaletion(e.target.value)}
                    value={amountEvaletion}
                  />
                </div>

                <div className="mt-3">
                  <label htmlFor="" className="form-label text-start w-100">
                    Aprobó:
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    value={authorizedEvaletion}
                    onChange={(e) => setAuthorizedEvaletion(e.target.value)}
                  />
                </div>

                <div className="mt-3">
                  <label htmlFor="" className="form-label text-start w-100">
                    Document
                  </label>
                  <input
                    type="file"
                    className="form-control w-100"
                    onChange={(e) => setSelectedFile(e.target.files[0])}
                  />
                </div>

                {pathEvaletion && (
                  <div className="mt-3 w-100">
                    <label htmlFor="" className="text-start w-100">
                      Comprobante <br />
                      <a
                        href={`${REACT_APP_DOCS_URL}/docs/${props.pathEvaletion}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.pathEvaletion}
                      </a>
                    </label>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="custom_modal__footer">
            {error !== "" && <div className="deed-error">{error}</div>}
            <button className="deed-button w-auto px-3 py-1" onClick={onSubmit}>
              GUARDAR
            </button>
            <button
              className="deed-button w-auto px-3 py-1"
              onClick={handleClose}
            >
              CERRAR
            </button>
          </div>
        </div>
      </td>
    )
  );
};
