import "./loading.css";

const Loading = () => {
  return (
    <section className="tracker d-flex justify-content-center align-items-center m-auto">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </section>
  );
};

export default Loading;
