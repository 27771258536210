import { useState } from "react";

const ProgressBar = (props) => {
  return (
    <progress id="progress-bar" value={props.progress} max="100">
      {props.progress}
    </progress>
  );
};

export default ProgressBar;
