import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";

import { getAllBuyRequests } from "../../api/buyRequest/getAllBuyRequest";
import { BUY_REQUESTS_STATUS } from "../../constants/buyRequestsConstants";
import TokenContext from "../Token";
import { DeebContext } from "./DeedContext";

export const DeedProvider = ({ children }) => {
  const { token } = useContext(TokenContext);
  const [loading, setLoading] = useState(false);
  const [buyRequests, setBuyRequests] = useState([]);

  const getBuyRequestInDeed = async () => {
    setLoading(true);
    getAllBuyRequests(token, `status=${BUY_REQUESTS_STATUS.DEED}`)
      .then((buyRequests) => {
        setBuyRequests(buyRequests);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getBuyRequestInDeed();
  }, []);

  return (
    <DeebContext.Provider
      value={{
        token,
        loading,
        buyRequests,
        getBuyRequestInDeed,
        setBuyRequests
      }}
    >
      {children}
    </DeebContext.Provider>
  );
};
