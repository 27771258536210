import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { useContext, useState } from "react";

import { updatePreSellers } from "../../../api/seller/updatePreSeller";
import TokenContext from "../../../context/Token";
import { useModal } from "../../../hooks/useModal";

const EditDataSeller = ({
  sellerId,
  name,
  fLastName,
  mLastName,
  phoneNumber,
  email,
  refetchPreSellers
}) => {
  const { open, onOpenModal, onCloseModal } = useModal();
  const { token } = useContext(TokenContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    name: name ?? "",
    fLastName: fLastName ?? "",
    mLastName: mLastName ?? "",
    email: email ?? "",
    phoneNumber: phoneNumber ?? ""
  });

  const handleUserInfo = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const sendData = async () => {
    try {
      setLoading(true);

      const response = await updatePreSellers(token, sellerId, user);

      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        setSuccess(false);
        refetchPreSellers();
        onCloseModal();
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <button onClick={onOpenModal} className="btn-fill-blue mb-2">
        Editar Cliente
      </button>
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          aria-labelledby={"error-success-dialogue"}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            Informacion del cliente
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    // required
                    id="name"
                    name="name"
                    label="Nombre(s)"
                    value={user.name}
                    onChange={handleUserInfo}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    // required
                    id="fLastName"
                    name="fLastName"
                    label="Apellido paterno"
                    value={user.fLastName}
                    onChange={handleUserInfo}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    // required
                    id="mLastName"
                    name="mLastName"
                    label="Apellido Materno"
                    value={user.mLastName}
                    onChange={handleUserInfo}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    // required
                    id="email"
                    name="email"
                    label="Correo electrónico"
                    value={user.email}
                    onChange={handleUserInfo}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    // required
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Teléfono móvil"
                    value={user.phoneNumber}
                    onChange={handleUserInfo}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            {success === true && (
              <Alert severity="success" sx={{ mr: 2 }}>
                Actualizado
              </Alert>
            )}
            <Button
              disabled={loading}
              color="primary"
              variant="contained"
              onClick={sendData}
            >
              Actualizar
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default EditDataSeller;
