import { Grid, TextField, Typography } from "@mui/material";

const PropertyAddress = ({
  propertyData,
  setPropertyData,
  propertyAddress,
  setPropertyAddress,
  settlements,
  setAvgPrice
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            variant="body1"
            color="primary"
            fontSize={20}
            fontWeight="bold"
          >
            ¿Confirmas eliminar el lead?
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyAddress;
