import { Box, Typography } from "@mui/material";
import { useState } from "react";

import { useModal } from "../../../hooks/useModal";
import MonitorList from "./MonitorList";

const Monitoring = ({ monitoringEvents }) => {
  const { open, onOpenModal, onCloseModal } = useModal();
  const [success, setSuccess] = useState({ severity: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");

  return (
    <>
      <Box display={"flex"}>
        <Typography
          className="blue-text"
          variant="h5"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Monitoreo
        </Typography>
        {/* <IconButton onClick={onOpenModal} sx={{ color: "#0053C5" }}>
          <PostAddIcon color="inherit" />
        </IconButton> */}
      </Box>
      <Box
        sx={{
          mt: 1,
          height: 300,
          border: "1px solid rgba(111, 128, 149, 0.6)",
          borderRadius: 2,
          overflowY: "auto",
          "::-webkit-scrollbar": { width: "5px" },
          "::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",

            borderRadius: "10px"
          },
          "::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
            borderRadius: "10px"
          }
        }}
      >
        <MonitorList monitoringEvents={monitoringEvents} />
      </Box>
      {/* {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          aria-labelledby={"error-success-dialogue"}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            Monitoreos
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box component={"form"} mb={4} minWidth={600} height={"60vh"}>
              <MonitorList />
            </Box>
          </DialogContent>
          <DialogActions>
            {success.severity === "success" && (
              <Alert severity={success.severity} sx={{ mr: 2 }}>
                {success.message}
              </Alert>
            )}
            {success.severity === "error" && (
              <Alert severity={success.severity} sx={{ mr: 2 }}>
                {success.message}
              </Alert>
            )}
            <Box display={"flex"}></Box>
          </DialogActions>
        </Dialog>
      )} */}
    </>
  );
};

export default Monitoring;
