import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import { set, setDate, setMonth } from "date-fns";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useQuery } from "react-query";

import { getTotalPropertiesByPriority } from "../../api/metrics/getTotalPropertiesByPriority";
import { searchPostalCode } from "../../api/postalCode/searchPostalCode";
import { getProperties } from "../../api/properties/getProperties";
import { getPreSellers } from "../../api/seller/getPreSellers";
// import { getSellers } from "../../api/seller/getSellers";
import { preSellers } from "../../cacheQueries/cacheQueries";
import Loading from "../../components/loading/Loading";
import ProfileCategories from "../../components/Profile/ProfileCategories.jsx";
import SearchBar from "../../components/SearchBar";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";
import CreateProspect from "./CreateProspect";
import DrawerContainer from "./Drawer/DrawerContainer";
import NoAptosTableContainer from "./NoAptosTableContainer";
// import SearchBar from "../../components/SearchBar";
// import ProfileCard from "../../components/Profile/ProfileCard";

const { REACT_APP_BASE_URL } = process.env;

const defaultFilter = {
  /* name: "",
  phoneNumber: "", */
  adviserRef: "",
  postalCode: "",
  settlement: "",
  state: "",
  dateStart: setMonth(setDate(new Date(), 1), 0),
  dateEnd: new Date(),
  preSeller: false,
  multiSearch: ""
};

const Profiles = () => {
  const { token } = useContext(TokenContext);
  const [filters, setFilters] = useState({ ...defaultFilter });
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [settlements, setSettlements] = useState([]);
  const [value, setValue] = useState({
    selectTab: "Nuevo" //pestaña seleccionada (nuevo, apto, ICSE, pre-registro)
  });

  const [results] = useState(20);
  const [page, setPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(1);
  const { isError, data, refetch, isFetching } = useQuery(
    [
      "perfilado-properties",
      token,
      results,
      page,
      "Perfilado",
      value.selectTab
    ],
    () =>
      getProperties(
        token,
        results,
        page,
        "Perfilado",
        value.selectTab,
        "",
        {
          ...filters,
          dateStart: set(new Date(filters.dateStart), {
            hours: 0,
            minutes: 0,
            seconds: 0
          }).toISOString(),
          dateEnd: set(new Date(filters.dateEnd), {
            hours: 23,
            minutes: 59,
            seconds: 59
          }).toISOString()
        },
        undefined,
        advancedFilters
      ),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  const {
    data: preSellerData,
    isLoading: loadingPreSellers,
    refetch: refetchPreSellers
  } = useQuery(
    [preSellers, token, results, page, value.selectTab],
    () => getPreSellers(token, results, page),
    {
      enabled: value.selectTab === "preRegistro"
    }
  );

  /* const {
    data: sellerData,
    isLoading: loadingSellers,
    refetch: refetchSellers
  } = useQuery([sellers], () => getSellers(token), {
    enabled: value.selectTab === "registro"
  }); */

  const { data: counterData, refetch: counterRefetch } = useQuery(
    ["priority-counter"],
    () =>
      getTotalPropertiesByPriority(token, results, page, "Perfilado", "", "", {
        ...filters,
        dateStart: set(new Date(filters.dateStart), {
          hours: 0,
          minutes: 0,
          seconds: 0
        }).toISOString(),
        dateEnd: set(new Date(filters.dateEnd), {
          hours: 23,
          minutes: 59,
          seconds: 59
        }).toISOString()
      }),
    { keepPreviousData: true }
  );

  const handleSelect = (type, target, id) => {
    //type puede ser selectTab (pestaña elegida), priority (alta, media o baja), id (de la casa)
    setPage(1);
    setValue({
      ...value,
      [type]: target,
      id: id
    });
  };

  useEffect(() => {
    //calcula tamaño y valor actual del paginador
    const getPaginationValue = () => {
      let param = "";

      if (value.selectTab !== "preRegistro") {
        param = data?.properties?.totalDocs;
      } else {
        param = preSellerData?.data?.seller?.totalDocs ?? 0;
      }

      setPaginationValue(Math.ceil(param / results));
    };

    getPaginationValue();
  }, [data?.properties?.totalDocs, value.selectTab, results, preSellerData]);

  const handleChangeFilters = (e, a) => {
    console.log(e.target);

    if (e?.target?.name === "preSeller") {
      setFilters({
        ...filters,
        [e.target.name]: Boolean(e.target.checked)
      });
      setSettlements([]);
    } else if (e?.target?.name === "postalCode") {
      setFilters({
        ...filters,
        [e.target.name]: String(e.target.value),
        state: "",
        settlement: ""
      });
      setSettlements([]);
    } else if (e?.target?.name === "state") {
      setFilters({
        ...filters,
        [e?.target?.name]: String(e?.target?.value),
        postalCode: "",
        settlement: ""
      });
      setSettlements([]);
    } else if (
      e?.target?.name === "dateStart" ||
      e?.target?.name === "dateEnd"
    ) {
      setFilters({ ...filters, [e?.target?.name]: new Date(e?.target?.value) });
    } else if (e?.target?.name !== undefined) {
      setFilters({ ...filters, [e?.target?.name]: String(e?.target?.value) });
    }
  };

  const handleCleanFilters = () => {
    setFilters({ ...defaultFilter });
  };

  const handleStateChange = () => {
    setFilters({ ...defaultFilter, postalCode: "", settlement: "" });
  };

  useEffect(() => {
    const searchByCp = async (postalCode) => {
      if (postalCode !== "" && postalCode.length === 5) {
        const response = await searchPostalCode(postalCode);

        if (
          response?.data?.postalCodes &&
          response?.data?.postalCodes[0]?.city
        ) {
          setSettlements(response.data.postalCodes[0].settlements);
          setFilters({
            ...filters,
            state: response.data.postalCodes[0].state,
            postalCode
          });
        }
      }
    };

    searchByCp(filters.postalCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.postalCode]);

  const handleFilter = () => {
    refetch();
  };

  const handleAndvancedFilters = (filters) => {
    setAdvancedFilters(filters);
  };

  // if (isLoading) {
  //   return (
  //     <section className="flex-center">
  //       <Loading />
  //     </section>
  //   );
  // }

  if (isError) {
    return <h2>Error</h2>;
  }

  // console.log(preSellerData?.data?.seller ?? "")
  return (
    <section className="flex-center">
      <ProfileCategories
        onSelect={handleSelect}
        currentTab={value.selectTab}
        data={counterData ?? {}}
      />
      <Box mt={2} height={32}>
        {counterData && (
          <Pagination
            color="primary"
            count={paginationValue}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        )}
      </Box>
      <a
        className="mt-3"
        href={`${REACT_APP_BASE_URL}/csv/properties?status=Perfilado`}
      >
        <Button variant="contained">Descargar CSV</Button>
      </a>
      <Box style={{ width: "100%", paddingLeft: "8px" }} display="flex">
        <Box style={{ display: "flex", gap: "8px" }}>
          <Box>
            <TextField
              size="small"
              fullWidth
              id="multiSearch"
              name="multiSearch"
              label="Nombre/Telefono/Email"
              value={filters.multiSearch}
              onChange={handleChangeFilters}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleFilter();
                }
              }}
              InputProps={{
                style: { borderRadius: "16px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleFilter}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>
        <SearchBar
          {...filters}
          onChange={handleChangeFilters}
          handleStateChange={handleStateChange}
          clean={handleCleanFilters}
          settlements={settlements}
          handleFilter={handleFilter}
        />
        <DrawerContainer
          handleAndvancedFilters={handleAndvancedFilters}
          handleFilter={handleFilter}
        />

        <CreateProspect
          refetchPreSellers={refetchPreSellers}
          refetchProperties={refetch}
        />
      </Box>
      {value.selectTab !== "preRegistro" &&
      data?.properties?.docs?.length !== 0 ? (
        <>
          {isFetching ? (
            <Grid
              container
              sx={{ flexGrow: 1 }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item>
                <Loading />
              </Grid>
            </Grid>
          ) : (
            <Table
              columnTitles={[
                "Estado del lead",
                "Tipo de lead",
                "Fecha y Hora",
                "Informacion del Cliente",
                "Informacion del Inmueble",
                "Fuente",
                "Acciones"
              ]}
              properties={data?.properties?.docs}
              refetchProperties={refetch}
              counterRefetch={counterRefetch}
              tableType={"Perfilado"}
            />
          )}
        </>
      ) : value.selectTab === "preRegistro" &&
        !loadingPreSellers &&
        preSellerData.data?.properties?.docs !== 0 ? (
        <Table
          columnTitles={[
            "Fecha y Hora",
            "Informacion del Cliente",
            "Fuente",
            "Acciones"
          ]}
          tableType={"preRegistro"}
          sellers={preSellerData?.data?.seller?.docs}
          refetchPreSellers={refetchPreSellers}
        />
      ) : value.selectTab === "No Apto" ? (
        <NoAptosTableContainer />
      ) : (
        <h2 className="fs-5 margin-element">
          No se encontraron casas con prioridad {value.selectTab.toLowerCase()}
        </h2>
      )}
    </section>
  );
};

export default Profiles;
