import { useContext, useEffect } from "react";
import { useState } from "react";

import { createDebt } from "../../../api/debts/createDebt";
import { uploadDebt } from "../../../api/debts/uploadDebt";
import { getUploadFileUrl, uploadFile } from "../../../api/utilsApi";
import TokenContext from "../../../context/Token";
import { toNormalForm } from "../../../helpers/common";
import DebtsForm from "./DebtsForm";

// PROPS DEL COMPONENTE: id={props.property._id} debtsRef={props.property.debtsRef} refetchProperties
const ModalDebts = (props) => {
  const { token } = useContext(TokenContext);

  const [options, setOptions] = useState([]);
  const [text, setText] = useState(props.inheritedText ?? "");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const clearInputs = () => {
    setText("");
    setAmount("");
  };

  const handleAction = async (e) => {
    e.preventDefault();

    if (!amount) {
      setErrorMessage("Cantidad Obligatoria");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    try {
      const response = await createDebt(token, props.id, {
        text,
        amount,
        type
      });

      if (file) {
        const { url, signedUrl } = await getUploadFileUrl(
          token,
          `tmp/properties/${props.id}/docs/expediente/adeudos`,
          toNormalForm(file?.name?.trim()?.replaceAll(" ", "-").toLowerCase())
        );

        await uploadFile(signedUrl, file);
        await uploadDebt(token, response?.debt_id, url);
      }
      console.log(response);
      setSuccessMessage("Registro Creado");
      props.refetchProperties();
      clearInputs();
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000);
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    const updateOptions = () => {
      const debtOptions = [
        {
          name: "Presupuesto de mantenimiento",
          value: "Presupuesto de mantenimiento"
        },
        { name: "Medidor de agua", value: "Medidor de agua" },
        { name: "Medidor de luz", value: "Medidor de luz" },
        { name: "Adeudo de la cerrada", value: "Adeudo de la cerrada" },
        { name: "Adeudo de agua", value: "agua" },
        { name: "Adeudo de Predio", value: "predial" },
        { name: "Adeudo de Luz", value: "luz" },
        { name: "ISR", value: "impuestos" }
      ];

      const aux = debtOptions?.filter(
        (option) => !props?.debtsRef?.some((debt) => debt.type === option.value)
      );

      setOptions(aux);
    };

    updateOptions();
  }, [props.debtsRef]);

  return (
    <div
      className="modal"
      tabIndex="-1"
      id={"modalDebts" + props.id}
      aria-labelledby={`modalDebtsLabel`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Adeudos</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-column pe-3">
              <DebtsForm
                showOptions={props.showOptions}
                options={options}
                text={text}
                setText={setText}
                type={type}
                setType={setType}
                file={file}
                setFile={setFile}
                amount={amount}
                setAmount={setAmount}
                handleAction={handleAction}
              />
              {/* } */}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-disabled"
              data-bs-dismiss="modal"
              onClick={clearInputs}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn-fill-orange"
              onClick={handleAction}
            >
              Crear Entrada
            </button>
            <br />
            {successMessage && (
              <p className="d-block w-100 text-center me-1 py-1 px-3 rounded bg-success text-white fw-bold">
                {successMessage}{" "}
              </p>
            )}
            {errorMessage && (
              <p className="d-block w-100 text-center me-1 py-1 px-3 rounded bg-danger text-white fw-bold">
                {errorMessage}{" "}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDebts;
