import SaveIcon from "@mui/icons-material/Download";
import ForwardIcon from "@mui/icons-material/Forward";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

import PdfCtc from "../../components/InfoFields/PdfCtc";
import OfferPdf from "../../components/pdfs/Pdf";
function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
}

const SaveQuoteResultDialog = ({
  open,
  handleClose,
  handleCancel,
  onCloseModal,
  saveQuoteResults,
  pdf
}) => {
  const { netOffer, generatePdf } = pdf;

  const navigate = useNavigate();

  const parsedDebts = {};

  let debtsInterests = 0;

  if (saveQuoteResults?.resultQuote?.quoteData?.debts) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const debt of saveQuoteResults?.resultQuote?.quoteData?.debts) {
      parsedDebts[debt?.type] = debt?.amount;

      debtsInterests += debt?.amount * debt?.rate;
    }
  }

  /* console.log(saveQuoteResults?.property);
  console.log(saveQuoteResults?.resultQuote);

  const PdfCtcDocument = (
    <PdfCtc
      data={{
        fileNumber: saveQuoteResults?.resultQuote?.fileNumber,
        clientData: {
          name: capitalizeFirstLetter(
            saveQuoteResults?.property?.sellerRef?.name
          ),
          fLastName: capitalizeFirstLetter(
            saveQuoteResults?.property?.sellerRef?.fLastName
          ),
          mLastName: capitalizeFirstLetter(
            saveQuoteResults?.property?.sellerRef?.mLastName
          )
        },
        rangeDays: saveQuoteResults?.resultQuote?.quoteData?.rangeDays,
        grossOffer: saveQuoteResults?.resultQuote?.quoteData?.grossOffer,
        debtCreditAmount:
          saveQuoteResults?.resultQuote?.quoteData?.creditAmount,
        rehabilitation:
          saveQuoteResults?.resultQuote?.quoteData?.rehabilitation,
        debts: {
          mantenimiento: saveQuoteResults?.resultQuote?.quoteData?.debts?.find(
            (deb) => deb.type === "mantenimiento"
          )?.amount,
          agua: saveQuoteResults?.resultQuote?.quoteData?.debts?.find(
            (deb) => deb.type === "agua"
          )?.amount,
          luz: saveQuoteResults?.resultQuote?.quoteData?.debts?.find(
            (deb) => deb.type === "luz"
          )?.amount,
          predial: saveQuoteResults?.resultQuote?.quoteData?.debts?.find(
            (deb) => deb.type === "predial"
          )?.amount
        },
        advancedPaymentAmount:
          saveQuoteResults?.resultQuote?.quoteData?.advancedPaymentAmount,
        creditInterests:
          saveQuoteResults?.resultQuote?.quoteData?.creditAmount *
          saveQuoteResults?.resultQuote?.quoteData?.advancedPaymentRate,
        debtsInterests,
        advancedPaymentInterests:
          saveQuoteResults?.resultQuote?.quoteData?.advancedPaymentAmount *
          saveQuoteResults?.resultQuote?.quoteData?.advancedPaymentRate,
        taxes:
          saveQuoteResults?.resultQuote?.quoteData?.cfdi +
          saveQuoteResults?.resultQuote?.quoteData?.creditTaxes,
        netOffer: saveQuoteResults?.resultQuote?.quoteData?.netOffer,
        commercialPrice:
          saveQuoteResults?.resultQuote?.quoteData?.commercialPrice,
        streetAndNumber: saveQuoteResults?.property.address?.street,
        settlement: saveQuoteResults?.resultQuote?.settlement,
        postalCode: saveQuoteResults?.resultQuote?.postalCode,
        city: saveQuoteResults?.property?.city,
        state: saveQuoteResults?.property?.state,
        rooms: saveQuoteResults?.resultQuote?.quoteData?.rooms,
        baths: saveQuoteResults?.resultQuote?.quoteData?.baths,
        mBuilding: saveQuoteResults?.resultQuote?.quoteData?.metersBuild,
        mLand: saveQuoteResults?.property.address?.mLand,
        parkingLot: saveQuoteResults?.property.address?.parkingLot || 0,
        levels: saveQuoteResults?.property?.address?.levels || 0,
        floor: saveQuoteResults?.property?.address?.floor || 0,
        notes: saveQuoteResults?.property?.notes || "",
        driveUrl: saveQuoteResults?.property?.driveUrl || "",
        locationUrl: saveQuoteResults?.property?.locationUrl || "",
        inspectionUrl: saveQuoteResults?.property?.inspectionUrl || "",
        adviser: saveQuoteResults?.resultQuote?.advicerRef,
        leader: saveQuoteResults?.resultQuote?.leaderRef
      }}
    />
  ); */

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle color={"#212427c0"}>Atención</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography textAlign={"center"} variant="h6" color="#212427">
            {(() => {
              switch (saveQuoteResults?.error) {
                case "EXISTING_PROPERTY":
                  return "Ya existe un usuario con ese Correo y Teléfono asociado a una propiedad";
                case null:
                  return "Usuario creado exitosamente.";

                default:
                  break;
              }
            })()}
          </Typography>
        </DialogContentText>
        <Grid container width={"100%"}>
          <Grid item container justifyContent={"center"}>
            {saveQuoteResults?.error === "EXISTING_PROPERTY" ? (
              <>
                <Link
                  to={`/ctc/compra/propiedad/${saveQuoteResults?.property?._id}/cliente`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    variant="text"
                    size="large"
                    startIcon={<ForwardIcon />}
                  >
                    Ir al usuario{" "}
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Button
                  variant="text"
                  size="large"
                  onClick={() => {
                    navigate(
                      `/ctc/compra/propiedad/${saveQuoteResults?.property?._id}/cliente`
                    );

                    handleClose();
                    onCloseModal();
                  }}
                  startIcon={<ForwardIcon />}
                >
                  Ir al usuario{" "}
                </Button>
              </>
            )}
          </Grid>

          <Grid
            container
            item
            alignItems={"center"}
            justifyContent={"center"}
            gap={2}
          >
            {!saveQuoteResults?.error &&
              generatePdf === true &&
              netOffer >= 0 && (
                <>
                  {/* <Button
                    variant="contained"
                    size="medium"
                    startIcon={<SaveIcon />}
                  >
                    <PDFDownloadLink
                      style={{ textDecoration: "none", color: "#fff" }}
                      document={
                        <OfferPdf
                          data={{
                            quoteId: saveQuoteResults?.resultQuote?.fileNumber,
                            name: capitalizeFirstLetter(
                              saveQuoteResults?.property?.sellerRef?.name
                            ),
                            firstName: capitalizeFirstLetter(
                              saveQuoteResults?.property?.sellerRef?.fLastName
                            ),
                            lastName: capitalizeFirstLetter(
                              saveQuoteResults?.property?.sellerRef?.mLastName
                            ),
                            streetAndNumber: "",
                            settlement:
                              saveQuoteResults?.resultQuote?.settlement,
                            postalCode:
                              saveQuoteResults?.resultQuote?.postalCode,
                            city: saveQuoteResults?.property?.city,
                            state: saveQuoteResults?.property?.state,
                            netOffer:
                              saveQuoteResults?.resultQuote?.quoteData
                                ?.netOffer,
                            grossOffer:
                              saveQuoteResults?.resultQuote?.quoteData
                                ?.grossOffer,
                            advancedPaymentAmount:
                              saveQuoteResults?.resultQuote?.quoteData
                                ?.advancedPaymentAmount +
                              saveQuoteResults?.resultQuote?.quoteData
                                ?.advancedPaymentAmount *
                                saveQuoteResults?.resultQuote?.quoteData
                                  ?.advancedPaymentRate,
                            taxes:
                              saveQuoteResults?.resultQuote?.quoteData?.cfdi +
                              saveQuoteResults?.resultQuote?.quoteData
                                ?.creditTaxes,
                            debts: {
                              creditAmount:
                                saveQuoteResults?.resultQuote?.quoteData
                                  ?.creditAmount +
                                saveQuoteResults?.resultQuote?.quoteData
                                  ?.creditAmount *
                                  saveQuoteResults?.resultQuote?.quoteData
                                    ?.creditAmountRate,
                              ...parsedDebts
                            },
                            rehabilitation:
                              saveQuoteResults?.resultQuote?.quoteData
                                ?.rehabilitation,
                            debtsInterests
                          }}
                        />
                      }
                      fileName={`Oferta ${
                        saveQuoteResults?.property.sellerRef?.name
                      } ${
                        saveQuoteResults?.property.sellerRef?.fLastName ?? ""
                      } ${moment().format("DD-MM-YYYY")}`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Cargando..." : "Descargar Oferta"
                      }
                    </PDFDownloadLink>
                  </Button> */}
                  {/* <Button
                    variant="contained"
                    size="medium"
                    startIcon={<SaveIcon />}
                  >
                    <PDFDownloadLink
                      style={{ textDecoration: "none", color: "#fff" }}
                      document={PdfCtcDocument}
                      fileName={`Oferta ${
                        saveQuoteResults?.property.sellerRef?.name
                      } ${
                        saveQuoteResults?.property.sellerRef?.fLastName ?? ""
                      } ${moment().format("DD-MM-YYYY")}`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Cargando..." : "Detalle Oferta"
                      }
                    </PDFDownloadLink>
                  </Button> */}
                </>
              )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {saveQuoteResults?.error === "EXISTING_PROPERTY" ? (
          <>
            <Button onClick={handleCancel}>Cancelar</Button>
          </>
        ) : (
          <>
            <Button onClick={handleClose} variant="text">
              Continuar
            </Button>
          </>
        )}

        {/* {saveQuoteResults?.error === "EXISTING_PROPERTY" && (
          <Button onClick={onSubmit}>Reemplazar</Button>
        )} */}
      </DialogActions>
    </Dialog>
  );
};

export default SaveQuoteResultDialog;
