import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from "@mui/material";

const DrawerCard = ({
  param,
  setShownSection,
  index,
  setParamsArray,
  paramsArray,
  currentParam,
  setCurrentParam
}) => {
  const handleDelete = () => {
    let helper = [...paramsArray];

    helper.splice(index, 1);

    setParamsArray(helper);
  };

  const handleAnd = () => {
    setShownSection("list");
    setCurrentParam({
      ...currentParam,
      name: "",
      reason: "",
      title: "",
      criteria: [],
      operator: "and"
    });
  };

  return (
    <Card sx={{ width: 300, margin: "auto", px: 1 }}>
      <Button onClick={handleDelete}>Eliminar entrada</Button>
      <CardContent sx={{ border: "1px solid #8D9AAA", borderRadius: 1 }}>
        <Typography color={"primary"} fontWeight={"bold"} gutterBottom>
          {param.title}{" "}
          {param.reason === "equals"
            ? "incluye: "
            : param.reason === "different"
            ? "es diferente de: "
            : param.reason === "defined"
            ? "es un valor conocido"
            : param.reason === "undefined"
            ? "es un valor desconocido"
            : ""}
          {param.criteria.length > 0 && param.criteria?.map((c) => `${c} `)}
        </Typography>
        <Typography variant="h5" component="div"></Typography>
      </CardContent>
      <CardActions>
        <Button sx={{ border: "1px solid #8D9AAA" }} onClick={handleAnd}>
          Y
        </Button>
      </CardActions>
    </Card>
  );
};

export default DrawerCard;
