import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useQuery } from "react-query";

import { getAllAdvisers } from "../../api/advisers/getAllAdvisers";
import { getAllReconversion } from "../../api/reconversion/getAllReconversion";
import { reconversionProperties } from "../../cacheQueries/cacheQueries";
import ReconvertCategories from "../../components/Reconvert/ReconvertCategories";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";

const Reconvert = () => {
  const { token } = useContext(TokenContext);

  const [select, setSelect] = useState("");
  const [results] = useState(20);
  const [page, setPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(1);

  const { data, refetch } = useQuery(
    [reconversionProperties, select],
    () => getAllReconversion(token, select),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );
  const getAdvisers = useQuery(["advisers"], () => getAllAdvisers(token));

  const counter = (properties, status) => {
    if (!status) {
      return properties.filter(
        (property) => property?.propertyRef?.showLead === true
      ).length;
    }
    return properties.filter(
      (property) =>
        property?.propertyRef?.status === status &&
        property?.propertyRef?.showLead === true
    ).length;
  };

  const handleSelectTab = (target) => {
    setSelect(target);
  };

  // const reconvertFilter = data?.data?.properties?.docs
  //   ?.filter(
  //     (card) =>
  //       card?.propertyRef?.status === select &&
  //       card?.propertyRef?.showLead === true
  //   )
  //   .map((card, idx) => {
  //     // console.log(card.propertyRef?.showLead)

  //     return (
  //       <ReconvertCard
  //         key={idx}
  //         data={card}
  //         advisers={getAdvisers?.data?.data?.advisers ?? []}
  //         refetchReconversion={refetch}
  //       />
  //     );
  //   });

  const tableFilter = data?.data?.properties?.docs
    .filter(
      (property) =>
        property?.propertyRef?.status === select &&
        property.propertyRef?.showLead === true
    )
    .map((property) => property);

  useEffect(() => {
    const getPaginationValue = () => {
      let param = "";

      param = data?.data?.properties?.totalDocs;

      setPaginationValue(Math.ceil(param / results));
    };

    getPaginationValue();
  }, [data?.data?.properties?.totalDocs, select, results]);
  return (
    <section className="flex-center">
      <ReconvertCategories
        totalLength={data?.data?.properties?.totalDocs ?? 0}
        perfiladosLength={counter(
          data?.data?.properties?.docs ?? [],
          "Perfilado"
        )}
        prospectosLength={counter(
          data?.data?.properties?.docs ?? [],
          "Prospectos"
        )}
        expedienteLength={counter(
          data?.data?.properties?.docs ?? [],
          "Expediente"
        )}
        firmaLength={counter(data?.data?.properties?.docs ?? [], "Firma")}
        onSelect={handleSelectTab}
        currentTab={select}
      />
      {data?.data?.properties?.totalDocs > 0 && (
        <Pagination
          color="primary"
          count={paginationValue}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      )}
      {select === "No Apto" ? (
        <Table
          columnTitles={[
            "Origen",
            "Enviado por",
            "Informacion del Cliente",
            "Informacion del Inmueble",
            "Contacto",
            "Razón"
          ]}
          properties={data?.data?.properties?.docs ?? []}
          advisers={getAdvisers?.data?.data?.advisers ?? []}
          refetchReconversion={refetch}
          tableType={"No Apto"}
        />
      ) : (
        <Table
          columnTitles={[
            "Origen",
            "Enviado por",
            "Informacion del Cliente",
            "Informacion del Inmueble",
            "Contacto",
            "Razón",
            "Acciones"
          ]}
          properties={select ? tableFilter : data?.data?.properties?.docs ?? []}
          advisers={getAdvisers?.data?.data?.advisers ?? []}
          refetchReconversion={refetch}
          tableType={"Reconversion"}
        />
      )}
      {/* {select ?
        reconvertFilter.length !== 0 ?
          reconvertFilter :
          <h2 className='fs-5 margin-element'>No se encontraron leads con {select.toLowerCase()}</h2> :
        data?.data?.properties.filter(card => card?.propertyRef?.showLead === true).map((card, idx) => {
          return (
            <ReconvertCard
              key={idx}
              data={card}
              advisers={getAdvisers?.data?.data?.advisers ?? []}
              refetchReconversion={refetch}
            />
          )
        })
      } */}
    </section>
  );
};

export default Reconvert;
