import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from "@react-pdf/renderer";
import React from "react";

import background from "../../assets/fondoPagina1.png";
import poppinsBold from "../../assets/fonts/Poppins-Bold.ttf";
import poppinsMedium from "../../assets/fonts/Poppins-Medium.ttf";
import poppinsRegular from "../../assets/fonts/Poppins-Regular.ttf";
import insta from "../../assets/insta.png";
import logo from "../../assets/Logo_CTC.png";
import mail from "../../assets/mail.png";
import red from "../../assets/red.png";
import tel from "../../assets/tel.png";
import { stringToCurrency } from "../../helpers/currencies";
import getOffer from "../../helpers/offerCalculation";

// Create styles
Font.register({
  family: "poppins",
  fonts: [
    { src: poppinsRegular },
    { src: poppinsMedium, fontWeight: "medium" },
    { src: poppinsBold, fontWeight: "bold" }
  ]
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "poppins",
    fontSize: 14
  },
  folio: {
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: "16px",
    fontSize: "12px"
  },
  header: {
    marginVertical: "16px",
    flexDirection: "row",
    height: "70px"
  },
  headerLeft: {
    width: "40%",
    marginLeft: 30
  },
  headerRight: {
    width: "60%",
    backgroundColor: "#EE7833",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    padding: "15px"
  },
  title1: {
    fontSize: "20px",
    color: "#fff",
    fontWeight: "bold"
  },
  title2: {
    fontSize: "16px"
  },
  container: { paddingHorizontal: "30px" },
  homeInfo: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  homeInfoText: {
    fontSize: "15",
    color: "#718195"
  },
  blueSquare: {
    marginHorizontal: "auto",
    backgroundColor: "#0053C3",
    border: "1px solid black",
    position: "relative",
    zIndex: "5",
    width: "360",
    height: "262px",
    borderRadius: "30px"
  },
  orangeSquare: {
    backgroundColor: "#EE7833",
    position: "absolute",
    top: "30px",
    zIndex: "5",
    width: "360",
    height: "200px",
    borderRadius: "30px"
  },
  whiteSquare: {
    backgroundColor: "#fff",
    position: "absolute",
    top: "60px",
    zIndex: "5",
    width: "360px",
    height: "200px",
    borderRadius: "30px",
    padding: "20px"
  },
  whiteSquareContainer: {
    display: "flex",
    flexDirection: "row"
  },
  image: {
    width: "60%",
    marginLeft: 2
  },
  logoDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  icon: {
    height: "15px",
    width: "15px"
  }
});

// Create Document Component
const Pdf = ({
  totalAll,
  property,
  clientData,
  totalDebts,
  advancedPayment,
  debtsRef = [],
  source = null,
  fileNumber,
  rangeDays,
  interesLiquidarCredito,
  interesDeudas,
  debts
}) => (
  <Document>
    <Page size="letter" style={styles.page}>
      <View style={styles.folio}>
        <Text>{fileNumber}</Text>
      </View>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Image style={styles.image} src={logo} />
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.title1}>¡Felicidades!</Text>
          <Text style={styles.title2}>Estás muy cerca de vender tu casa.</Text>
        </View>
      </View>
      <View style={[styles.container, { position: "relative" }]}>
        <Image
          src={background}
          style={{ width: 550, height: 550, position: "absolute" }}
        />
        <View style={styles.homeInfo}>
          <Text>
            La oferta por tu casa ubicada en:
            <Text style={styles.homeInfoText}>
              {" "}
              {property?.address?.street ?? ""}
              {property?.address?.street && " "}
              {property?.address?.numInt ?? ""}
              {property?.address?.numInt && ", "}
              {property?.address?.numExt ?? ""}
              {property?.address?.numExt && ", "}
              {property?.settlement ?? ""}
              {property?.settlement && ", "}
              {property?.city ?? ""}
              {property?.city && ", "}
              {property?.state ?? ""}
              {property?.state && ", "}
              {property?.postalCode && "C.P.: "}
              {property?.postalCode ?? ""}
            </Text>
          </Text>
          {/* <Text>
            Que cuenta con las siguientes características:
            <Text style={styles.homeInfoText}></Text>
          </Text> */}
          {property?.debtCredit && (
            <Text>
              Que actualmente cuenta con una hipoteca con:
              <Text style={styles.homeInfoText}>
                {" "}
                {property?.debtCredit &&
                  stringToCurrency(property?.debtCredit)}{" "}
              </Text>
            </Text>
          )}
          {property?.sellerRef?.credit && (
            <Text>
              Con el número de crédito:
              <Text style={styles.homeInfoText}>
                {" "}
                {property?.sellerRef?.credit}{" "}
              </Text>
            </Text>
          )}

          <Text>Es la siguiente:</Text>
        </View>
        {/* "offer data" */}
        <View>
          <View style={styles.blueSquare}>
            <Text
              style={{ color: "#fff", textAlign: "center", marginTop: "5px" }}
            >
              {property?.sellerRef?.name ?? ""}
              {property?.sellerRef?.name && " "}
              {property?.sellerRef?.fLastName ?? ""}
              {property?.sellerRef?.fLastName && " "}
              {property?.sellerRef?.mLastName ?? ""}
            </Text>
            <View style={styles.orangeSquare}>
              <Text
                style={{ color: "#fff", textAlign: "center", marginTop: "5px" }}
              >
                Oferta {rangeDays === 0 ? "Inmediata" : rangeDays + " días"}:{" "}
                {source === "dashboard-quoter"
                  ? stringToCurrency(property?.offer)
                  : stringToCurrency(
                      getOffer(
                        property?.offer ?? property?.quote,
                        property?.interval
                      )
                    )}
              </Text>
            </View>
            <View style={styles.whiteSquare}>
              <View style={styles.whiteSquareContainer}>
                <View style={{ width: "50%", fontSize: "12px" }}>
                  <Text
                    style={{
                      textAlign: "right"
                    }}
                  >
                    Crédito Hipotecario:
                  </Text>
                  <Text
                    style={{
                      textAlign: "right",
                      marginTop: "6px"
                    }}
                  >
                    Intereses liq. crédito
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "6px" }}>
                    Cuota condominal:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "6px" }}>
                    Agua:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "6px" }}>
                    Luz:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "6px" }}>
                    Predial:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "6px" }}>
                    Impuestos{" "}
                    {clientData?.debtCredit === "false"
                      ? ""
                      : clientData?.debtCredit}
                    :
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "6px" }}>
                    Interes servicios.
                  </Text>
                  <Text
                    style={{
                      textAlign: "right",
                      marginTop: "6px"
                    }}
                  >
                    Adelanto + Intereses:
                  </Text>
                  <Text
                    style={{
                      textAlign: "right",
                      marginTop: "6px"
                    }}
                  >
                    Gestión CFDI:
                  </Text>
                  <Text
                    style={{
                      textAlign: "right",
                      marginTop: "6px"
                    }}
                  >
                    Rehabilitación:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "6px" }}>
                    Total:
                  </Text>
                  <Text style={{ textAlign: "right", marginTop: "20px" }}>
                    Saldo final:
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                    paddingLeft: "10px",
                    fontSize: "12px"
                  }}
                >
                  <Text style={{ textAlign: "left" }}>
                    {stringToCurrency(property?.debtCredit || 0)}{" "}
                  </Text>
                  <Text style={{ textAlign: "left", marginTop: "6px" }}>
                    {stringToCurrency(interesLiquidarCredito || 0)}{" "}
                  </Text>
                  {debtsRef && (
                    <>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(
                          debtsRef?.find(
                            (debt) => debt.type === "mantenimiento"
                          )?.amount || 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "agua")
                            ?.amount || 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "luz")
                            ?.amount || 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "predial")
                            ?.amount || 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(
                          debtsRef?.find((debt) => debt.type === "impuestos")
                            ?.amount || 0
                        )}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(interesDeudas || 0)}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(advancedPayment.total || 0)}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(clientData.cfdiCost || 0)}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(debts.rehabilitation || 0)}
                      </Text>
                      <Text style={{ textAlign: "left", marginTop: "6px" }}>
                        {stringToCurrency(
                          Number(totalAll) +
                            Number(clientData.debtCreditAmount) +
                            Number(interesLiquidarCredito) +
                            Number(totalDebts) +
                            Number(advancedPayment.total) +
                            Number(debts.rehabilitation) +
                            Number(interesDeudas)
                        )}
                      </Text>
                    </>
                  )}
                  <Text style={{ textAlign: "left", marginTop: "20px" }}>
                    {stringToCurrency(property?.netOffer)}{" "}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View>
          <Text
            style={{
              color: "#0053C3",
              fontWeight: "bold",
              textAlign: "center",
              marginTop: "15px"
            }}
          >
            Acepto carta oferta
          </Text>
        </View>
        <View
          style={{
            marginTop: "30px",
            marginHorizontal: "auto",
            width: "300px",
            border: "1px solid black"
          }}
        ></View>
        <View>
          <Text
            style={{
              textAlign: "center",
              marginTop: "5px",
              fontSize: "15px"
            }}
          >
            {property?.sellerRef?.name ?? ""}
            {property?.sellerRef?.name && " "}
            {property?.sellerRef?.fLastName ?? ""}
            {property?.sellerRef?.fLastName && " "}
            {property?.sellerRef?.mLastName ?? ""}
          </Text>
          <Text
            style={{
              color: "#0053C3",
              fontWeight: "bold",
              textAlign: "center"
            }}
          >
            CLIENTE
          </Text>
        </View>
      </View>
      <View
        style={{ paddingHorizontal: "30px", position: "absolute", bottom: 0 }}
      >
        <View>
          <Text
            style={{ fontWeight: "bold", color: "#EE7833", fontSize: "12px" }}
          >
            En COMPRAMOSTUCASA.MX
            <Text style={{ color: "#000", fontWeight: "normal" }}>
              {" "}
              ¡Tú decides qué tan rápido quieres vender tu casa!
            </Text>
          </Text>
        </View>
        {/* <View
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: "11px",
            color: "#0053C3"
          }}
        >
          <View style={{ fontSize: 10, width: "60%" }}>
            <View style={styles.logoDiv}>
              <Image src={tel} style={styles.icon} />
              <Text> (55) 2462 0819</Text>
            </View>
            <View style={styles.logoDiv}>
              <Image src={red} style={styles.icon} />
              <Text> compramostucasa.mx</Text>
            </View>
            <View style={styles.logoDiv}>
              <Image src={mail} style={styles.icon} />
              <Text>contacto@compramostucasa.mx</Text>
            </View>
            <View style={styles.logoDiv}>
              <Image src={insta} style={styles.icon} />
              <Text> compramostucasa.mx</Text>
            </View>
          </View>
          <View style={{ width: "40%" }}>
            <Text style={{ fontSize: 10, textAlign: "right" }}>
              Alexander Von Humboldt 43A, Lomas Verdes 3ra Sección, Naucalpan
              Edo. de Méx. CP 53125
            </Text>
          </View>
        </View> */}
        <View style={{ width: "100%", marginTop: "8px" }}>
          <Text style={{ fontSize: 8, color: "#EE7833" }}>
            *Aplican términos y condiciones
            <Text style={{ color: "#000" }}>
              {" "}
              | La siguiente oferta tiene una vigencia de 15 días a partir de la
              fecha emitida, misma que puede estar sujeta a cambios a la fecha
              de compraventa. La presente oferta se envía bajo la condición de
              que su contenido es confidencial y no será revelado a ningún
              tercero sin previo aviso. Para mayor información consulta
              directamente con tu asesor.
            </Text>
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);

export default Pdf;
