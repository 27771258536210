import Typography from "@mui/material/Typography";

import ClientInfo from "../InfoFields/ClientInfo";
import DatesInfo from "../InfoFields/DatesInfo";
import CreateHouseSeller from "../Modals/createHouseSeller/CreateHouseSeller";
import DeleteHousePreSeller from "../Modals/deleteHousePreSeller/DeleteHousePreSeller";
import EditDataSeller from "../Modals/editDataSeller/EditDataSeller";

//props del componente: sellers={[]}, refetchPresellers=react query refetch
const TableBodyPreSeller = (props) => {
  return (
    <tbody>
      {props.sellers?.map((item) => (
        <tr key={item?._id}>
          <td style={{ width: 220 }}>
            <DatesInfo createdAt={item.createdAt ?? ""} />
          </td>
          <td style={{ width: 400 }}>
            <ClientInfo sellerRef={item ?? {}} />
          </td>

          <td style={{ width: 200 }}>
            <Typography variant="body1" color="secondary" fontWeight={"bold"}>
              {item.source}
            </Typography>
          </td>

          <td>
            <div className="d-flex flex-column" style={{ width: 200 }}>
              <EditDataSeller
                sellerId={item._id}
                name={item.name}
                fLastName={item.fLastName}
                mLastName={item.mLastName}
                phoneNumber={item.phoneNumber}
                email={item.email}
                refetchPreSellers={props.refetchPreSellers}
              />
              <CreateHouseSeller
                preSellerId={item._id}
                refetchPreSellers={props.refetchPreSellers}
              />
              <DeleteHousePreSeller
                preSellerId={item._id}
                refetchPreSellers={props.refetchPreSellers}
              />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBodyPreSeller;
