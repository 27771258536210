import ExpedienteContainer from "../../components/Proceeding/ExpedienteContainer";

const Proceedings = () => {
  return (
    <section className="flex-center">
      <ExpedienteContainer />
    </section>
  );
};

export default Proceedings;
