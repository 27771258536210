import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import { useContext, useState } from "react";

import TokenContext from "../../../../../context/Token";
import { useModal } from "../../../../../hooks/useModal";

const AddWorker = ({ workerType, creationFunction, refetch }) => {
  const { open, onOpenModal, onCloseModal } = useModal();
  const { token } = useContext(TokenContext);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [loading, setLoading] = useState(false);
  const [workerData, setWorkerData] = useState({
    name: "",
    fLastName: "",
    mLastName: "",
    email: "",
    phoneNumber: ""
  });

  const handleChange = (e) => {
    setWorkerData({ ...workerData, [e.target.name]: e.target.value });
  };

  const sendData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await creationFunction(token, workerData);
      setMessage({ type: "success", text: `${workerType} creado` });
      setLoading(false);
      refetch();
      setWorkerData({
        name: "",
        fLastName: "",
        mLastName: "",
        email: "",
        phoneNumber: ""
      });
      setTimeout(() => {
        setMessage({ type: "", text: "" });
      }, 3000);
    } catch (error) {
      setMessage({ type: "error", text: error });
      setLoading(false);
      setTimeout(() => {
        setMessage({ type: "", text: "" });
      }, 3000);
    }
  };

  return (
    <Box mt={1}>
      <IconButton color="primary" onClick={onOpenModal}>
        <AddIcon color="inherit" />
      </IconButton>
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          aria-labelledby={"error-success-dialogue"}
          maxWidth="md"
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            Añadir {workerType} nuevo
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              component={"form"}
              onSubmit={sendData}
              sx={{ width: { xs: 300, md: 500 } }}
            >
              <TextField
                fullWidth
                required
                id="name"
                name="name"
                label="Nombre(s)"
                value={workerData.name}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                id="fLastName"
                name="fLastName"
                label="Apellido paterno"
                value={workerData.fLastName}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                id="mLastName"
                name="mLastName"
                label="Apellido materno"
                value={workerData.mLastName}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                id="email"
                name="email"
                label="Correo electrónico"
                value={workerData.email}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                id="phoneNumber"
                name="phoneNumber"
                label="Teléfono"
                value={workerData.phoneNumber}
                onChange={handleChange}
              />
              <Box display={"flex"} justifyContent={"end"} gap={2}>
                {message.type !== "" && (
                  <Alert severity={message.type} sx={{ mr: 2 }}>
                    {message.text}
                  </Alert>
                )}
                <Button
                  disabled={loading}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {loading ? "Enviando..." : "Enviar"}
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
};

export default AddWorker;
