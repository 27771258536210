import { stringToDMY } from "../../helpers/dates";

const LastContact = (props) => {
  return (
    <>
      {props.propertyRef?.callsRef?.length > 0 && (
        <label className="value-title">
          por{" "}
          <span className="text-capitalize">
            {props.propertyRef?.callsRef[0].type}
          </span>{" "}
          el {stringToDMY(props.propertyRef?.callsRef[0].date)}
        </label>
      )}
    </>
  );
};

export default LastContact;
