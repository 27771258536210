import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { createCall } from "../../api/call/createCall";
import { updateProperties } from "../../api/properties/updateProperties";
import { createReconversion } from "../../api/reconversion/createReconversion";
import TokenContext from "../../context/Token";
import "../styles/Modal.css";

const ModalReconversion = (props) => {
  const { token } = useContext(TokenContext);
  const navigate = useNavigate();

  const [reason, setReason] = useState("");
  const [reasonDetail, setReasonDetail] = useState("");
  const [callType, setCallType] = useState("");
  const [callDate, setCallDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const sendData = async (e) => {
    // console.log("sirve")
    if ([reason, reasonDetail].includes("")) {
      setErrorMessage("Todos los campos son obligatorios");
      setTimeout(() => setErrorMessage(""), 3000);
      return;
    }
    try {
      await updateProperties(token, props.id, { reconversion: true });

      const reconversion = await createReconversion(token, {
        reason,
        reasonDetail,
        propertyRef: props.id
      });

      if (reconversion.status === 201) {
        const call = { propertyRef: props.id, date: callDate, type: callType };
        const callResponse = await createCall(token, call);

        if (callResponse) {
          navigate(0);
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      id={"modalComment" + (props.type ?? "") + props.id}
      aria-labelledby={`modalCommentLabel`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.reason}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {props.select && (
              <>
                <label className="text-gray fw-bold mb-3">
                  Razon por la que no está perfilado
                </label>
                <select
                  className="form-select"
                  style={{ width: 250 }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                >
                  <option value=""> Razones</option>
                  <option value="Adeudo Mayor">Adeudo Mayor</option>
                  <option value="Viviendas sin formalidad">
                    Viviendas sin formalidad
                  </option>
                  <option value="La casa esta invadida">
                    La casa esta invadida
                  </option>
                  <option value="No le interesa vender">
                    No le interesa vender
                  </option>
                  <option value="No se registro">No se registro</option>
                  <option value="No se compra dentro de ese fraccionamiento">
                    No se compra dentro de ese fraccionamiento
                  </option>
                  <option value="Lead sin datos">Lead sin datos</option>
                  <option value="Lead repetido">Lead repetido</option>
                  <option value="Datos incorrectos">Datos Incorrectos</option>
                  <option value="Escrituras y traslado de dominio">
                    Escrituras y traslado de dominio
                  </option>
                </select>
              </>
            )}
            <label className="text-gray fw-bold my-3">Comentario</label>
            <textarea
              className="modal-textarea"
              value={reasonDetail}
              onChange={(e) => setReasonDetail(e.target.value)}
            />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px"
              }}
            >
              <div>
                <label className="text-gray fw-bold my-3">
                  Forma de Contacto
                </label>
                <select
                  className="form-select"
                  style={{ width: 200 }}
                  name=""
                  id=""
                  value={callType}
                  onChange={(e) => setCallType(e.target.value)}
                >
                  <option value="" hidden>
                    Seleccionar
                  </option>
                  <option value="telefono">Llamada Telefónica</option>
                  <option value="sms">SMS</option>
                </select>
              </div>
              <div>
                <label className="text-gray fw-bold my-3">
                  Fecha de Contacto
                </label>
                <input
                  className="p-1 rounded-2 border border-2"
                  type="datetime-local"
                  name=""
                  id=""
                  value={callDate}
                  onChange={(e) => setCallDate(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {/* {successMessage && <p className='mx-auto text-center py-2 rounded bg-success text-white fw-bold'>{successMessage} </p>} */}
            {errorMessage && (
              <p className="mx-auto text-center py-2 rounded bg-danger text-white fw-bold">
                {errorMessage}{" "}
              </p>
            )}
            <button
              type="button"
              className="btn btn-disabled"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn-fill-orange"
              onClick={sendData}
            >
              Enviar Reconversión
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReconversion;
