import { Button } from "@mui/material";

const FinishRehabButtom = () => {
  return (
    <Button variant="contained" fullWidth>
      Finalizar Rehabilitación
    </Button>
  );
};

export default FinishRehabButtom;
