import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { forwardRef, useState } from "react";
import { NumericFormat } from "react-number-format";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
    />
  );
});
const NumericFormatCustomPercentaje = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      isAllowed={(values) => {
        const { floatValue } = values;

        return (floatValue >= 0 && floatValue <= 100) || !floatValue;
      }}
    />
  );
});

const Debts = ({
  debts,
  handleDebts,
  advancedPayment,
  setAdvancedPayment,
  clientData,
  setClientData,
  rangeDays,
  allowedByAdmin
}) => {
  return (
    <Grid container columnSpacing={2} mt={2} mb={2}>
      <Grid item container xs={6} columnSpacing={2}>
        <Grid item xs={8}>
          <TextField
            fullWidth
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Agua
              </Typography>
            }
            value={debts.agua}
            onChange={handleDebts}
            name="agua"
            id="agua"
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={!allowedByAdmin}
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Inter. Agua
              </Typography>
            }
            value={debts.aguaTax}
            onChange={handleDebts}
            name="aguaTax"
            id="aguaTax"
            InputProps={{
              inputComponent: NumericFormatCustomPercentaje,
              endAdornment: "%"
            }}
            type="number"
          />
        </Grid>

        <Grid item xs={8}>
          <TextField
            fullWidth
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Luz
              </Typography>
            }
            value={debts.luz}
            onChange={handleDebts}
            name="luz"
            id="luz"
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={!allowedByAdmin}
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Inter. Luz
              </Typography>
            }
            value={debts.luzTax}
            onChange={handleDebts}
            name="luzTax"
            id="luzTax"
            InputProps={{
              inputComponent: NumericFormatCustomPercentaje,
              endAdornment: "%"
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <TextField
            fullWidth
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Predial
              </Typography>
            }
            value={debts.predial}
            onChange={handleDebts}
            name="predial"
            id="predial"
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            disabled={!allowedByAdmin}
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Inter. Predial
              </Typography>
            }
            value={debts.predialTax}
            onChange={handleDebts}
            name="predialTax"
            id="predialTax"
            InputProps={{
              inputComponent: NumericFormatCustomPercentaje,
              endAdornment: "%"
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Cuota Condominal
              </Typography>
            }
            value={debts.mantenimiento}
            onChange={handleDebts}
            name="mantenimiento"
            id="mantenimiento"
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            disabled={!allowedByAdmin}
            size="small"
            name="mantenimientoTax"
            label={
              <Typography variant="headline" component="h5">
                Inter. Cuota
              </Typography>
            }
            type={"number"}
            value={debts.mantenimientoTax}
            onChange={handleDebts}
            InputProps={{
              inputComponent: NumericFormatCustomPercentaje,
              endAdornment: "%"
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Rehabilitación
              </Typography>
            }
            value={debts.rehabilitation}
            onChange={handleDebts}
            name="rehabilitation"
            id="rehabilitation"
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={6} columnSpacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            select
            id="cfdi"
            key="cfdi"
            name="cfdi"
            label={
              <Typography variant="headline" component="h5">
                CFDI
              </Typography>
            }
            value={clientData.cfdi}
            onChange={(e) => {
              setClientData({
                ...clientData,
                cfdi: e.target.value,
                cfdiCost: ""
              });
            }}
          >
            <MenuItem value={true}>Con CFDI</MenuItem>
            <MenuItem value={false}>Sin CFDI</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={clientData.cfdi && !clientData?.cfdiCost}
            fullWidth
            size="small"
            label={
              <Typography variant="headline" component="h5">
                CFDI Gestión
              </Typography>
            }
            value={clientData?.cfdiCost}
            onChange={(e) => {
              setClientData({
                ...clientData,
                cfdiCost: e.target.value.toString()
              });
            }}
            name="cfdiCost"
            id="cfdiCost"
            key="cfdiCost"
            disableAnimation={true}
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            select
            id="debtCredit"
            name="debtCredit"
            label={
              <Typography variant="headline" component="h5">
                Crédito
              </Typography>
            }
            value={clientData.debtCredit}
            onChange={(e) => {
              console.log(e.target.value);
              setClientData({
                ...clientData,
                debtCredit: e.target.value,
                /* debtCreditSettled: false,
                debtCreditAmount: 0,
                debtCreditRate: 0.3, */
                taxes:
                  e.target.value === "false"
                    ? ""
                    : e.target.value === "Infonavit"
                    ? "19000"
                    : "22000"
              });
            }}
          >
            <MenuItem value={"false"}>Sin Crédito</MenuItem>
            <MenuItem value={"Infonavit"}>Infonavit</MenuItem>
            <MenuItem value={"FOVISSSTE"}>FOVISSSTE</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            disabled={clientData.debtCredit === "false"}
            fullWidth
            size="small"
            name="taxes"
            label={
              <Typography variant="headline" component="h5">
                Impuestos
              </Typography>
            }
            value={clientData.taxes}
            onChange={(e) => {
              setClientData({
                ...clientData,
                taxes: e.target.value
              });
            }}
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            select
            id="debtCredit"
            name="debtCreditSettled"
            label={
              <Typography variant="headline" component="h5">
                Credito
              </Typography>
            }
            value={clientData.debtCreditSettled}
            onChange={(e) => {
              setClientData({
                ...clientData,
                debtCreditSettled: e.target.value
              });
              /* if (e.target.value) {
                setClientData({
                  ...clientData,
                  debtCreditSettled: e.target.value
                });
              } else {
                setClientData({
                  ...clientData,
                  debtCreditSettled: e.target.value,
                  debtCreditAmount: 0,
                  debtCreditRate: 0
                });
              } */
            }}
          >
            <MenuItem value={true}>Se liquida</MenuItem>
            <MenuItem value={false}>No se liquida</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label={
              <Typography variant="headline" component="h5">
                Monto Crédito
              </Typography>
            }
            value={clientData.debtCreditAmount}
            onChange={(e) => {
              setClientData({
                ...clientData,
                debtCreditAmount: e.target.value
              });
            }}
            name="debtCreditAmount"
            id="debtCreditAmount"
            InputProps={{
              inputComponent: NumericFormatCustom,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            select
            name="debtCreditRate"
            label={
              <Typography variant="headline" component="h5">
                Tasa Crédito
              </Typography>
            }
            value={clientData.debtCreditRate}
            onChange={(e) => {
              setClientData({
                ...clientData,
                debtCreditRate: e.target.value
              });
            }}
          >
            <MenuItem value={0}>0%</MenuItem>
            <MenuItem value={0.25}>25%</MenuItem>
            <MenuItem value={0.3}>30%</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Grid item sx={{ position: "relative" }}>
            <TextField
              disabled={rangeDays < 1}
              fullWidth
              size="small"
              label={
                <Typography variant="headline" component="h5">
                  Adelanto
                </Typography>
              }
              value={advancedPayment.required}
              onChange={(e) =>
                setAdvancedPayment({
                  ...advancedPayment,
                  required: e.target.value
                })
              }
              name="adelanto"
              id="adelanto"
              InputProps={{
                inputComponent: NumericFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
            <Tooltip
              placement="top-end"
              arrow
              title="Solo se desbloquea si el intervalo es mayor a cero días."
              sx={{ fontSize: 18 }}
            >
              <HelpOutlineIcon
                color="primary"
                fontSize="inherit"
                sx={{ mt: 1, position: "absolute", right: 0 }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Debts;
