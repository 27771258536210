import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useRef, useState } from "react";

import { uploadImageProperty } from "../../../api/properties/uploadImageProperty";
import TokenContext from "../../../context/Token";

const UploadImageModal = ({ open, onCloseModal, propertyId, refetch }) => {
  const { token } = useContext(TokenContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState({ severity: "", text: "" });

  const inputRef = useRef(null);
  const handleOpenFile = () => inputRef.current.click();

  function handleImageChange(e) {
    const image = e.target.files[0];

    setSelectedImage(URL.createObjectURL(image));
    setUploadedImage(image);
  }

  const handleImageUpload = async () => {
    try {
      await uploadImageProperty(token, propertyId, uploadedImage, description);
      setSuccess({ severity: "success", text: "Actualizado" });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
      refetch();
    } catch (error) {
      console.log(error);
      setSuccess({ severity: "error", text: error });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"image-modal"}
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"image-modal-title"}>
        Fotos del Inmueble
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box width={500}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {selectedImage && (
                <Box
                  sx={{
                    width: 200,
                    height: 190,
                    border: "solid 1px rgba(111, 128, 149, 0.6)",
                    borderRadius: 2,
                    overflow: "hidden",
                    mx: "auto",
                    mt: 1
                  }}
                >
                  <Box
                    component={"img"}
                    src={selectedImage}
                    alt="imagen"
                    sx={{ height: "100%", width: "100%", objectFit: "contain" }}
                  />
                </Box>
              )}
              <Box display={"flex"} justifyContent="center">
                <Button onClick={handleOpenFile}>Elegir Imágen</Button>
              </Box>

              <input
                ref={inputRef}
                hidden
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                multiline
                label="Descripción"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxRows={7}
                minRows={7}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        {success.severity === "success" && (
          <Alert severity={success.severity}>{success.text}</Alert>
        )}
        {success.severity === "error" && (
          <Alert severity={success.severity}>{success.text}</Alert>
        )}
        <Button
          disabled={!selectedImage}
          variant="contained"
          sx={{ marginRight: 2 }}
          onClick={handleImageUpload}
        >
          Subir Foto
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImageModal;
