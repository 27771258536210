export const categories = [
  {
    subCategories: [
      {
        subcategory: "Busqueda y conexión / toma hidráulica de 0.00 a 3.00 M",
        price: 1300
      },
      { subcategory: "Demolición controlada de acabado en muros", price: 55 },
      { subcategory: "Demolición controlada de acabado en losa", price: 55 },
      {
        subcategory: "Demolición controlada para reparación de humedad",
        price: 55
      }
    ],
    category: "Albañileria Demolicion"
  },

  {
    subCategories: [
      { subcategory: "Aplanado en muros interiores", price: 115 },
      { subcategory: "Aplanado en losa", price: 115 }
    ],
    category: "Albañileria Acabados"
  },

  {
    subCategories: [
      { subcategory: "Pintura vinilica para losa", price: 105 },
      { subcategory: "Pintura vinilica para muros", price: 105 },
      {
        subcategory: "Pintura en Herreria (Protecciones y escaleras)",
        price: 1500
      },
      { subcategory: "Impermeabilizante 3 años", price: 115 }
    ],
    category: "impermeabilización y pintura"
  },

  {
    subCategories: [
      { subcategory: "Colocación de base para medidor de CFE", price: 850 },
      {
        subcategory: "Colocación de base para medidor de CFE con uberia y mufa",
        price: 1350
      },
      { subcategory: "Colocación de centro de carga.", price: 850 },
      { subcategory: "Instalación de apagador, contacto, foco", price: 150 },
      { subcategory: "Salida para iluminación en techos, muros", price: 250 },
      {
        subcategory: "Salida electrica para apagador y contacto sencillo",
        price: 200
      }
    ],
    category: "Instalación Eléctrica"
  },

  {
    subCategories: [
      { subcategory: "Colocación de WC", price: 2100 },
      { subcategory: "Colocación de lavamanos", price: 1600 },
      { subcategory: "Colocación de tarja de acero inoxidable", price: 2000 },
      { subcategory: "Colocación de llave nariz para manguera", price: 150 },
      { subcategory: "Colocación de regadera completa", price: 800 },
      { subcategory: "Mezcladora (tarja, lavabo)", price: 1000 },
      { subcategory: "Colocación de lavadero prefabricado", price: 1000 },
      { subcategory: "Colocación de tapa para registro", price: 1500 }
    ],
    category: "Accesorios"
  },

  {
    subCategories: [
      { subcategory: "Puerta Exterior 2", price: 2500 },
      { subcategory: "Puerta Interior", price: 1500 },
      { subcategory: "Marco para puerta", price: 1200 },
      { subcategory: "Chapas", price: 500 }
    ],
    category: "Puertas y marcos"
  },

  {
    subCategories: [
      { subcategory: "Vidrios", price: 1500 },
      { subcategory: "Ventanas", price: 3500 },
      { subcategory: "Domo", price: 1200 }
    ],
    category: "Vidrios y ventanas"
  },

  {
    subCategories: [{ subcategory: "Puerta principal", price: 500 }],
    category: "Llaves"
  }
];
