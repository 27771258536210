const { REACT_APP_IMAGES_URL } = process.env;

const Gallery = (props) => {
  return (
    <div>
      <div
        className="w-100"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "5px"
        }}
      >
        {props.images?.map((i) => (
          <a key={i._id} href={`${i.path}`} target="_blank" rel="noreferrer">
            <img className="w-100" src={`${i.path}`} alt={i._id} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
