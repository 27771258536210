import { Button } from "@mui/material";

const columns = [
  "fecha",
  "nombre",
  "telefono",
  "monto",
  "reembolso",
  "perdido"
];
const width = 100 / columns.length;
const LayawaysTable = () => {
  return (
    <table
      className="table mt-3 table-bordered border-3"
      style={{ borderColor: "#707070", width: "100%" }}
    >
      <thead style={{ backgroundColor: "#F2F2F2", color: "#718195" }}>
        <tr>
          {columns.map((title, i) => (
            <th style={{ width: `${width}%` }} key={i}>
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>20/07/2023</td>
          <td>test apellido1 apellido2</td>
          <td>0000000000</td>
          <td>$2,000.00</td>
          <td>
            <Button sx={{ m: 0 }}>Reembolsar depósito</Button>
          </td>
          <td>No</td>
        </tr>
        <tr>
          <td>20/07/2023</td>
          <td>test apellido1 apellido2</td>
          <td>0000000000</td>
          <td>$2,000.00</td>
          <td>$2,000.00</td>
          <td>Si</td>
        </tr>
      </tbody>
    </table>
  );
};

export default LayawaysTable;
