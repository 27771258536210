import moment from "moment";

export const getOnlyDate = (date) => {
  const formattedDate = moment(date).format("DD/MM/Y");

  return formattedDate ?? "Sin Fecha";
};

export const getOnlyTime = (date) => {
  const formattedDate = moment(date).format("h:mm:ss A");

  return formattedDate ?? "Sin Hora";
};

export const getDateAndTime = (date) => {
  const formattedDate = moment(date).format("DD/MM/Y, h:mm:ss A");

  return formattedDate ?? "Sin Hora";
};

export const stringToDMY = (date) => {
  const fullDate = date?.split("T")[0] ?? "";

  return fullDate;
};
