import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import SendIcon from "@mui/icons-material/Send";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { useContext, useState } from "react";
import { useQuery } from "react-query";

import { getQuotersAdmins } from "../../../api/quoters/getQuotersAdmins";
import { getQuotesHistory } from "../../../api/quoters/getQuotesHistory";
import {
  deleteWeeTrustDocument,
  getUploadFileUrl,
  getWeeTrustDocument,
  regeneratedWeeTrustSignUrl,
  resendWeeTrustdSignDocumentEmail,
  resetWeeTrustDocument,
  sendDocumentToSign,
  uploadFile
} from "../../../api/utilsApi";
import PdfCtc from "../../../components/InfoFields/PdfCtc";
import Loading from "../../../components/loading/Loading";
import CotizerModal from "../../../components/Modals/CotizerModal/CotizerModal";
import OfferPdf from "../../../components/pdfs/Pdf";
import LoadingContext from "../../../context/Loading";
import TokenContext from "../../../context/Token";
import { stringToCurrency } from "../../../helpers/currencies";
import getOffer from "../../../helpers/offerCalculation";
import { useModal } from "../../../hooks/useModal";
import InfoOffer from "./InfoOffer";
import QuoterHistoryModal from "./QuoterHistoryModal";

function capitalizeFirstLetter(string) {
  if (string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
  } else {
    return "";
  }
}

function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const DialogQuoterAdmins = ({
  open,
  handleClose,
  setValidatorUser,
  sendPdfOfferToSign,
  adminData,
  setAdminData
}) => {
  const { token } = useContext(TokenContext);
  const { data: { data: { data: adminsList } = {} } = {}, isLoading } =
    useQuery([], () => getQuotersAdmins(token));

  if (isLoading)
    return (
      <Grid
        item
        columnSpacing={2}
        style={{
          position: "absolute",
          width: "100%"
        }}
      >
        <Loading />
      </Grid>
    );

  return (
    <Dialog open={open} onClose={handleClose} PaperProps={{}}>
      <DialogTitle>Visto bueno</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Por favor, selecciona al admin que dará el visto bueno de la oferta y
          firma
        </DialogContentText>
        <Autocomplete
          id="combo-box-demo"
          options={adminsList?.map((admin) => ({
            label: admin.email,
            id: admin?._id
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Administrador"
              name="adminEmail"
              autoComplete="off"
            />
          )}
          inputValue={adminData.adminEmail || ""}
          onChange={(event, newValue) => {
            console.log(newValue);
            setAdminData({ adminEmail: newValue?.label });
            setValidatorUser({ id: newValue?.id, email: newValue?.label });
          }}
        />
      </DialogContent>
      <DialogActions style={{ gap: "32px" }}>
        <Button onClick={handleClose} variant="text">
          Cancelar
        </Button>
        <Button onClick={sendPdfOfferToSign} disabled={!adminData.adminEmail}>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const InfoOffers = ({
  propertyId,
  mBuilding,
  quote,
  offer,
  interval,
  debtCredit,
  debtsRef,
  offerPercentage,
  netOffer,
  status,
  offerAccepted = null,
  refetch,
  lastQuote,
  refetchCurrentProperty,
  property
}) => {
  const { appLoading, setAppLoading } = useContext(LoadingContext);
  const { token } = useContext(TokenContext);
  const { open, onOpenModal, onCloseModal } = useModal();
  const [showValidatorDialog, setShowValidatorDialog] = useState(false);
  const [validatorUser, setValidatorUser] = useState({ email: "", id: "" });

  const [adminData, setAdminData] = useState({
    adminEmail: ""
  });
  const {
    open: openQuoteHistoryModal,
    onOpenModal: onOpenQuoteHistoryModal,
    onCloseModal: onCloseQuoteHistoryModal
  } = useModal();

  const {
    data,
    isLoading,
    refetch: refetchQuotesHistory
  } = useQuery(
    ["history", propertyId],
    () => getQuotesHistory(token, propertyId),
    { cacheTime: 0 }
  );

  const {
    data: { data: { data: adminsList } = {} } = {},
    isLoadingAdminsList
  } = useQuery([], () => getQuotersAdmins(token));

  const parsedDebts = {};
  let debtsInterests = 0;

  if (property?.lastQuoteRef?.quoteData?.debts) {
    for (const debt of property.lastQuoteRef.quoteData.debts) {
      parsedDebts[debt?.type] = debt?.amount;
      debtsInterests += debt?.amount * debt?.rate;
    }
  }

  const PdfDocument = (
    <OfferPdf
      data={{
        updatedAt: property?.lastQuoteRef?.updatedAt,
        quoteId: property?.lastQuoteRef?.fileNumber,
        name: capitalizeFirstLetter(property?.sellerRef?.name),
        firstName: capitalizeFirstLetter(property?.sellerRef?.fLastName),
        lastName: capitalizeFirstLetter(property?.sellerRef?.mLastName),
        streetAndNumber: capitalizeFirstLetter(property?.address?.street),
        settlement: capitalizeFirstLetter(property?.settlement),
        postalCode: property?.postalCode,
        city: property?.city,
        state: property?.state,
        netOffer: property?.lastQuoteRef?.quoteData?.netOffer,
        grossOffer: property?.lastQuoteRef?.quoteData?.grossOffer,
        taxes:
          property?.lastQuoteRef?.quoteData?.cfdi +
          property?.lastQuoteRef?.quoteData?.creditTaxes,
        debts: {
          creditAmount:
            property?.lastQuoteRef?.quoteData?.creditAmount +
            property?.lastQuoteRef?.quoteData?.creditAmount *
              property?.lastQuoteRef?.quoteData?.creditAmountRate,
          ...parsedDebts
        },
        rehabilitation: property?.lastQuoteRef?.quoteData?.rehabilitation,
        debtsInterests,
        debtCreditAmount: property?.lastQuoteRef?.quoteData?.creditAmount,
        creditInterests:
          property?.lastQuoteRef?.quoteData?.creditAmount *
          property?.lastQuoteRef?.quoteData?.creditAmountRate,
        advancedPaymentAmount:
          property?.lastQuoteRef?.quoteData?.advancedPaymentAmount,
        advancedPaymentInterests:
          property?.lastQuoteRef?.quoteData?.advancedPaymentAmount *
          property?.lastQuoteRef?.quoteData?.advancedPaymentRate,
        rangeDays: property?.lastQuoteRef?.quoteData?.rangeDays
      }}
    />
  );

  const PdfCtcDocument = (
    <PdfCtc
      data={{
        updatedAt: property?.lastQuoteRef?.updatedAt,
        fileNumber: property?.lastQuoteRef?.fileNumber,
        clientData: {
          name: capitalizeFirstLetter(property?.sellerRef?.name),
          fLastName: capitalizeFirstLetter(property?.sellerRef?.fLastName),
          mLastName: capitalizeFirstLetter(property?.sellerRef?.mLastName)
        },
        rangeDays: property?.lastQuoteRef?.quoteData?.rangeDays,
        grossOffer: property?.lastQuoteRef?.quoteData?.grossOffer,
        debtCreditAmount: property?.lastQuoteRef?.quoteData?.creditAmount,
        rehabilitation: property?.lastQuoteRef?.quoteData?.rehabilitation,
        debts: {
          mantenimiento: property?.lastQuoteRef?.quoteData?.debts?.find(
            (deb) => deb.type === "mantenimiento"
          )?.amount,
          agua: property?.lastQuoteRef?.quoteData?.debts?.find(
            (deb) => deb.type === "agua"
          )?.amount,
          luz: property?.lastQuoteRef?.quoteData?.debts?.find(
            (deb) => deb.type === "luz"
          )?.amount,
          predial: property?.lastQuoteRef?.quoteData?.debts?.find(
            (deb) => deb.type === "predial"
          )?.amount
        },
        advancedPaymentAmount:
          property?.lastQuoteRef?.quoteData?.advancedPaymentAmount,
        creditInterests:
          property?.lastQuoteRef?.quoteData?.creditAmount *
          property?.lastQuoteRef?.quoteData?.creditAmountRate,
        debtsInterests,
        advancedPaymentInterests:
          property?.lastQuoteRef?.quoteData?.advancedPaymentAmount *
          property?.lastQuoteRef?.quoteData?.advancedPaymentRate,
        taxes:
          property?.lastQuoteRef?.quoteData?.cfdi +
          property?.lastQuoteRef?.quoteData?.creditTaxes,
        netOffer: property?.lastQuoteRef?.quoteData?.netOffer,
        commercialPrice: property?.lastQuoteRef?.quoteData?.commercialPrice,
        streetAndNumber: property?.address?.street,
        settlement: property?.lastQuoteRef?.settlement,
        postalCode: property?.lastQuoteRef?.postalCode,
        city: property?.city,
        state: property?.state,
        rooms: property?.lastQuoteRef?.quoteData?.rooms,
        baths: property?.lastQuoteRef?.quoteData?.baths,
        mBuilding: property?.lastQuoteRef?.quoteData?.metersBuild,
        mLand: property?.address?.mLand,
        parkingLot: property?.address?.parkingLot || 0,
        levels: property?.address?.levels || 0,
        floor: property?.address?.floor || 0,
        notes: property?.notes || "",
        driveUrl: property?.driveUrl || "",
        locationUrl: property?.locationUrl || "",
        inspectionUrl: property?.inspectionUrl || "",
        adviser: property?.lastQuoteRef?.advicerRef,
        leader: property?.lastQuoteRef?.leaderRef
      }}
    />
  );

  const sendPdfOfferToSign = async () => {
    setAppLoading(true);
    try {
      setShowValidatorDialog(false);
      setValidatorUser({ email: "", id: "" });
      await handleFile();
      setAdminData({ adminEmail: "" });
      await refetchCurrentProperty();
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoading(false);
    }
  };

  const handleFile = async () => {
    const blob = await pdf(PdfDocument).toBlob();

    console.log(blob);

    const path = "cdn/tmp/quotes/" + property?.lastQuoteRef?._id;

    const { url, signedUrl } = await getUploadFileUrl({
      token,
      path,
      fileName:
        property?.sellerRef?.name?.toLowerCase().replaceAll(" ", "_") + ".pdf"
    });

    await uploadFile(signedUrl, blob);
    await sendDocumentToSign(token, {
      name: property?.sellerRef?.name,
      fLastName: property?.sellerRef?.fLastName,
      mLastName: property?.sellerRef?.mLastName,
      email: property?.sellerRef?.email,
      quoteId: property?.lastQuoteRef?._id,
      fileKey: url.replace("cdn/", ""),
      validatorId: validatorUser?.id,
      validatorEmail: validatorUser?.email
    });
    return path;
  };

  const downloadWeeTrustDocument = async () => {
    setAppLoading(true);

    try {
      const { documentUrl } = await getWeeTrustDocument(
        token,
        property?.lastQuoteRef?._id
      );

      await fetch(documentUrl, { mode: "cors" })
        .then((res) => res.blob())
        .then((data) => {
          var a = document.createElement("a");

          a.href = window.URL.createObjectURL(data);
          a.target = "_blank";
          a.download = "weeTrust.pdf";
          a.click();
        });
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoading(false);
    }
  };

  const resendSignDocumentEmail = async () => {
    setAppLoading(true);

    try {
      const res = await resendWeeTrustdSignDocumentEmail(
        token,
        property?.lastQuoteRef?.signedDoc?.documentId
      );

      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoading(false);
    }
  };

  const regeneratedSignUrl = async () => {
    setAppLoading(true);

    try {
      const res = await regeneratedWeeTrustSignUrl(
        token,
        property?.lastQuoteRef?.signedDoc?.documentId
      );

      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoading(false);
    }
  };

  const deleteDocument = async () => {
    setAppLoading(true);

    try {
      const res = await deleteWeeTrustDocument(
        token,
        property?.lastQuoteRef?.signedDoc?.documentId
      );

      refetchCurrentProperty();
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoading(false);
    }
  };

  const resetDocument = async () => {
    setAppLoading(true);

    try {
      const res = await resetWeeTrustDocument(
        token,
        property?.lastQuoteRef?.signedDoc?.documentId
      );

      wait(2000);

      refetchCurrentProperty();
    } catch (error) {
      console.log(error);
    } finally {
      setAppLoading(false);
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "QUOTE_SENDED":
        return "Oferta enviada al cliente";
      case "QUOTE_REJECTED":
        return "Oferta sin visto bueno";
      case "QUOTE_SIGNED_REJECTED":
        return "Oferta firmada sin visto bueno";
      case "QUOTE_SIGNED_APPROVED":
        return "Oferta firmada aprobada";
      case "QUOTE_REVISION":
        return "Oferta en revisión.";
      case "QUOTE_SIGNED":
        return "Oferta Firmada.";
      default:
        break;
    }
  };

  return (
    <>
      <Grid item xs={12} flexDirection={"row"}>
        <Typography
          variant="h4"
          color="primary"
          textAlign={"left"}
          fontWeight={600}
        >
          Oferta
        </Typography>
        <Grid
          container
          item
          xs={12}
          justifyContent={"flex-end"}
          direction={"row"}
          gap={2}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 0 }}
            startIcon={<ArrowDownwardIcon />}
            disabled={!property?.lastQuoteRef}
          >
            <PDFDownloadLink
              style={{ textDecoration: "none", color: "#fff" }}
              document={PdfCtcDocument}
              fileName={`oferta.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Cargando..." : "Detalle Oferta"
              }
            </PDFDownloadLink>
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 0 }}
            startIcon={<ArrowDownwardIcon />}
            disabled={!property?.lastQuoteRef}
          >
            <PDFDownloadLink
              style={{ textDecoration: "none", color: "#fff" }}
              document={PdfDocument}
              fileName={`oferta.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Cargando..." : "Descargar Oferta"
              }
            </PDFDownloadLink>
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 0 }}
            onClick={onOpenModal}
            startIcon={<EditIcon />}
            disabled={!property?.lastQuoteRef}
          >
            Actualizar oferta
          </Button>
          {!property?.lastQuoteRef && (
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 0 }}
              onClick={onOpenModal}
              startIcon={<EditIcon />}
            >
              Crear oferta
            </Button>
          )}
        </Grid>
      </Grid>

      <InfoOffer quote={lastQuote} />
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          {property?.lastQuoteRef && (
            <Button
              variant="outlined"
              onClick={onOpenQuoteHistoryModal}
              size="large"
              startIcon={<HistoryIcon />}
              style={{ color: "#F1592A" }}
            >
              Historial
            </Button>
          )}
        </Grid>
      </Grid>
      <Divider
        style={{
          color: "red",
          backgroundColor: "grey",
          height: "2px",
          marginTop: "2em",
          marginBottom: "2em"
        }}
      />
      <Grid container direction={"column"}>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="primary"
              textAlign={"left"}
              fontWeight={600}
            >
              Oferta firmada
            </Typography>
          </Grid>
          <Grid container item xs={12} gap={2} justifyContent={"flex-end"}>
            <Button
              size="medium"
              variant="text"
              color="primary"
              startIcon={<DeleteForeverIcon />}
              onClick={deleteDocument}
              disabled={
                !property?.lastQuoteRef?.signedDoc?.documentId ||
                property?.lastQuoteRef?.signedDoc?.signedDate
              }
            >
              Eliminar Documento
            </Button>
            <Button
              size="medium"
              variant="text"
              color="primary"
              startIcon={<SendIcon />}
              onClick={resendSignDocumentEmail}
              disabled={
                !property?.lastQuoteRef?.signedDoc?.documentId ||
                property?.lastQuoteRef?.signedDoc?.signedDate ||
                property?.lastQuoteRef?.signedDoc?.status !== "QUOTE_SENDED"
              }
            >
              Reenviar correo firma
            </Button>
            {/* <Button
                size="medium"
                variant="text"
                color="primary"
                startIcon={<SendIcon />}
                onClick={regeneratedSignUrl}
                disabled={
                  property?.lastQuoteRef?.signedDoc?.documentUrlExpiration &&
                  new Date(
                    property?.lastQuoteRef?.signedDoc?.documentUrlExpiration
                  ) > new Date()
                }
              >
                Regenerar url firma
              </Button> */}
            {property?.lastQuoteRef?.signedDoc?.status ===
              "QUOTE_SIGNED_REJECTED" ||
            property?.lastQuoteRef?.signedDoc?.status ===
              "QUOTE_SIGNED_APPROVED" ||
            property?.lastQuoteRef?.signedDoc?.status === "QUOTE_SIGNED" ? (
              <Button
                size="medium"
                variant="text"
                color="primary"
                startIcon={<SendIcon />}
                onClick={resetDocument}
              >
                Reiniciar firma
              </Button>
            ) : (
              <Button
                size="medium"
                variant="text"
                color="primary"
                startIcon={<SendIcon />}
                /* onClick={sendPdfOfferToSign} */
                onClick={() => {
                  setShowValidatorDialog(true);
                }}
                disabled={
                  property?.lastQuoteRef?.signedDoc?.documentId ||
                  !property?.lastQuoteRef
                }
              >
                Enviar a revisión
              </Button>
            )}

            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<ArrowDownwardIcon />}
              onClick={downloadWeeTrustDocument}
              disabled={
                !property?.lastQuoteRef?.signedDoc?.documentId ||
                !property?.lastQuoteRef?.signedDoc?.signedDate
              }
            >
              Descargar oferta firmada
            </Button>
          </Grid>
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              className="blue-text"
              textAlign={"left"}
              fontWeight={600}
            >
              Status de oferta:{" "}
              <Typography
                variant="body1"
                color="secondary"
                component={"span"}
                fontSize={20}
                fontWeight={600}
              >
                {property?.lastQuoteRef?.signedDoc?.status && offer
                  ? getStatusText(property?.lastQuoteRef?.signedDoc?.status)
                  : "Aún no se envía la oferta."}
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              className="blue-text"
              textAlign={"left"}
              fontWeight={600}
            >
              Visto bueno por:{" "}
              <Typography
                variant="body1"
                color="secondary"
                component={"span"}
                fontSize={20}
                fontWeight={600}
              >
                {property?.lastQuoteRef?.signedDoc?.validatorRef?.email}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Grid item>
            <Typography
              variant="h6"
              className="blue-text"
              textAlign={"left"}
              fontWeight={600}
            >
              Fecha de firma:{" "}
              <Typography
                variant="body1"
                color="secondary"
                component={"span"}
                fontSize={20}
                fontWeight={600}
              >
                {property?.lastQuoteRef?.signedDoc?.signedDate
                  ? new Date(
                      property?.lastQuoteRef?.signedDoc?.signedDate
                    ).toLocaleString("es-mx")
                  : "---"}
              </Typography>
            </Typography>
          </Grid>
          <Grid item>
            {/* <Link
                href={property?.lastQuoteRef?.signedDoc?.documentUrl}
                target="_blank"
                rel="noopener"
              >
                <Button
                  size="medium"
                  variant="text"
                  color="primary"
                  startIcon={<LinkIcon />}
                  disabled={!property?.lastQuoteRef?.signedDoc?.documentId}
                >
                  Url firma WeeTrust
                </Button>
              </Link> */}
          </Grid>
        </Grid>
      </Grid>

      <QuoterHistoryModal
        open={openQuoteHistoryModal}
        onCloseModal={onCloseQuoteHistoryModal}
        lastQuote={lastQuote}
        propertyId={propertyId}
        data={data}
        isLoading={isLoading}
      />

      {open && (
        <CotizerModal
          open={open}
          onCloseModal={onCloseModal}
          lastQuote={lastQuote}
          refetchCurrentProperty={refetchCurrentProperty}
          refetchQuotesHistory={refetchQuotesHistory}
          propertyUser={property?.sellerRef}
          property={property}
        />
      )}

      <DialogQuoterAdmins
        open={showValidatorDialog}
        handleClose={() => setShowValidatorDialog(false)}
        setValidatorUser={setValidatorUser}
        sendPdfOfferToSign={sendPdfOfferToSign}
        adminData={adminData}
        setAdminData={setAdminData}
        /* handleClose={handleClose}
          setAllowedByAdmin={setAllowedByAdmin}
          setSuccess={setSuccess}
          setAllowedByAdminRef={setAllowedByAdminRef} */
      />
    </>
  );
};

export default InfoOffers;
