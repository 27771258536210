import React from "react";

import { stringToCurrency } from "../../helpers/currencies";

export const AppBuyRequestTile = ({
  buyerRef,
  propertyRef,
  adviserRef,
  ...props
}) => {
  const address = `${propertyRef?.address?.street ?? ""} ${
    propertyRef?.address?.numExt ?? ""
  } ${propertyRef?.settlement ?? ""}`;

  return (
    <tr>
      <td>{propertyRef?._id ?? "Sin id"}</td>
      <td>{stringToCurrency(props.offer)}</td>
      <td style={{ width: "15%" }}>
        <p className="deed-text">
          <b>Nombre:</b> {buyerRef?.name}
        </p>
        <p className="deed-text">
          <b>Correo:</b> {buyerRef?.email}
        </p>
        <p className="deed-text">
          <b>Teléfono:</b> {buyerRef?.phoneNumber}
        </p>
        <p className="deed-text">
          <b>Casado:</b> {buyerRef?.married ? "Si" : "No"}
        </p>
        <p className="deed-text">
          <b>Tipo de cŕedito:</b> Infonavit
        </p>
      </td>
      <td style={{ width: "30%" }}>
        <p className="deed-text">
          <b>Estado:</b> {propertyRef?.state}
        </p>
        <p className="deed-text">
          <b>Ubicación:</b> {address}
        </p>
        <p className="deed-text">
          <b>Asesor asignado:</b>{" "}
          {adviserRef?.name ?? propertyRef?.saleAdviserRef?.name}
        </p>
      </td>
      <td>Facebook Ats</td>
      {props.children}
    </tr>
  );
};
