import moment from "moment/moment";
import { useState, useContext } from "react";

import TokenContext from "../../../context/Token";
import FirmConfirmation from "./FirmConfirmation";
import UpdateFirmDate from "./UpdateFirmDate";

//PROPS DEL COMPONENTE: id={props.property._id} refetchProperties
const ModalFirma = (props) => {
  const { token } = useContext(TokenContext);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      id={"modalFirma" + props.id}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-gray fw-bold">Datos de la firma</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <UpdateFirmDate
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              id={props.id}
              token={token}
              firmDate={props.firmDate ?? ""}
              refetchProperties={props.refetchProperties}
            />

            {props.firmDate &&
              moment(props.firmDate).isSameOrBefore(moment().format()) && (
                <FirmConfirmation
                  setSuccessMessage={setSuccessMessage}
                  setErrorMessage={setErrorMessage}
                  id={props.id}
                  token={token}
                  refetchProperties={props.refetchProperties}
                />
              )}
          </div>
          <div className="modal-footer">
            <div className="d-flex">
              {successMessage && (
                <p className="bg-success text-white py-1 px-5 rounded mx-4 fw-bold m-0 text-center">
                  {successMessage}
                </p>
              )}
              {errorMessage && (
                <p className="bg-danger text-white py-1 px-5 rounded mx-4 fw-bold m-0 text-center">
                  {errorMessage}
                </p>
              )}

              <button
                type="button"
                className="btn-fill-orange mb-1"
                data-bs-dismiss="modal"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFirma;
