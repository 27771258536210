export const formulaCTC = (offer, rangeDays) => {
  // const equalizer =
  //   rangeDays === 365
  //     ? 0
  //     : rangeDays <= 360 && rangeDays >= 330
  //     ? rangeDays / 365
  //     : rangeDays <= 329 && rangeDays >= 300
  //     ? rangeDays / 40
  //     : rangeDays <= 299 && rangeDays >= 270
  //     ? rangeDays / 24
  //     : rangeDays <= 269 && rangeDays >= 240
  //     ? rangeDays / 14
  //     : rangeDays <= 239 && rangeDays >= 210
  //     ? rangeDays / 10
  //     : rangeDays <= 209 && rangeDays >= 180
  //     ? rangeDays / 7
  //     : rangeDays <= 179 && rangeDays >= 150
  //     ? rangeDays / 5
  //     : rangeDays <= 149 && rangeDays >= 120
  //     ? rangeDays / 3.4
  //     : rangeDays <= 119 && rangeDays >= 90
  //     ? rangeDays / 2.2
  //     : rangeDays <= 89 && rangeDays >= 60
  //     ? rangeDays / 1.25
  //     : rangeDays <= 59 && rangeDays >= 30
  //     ? rangeDays * 1.8
  //     : rangeDays * 50;
  const result = Math.floor(offer - offer * 0.000712 * (365 - rangeDays));

  return result;
};
