import { useContext } from "react";
import { MdModeEditOutline } from "react-icons/md";

import { updateObservations } from "../api/Observations/updateObservations";
import TokenContext from "../context/Token";
import { getDateAndTime } from "../helpers/dates";
import EditObservation from "./Proceeding/EditObservation";

//PROPS DEL COMPONENTE property, editable, lockBadges
const Observations = (props) => {
  const { token } = useContext(TokenContext);

  const completeTask = async (observationId, taskState) => {
    const response = await updateObservations(token, observationId, {
      propertyRef: props.property._id,
      completed: !taskState
    });

    if (response?.status === 200) {
      props.refetchProperties();
    }
  };

  return (
    <>
      {props.editable ? (
        <div style={{ height: 120, maxHeight: 150, overflowY: "auto" }}>
          {props.property?.observationsRef
            ?.map((observation) => {
              return (
                <article
                  key={observation._id}
                  className="py-2 d-flex"
                  style={{ borderBottom: "1px solid #b3abab" }}
                >
                  <div>
                    <p className="m-0">{observation.observation}</p>
                    <small>
                      <label
                        className="property-title"
                        style={{ fontSize: 12 }}
                      >
                        Creado:
                        <label className="ms-2 value-title">
                          {getDateAndTime(observation.createdAt) ?? ""}
                        </label>
                      </label>

                      {/* <label
                        className="property-title"
                        style={{ fontSize: 12 }}
                      >
                        Actualizado:
                        <label className="ms-2 value-title">
                          {getDateAndTime(observation.updatedAt) ?? ""}
                        </label>
                      </label> */}
                    </small>
                  </div>

                  <h4 className="text-orange">
                    <MdModeEditOutline
                      data-bs-toggle="modal"
                      data-bs-target={"#modalComment" + observation._id}
                      style={{ cursor: "pointer" }}
                    />
                  </h4>
                  <EditObservation
                    id={observation._id}
                    propertyId={props.property._id}
                    observation={observation.observation ?? ""}
                    refetch={props.refetchProperties}
                  />
                </article>
              );
            })
            .reverse()}
        </div>
      ) : (
        <div style={{ maxHeight: "120px", overflowY: "auto" }}>
          {props.property?.observationsRef?.map((observation) => {
            return (
              <article
                key={observation._id}
                className="py-2"
                style={{ borderBottom: "1px solid #b3abab" }}
              >
                <p className="m-0">{observation.observation}</p>
                <small>
                  <label className="property-title">
                    Creado:
                    <label className="ms-2 value-title">
                      {getDateAndTime(observation.createdAt) ?? ""}
                    </label>
                  </label>

                  <label className="property-title">
                    Actualizado:
                    <label className="ms-2 value-title">
                      {getDateAndTime(observation.updatedAt) ?? ""}
                    </label>
                  </label>
                </small>
                {props.lockBadges ? (
                  <span
                    className={`ms-3 badge ${
                      observation.completed ? "bg-success" : "bg-secondary"
                    }`}
                    // onClick={() => completeTask(observation._id, observation.completed)}
                  >
                    {observation.completed ? "Hecho" : "Pendiente"}
                  </span>
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    className={`ms-3 badge ${
                      observation.completed ? "bg-success" : "bg-secondary"
                    }`}
                    onClick={() =>
                      completeTask(observation._id, observation.completed)
                    }
                  >
                    {observation.completed ? "Hecho" : "Pendiente"}
                  </span>
                )}
              </article>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Observations;
