import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { updateObservations } from "../../../api/Observations/updateObservations";
import TokenContext from "../../../context/Token";

const EditObservation = ({ open, onOpen, onClose, obs, refetch }) => {
  const { propertyId } = useParams();
  const { token } = useContext(TokenContext);
  const [newObs, setNewObs] = useState(obs.observation ?? "");
  const [success, setSuccess] = useState(false);

  const sendData = async () => {
    try {
      const response = await updateObservations(token, obs._id, {
        propertyRef: propertyId,
        observation: newObs
      });

      // console.log(response);
      setSuccess(true);
      refetch();
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={"edit-observation"}
      maxWidth={"md"}
    >
      <DialogTitle id={"edit-observation-title"}>
        Editar Observación
      </DialogTitle>
      <DialogContent sx={{ width: { xs: 300, md: 500 } }}>
        <TextField
          multiline
          fullWidth
          id="observation"
          label="Editar Observación"
          value={newObs}
          onChange={(e) => setNewObs(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        {success && <Alert severity="success">Actualizado</Alert>}
        <Button variant="contained" sx={{ mr: 2 }} onClick={sendData}>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditObservation;
