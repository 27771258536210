import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getSettlements = async ({ state = "", city = "" }) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/states/settlements?state=${state}&city=${city}`
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const myError = {
      errors: err?.errors ?? [],
      message: err?.message ?? "Error en la petición"
    };

    return Promise.reject(myError);
  }
};
