import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SearchIcon from "@mui/icons-material/Search";
import ShowerIcon from "@mui/icons-material/Shower";
import SignpostIcon from "@mui/icons-material/Signpost";
import StraightenIcon from "@mui/icons-material/Straighten";
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import { forwardRef } from "react";
import { NumericFormat } from "react-number-format";

import Loading from "../../components/loading/Loading";
import { stringToCurrency } from "../../helpers/currencies";

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      valueIsNumericString
      allowNegative={false}
    />
  );
});

const PostalCodeDisplay = ({
  postalCode,
  setPostalCode,
  searchPostalCodePrice,
  searchPostalCodeDetails,
  clearFields,
  loading,
  settlements,
  setSettlements,
  setQuoteData,
  quoteData,
  emptyResponse,
  grossOffer,
  stockPrice,
  averagePricePerMeter,
  averagePriceCombinationsRef,
  lastQuote
}) => {
  return (
    <>
      <Grid container columnSpacing={2} style={{ position: "relative" }}>
        {loading && (
          <Grid
            item
            columnSpacing={2}
            style={{
              position: "absolute",
              width: "100%"
            }}
          >
            <Loading />
          </Grid>
        )}
        <>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="medium"
                id="postalCode"
                label={
                  <Typography variant="headline" component="h5">
                    Código Postal
                  </Typography>
                }
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                  setSettlements([]);
                  emptyResponse(false);
                }}
                inputProps={{ maxLength: 5 }}
                disabled={(averagePricePerMeter && !loading) || emptyResponse}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              size="medium"
              id="mBuilding"
              name={"mBuilding"}
              value={quoteData.mBuilding}
              onChange={(e) =>
                setQuoteData({ ...quoteData, mBuilding: e.target.value })
              }
              InputProps={{
                inputComponent: NumericFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    <StraightenIcon />
                  </InputAdornment>
                )
              }}
              label={
                <Typography variant="headline" component="h5">
                  M² Const.
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="medium"
              select
              id="type"
              name="type"
              label={
                <Typography variant="headline" component="h5">
                  Tipo inmueble
                </Typography>
              }
              value={quoteData.type}
              onChange={(e) =>
                setQuoteData({ ...quoteData, type: e.target.value })
              }
              style={{ width: "100%" }}
              disabled={!quoteData.selectedSettlement}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MapsHomeWorkIcon />
                  </InputAdornment>
                )
              }}
            >
              <MenuItem value={"casa"}>Casa</MenuItem>
              <MenuItem value={"depa"}>Departamento</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              size="medium"
              select
              id="settlements"
              name="settlements"
              label={
                <Typography variant="headline" component="h5">
                  Colonia
                </Typography>
              }
              value={quoteData.selectedSettlement}
              onChange={(e) =>
                setQuoteData({
                  ...quoteData,
                  selectedSettlement: e.target.value
                })
              }
              disabled={
                !settlements ||
                settlements.length === 0 ||
                (averagePricePerMeter && !loading) ||
                emptyResponse ||
                grossOffer
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SignpostIcon />
                  </InputAdornment>
                )
              }}
            >
              {settlements.map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              select
              size="medium"
              id="rooms"
              label={
                <Typography variant="headline" component="h5">
                  Cuartos
                </Typography>
              }
              type="number"
              disabled={!quoteData.selectedSettlement}
              value={quoteData.rooms}
              onChange={(e) =>
                setQuoteData({ ...quoteData, rooms: e.target.value })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MeetingRoomIcon />
                  </InputAdornment>
                )
              }}
            >
              <MenuItem value={""}>Omitir</MenuItem>
              {[1, 2, 3, 4, 5].map((number, index) => {
                return (
                  <MenuItem value={String(number)} key={index}>
                    {number} Cuartos{" "}
                    {averagePriceCombinationsRef?.current?.rooms?.includes(
                      number
                    ) && "(Con datos)"}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              select
              fullWidth
              size="medium"
              id="baths"
              label={
                <Typography variant="headline" component="h5">
                  Baños
                </Typography>
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ShowerIcon />
                  </InputAdornment>
                )
              }}
              type="number"
              disabled={!quoteData.selectedSettlement}
              value={quoteData.baths}
              onChange={(e) =>
                setQuoteData({ ...quoteData, baths: e.target.value })
              }
            >
              <MenuItem value={""}>Omitir</MenuItem>
              {[1, 2, 3, 4, 5].map((number, index) => {
                return (
                  <MenuItem value={String(number)} key={index}>
                    {number} Baños{" "}
                    {averagePriceCombinationsRef?.current?.baths?.includes(
                      number
                    ) && "(Con datos)"}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={searchPostalCodeDetails}
              size="medium"
              disabled={
                Number.isNaN(postalCode) ||
                postalCode.length !== 5 ||
                grossOffer ||
                emptyResponse
              }
              startIcon={<SearchIcon />}
            >
              Buscar Colonias
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={searchPostalCodePrice}
              disabled={
                !settlements ||
                !quoteData.mBuilding ||
                Number.isNaN(quoteData.mBuilding)
              }
              size="medium"
              startIcon={<SearchIcon />}
            >
              Cotizar
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              color="warning"
              onClick={clearFields}
              size="medium"
              startIcon={<CleaningServicesIcon />}
            >
              Limpiar Campos
            </Button>
          </Grid>
        </>
      </Grid>

      {(averagePricePerMeter && !loading) || emptyResponse ? (
        <Grid container mb={2} columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              ml={2}
            >
              <Typography
                variant="h6"
                component={"span"}
                color="secondary"
                fontWeight={"bold"}
              >
                Precio promedio M²:{" "}
              </Typography>
              {averagePricePerMeter
                ? stringToCurrency(averagePricePerMeter)
                : "Sin datos"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              ml={2}
            >
              <Typography
                variant="h6"
                component={"span"}
                color="secondary"
                fontWeight={"bold"}
              >
                Precio comercial:{" "}
              </Typography>
              {grossOffer ? stringToCurrency(grossOffer) : "Sin datos"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              color="secondary"
              textAlign={"start"}
              width={"100%"}
              ml={2}
            >
              <Typography
                variant="h6"
                component={"span"}
                color="secondary"
                fontWeight={"bold"}
              >
                Precio Inventario:{" "}
              </Typography>
              {grossOffer ? stringToCurrency(stockPrice) : "Sin datos"}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default PostalCodeDisplay;
