import { Button } from "@mui/material";
import { Link, useNavigate, useRoutes } from "react-router-dom";

import { calcOffer } from "../../helpers/calcOffer";
import { stringToCurrency } from "../../helpers/currencies";

const getPropertyUrl = (id) => {
  const { REACT_APP_ENV } = process.env;

  console.log(REACT_APP_ENV);
  switch (REACT_APP_ENV) {
    case "development":
      return "https://dev.ctc-pruebas.com/tienda/catalogo/" + id;
    case "production":
      return "https://compramostucasa.mx/tienda/catalogo/" + id;
    default:
      return "https://compramostucasa.mx/tienda/catalogo/" + id;
  }
};

const TableInventory = (props) => {
  const navigate = useNavigate();

  return (
    <tbody>
      {props.properties?.docs?.map((property) => {
        return (
          <tr key={property._id}>
            <td>
              <label className="property-title m-0">
                <a
                  href={getPropertyUrl(property?._id)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {property._id}
                </a>
              </label>
            </td>
            <td>
              <label className="value-title">{property.invetoryStatus}</label>
            </td>
            <td>
              <label className="value-title">
                {stringToCurrency(property.offer) ?? ""}
              </label>
            </td>
            <td>
              <label className="value-title">{property.state || ""}</label>
            </td>
            <td>
              <label className="value-title">
                <label className="value-title">
                  {property.city + ", " + property.settlement || ""}
                </label>
              </label>
            </td>
            <td>
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    "/ctc/dashboard/inventario?propertyId=" + property._id,
                    { replace: true }
                  );
                }}
              >
                Ver Más
              </Button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableInventory;
