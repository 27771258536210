const ClientInfo = (props) => {
  return (
    <div className="info-extend text-start">
      <label className="property-title">
        Nombre:{" "}
        <label className="value-title">
          {props.sellerRef?.name ?? ""} {props.sellerRef?.fLastName ?? ""}{" "}
          {props.sellerRef?.mLastName ?? ""}
        </label>
      </label>
      <label className="property-title">
        Correo electronico:{" "}
        <label className="value-title">{props.sellerRef?.email ?? ""}</label>
      </label>
      <label className="property-title">
        Telefono:{" "}
        <label className="value-title">
          {props.sellerRef?.phoneNumber ?? ""}
        </label>
      </label>
    </div>
  );
};

export default ClientInfo;
