import { Button } from "@mui/material";

import FileIntegration from "../../components/Modals/FileIntegration/FileIntegration";
import { useModal } from "../../hooks/useModal";

const DocumentationButton = ({ property, refetch }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Button
        variant="contained"
        sx={{ mt: 0 }}
        onClick={onOpenModal}
        fullWidth
      >
        Editar Documentación
      </Button>
      {open && (
        <FileIntegration
          onClose={onCloseModal}
          onOpen={onOpenModal}
          open={open}
          property={property}
          refetch={refetch}
          readOnly={false}
        />
      )}
    </>
  );
};

export default DocumentationButton;
