import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const updateCurrentDoc = async (token, id, documentations) => {
  try {
    const response = await axios.put(
      REACT_APP_BASE_URL + "/documentations/" + id,
      { documentations },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    return response.data?.documentation ?? [];
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
