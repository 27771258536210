import { Divider, ListItem, ListItemText, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/es";

const NoteItem = ({ note }) => {
  moment.locale("es");
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="secondary"
                fontSize={18}
              >
                {note.description}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                variant="body1"
                component={"span"}
                color="primary"
                fontWeight={500}
                fontSize={13}
              >
                {moment(note.createdAt).format("L LT a")}{" "}
                <Typography
                  component={"span"}
                  variant="body1"
                  color="secondary"
                  fontWeight={500}
                  fontSize={13}
                >
                  Creado en:{" "}
                  {note.status === "Perfilado"
                    ? "Prospectos"
                    : note.status === "Prospectos"
                    ? "Cliente"
                    : note.status === "Expediente"
                    ? "Integración de Expediente"
                    : note.status}
                </Typography>
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider component="li" />
    </>
  );
};

export default NoteItem;
