import AddIcon from "@mui/icons-material/Add";
import { Box, Grid, Typography, TextField, IconButton } from "@mui/material";
import moment from "moment";
import { useState } from "react";

const Conditions = ({ propertyConditions, sendData }) => {
  const [conditions, setConditions] = useState(
    propertyConditions.map((c) => ({
      date: moment(c.date).format("YYYY-MM-DDTHH:mm"),
      text: c.text
    })) || []
  );
  const [newCondition, setNewCondition] = useState({
    date: moment().format("YYYY-MM-DDTHH:mm"),
    text: ""
  });

  const handleChange = (e) => {
    setNewCondition({ ...newCondition, [e.target.name]: e.target.value });
  };

  const handleSave = (e) => {
    conditions.push(newCondition);
    setConditions([...conditions]);
    console.log(conditions);
    sendData(conditions);
  };

  return (
    <Box width={"90%"} margin={"auto"}>
      <Typography variant="h5" color="initial" my={1}>
        Condiciones
      </Typography>
      <Box
        maxHeight={150}
        border={"1px solid #9e9e9e"}
        borderRadius={2}
        overflow={"auto"}
        sx={{
          "::-webkit-scrollbar": { width: "5px" },
          "::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.1)",

            borderRadius: "10px"
          },
          "::-webkit-scrollbar-thumb": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)",
            borderRadius: "10px"
          }
        }}
      >
        {conditions?.map((c, i) => (
          <Grid container key={i._id} borderBottom={"1px solid #9e9e9e"}>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                color="secondary"
                textAlign={"left"}
                ml={1}
                mt={2}
              >
                {c.text}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" color="secondary" ml={3}>
                {c.date}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Grid container spacing={3} mt={2}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            name="text"
            label="Condición"
            value={newCondition.text}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <Box display={"flex"}>
            <TextField
              size="small"
              type="datetime-local"
              name="date"
              value={newCondition.date}
              onChange={handleChange}
            />
            <Box mt={1}>
              <IconButton size="large" color="primary" onClick={handleSave}>
                <AddIcon color="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Conditions;
