export const generalMandatoryDocs = [
  "INE con Validación Lista Nominal",
  "Acta de Nacimiento con Validación",
  "CURP",
  "RFC",
  "Comprobante de domicilio",
  "Escritura completa",
  "Boleta de pago de traslado de dominio",
  "CFDI si es una escritura posterior al 2014",
  "Cartas poder en original (4 juegos)",
  "Oferta firmada",
  "Aviso de privacidad en original",
  "Acuse de recepción de documento en original",
  "Hoja de datos generales"
];

export const marriedCaseMandatoryDocs = [];

export const infonavitMandatoryDocs = [];
