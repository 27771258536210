import { Box, Button, Typography } from "@mui/material";

import DrawerCard from "./DrawerCard";

const DrawerCardsContainer = ({
  paramsArray,
  setParamsArray,
  setShownSection,
  currentParam,
  setCurrentParam
}) => {
  const handleCreateNewParam = () => {
    setCurrentParam({ ...currentParam, criteria: [], reason: "equals" });
    setShownSection("list");
  };
  const handleOr = () => {
    setCurrentParam({
      ...currentParam,
      criteria: [],
      reason: "equals",
      operator: "or"
    });
    setShownSection("list");
  };

  return (
    <Box width={"100%"} p={2}>
      <Typography variant="h5" color="initial" textAlign={"center"} mb={2}>
        Parámetros de búsqueda:
      </Typography>
      <Button onClick={handleCreateNewParam}>Crear nuevo parámetro</Button>
      {paramsArray.map((param, i) => (
        <>
          <DrawerCard
            key={i}
            param={param}
            setShownSection={setShownSection}
            setParamsArray={setParamsArray}
            paramsArray={paramsArray}
            index={i}
            currentParam={currentParam}
            setCurrentParam={setCurrentParam}
          />
          {i === paramsArray.length - 1 && (
            <Button sx={{ border: "1px solid #8D9AAA" }} onClick={handleOr}>
              O
            </Button>
          )}
        </>
      ))}
    </Box>
  );
};

export default DrawerCardsContainer;
