import { Alert } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";

import { updateSellers } from "../../api/seller/updateSellers";
import TokenContext from "../../context/Token";

const EditDataClient = (props) => {
  const { token } = useContext(TokenContext);

  const [success, setSuccess] = useState({ severity: "", text: "" });

  const [sellerInfo, setSellerInfo] = useState({
    name: props.sellerRef?.name ?? "",
    fLastName: props.sellerRef?.fLastName ?? "",
    mLastName: props.sellerRef?.mLastName ?? "",
    phoneNumber: props.sellerRef?.phoneNumber ?? "",
    source: props.sellerRef?.source ?? "",
    email: props.sellerRef?.email ?? "",
    credit: props.sellerRef?.credit ?? "",
    socialSecurity: props.sellerRef?.socialSecurity ?? "",
    married: props.sellerRef?.married ?? false,
    communityProperty: props?.sellerRef?.communityProperty ?? false
  });

  const handleChange = (e) => {
    setSellerInfo({
      ...sellerInfo,
      [e.target.name]: e.target.value
    });
  };

  const sendData = async () => {
    try {
      const response = await updateSellers(
        token,
        props.sellerRef._id,
        sellerInfo
      );

      if (response?.data?.seller_id) {
        setSuccess({ severity: "success", text: "Éxito al guardar cambios" });
        props.refetch();
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setSuccess({ severity: "error", text: error });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <div
      className="modal fade"
      id={"EditDataClient" + props.id}
      tabIndex="-1"
      aria-hidden="true"
      data-bs-backdrop="false"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-gray fw-bold">
              Editar datos del cliente
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="d-flex justify-content-between modal-body fw-bold text-start p-4">
            <div className="d-flex flex-column">
              <label className="mb-2">Nombre(s)*</label>
              <input
                required
                className="mb-4 form-control"
                type="text"
                name="name"
                value={sellerInfo.name}
                onChange={handleChange}
              />
              <label className="mb-2">Correo electrónico*</label>
              <input
                required
                className="mb-4 form-control"
                type="text"
                name="email"
                value={sellerInfo.email}
                onChange={handleChange}
              />
              {/* <label className='mb-2'>Num. Crédito</label>
              <input
                className='mb-4 form-control'
                type="text"
                name='credit'
                value={sellerInfo.credit}
                onChange={handleChange}
              /> */}
              <label className="mb-2">NSS*</label>
              <input
                required
                className="mb-4 form-control"
                type="text"
                name="socialSecurity"
                value={sellerInfo.socialSecurity}
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column">
              <label className="mb-2">Apellido paterno*</label>
              <input
                className="mb-4 form-control"
                type="text"
                name="fLastName"
                value={sellerInfo.fLastName}
                onChange={handleChange}
              />
              <label className="mb-2">Teléfono*</label>
              <input
                className="mb-4 form-control"
                type="text"
                name="phoneNumber"
                value={sellerInfo.phoneNumber}
                onChange={handleChange}
              />

              <label className="mb-2">Estado Civil*</label>
              <select
                className="form-select"
                name="married"
                id="married"
                value={sellerInfo.married}
                onChange={handleChange}
              >
                <option value="" hidden>
                  Seleccionar
                </option>
                <option value={true}>Casado(a)</option>
                <option value={false}>Soltero(a)</option>
              </select>
            </div>
            <div className="d-flex flex-column">
              <label className="mb-2">Apellido materno*</label>
              <input
                className="mb-4 form-control"
                type="text"
                name="mLastName"
                value={sellerInfo.mLastName}
                onChange={handleChange}
              />
              <label className="mb-2">Fuente</label>
              <input
                className="mb-4 form-control"
                type="text"
                name="source"
                value={sellerInfo.source}
                onChange={handleChange}
              />

              <label className="mb-2">Bienes Mancomunados</label>
              <select
                className="form-select"
                name="communityProperty"
                id="communityProperty"
                value={sellerInfo.communityProperty}
                onChange={handleChange}
              >
                <option value="" hidden>
                  Seleccionar
                </option>
                <option value={true}>Si</option>
                <option value={false}>No/No Aplica</option>
              </select>
            </div>
          </div>

          <div className="modal-footer">
            {success.severity === "success" && (
              <Alert severity={success.severity}>{success.text}</Alert>
            )}
            {success.severity === "error" && (
              <Alert severity={success.severity}>{success.text}</Alert>
            )}
            <button
              type="button"
              className="btn-fill-orange my-2"
              onClick={sendData}
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDataClient;
