export const asesores = "asesores"; //lista de asesores

export const citas = "citas-properties"; //propiedades en etapa de cita

export const clientRequiredDocs = "client-required-docs"; //los documentos que necesita entregar el cliente

export const clientUploadedDocs = "client-uploaded-docs"; //documentos que ya subió el cliente

export const currentUser = "current-user"; //info del usuario del dashboard

export const gestion = "gestion-properties"; //propiedades en gestion

export const inspectorList = "inspector-list"; //lista de inspectores

export const rehabilitatorsList = "rehabilitators-list"; //lista de inspectores

export const legalDocs = "legal-docs"; //documentos para checar si son válidos

export const propertyInfo = "property-info"; //informacion de una sola propiedad buscada por id

export const perfilados = "perfilado-properties"; //clientes en etapa de perfilados

export const preSellers = "pre-sellers"; //clientes que no finalizaron la creación de su cuenta

export const prospectosFirma = "prospectos-firma"; //autoexplicativo, se usa en firma y legal

export const prospectosGestion = "prospectos-gestion"; //prospectos en estatus de gestión, se usa en legal

export const prospectosLegal = "prospectos-legal"; //contiene prospectos de firma y gestión

export const reconversionProperties = "reconversion-properties"; //propiedades en reconversión

export const sellerInfo = "seller-info"; //informacion del vendedor

export const sellers = "sellers"; //clientes que no finalizaron la creación de su cuenta

export const states = "states"; //clientes que no finalizaron la creación de su cuenta
