import { stringToCurrency } from "../../helpers/currencies";
import DeleteLead from "../Reconvert/DeleteLead";
import NewOffer from "../Reconvert/NewOffer";
import ReassignAdviser from "../Reconvert/ReassignAdviser";
import ReconvertStatus from "../Reconvert/ReconvertStatus";
import ToHistoryButton from "../ToHistoryButton";

const ReconvertActions = (props) => {
  return (
    <>
      <div className="d-flex justify-content-evenly">
        <ReassignAdviser
          advisers={props.advisers ?? []}
          refetchReconversion={props.refetchReconversion}
          id={props?.propertyRef?._id}
        />

        <DeleteLead
          id={props?.propertyRef?._id}
          refetchReconversion={props.refetchReconversion}
        />
      </div>

      <ToHistoryButton
        id={props?.propertyRef?._id}
        property={props?.propertyRef ?? {}}
      />

      <ReconvertStatus
        status={props?.status ?? ""}
        reconversionId={props._id}
      />
      {props?.propertyRef?.status === "Prospectos" && (
        <>
          <label className="property-title text-left mt-3 mb-0">
            Ultima oferta:
            <label className="value-title">
              {stringToCurrency(
                props?.propertyRef?.offer ?? props?.propertyRef?.quote
              ) ?? ""}
            </label>
          </label>
          <NewOffer
            id={props?.propertyRef?._id}
            reconversionId={props?._id}
            refetchReconversion={props.refetchReconversion}
          />
        </>
      )}
    </>
  );
};

export default ReconvertActions;
