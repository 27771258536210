import { Box } from "@mui/system";

import ImgComponent from "./ImgComponent";

const { REACT_APP_IMAGES_URL } = process.env;
const Gallery = ({ evidence = [] }) => {
  return (
    <Box display={"flex"} width={"100%"} height={180} overflow={"auto"}>
      <Box display={"flex"} width={"fit-content"} height={90}>
        {evidence?.map((ev) => (
          <ImgComponent
            key={ev?._id}
            id={ev?._id}
            img={
              // `${REACT_APP_IMAGES_URL}/${img?.path}`
              ev.photograph
            }
            description={ev?.comments}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Gallery;
