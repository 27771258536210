import { Grid, TextField } from "@mui/material";

const onlyNumbers = /^\d+$/;

const PersonalInfo = ({ user, handleUserInfo }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            fullWidth
            required
            id="name"
            name="name"
            label="Nombre(s)"
            value={user.name}
            onChange={handleUserInfo}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            fullWidth
            required
            id="fLastName"
            name="fLastName"
            label="Apellido paterno"
            value={user.fLastName}
            onChange={handleUserInfo}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            fullWidth
            required
            id="mLastName"
            name="mLastName"
            label="Apellido Materno"
            value={user.mLastName}
            onChange={handleUserInfo}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            type="email"
            fullWidth
            required
            id="email"
            name="email"
            label="Correo electrónico"
            value={user.email}
            onChange={handleUserInfo}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            helperText="10 dígitos, sin guiones ni espacios"
            type="tel"
            inputProps={{ pattern: "[0-9]{10}", maxLength: 10 }}
            fullWidth
            required
            id="phoneNumber"
            name="phoneNumber"
            label="Teléfono móvil"
            value={user.phoneNumber}
            onChange={(e) => {
              if (onlyNumbers.test(e.target.value) || e.target.value === "") {
                handleUserInfo(e);
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalInfo;
