import mapboxgl from "mapbox-gl/";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

// private variable accessible only within current module
let mapboxInstance = null;

const mapInstance = {
  // configure the single instance
  initializeMap: (mapConfigs) => {
    mapboxInstance = new mapboxgl.Map(mapConfigs);
  },
  // retrieve the single instance of the logged in user
  getMap: () => {
    return mapboxInstance;
  }
};

// export the methods so that components can use the single instance
export default mapInstance;

export function renderPopUpMuseum(data) {
  const { postalCode } = data;

  return `
      <div class="popUp">
        <h5>CP: ${postalCode}</h5> 
      </div>`;
}
