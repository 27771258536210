import { AccountCircle } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  Snackbar,
  Switch,
  TextField,
  Typography,
  styled
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { IMaskInput } from "react-imask";
import { NumericFormat } from "react-number-format";
import { useParams } from "react-router-dom";

import { searchPostalCode } from "../../api/postalCode/searchPostalCode";
import { createQuoteToUser } from "../../api/quoters/createQuoteToUser";
import { getQuoters } from "../../api/quoters/getQuoters2";
import { saveQuote } from "../../api/quoters/saveQuote";
import { updateQuote } from "../../api/quoters/updateQuote";
import Pdf from "../../components/InfoFields/Pdf";
import PdfCtc from "../../components/InfoFields/PdfCtc";
import Loading from "../../components/loading/Loading";
import OfferPdf from "../../components/pdfs/Pdf";
import TokenContext from "../../context/Token";
import getOffer from "../../helpers/offerCalculation";
import Debts from "./Debts";
import DialogQuoterAdmins from "./DialogQuoterAdmins";
import SaveQuoteResultDialog from "./DialogQuoterApiResult";
import OfferDisplay from "./OfferDisplay";
import PostalCodeDisplay from "./PostalCodeDisplay";
import QuoterSlider from "./QuoterSlider";

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        "#": /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
    />
  );
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  marginRight: 16,
  marginLeft: 8,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#F1592A" : "#F1592A",
        opacity: 1,
        border: 0
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5
      }
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff"
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3
    }
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500
    })
  }
}));

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const CotizerContainer = ({
  onCloseModal,
  lastQuote,
  refetchCurrentProperty,
  refetchQuotesHistory,
  propertyUser,
  property
}) => {
  const [loadingLastQuote, setLoadingLastQuote] = useState(Boolean(lastQuote));
  const { token } = useContext(TokenContext);
  const { propertyId } = useParams();
  const lastQuoteRef = useRef(Boolean(lastQuote));
  const isValidProfit = useRef(true);
  const quoteCleaned = useRef(false);
  const [rangeDays, setRangeDays] = useState(
    lastQuote?.quoteData?.rangeDays || 0
  );
  const [grossOffer, setGrossOffer] = useState(
    lastQuote?.quoteData?.commercialPrice || ""
  ); //valor inicial al que se le restan cosas
  const [maxOffer, setMaxOffer] = useState(
    lastQuote?.quoteData?.maxOffer || ""
  ); //valor inicial al que se le restan cosas
  const [netOffer, setNetOffer] = useState(
    lastQuote?.quoteData?.netOffer || ""
  ); //valor inicial al que se le restan cosas
  const [updatedNetOffer, setUpdatedNetOffer] = useState(""); //valor inicial al que se le restan cosas
  const [updatedGrossOffer, setUpdatedGrossOffer] = useState(""); //valor inicial al que se le restan cosas
  const [stockPrice, setStockPrice] = useState(
    lastQuote?.quoteData?.commercialPrice + 27500 || ""
  ); //valor inicial al que se le restan cosas
  const [averagePricePerMeter, setAveragePricePerMeter] = useState(
    lastQuote?.averageM2Price || ""
  );
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [totalAll, setTotalAll] = useState(0); //todas las daudas a quitar
  const [totalDebts, setTotalDebts] = useState(0); //todas las daudas a quitar
  const [generatePdf, setGeneratePdf] = useState(false); //lo hice por si se necesitaba info en los state antes de renderizar el componente de pdf, pero no funcionó
  const [loading, setLoading] = useState(false);
  const [calculatedPrice, setcalculatedPrice] = useState(
    lastQuote?.quoteData?.grossOffer || 0
  ); //valor - 10% e intervalo
  const [debtsChecked, setDebtsChecked] = useState(false); //que se vean los adeudos
  const [allowedByAdmin, setAllowedByAdmin] = useState(false); //que se vean los adeudos
  const [allowedByAdminRef, setAllowedByAdminRef] = useState(null); //que se vean los adeudos
  const [postalCode, setPostalCode] = useState(lastQuote?.postalCode || "");
  const [settlements, setSettlements] = useState([]);
  const [emptyResponse, setEmptyResponse] = useState(false);
  const [quoteId, setQuoteId] = useState(null); //lo que llega de consultar para cotizar
  const [fileNumber, setFileNumber] = useState(null); //lo que llega de consultar para cotizar
  const [postalCodeDetails, setPostalCodeDetails] = useState(null);
  const averagePriceCombinations = useRef(null);
  const [holdedNetOffer, setHoldedNetOffer] = useState(
    lastQuote?.quoteData?.holdedNetOffer || 0
  );
  const [quoteData, setQuoteData] = useState({
    baths: lastQuote?.quoteData?.baths || "",
    rooms: lastQuote?.quoteData?.rooms || "",
    type: lastQuote?.type || "depa",
    mBuilding: lastQuote?.quoteData?.metersBuild || "",
    selectedSettlement: lastQuote?.settlement || ""
  });
  const [advancedPayment, setAdvancedPayment] = useState({
    required: lastQuote?.quoteData?.advancedPaymentAmount || "",
    dayOfRequest: lastQuote?.quoteData?.advancedPaymentDay || 0,
    commissionPercentage:
      (lastQuote?.quoteData?.rangeDays > 180 ? 0.3 : 0.15) || 0.15,
    commissionAmount:
      lastQuote?.quoteData?.advancedPaymentAmount *
        lastQuote?.quoteData?.advancedPaymentRate || "",
    total:
      lastQuote?.quoteData?.advancedPaymentAmount *
        lastQuote?.quoteData?.advancedPaymentRate +
        lastQuote?.quoteData?.advancedPaymentAmount || ""
  });
  const [clientData, setClientData] = useState({
    name: propertyUser?.name || "",
    fLastName: propertyUser?.fLastName || "",
    mLastName: propertyUser?.mLastName || "",
    email: propertyUser?.email || "",
    phoneNumber: propertyUser?.phoneNumber || "",
    debtCredit: lastQuote?.quoteData?.creditTaxes
      ? lastQuote?.quoteData?.creditType
      : "false",
    debtCreditSettled: lastQuote?.quoteData?.creditAmount ? true : false,
    debtCreditAmount: lastQuote?.quoteData?.creditAmount || 0,
    debtCreditRate: lastQuote?.quoteData?.creditAmountRate || 0.3,
    taxes: lastQuote?.quoteData?.creditTaxes || 0,
    cfdi: lastQuote?.quoteData?.cfdi ? false : true,
    cfdiCost: lastQuote?.quoteData?.cfdi || 0,
    commission: ""
  });
  const defaultDebtTax = lastQuote?.quoteData?.rangeDays > 180 ? 30 : 0;
  const [debts, setDebts] = useState({
    agua:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "agua")
        ?.amount || "",
    aguaTax:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "agua")?.rate *
        100 || defaultDebtTax,
    luz:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "luz")
        ?.amount || "",
    luzTax:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "luz")?.rate *
        100 || defaultDebtTax,
    predial:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "predial")
        ?.amount || "",
    predialTax:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "predial")
        ?.rate * 100 || defaultDebtTax,
    mantenimiento:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "mantenimiento")
        ?.amount || "",
    mantenimientoTax:
      lastQuote?.quoteData?.debts?.find((debt) => debt.type === "mantenimiento")
        ?.rate * 100 || defaultDebtTax,
    rehabilitation: lastQuote?.quoteData?.rehabilitation || ""
  });

  const [open, setOpen] = useState(false);
  const [saveQuoteResults, setSaveQuoteResults] = useState(null);
  const [openOfferErrorDialog, setOpenOfferErrorDialog] = useState(false);

  const interesDeudas =
    rangeDays > 180
      ? Object.values({
          aguaTax: (debts.agua * debts.aguaTax) / 100,
          luzTax: (debts.luz * debts.luzTax) / 100,
          predialTax: (debts.predial * debts.predialTax) / 100,
          mantenimientoTax: (debts.mantenimiento * debts.mantenimientoTax) / 100
        })
          .filter((d) => d !== "" && Number(d) !== 0)
          .reduce((suma, val) => suma + Number(val), 0)
      : 0;
  const interesLiquidarCredito =
    Number(clientData.debtCreditAmount) * Number(clientData.debtCreditRate);

  useEffect(() => {
    let newNetOffer = Number(
      calculatedPrice -
        totalAll -
        advancedPayment.total -
        totalDebts -
        interesDeudas -
        debts.rehabilitation -
        clientData.debtCreditAmount * clientData.debtCreditRate -
        clientData.debtCreditAmount
    )?.toFixed(2);

    let newHoldedNetOffer = 0;

    /* if (advancedPayment?.required > 0 && rangeDays > 0) {
      newHoldedNetOffer =
        Number(calculatedPrice)?.toFixed(2) *
        (clientData.debtCreditAmount > 0 && clientData.debtCreditSettled
          ? 0.2
          : 0.1);
      setHoldedNetOffer(newHoldedNetOffer?.toFixed(2));
    } */

    setNetOffer(newNetOffer /*  - newHoldedNetOffer */);
  }, [
    advancedPayment?.required,
    advancedPayment.total,
    calculatedPrice,
    clientData.debtCreditAmount,
    clientData.debtCreditRate,
    interesDeudas,
    totalAll,
    totalDebts,
    allowedByAdmin,
    quoteData.mBuilding,
    debts.rehabilitation,
    lastQuote,
    grossOffer,
    rangeDays,
    holdedNetOffer,
    clientData.debtCreditSettled
  ]);

  useEffect(() => {
    console.log("Use Effect calculated price");
    console.log(lastQuoteRef);

    const getPrice = () => {
      setcalculatedPrice(getOffer(Number(grossOffer), rangeDays));
    };

    if (lastQuoteRef.current === false) {
      getPrice();
    }
  }, [rangeDays, grossOffer, allowedByAdmin]);

  useEffect(() => {
    // Set a timeout of 5 seconds
    if (lastQuote) {
      const timer = setTimeout(() => {
        lastQuoteRef.current = false;
        setLoadingLastQuote(false);
      }, 2000);

      return () => clearTimeout(timer);
    }

    // Cleanup function to clear the timeout if the component unmounts
  }, [lastQuote]);

  useEffect(() => {
    const searchSettlementsByPostalCode = async () => {
      const response = await searchPostalCode(lastQuote?.postalCode);

      if (response?.data?.postalCodes && response?.data?.postalCodes[0]?.city) {
        setPostalCodeDetails({
          city: response.data.postalCodes[0].city,
          state: response.data.postalCodes[0].state
        });
        setSettlements(response.data.postalCodes[0].settlements);
      }
    };

    if (quoteData.selectedSettlement !== "" && quoteCleaned.current === false) {
      console.log("Entrye");
      console.log(quoteData);
      console.log(quoteCleaned);
      searchSettlementsByPostalCode();
    }
  }, [
    lastQuote?.postalCode,
    quoteData,
    quoteData.selectedSettlement,
    settlements.length
  ]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSaveQuoteResults(null);
  };

  const handleCloseDialogApiResult = () => {
    setSaveQuoteResults(null);
    clearFields(true);
  };

  const searchPostalCodeDetails = async (e) => {
    setLoading(true);
    //TODO
    //clearFields(false);
    try {
      const response = await searchPostalCode(postalCode);

      if (response?.data?.postalCodes && response?.data?.postalCodes[0]?.city) {
        setPostalCodeDetails({
          city: response.data.postalCodes[0].city,
          state: response.data.postalCodes[0].state
        });
        setSettlements(response.data.postalCodes[0].settlements);
      } else {
        setEmptyResponse(true);
        setSuccess({
          severity: "error",
          text: "Error buscando C.P."
        });
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      }
    } catch (error) {
      setSuccess({
        severity: "error",
        text: "Error buscando C.P."
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const searchPostalCodePrice = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const integerM2 = Math.floor(Number(quoteData.mBuilding) / 10) * 10;
      const floatM2 = parseFloat(quoteData.mBuilding - integerM2);
      let response = await getQuoters(
        {
          postalCode,
          m2: integerM2,
          type: quoteData.type,
          rooms: quoteData.rooms,
          baths: quoteData.baths,
          settlement: quoteData?.selectedSettlement
        },
        token
      );

      let quote = response?.data?.data?.quote;

      setQuoteId(response?.data?.data?.quoteId);
      setFileNumber(response?.data?.data?.fileNumber);

      if (quote?.averagePricePerMeter) {
        console.log(integerM2);
        console.log(floatM2);
        console.log(quote.averagePricePerMeter.toFixed(2));

        const pricePerMeter = quote.averagePricePerMeter.toFixed(2);
        const pricePerMeterPerM2 =
          pricePerMeter * integerM2 + pricePerMeter * floatM2;

        setGrossOffer(pricePerMeterPerM2);
        setStockPrice(pricePerMeterPerM2 + 27500);
        setMaxOffer(pricePerMeterPerM2 * 0.9);
        setAveragePricePerMeter(quote?.averagePricePerMeter);
        setAllowedByAdmin(false);

        if (!averagePriceCombinations.current) {
          averagePriceCombinations.current = {
            baths: quote.baths,
            rooms: quote.rooms
          };
        }
      } else {
        setEmptyResponse(true);
        setGrossOffer("");
        setStockPrice("");
        setMaxOffer("");
        setAveragePricePerMeter("");
        setSuccess({
          severity: "error",
          text: "Error buscando C.P."
        });
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSuccess({
        severity: "error",
        text: error
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };
  const handleClientData = (e) => {
    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setClientData({ ...clientData, [e.target.name]: e.target.value });
  };

  const handleDebts = (e) => {
    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setDebts({ ...debts, [e.target.name]: e.target.value });
  };
  const onChangeRange = (e) => {
    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setRangeDays(e.target.value);

    if (e.target.value === 0) {
      setAdvancedPayment({
        required: "",
        dayOfRequest: 0,
        commissionPercentage: "",
        commissionAmount: "",
        total: ""
      });
    }

    if (e.target.value > 180) {
      setDebts({
        ...debts,
        aguaTax: 30,
        luzTax: 30,
        predialTax: 30,
        mantenimientoTax: 30
      });
    } else {
      setDebts({
        ...debts,
        aguaTax: 0,
        luzTax: 0,
        predialTax: 0,
        mantenimientoTax: 0
      });
    }

    setAllowedByAdmin(false);
    setAllowedByAdminRef(null);
  };

  const clearFields = (clearPostalCode = true) => {
    quoteCleaned.current = true;

    if (generatePdf === true) {
      setGeneratePdf(false);
    }
    setClientData({
      name: "",
      fLastName: "",
      mLastName: "",
      email: "",
      phoneNumber: "",
      debtCredit: "false",
      debtCreditSettled: false,
      debtCreditAmount: 0,
      debtCreditRate: 0.3,
      taxes: 0,
      cfdi: true,
      cfdiCost: 0,
      commission: ""
    });
    setDebts({
      agua: "",
      aguaTax: 0,
      luz: "",
      luzTax: 0,
      predial: "",
      predialTax: 0,
      mantenimiento: "",
      mantenimientoTax: 0,
      rehabilitation: ""
    });
    setAdvancedPayment({
      ...advancedPayment,
      required: "",
      dayOfRequest: 0,
      commissionPercentage: "",
      commissionAmount: "",
      total: ""
    });

    if (clearPostalCode) {
      setPostalCode("");
    }
    setEmptyResponse(false);
    setGrossOffer("");
    setMaxOffer("");
    setGeneratePdf(false);
    setSettlements([]);
    setQuoteId(null);
    setFileNumber(null);
    console.log(advancedPayment);
    setQuoteData({
      baths: "",
      rooms: "",
      type: "casa",
      mBuilding: "",
      selectedSettlement: ""
    });
    setPostalCodeDetails(null);
    setAllowedByAdmin(false);
    setDebtsChecked(false);
    setcalculatedPrice(0);
    setTotalDebts(0);
    setTotalAll(0);
    setRangeDays(0);
    setUpdatedNetOffer(false);
    setAveragePricePerMeter("");
    setStockPrice("");
    setNetOffer("");
    averagePriceCombinations.current = null;
  };

  const saveClientAndDownloadPdf = async () => {
    setLoading(true);
    try {
      if (
        clientData.name &&
        clientData.fLastName &&
        clientData.mLastName &&
        clientData.email &&
        clientData.phoneNumber
      ) {
        const debtsArray = Object.keys(debts)
          .filter((key) => key !== "rehabilitation" && !key.endsWith("Tax"))
          .filter((key) => debts[key] !== "") // Exclude rehabilitation and tax properties
          .map((key) => ({
            type: key,
            amount: debts[key],
            rate: Number(debts[`${key}Tax`] / 100) || null // Set rate based on corresponding tax property
          }));

        const quoteDataPost = {
          commercialPrice: parseFloat(grossOffer || 0),
          maxOffer,
          grossOffer: parseFloat(calculatedPrice || 0),
          netOffer: parseFloat(netOffer || 0),
          baths: Number(quoteData?.baths),
          rooms: Number(quoteData?.rooms),
          metersBuild: Number(quoteData?.mBuilding || 0),
          rehabilitation: Number(debts?.rehabilitation || 0),
          creditType:
            clientData?.debtCredit === "false" ? "" : clientData?.debtCredit,
          creditTaxes: parseFloat(clientData?.taxes || 0),
          creditAmount: parseFloat(clientData?.debtCreditAmount || 0),
          creditAmountRate: parseFloat(clientData?.debtCreditRate || 0),
          cfdi: parseFloat(clientData?.cfdiCost || 0),
          advancedPaymentAmount: parseFloat(advancedPayment?.required || 0),
          advancedPaymentRate: parseFloat(
            advancedPayment?.commissionPercentage || 0
          ),
          advancedPaymentDay: rangeDays,
          rangeDays: rangeDays,
          debts: debtsArray,
          holdedNetOffer
        };

        const quoteObject = {
          quoteRef: quoteId,
          quoteData: quoteDataPost
        };

        if (allowedByAdminRef) quoteObject.authorizedByRef = allowedByAdminRef;

        const response = await saveQuote(
          {
            quote: quoteObject,
            seller: { ...clientData, source: "Dashboard" },
            property: { offer: calculatedPrice, rangeDays }
          },
          token
        );

        if (response?.data?.error) {
          console.log(response?.data?.error);

          if (response?.data?.error === "EXISTING_PROPERTY") {
            setSaveQuoteResults(response?.data);
          }
          setSuccess({
            severity: "error",
            text: "Ocurrió un error."
          });
          setTimeout(() => {
            setSuccess({ severity: "", text: "" });
          }, 3000);
        } else {
          setSaveQuoteResults(response?.data);
          setGeneratePdf(true);
          setSuccess({
            severity: "success",
            text: "Cliente y Oferta creados."
          });
          setTimeout(() => {
            setSuccess({ severity: "", text: "" });
          }, 3000);
        }
      }
    } catch (error) {
      setSuccess({
        severity: "error",
        text: error
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const createClientQuote = async () => {
    setLoading(true);
    try {
      const debtsArray = Object.keys(debts)
        .filter((key) => key !== "rehabilitation" && !key.endsWith("Tax"))
        .filter((key) => debts[key] !== "") // Exclude rehabilitation and tax properties
        .map((key) => ({
          type: key,
          amount: debts[key],
          rate: Number(debts[`${key}Tax`] / 100) || null // Set rate based on corresponding tax property
        }));

      const quoteDataPost = {
        commercialPrice: parseFloat(grossOffer || 0),
        maxOffer,
        grossOffer: parseFloat(calculatedPrice || 0),
        netOffer: parseFloat(netOffer || 0),
        baths: Number(quoteData?.baths),
        rooms: Number(quoteData?.rooms),
        metersBuild: Number(quoteData?.mBuilding || 0),
        rehabilitation: Number(debts?.rehabilitation || 0),
        creditType:
          clientData?.debtCredit === "false" ? "" : clientData?.debtCredit,
        creditTaxes: parseFloat(clientData?.taxes || 0),
        creditAmount: parseFloat(clientData?.debtCreditAmount || 0),
        creditAmountRate: parseFloat(clientData?.debtCreditRate || 0),
        cfdi: parseFloat(clientData?.cfdiCost || 0),
        advancedPaymentAmount: parseFloat(advancedPayment?.required || 0),
        advancedPaymentRate: parseFloat(
          advancedPayment?.commissionPercentage || 0
        ),
        advancedPaymentDay: rangeDays,
        rangeDays: rangeDays,
        debts: debtsArray,
        holdedNetOffer
      };

      const quoteObject = {
        quoteRef: quoteId,
        quoteData: quoteDataPost
      };

      if (allowedByAdminRef) quoteObject.authorizedByRef = allowedByAdminRef;

      const response = await createQuoteToUser(
        {
          quote: quoteObject,
          /* seller: { ...clientData, source: "Dashboard" }, */
          property: { offer: calculatedPrice, rangeDays },
          propertyId
        },
        token
      );

      console.log(response);

      await refetchCurrentProperty();

      /* if (response?.data?.error) {
        console.log(response?.data?.error);

        if (response?.data?.error === "EXISTING_PROPERTY") {
          setSaveQuoteResults(response?.data);
        }
        setSuccess({
          severity: "error",
          text: "Ocurrió un error."
        });
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      } else {
        setSaveQuoteResults(response?.data);
        setGeneratePdf(true);
        setSuccess({
          severity: "success",
          text: "Cliente y Oferta creados."
        });
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      } */
    } catch (error) {
      setSuccess({
        severity: "error",
        text: error
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  const updateClientAndDownloadPdf = async () => {
    setLoading(true);
    try {
      const debtsArray = Object.keys(debts)
        .filter((key) => key !== "rehabilitation" && !key.endsWith("Tax"))
        .filter((key) => debts[key] !== "") // Exclude rehabilitation and tax properties
        .map((key) => ({
          type: key,
          amount: debts[key],
          rate: Number(debts[`${key}Tax`] / 100) || null // Set rate based on corresponding tax property
        }));

      const quoteDataPost = {
        commercialPrice: parseFloat(grossOffer || 0),
        maxOffer,
        grossOffer: parseFloat(calculatedPrice || 0),
        netOffer: parseFloat(netOffer || 0),
        baths: Number(quoteData?.baths),
        rooms: Number(quoteData?.rooms),
        metersBuild: Number(quoteData?.mBuilding || 0),
        rehabilitation: Number(debts?.rehabilitation || 0),
        creditType:
          clientData?.debtCredit === "false" ? "" : clientData?.debtCredit,
        creditTaxes: parseFloat(clientData?.taxes || 0),
        creditAmount: parseFloat(clientData?.debtCreditAmount || 0),
        creditAmountRate: parseFloat(clientData?.debtCreditRate || 0),
        cfdi: parseFloat(clientData?.cfdiCost || 0),
        advancedPaymentAmount: parseFloat(advancedPayment?.required || 0),
        advancedPaymentRate: parseFloat(
          advancedPayment?.commissionPercentage || 0
        ),
        advancedPaymentDay: rangeDays,
        rangeDays: rangeDays,
        debts: debtsArray,
        holdedNetOffer
      };

      const integerM2 = Math.floor(Number(quoteData.mBuilding) / 10) * 10;

      const quoteObject = {
        quoteRef: lastQuote?._id,
        quoteData: quoteDataPost,
        quotePropertyData: {
          postalCode,
          m2: integerM2,
          type: quoteData.type,
          rooms: quoteData.rooms,
          baths: quoteData.baths,
          settlement: quoteData?.selectedSettlement
        }
      };

      if (allowedByAdminRef) quoteObject.authorizedByRef = allowedByAdminRef;

      const response = await updateQuote(
        {
          quote: quoteObject,
          propertyQuoteRef: propertyId
        },
        token
      );

      if (response?.data?.error) {
        console.log(response?.data?.error);

        setSuccess({
          severity: "error",
          text: "Ocurrió un error."
        });
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      } else {
        setSuccess({
          severity: "success",
          text: "Oferta actualizada"
        });
        setTimeout(() => {
          setSuccess({ severity: "", text: "" });
        }, 3000);
      }
      /* setAllowedByAdmin(false);
      setAllowedByAdminRef(null); */
      refetchCurrentProperty();
      refetchQuotesHistory();
    } catch (error) {
      setSuccess({
        severity: "error",
        text: error
      });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } finally {
      setLoading(false);
    }
  };

  console.log(saveQuoteResults);

  const parsedDebts = {};

  if (lastQuote?.quoteData?.debts) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const debt of lastQuote?.quoteData.debts) {
      parsedDebts[debt?.type] = debt?.amount * debt?.rate + debt?.amount;
    }
  }

  return (
    <Grid
      container
      item
      maxWidth="md"
      sx={{
        mt: 1,
        border: "1px solid rgba(111, 128, 149, 0.6)",
        borderRadius: 2,
        py: 2,
        px: 2,
        width: "100%",
        height: "100%",
        position: "relative"
      }}
    >
      {loadingLastQuote && (
        <Box
          style={{
            display: "flex",
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 9999
          }}
        >
          <CircularProgress size={64} />
        </Box>
      )}
      {success.severity !== "" && (
        <Snackbar open={success.severity !== ""} autoHideDuration={3000}>
          <Alert severity={success.severity}>{success.text}</Alert>
        </Snackbar>
      )}
      {!propertyUser?.email && (
        <>
          <Grid container mb={1}>
            <Grid item>
              <Typography
                textAlign={"left"}
                mb={1}
                variant="h5"
                color="secondary"
              >
                Datos del cliente:
              </Typography>
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                name="name"
                label={
                  <Typography variant="headline" component="h5">
                    Nombre(s)
                  </Typography>
                }
                value={clientData.name}
                onChange={handleClientData}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                name="fLastName"
                label={
                  <Typography variant="headline" component="h5">
                    Apellido paterno
                  </Typography>
                }
                value={clientData.fLastName}
                onChange={handleClientData}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                name="mLastName"
                label={
                  <Typography variant="headline" component="h5">
                    Apellido Materno
                  </Typography>
                }
                value={clientData.mLastName}
                onChange={handleClientData}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                name="email"
                label={
                  <Typography variant="headline" component="h5">
                    e-mail
                  </Typography>
                }
                value={clientData.email}
                onChange={handleClientData}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size="small"
                name="phoneNumber"
                label={
                  <Typography variant="headline" component="h5">
                    Teléfono
                  </Typography>
                }
                value={clientData.phoneNumber}
                onChange={handleClientData}
                InputProps={{
                  inputComponent: TextMaskCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphoneIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Grid container mb={2} mt={2}>
        <Grid item md={6}>
          <Typography textAlign={"left"} variant="h5" color="secondary">
            Datos del inmueble:
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"row"}
        >
          {postalCodeDetails?.state && postalCodeDetails?.city ? (
            <Typography
              variant="h7"
              component={"span"}
              color="secondary"
              fontWeight={"600"}
            >
              {postalCodeDetails.city}, {postalCodeDetails.state}
            </Typography>
          ) : (
            emptyResponse && (
              <Typography
                variant="h7"
                component={"span"}
                color="secondary"
                fontWeight={"600"}
              >
                Código Postal incorrecto.
              </Typography>
            )
          )}
        </Grid>
      </Grid>
      <PostalCodeDisplay
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        searchPostalCodePrice={searchPostalCodePrice}
        searchPostalCodeDetails={searchPostalCodeDetails}
        clearFields={clearFields}
        setSettlements={setSettlements}
        settlements={settlements}
        loading={loading}
        setQuoteData={setQuoteData}
        quoteData={quoteData}
        emptyResponse={emptyResponse}
        postalCodeDetails={postalCodeDetails}
        grossOffer={grossOffer}
        stockPrice={stockPrice}
        averagePricePerMeter={averagePricePerMeter}
        averagePriceCombinationsRef={averagePriceCombinations}
        lastQuote={lastQuote}
      />
      <Box display={"flex"} mt={2} mb={2}>
        <FormControlLabel
          control={
            <IOSSwitch
              checked={debtsChecked}
              onChange={() => setDebtsChecked(!debtsChecked)}
            />
          }
          label="Mostrar extras"
        />
      </Box>
      {debtsChecked && (
        <Debts
          debts={debts}
          clientData={clientData}
          setClientData={setClientData}
          handleDebts={handleDebts}
          advancedPayment={advancedPayment}
          setAdvancedPayment={setAdvancedPayment}
          rangeDays={rangeDays}
          allowedByAdmin={allowedByAdmin}
        />
      )}
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          {allowedByAdmin ? (
            <Grid container xs={12} md={12}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="Introduce oferta"
                  label={
                    <Typography variant="headline" component="h5">
                      Precio comercial
                    </Typography>
                  }
                  value={grossOffer || 0}
                  onChange={(e) => setUpdatedGrossOffer(e.target.value)}
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ paddingLeft: "2em", paddingRight: "2em" }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    setGrossOffer(updatedGrossOffer);
                    setStockPrice(Number(updatedGrossOffer) + 27500);
                    setMaxOffer(Number(updatedGrossOffer) * 0.9);
                    setAveragePricePerMeter(
                      updatedGrossOffer / quoteData?.mBuilding
                    );
                  }}
                  size="medium"
                  startIcon={<EditIcon />}
                >
                  Actualizar
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="Introduce oferta"
                  label={
                    <Typography variant="headline" component="h5">
                      Oferta Neta
                    </Typography>
                  }
                  value={netOffer}
                  onChange={(e) => setUpdatedNetOffer(e.target.value)}
                  /*onChange={(e) => {
                     
                  }}*/
                  InputProps={{
                    inputComponent: NumericFormatCustom,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                sx={{ paddingLeft: "2em", paddingRight: "2em" }}
              >
                <Button
                  variant="text"
                  onClick={() => {
                    const difference =
                      getOffer(Number(grossOffer), rangeDays) -
                      totalAll -
                      advancedPayment.total -
                      debts.rehabilitation -
                      totalDebts -
                      interesDeudas -
                      clientData.debtCreditAmount * clientData.debtCreditRate -
                      clientData.debtCreditAmount -
                      updatedNetOffer;

                    console.log("Diference");
                    console.log(difference);

                    let newCalculatedOffer;

                    if (difference > 0) {
                      newCalculatedOffer = Number(
                        getOffer(Number(grossOffer), rangeDays) -
                          Math.abs(difference)
                      );
                    } else {
                      newCalculatedOffer = Number(
                        getOffer(Number(grossOffer), rangeDays) +
                          Math.abs(difference)
                      );
                    }

                    if (newCalculatedOffer > maxOffer) {
                      setOpenOfferErrorDialog(true);
                    } else {
                      setcalculatedPrice(newCalculatedOffer);
                    }
                  }}
                  size="medium"
                  startIcon={<EditIcon />}
                >
                  Actualizar
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button
              variant="contained"
              onClick={handleClickOpen}
              size="medium"
              startIcon={<EditIcon />}
            >
              Editar Oferta
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <QuoterSlider
            onChangeRange={onChangeRange}
            rangeDays={rangeDays}
            maxOffer={maxOffer}
          />
        </Grid>
      </Grid>
      <OfferDisplay
        debts={debts}
        clientData={clientData}
        setClientData={setClientData}
        maxOffer={maxOffer}
        netOffer={netOffer}
        grossOffer={grossOffer}
        rangeDays={rangeDays}
        calculatedPrice={calculatedPrice}
        advancedPayment={advancedPayment}
        setAdvancedPayment={setAdvancedPayment}
        totalAll={totalAll}
        setTotalAll={setTotalAll}
        totalDebts={totalDebts}
        setTotalDebts={setTotalDebts}
        stockPrice={stockPrice}
        isValidProfit={isValidProfit}
        holdedNetOffer={holdedNetOffer}
        allowedByAdmin={allowedByAdmin}
        authorizedByRef={lastQuote?.authorizedByRef}
        quoteCleaned={quoteCleaned}
      />

      {!lastQuote ? (
        propertyId ? (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            mt={4}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={createClientQuote}
                disabled={
                  (!clientData.name ||
                    !clientData.fLastName ||
                    !clientData.mLastName ||
                    !clientData.email ||
                    !clientData.phoneNumber ||
                    !isValidProfit.current ||
                    netOffer < 0) &&
                  rangeDays === 365 &&
                  !allowedByAdmin
                }
                startIcon={<SaveIcon />}
              >
                Guardar Oferta
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            mt={4}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => saveClientAndDownloadPdf()}
                disabled={
                  (!clientData.name ||
                    !clientData.fLastName ||
                    !clientData.mLastName ||
                    !clientData.email ||
                    !clientData.phoneNumber ||
                    !isValidProfit.current ||
                    netOffer < 0) &&
                  rangeDays === 365 &&
                  !allowedByAdmin
                }
                startIcon={<SaveIcon />}
              >
                Guardar Oferta
              </Button>
            </Grid>
          </Grid>
        )
      ) : (
        <Grid container justifyContent={"center"} alignItems={"center"} mt={2}>
          {/* <Button variant="contained" size="medium" startIcon={<SaveIcon />}>
            <PDFDownloadLink
              style={{ textDecoration: "none", color: "#fff" }}
              document={
                <OfferPdf
                  data={{
                    quoteId: lastQuote?.fileNumber,
                    name: capitalizeFirstLetter(propertyUser?.name),
                    firstName: capitalizeFirstLetter(propertyUser?.fLastName),
                    lastName: capitalizeFirstLetter(propertyUser?.mLastName),
                    streetAndNumber: "",
                    settlement: lastQuote?.settlement,
                    postalCode: lastQuote?.postalCode,
                    city: property?.city,
                    state: property?.state,
                    netOffer: lastQuote?.quoteData?.netOffer,
                    grossOffer: lastQuote?.quoteData?.grossOffer,
                    advancedPaymentAmount:
                      lastQuote?.quoteData?.advancedPaymentAmount +
                      lastQuote?.quoteData?.advancedPaymentAmount *
                        lastQuote?.quoteData?.advancedPaymentRate,
                    taxes:
                      lastQuote?.quoteData?.cfdi +
                      lastQuote?.quoteData?.creditTaxes,
                    debts: {
                      creditAmount:
                        lastQuote?.quoteData?.creditAmount +
                        lastQuote?.quoteData?.creditAmount *
                          lastQuote?.quoteData?.creditAmountRate,
                      ...parsedDebts
                    },
                    rehabilitation: lastQuote?.quoteData?.rehabilitation || 0
                  }}
                />
              }
              fileName={`Oferta ${clientData?.name} ${
                clientData?.fLastName ?? ""
              } ${new Date().getDate()}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Cargando..." : "Descargar Oferta"
              }
            </PDFDownloadLink>
          </Button>
          <Button
            style={{ marginRight: "auto" }}
            variant="contained"
            size="medium"
            startIcon={<SaveIcon />}
            onclick={() => setAllowedByAdmin(!!allowedByAdmin)}
          >
            <PDFDownloadLink
              style={{ textDecoration: "none", color: "#fff" }}
              document={
                <PdfCtc
                  data={{
                    debts,
                    clientData,
                    setClientData,
                    maxOffer,
                    netOffer,
                    grossOffer,
                    rangeDays,
                    calculatedPrice,
                    advancedPayment,
                    setAdvancedPayment,
                    totalAll,
                    setTotalAll,
                    totalDebts,
                    setTotalDebts,
                    stockPrice,
                    isValidProfit,
                    quoteData,
                    postalCodeDetails,
                    postalCode
                  }}
                />
              }
              fileName={`Oferta${new Date().toISOString()}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Cargando..." : "Descargar PDFCtc"
              }
            </PDFDownloadLink>
          </Button> */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => updateClientAndDownloadPdf()}
            disabled={!isValidProfit.current}
            startIcon={<SaveIcon />}
          >
            Actualizar Oferta
          </Button>
        </Grid>
      )}
      {/* <Button
        variant="contained"
        size="medium"
        startIcon={<SaveIcon />}
        onclick={() => setAllowedByAdmin(!!allowedByAdmin)}
      >
        <PDFDownloadLink
          style={{ textDecoration: "none", color: "#fff" }}
          document={
            <PdfCtc
              data={{
                debts,
                clientData,
                setClientData,
                maxOffer,
                netOffer,
                grossOffer,
                rangeDays,
                calculatedPrice,
                advancedPayment,
                setAdvancedPayment,
                totalAll,
                setTotalAll,
                totalDebts,
                setTotalDebts,
                stockPrice,
                isValidProfit,
                quoteData,
                postalCodeDetails,
                postalCode
              }}
            />
          }
          fileName={`Oferta${new Date().toISOString()}`}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Cargando..." : "Descargar PDFCtc"
          }
        </PDFDownloadLink>
      </Button> */}
      <SaveQuoteResultDialog
        open={!!saveQuoteResults}
        handleClose={handleCloseDialogApiResult}
        handleCancel={handleClose}
        handleCloseQuoter={onCloseModal}
        setSuccess={setSuccess}
        saveQuoteResults={saveQuoteResults}
        pdf={{
          fileNumber,
          clientData,
          advancedPayment,
          calculatedPrice,
          netOffer,
          postalCodeDetails,
          quoteData,
          debts,
          postalCode,
          generatePdf,
          rangeDays,
          stockPrice,
          totalAll,
          totalDebts,
          interesDeudas
        }}
      />
      <DialogQuoterAdmins
        open={open}
        handleClose={handleClose}
        setAllowedByAdmin={setAllowedByAdmin}
        setSuccess={setSuccess}
        setAllowedByAdminRef={setAllowedByAdminRef}
      />
      <Dialog
        open={openOfferErrorDialog}
        onClose={() => setOpenOfferErrorDialog(false)}
        PaperProps={{
          component: "form"
        }}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            La oferta bruta no puede ser inferior a la oferta máxima.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenOfferErrorDialog(false)}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CotizerContainer;
