import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const createMarketplaceProperty = async ({ token, property }) => {
  let url = `${REACT_APP_BASE_URL}/landing/createMarketplaceProperty`;

  try {
    const response = await axios.post(
      url,
      { property },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
