import { useContext } from "react";
import { useQuery } from "react-query";

import { getDocListSeller } from "../../../api/documentations/getDocListSeller";
import Loading from "../../../components/loading/Loading";
import TokenContext from "../../../context/Token";

const DocTypeSelect = (props) => {
  const { token } = useContext(TokenContext);

  const { data, isLoading } = useQuery(
    ["DocsList", props.isMarried, props.creditType, props.communityProperty],
    () =>
      getDocListSeller(token, [
        props.isMarried,
        props.creditType,
        props.communityProperty
      ])
  );

  // const getDocs = async () => {
  //   if (props.MUDisActive) {
  //     const aux = await getDocsSeller(token, props.sellerId)
  //     // console.log(aux)
  //     setResponse(aux.data)
  //   }
  // }

  // useEffect(() => { getDocs() }, [props.MUDisActive])

  if (isLoading) return <Loading />;

  // console.log(data??"")
  // console.log(props.creditType)

  // const clientList = !props.isMarried ? data?.data?.documentations?.filter(d => !d.includes("(conyuge)")) : data?.data?.documentations ?? []

  return (
    <select
      className="select-documentacion p-1 mb-3"
      style={{ width: "160px", height: 30, margin: "0 auto" }}
      value={props.docType}
      onChange={(e) => props.setDocType(e.target.value)}
    >
      <option value="" hidden>
        Seleccionar Tipo
      </option>
      {data?.data?.documentations.map((d) => (
        <option key={d} value={d}>
          {d}
        </option>
      ))}
    </select>
  );
};

export default DocTypeSelect;
