import { Button, Grid } from "@mui/material";

const RehabButtons = () => {
  return (
    <Grid container columnSpacing={5}>
      <Grid item xs={12} md={6}>
        <Button variant="contained" fullWidth>
          Reparación Terminada
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button variant="blue" fullWidth>
          Reparación suspendida
        </Button>
      </Grid>
    </Grid>
  );
};

export default RehabButtons;
