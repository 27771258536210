import { Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { updateProperties } from "../../../api/properties/updateProperties";
import TokenContext from "../../../context/Token";

const SendToGestion = (props) => {
  const { token } = useContext(TokenContext);
  const navigate = useNavigate();

  const sendData = async () => {
    try {
      const response = await updateProperties(token, props.id, {
        status: "Gestion",
        legal: false
      });

      if (response._id) {
        navigate(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button onClick={() => sendData(true)} sx={{ my: 0 }}>
      Enviar a Gestión
    </Button>
  );
};

export default SendToGestion;
