import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import { useEffect } from "react";

import { searchPostalCode } from "../../api/postalCode/searchPostalCode";
import SearchBar from "../SearchBar";
import { defaultFilter } from "./defaultFilter";

const GeneralFilter = ({
  refetch,
  filters,
  setFilters,
  settlements,
  setSettlements
}) => {
  const handleChangeFilters = (e, a) => {
    console.log(e.target);

    if (e?.target?.name === "preSeller") {
      setFilters({
        ...filters,
        [e.target.name]: Boolean(e.target.checked)
      });
      setSettlements([]);
    } else if (e?.target?.name === "postalCode") {
      setFilters({
        ...filters,
        [e.target.name]: String(e.target.value),
        state: "",
        settlement: ""
      });
      setSettlements([]);
    } else if (e?.target?.name === "state") {
      setFilters({
        ...filters,
        [e?.target?.name]: String(e?.target?.value),
        postalCode: "",
        settlement: ""
      });
      setSettlements([]);
    } else if (
      e?.target?.name === "dateStart" ||
      e?.target?.name === "dateEnd"
    ) {
      setFilters({ ...filters, [e?.target?.name]: new Date(e?.target?.value) });
    } else if (e?.target?.name !== undefined) {
      setFilters({ ...filters, [e?.target?.name]: String(e?.target?.value) });
    }
  };

  const handleCleanFilters = () => {
    setFilters({ ...defaultFilter });
  };

  const handleStateChange = () => {
    setFilters({ ...defaultFilter, postalCode: "", settlement: "" });
  };

  useEffect(() => {
    const searchByCp = async (postalCode) => {
      if (postalCode !== "" && postalCode.length === 5) {
        const response = await searchPostalCode(postalCode);

        if (
          response?.data?.postalCodes &&
          response?.data?.postalCodes[0]?.city
        ) {
          setSettlements(response.data.postalCodes[0].settlements);
          setFilters({
            ...filters,
            state: response.data.postalCodes[0].state,
            postalCode
          });
        }
      }
    };

    searchByCp(filters.postalCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.postalCode]);

  const handleFilter = () => {
    refetch();
  };

  return (
    <>
      <Box style={{ display: "flex", gap: "8px" }}>
        <Box>
          <TextField
            size="small"
            fullWidth
            id="multiSearch"
            name="multiSearch"
            label="Nombre/Telefono/Email"
            value={filters.multiSearch}
            onChange={handleChangeFilters}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleFilter();
              }
            }}
            InputProps={{
              style: { borderRadius: "16px" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleFilter}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Box>
      <SearchBar
        {...filters}
        onChange={handleChangeFilters}
        handleStateChange={handleStateChange}
        clean={handleCleanFilters}
        settlements={settlements}
        handleFilter={handleFilter}
      />
    </>
  );
};

export default GeneralFilter;
