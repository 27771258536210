import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";
import ModalUploadDocumentation from "../Modals/ModalUploadDocumentation/ModalUploadDocumentation";
import NoPerfiladoButton from "../Profile/NoPerfiladoButton";

const GestionActions = (props) => {
  const { token } = useContext(TokenContext);
  const navigate = useNavigate();
  const [MUDisActive, setMUDisActive] = useState(false);
  const [MVDisActive, setMVDisActive] = useState(false);
  const [docsNum, setDocsNum] = useState(0);

  return (
    <>
      <div className="row row-cols-2 g-2 mb-3">
        <div className="col d-flex justify-content-center">
          <button
            type="button"
            className="btn-fill-orange"
            data-bs-toggle="modal"
            data-bs-target={"#modalUploadDocumentation" + props.property._id}
            onClick={() => setMUDisActive(true)}
          >
            Documentación
          </button>
        </div>
        <div className="col d-flex justify-content-center">
          <button
            className="btn-fill-orange"
            onClick={async () => {
              try {
                const response = await updateProperties(
                  token,
                  props?.property?._id,
                  { status: "Expediente" }
                );

                if (response) {
                  navigate(0);
                }
              } catch (error) {
                console.log(error);
                // setErrorMessage(error)
                // setTimeout(() => {
                //   setErrorMessage("")
                // }, 3000);
              }
            }}
          >
            Completado
          </button>
        </div>
        <ModalUploadDocumentation
          id={props.property._id}
          sellerId={props.property?.sellerRef?._id}
          MUDisActive={MUDisActive}
          setMUDisActive={setMUDisActive}
        />
      </div>
      <NoPerfiladoButton
        propertyId={props.property._id}
        buttonText={"Reconversión"}
        type={"Rec"}
      />
    </>
  );
};

export default GestionActions;
