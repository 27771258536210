import moment from "moment";

moment.updateLocale("es", {
  months:
    "enero_febrero_marzo_abril_mayo_junio_juilio_agosto_septiembre_octubre_noviembre_diciembre".split(
      "_"
    ),
  monthsShort: "ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic".split("_"),
  relativeTime: {
    past: "%s",
    s: "unos segundos",
    ss: "%d segundos",
    m: "un minuto",
    mm: "%d minutes",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    w: "una semana",
    ww: "%d semanas",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años"
  }
});

moment.locale("es");

const DatesInfo = (props) => {
  return (
    <div
      className="info-extend text-start value-title"
      style={{ fontSize: props.fontSize ?? 16 }}
    >
      <label>{moment(props?.createdAt).format("D/MMM/YY") ?? ""}</label>
      <label>{moment(props?.createdAt).format("h:mm A") ?? ""}</label>
      <label>
        {moment(props?.createdAt).fromNow(true) ?? ""} en el Dashboard
      </label>
    </div>
  );
};

export default DatesInfo;
