import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, MenuItem, TextField, IconButton } from "@mui/material";
import { useEffect, useState } from "react";

import { searchPostalCode } from "../../../api/postalCode/searchPostalCode";

const PostalCodeInfo = ({ propertyData, setPropertyData }) => {
  const [settlements, setSettlements] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await searchPostalCode(propertyData.postalCode);

      if (response.status === 200) {
        setPropertyData({
          ...propertyData,
          state: response?.data?.postalCodes[0]?.state ?? "",
          city: response?.data?.postalCodes[0]?.city ?? ""
        });

        if (response?.data?.postalCodes[0]?.settlements.length > 0) {
          setSettlements(response?.data?.postalCodes[0].settlements);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (propertyData.postalCode && propertyData.postalCode.length === 5) {
      handleSearch();
    }
  }, []);

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <Box display={"flex"}>
          <TextField
            fullWidth
            required
            id="postalCode"
            name="postalCode"
            label="Código Postal"
            disabled
            value={propertyData.postalCode}
            onChange={(e) => {
              setPropertyData({ ...propertyData, postalCode: e.target.value });
            }}
          />
          {/* <IconButton aria-label="search-postalCode" onClick={handleSearch}>
            <SearchIcon />
          </IconButton> */}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          disabled
          fullWidth
          required
          id="state"
          name="state"
          label="Estado"
          value={propertyData.state}
          // onChange={handlePropertyDataChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          disabled
          fullWidth
          required
          id="city"
          name="city"
          label="Municipio/Alcaldía"
          value={propertyData.city}
          // onChange={handlePropertyDataChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        {settlements.length > 0 && (
          <TextField
            fullWidth
            select
            required
            id="settlement"
            name="settlement"
            label="Colonia"
            disabled
            value={propertyData.settlement || ""}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                [e.target.name]: e.target.value
              })
            }
          >
            <MenuItem value={propertyData.settlement}>
              {propertyData.settlement}
            </MenuItem>
          </TextField>
        )}
      </Grid>
    </>
  );
};

export default PostalCodeInfo;
