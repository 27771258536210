import { Close, AttachFile } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  DialogActions,
  Grid,
  Divider,
  Typography,
  IconButton,
  TextField,
  MenuItem,
  Alert,
  FormControlLabel,
  Switch
} from "@mui/material";
import { useMemo, useState } from "react";
import { useRef } from "react";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { createDocsSeller } from "../../../api/documentations/createDocsSeller";
import { getDocListSeller } from "../../../api/documentations/getDocListSeller";
import { getDocsSeller } from "../../../api/documentations/getDocsSeller";
import { uploadDocumentationFile } from "../../../api/documentations/uploadDocumentationsFile";
import { updateDocuments } from "../../../api/properties/updateDocuments";
import { updateProperties } from "../../../api/properties/updateProperties";
import { getUploadFileUrl, uploadFile } from "../../../api/utilsApi";
import {
  clientRequiredDocs,
  clientUploadedDocs
} from "../../../cacheQueries/cacheQueries";
import TokenContext from "../../../context/Token";
import { toNormalForm } from "../../../helpers/common";
import { generalMandatoryDocs } from "../../../helpers/mandatoryDocs";
import Loading from "../../loading/Loading";
import { FileIntegrationItem } from "./FileIntegrationItem";

const styles = {
  container: {
    display: "block"
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: "1rem"
  },
  title: {
    my: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  fieldWrapper: {
    px: "1rem"
  },
  iconButton: {
    p: "2.5rem",
    borderStyle: "dashed",
    color: "#FF861B"
  },
  newFile: {
    textAlign: "center"
  },
  column: {
    px: "1rem"
  }
};

const FileIntegration = ({ property, ...props }) => {
  const { token } = useContext(TokenContext);
  const navigate = useNavigate();

  const searchParams = [];

  if (property?.sellerRef?.married === true) searchParams.push("married");

  if (property?.creditType && property?.creditType === "Infonavit")
    searchParams.push("infonavit");

  if (property?.creditType && property?.creditType === "Otro")
    searchParams.push("carta");

  if (
    property?.sellerRef?.married === true &&
    property?.sellerRef.communityProperty === true
  )
    searchParams.push("communityProperty");

  //=============  QUERIES  =============//
  const { data: requiredDocsList } = useQuery(
    [clientRequiredDocs, searchParams],
    () => getDocListSeller(token, searchParams)
  );

  const { data: uploadedDocs, refetch: refetchClientUploadedDocs } = useQuery(
    [clientUploadedDocs, property?.sellerRef?._id],
    () => getDocsSeller(token, property?.sellerRef?._id)
  );
  //=============  QUERIES  =============//

  const inputFile = useRef(null);

  const [type, setType] = useState("");
  const [doc, setDoc] = useState(null);
  const [cfdiOptional, setCfdiOptional] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({ severity: "", text: "" });

  const handleClose = () => props.onClose();
  const handleTypeChange = (e) => setType(e.target.value);
  const handleFileChange = (e) => setDoc(e.target.files[0]);
  const handleOpenFile = () => inputFile.current.click();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const CDSResponse = await createDocsSeller(
        token,
        type,
        property?.sellerRef?._id
      );
      // await uploadDocumentationFile(token, CDSResponse.data.document_id, doc);

      const { url, signedUrl } = await getUploadFileUrl(
        token,
        `tmp/properties/${property._id}/docs/expediente/comprador`,
        toNormalForm(doc?.name?.trim()?.replaceAll(" ", "-").toLowerCase())
      );

      await uploadFile(signedUrl, doc);

      await uploadDocumentationFile(token, CDSResponse.data.document_id, url);

      setType("");
      inputFile.current.value = null;
      refetchClientUploadedDocs();
      setLoading(false);
      setDoc(null);
      setSuccess({ severity: "success", text: "Documento subido" });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setSuccess({ severity: "error", text: String(error) });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  //listDocs = requiredDocsList, userDocs = uploadedDocs
  const getListDocs = (listDocs = [], userDocs = []) => {
    if (userDocs.length === 0) return listDocs;

    const invalidDocs = userDocs
      ?.filter((doc) => doc?.valid === false)
      ?.map((doc) => doc.type);
    const docsUploaded = userDocs
      .map((doc) => doc.type)
      ?.filter((doc) => !invalidDocs.includes(doc));
    const newListDocs = listDocs.filter((doc) => !docsUploaded.includes(doc));

    return newListDocs;
  };

  const updateSellStatus = () => {
    updateProperties(token, property?._id, { status: "Firma" }).then(
      (response) => {
        if (response?._id) {
          navigate(0);
        }
      }
    );
    props.refetchProperties();
  };
  const mandatoryDocsDelivered = useMemo(() => {
    const entregadosTypes = uploadedDocs?.data?.documentations?.map(
      (item) => item.type
    );

    return generalMandatoryDocs?.every((obligatorio) =>
      entregadosTypes?.includes(obligatorio)
    );
  }, [uploadedDocs?.data?.documentations]);

  return (
    <Dialog fullWidth maxWidth={"lg"} open={props.open}>
      <DialogTitle>
        <Box sx={styles.title}>
          <Typography variant="h5">Integrar expediente</Typography>
          <IconButton disabled={loading} onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Grid container>
            {!props.readOnly && (
              <>
                <Grid //select y boton para indicar/subir archivo
                  item
                  xs={12}
                  md={6}
                  sx={{ ...styles.newFile, ...styles.column }}
                >
                  {requiredDocsList?.data.documentations && (
                    <TextField
                      fullWidth
                      select
                      disabled={loading}
                      label="Tipo"
                      name="type"
                      value={type}
                      onChange={handleTypeChange}
                      color="secondary"
                    >
                      {getListDocs(
                        requiredDocsList?.data?.documentations,
                        uploadedDocs?.data?.documentations
                      ).map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}

                  <Button //boton con icono de clip para subir archivo
                    variant="outlined"
                    color="primary"
                    component="label"
                    size="large"
                    sx={styles.iconButton}
                    onClick={handleOpenFile}
                    disabled={!type || loading}
                  >
                    <AttachFile />
                  </Button>

                  <input
                    disabled={loading}
                    ref={inputFile}
                    hidden
                    accept="image/*, application/pdf"
                    type="file"
                    onChange={handleFileChange}
                    id="inputFile"
                  />

                  <Typography>{doc?.name ?? "Seleccionar archivo"}</Typography>

                  <Button //boton para subir archivo nuevo
                    onClick={handleSubmit}
                    disabled={!type || !doc || loading === true}
                  >
                    {!loading ? "Subir" : "Subiendo archivo..."}
                  </Button>
                  <FormControlLabel
                    sx={{ width: "100%" }}
                    required
                    control={
                      <Switch
                        checked={cfdiOptional}
                        onClick={(e) => setCfdiOptional(!cfdiOptional)}
                      />
                    }
                    label="CFDI opcional"
                  />
                  {loading === true && <Loading />}
                </Grid>
              </>
            )}
            <Grid //lista de documentos con enlace a los subidos
              item
              xs={12}
              md={!props.readOnly ? 6 : 12}
              sx={styles.column}
            >
              <Typography variant="h6">
                Documentos (<b>Casado:</b>{" "}
                {property.sellerRef?.married ? "Si" : "No"},{" "}
                <b>Bienes Mancomunados:</b>{" "}
                {property.sellerRef?.communityProperty ? "Si" : "No"})
              </Typography>
              {uploadedDocs?.data?.documentations?.filter(
                (d) => d.valid === false
              ).length > 0 && (
                <Alert severity="error">
                  hay documentos que necesitan revisión
                </Alert>
              )}
              <Box height={350} sx={{ overflowY: "auto" }}>
                {requiredDocsList?.data?.documentations &&
                  requiredDocsList?.data?.documentations?.map((option) => (
                    <FileIntegrationItem
                      key={option}
                      type={option}
                      files={uploadedDocs?.data?.documentations}
                      refetchClientUploadedDocs={refetchClientUploadedDocs}
                      readOnly={props.readOnly}
                    />
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        {success.severity !== "" && (
          <Alert severity={success.severity} sx={{ mr: "auto", ml: 3 }}>
            {success.text}
          </Alert>
        )}
        {!props.readOnly && cfdiOptional === false ? (
          <Button
            disabled={!mandatoryDocsDelivered}
            variant={"text"}
            onClick={updateSellStatus}
          >
            Enviar a Firma
          </Button>
        ) : (
          <Button
            disabled={
              !mandatoryDocsDelivered &&
              uploadedDocs?.data?.documentations?.some(
                (doc) =>
                  doc.type === "CFDI si es una escritura posterior al 2014"
              )
            }
            variant={"text"}
            onClick={updateSellStatus}
          >
            Enviar a Firma
          </Button>
        )}
        <Button onClick={handleClose} disabled={loading}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileIntegration;
