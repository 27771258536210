import { Box, Grid, Pagination } from "@mui/material";
import { set } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getProperties } from "../../api/properties/getProperties";
import { citas } from "../../cacheQueries/cacheQueries";
import { defaultFilter } from "../../components/GeneralFilter/defaultFilter";
import GeneralFilter from "../../components/GeneralFilter/GeneralFilter";
import Loading from "../../components/loading/Loading";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";

const BuyDatesContainer = () => {
  const { token } = useContext(TokenContext);
  const [filters, setFilters] = useState({ ...defaultFilter });
  const [settlements, setSettlements] = useState([]);
  const [results] = useState(20);
  const [page, setPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(1);

  const { isLoading, isError, data, refetch, isFetching } = useQuery(
    [citas, results, page],
    () =>
      getProperties(token, results, page, "Cita", "", "", {
        ...filters,
        dateStart: set(new Date(filters.dateStart), {
          hours: 0,
          minutes: 0,
          seconds: 0
        }).toISOString(),
        dateEnd: set(new Date(filters.dateEnd), {
          hours: 23,
          minutes: 59,
          seconds: 59
        }).toISOString()
      }),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  useEffect(() => {
    const getPaginationValue = () => {
      setPaginationValue(Math.ceil(data?.properties?.totalDocs / results));
    };

    getPaginationValue();
  }, [data?.properties?.totalDocs, results]);

  // if (isLoading) return <Loading />;

  if (isError) {
    return <h2>Error al cargar datos</h2>;
  }
  return (
    <>
      <Box mt={2} height={32} mb={11}>
        {paginationValue > 0 && (
          <Pagination
            color="primary"
            count={paginationValue}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        )}
      </Box>
      <Box style={{ width: "100%", paddingLeft: "8px" }} display="flex">
        <GeneralFilter
          refetch={refetch}
          filters={filters}
          setFilters={setFilters}
          settlements={settlements}
          setSettlements={setSettlements}
        />
      </Box>
      {data?.properties.length === 0 ? (
        <h2 className="fs-5 margin-element">
          No se encontraron casas en Clientes
        </h2>
      ) : isFetching ? (
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Table
          columnTitles={[
            "ID de la Casa",
            "Fecha de Cita",
            "Informacion del Cliente",
            "Informacion del Inmueble",
            "Fuente",
            "Estado de la cita",
            "Acciones"
          ]}
          properties={data?.properties?.docs ?? []}
          refetchProperties={refetch}
          tableType={"Cita"}
        />
      )}
    </>
  );
};

export default BuyDatesContainer;
