import CalendarContainer from "../../components/CalendarPage/CalendarContainer";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { useContext } from "react";
import { useQuery } from "react-query";

import { getPropertiesForFirm } from "../../api/properties/getPropertiesForFirm";
import Loading from "../../components/loading/Loading";
import TokenContext from "../../context/Token";

const CalendarPage = () => {
  const { token } = useContext(TokenContext);
  const { data, isLoading } = useQuery(["firmDates"], () =>
    getPropertiesForFirm(token)
  );

  // data && console.log(data)
  return (
    <section className="flex-center">
      {isLoading ? (
        <Loading />
      ) : (
        <CalendarContainer firmDates={data?.properties ?? []} />
      )}
    </section>
  );
};

export default CalendarPage;
