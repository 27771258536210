import { Box, Slider, Typography } from "@mui/material";

const marks = [
  {
    value: 0,
    label: "Inmediato"
  },
  {
    value: 90,
    label: "90"
  },
  {
    value: 180,
    label: "180 Días"
  },
  {
    value: 270,
    label: "270 Días"
  },
  {
    value: 365,
    label: "365 Días"
  }
];

function valuetext(value) {
  return `${value} Días`;
}

const QuoterSlider = ({ onChangeRange, rangeDays, maxOffer }) => {
  return (
    <Box width={"90%"} marginX="auto">
      <Typography
        variant="body1"
        color="secondary"
        textAlign={"start"}
        fontSize={14}
        mb={"1em"}
      >
        Días para el pago
      </Typography>
      <Slider
        disabled={!maxOffer}
        aria-label={"Intervalo"}
        label={
          <Typography variant="headline" component="h5">
            Intervalo
          </Typography>
        }
        valueLabelDisplay="auto"
        min={0}
        max={365}
        step={null}
        color="primary"
        value={rangeDays ?? 365}
        onChange={onChangeRange}
        marks={marks}
        getAriaValueText={valuetext}
      />
    </Box>
  );
};

export default QuoterSlider;
