import { formulaCTC } from "./cotizador";

export const calcOffer = (interval, basePrice) => {
  if (interval !== 0) {
    // const result = Math.round(quote - (quote * ((0.2599 / 365) * (365 - interval))));
    // const result = formulaCTC((basePrice * 0.9), interval)
    const result = formulaCTC(basePrice, interval);

    return result;
  }
};
