import { useContext } from "react";
import { useState } from "react";
import { MdOutlineVideocam } from "react-icons/md";

import { uploadVideoProperty } from "../../api/properties/uploadVideoProperty";
import TokenContext from "../../context/Token";
import ProgressBar from "../loading/ProgressBar";

const UploadVideo = (props) => {
  const { token } = useContext(TokenContext);

  const [video, setVideo] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const sendData = async (e) => {
    e.preventDefault();
    setShowProgressBar(true);

    try {
      const response = await uploadVideoProperty(
        token,
        props?.id,
        video,
        setProgress
      );

      // console.log(response)
      if (response?.property) {
        setShowProgressBar(false);
        setSuccessMessage("Video subido");
        props.refetch();
        setVideo({});
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      }
    } catch (error) {
      setShowProgressBar(false);
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    }
  };

  return (
    <>
      <form onSubmit={sendData}>
        <label className="select-file-box" style={{ cursor: "pointer" }}>
          <input
            type="file"
            accept="video/*"
            onChange={(e) => setVideo(e.target.files[0])}
            hidden
          />
          <MdOutlineVideocam className="select-icons" />
          <span style={{ fontSize: 12, userSelect: "none" }}>
            {video.name
              ? video?.name?.length <= 11
                ? video.name
                : `${video?.name?.substr(0, 10)}...`
              : "Agregar video"}
          </span>
        </label>
        {video.name && (
          <button className="btn-fill-orange w-100 my-2" type="submit">
            Subir
          </button>
        )}
        {showProgressBar && <ProgressBar progress={progress} />}
        {successMessage && (
          <p className="bg-success text-white py-1 px-1 rounded mx-1 fw-bold text-center">
            {successMessage}
          </p>
        )}
        {errorMessage && (
          <p className="bg-danger text-white py-1 px-1 rounded mx-1 fw-bold text-center">
            {errorMessage}
          </p>
        )}
      </form>
    </>
  );
};

export default UploadVideo;
