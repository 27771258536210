import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getQuotersMetrics = async (params, token) => {
  try {
    console.log(params);

    const searchParams = new URLSearchParams();

    for (const key in params) {
      if (params[key] !== "all" && params[key] !== 0) {
        searchParams.append(key, params[key]);
      }
    }
    console.log(searchParams.toString());

    const response = await axios.get(
      `${REACT_APP_BASE_URL}/quoters/metrics?${searchParams.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
