import EditIcon from "@mui/icons-material/Edit";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField
} from "@mui/material";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { updateProperties } from "../../api/properties/updateProperties";
import { getMe } from "../../api/session/getMe";
import { currentUser } from "../../cacheQueries/cacheQueries";
import TokenContext from "../../context/Token";
import { calcOffer } from "../../helpers/calcOffer";
import { stringToCurrency } from "../../helpers/currencies";

const Prices = (props) => {
  const { propertyId } = useParams();
  const { token } = useContext(TokenContext);
  const { data } = useQuery([currentUser, token], () => getMe(token));
  const [changeValue, setChangeValue] = useState(false);
  const [newOffer, setNewOffer] = useState(props.offer ?? 0);
  const [success, setSuccess] = useState({ severity: "", text: "" });

  const updateSellingPrice = async () => {
    try {
      await updateProperties(token, propertyId, { offer: newOffer });
      setSuccess({ severity: "success", text: "Actualizado" });
      setChangeValue(false);
      props.refetch();
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    } catch (error) {
      console.log(error);
      setSuccess({ severity: "error", text: error });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"}>
        <label className="fs-4 property-title text-start">
          Precio de compra:
        </label>
        <label className="fs-4 value-title text-start">
          {stringToCurrency(
            calcOffer(props.interval, props.offer ?? props.quote)
          ) ?? " N/A"}
        </label>
      </Grid>
      <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"}>
        <Box textAlign={"start"}>
          <label className="fs-4 property-title">Precio de venta:</label>
          {data?.data?.admin?.role === "Super" && (
            <IconButton
              sx={{ color: "#0053C5" }}
              onClick={(e) => setChangeValue(!changeValue)}
            >
              <EditIcon color="inherit" />
            </IconButton>
          )}
        </Box>
        {changeValue === false ? (
          <label className="fs-4 value-title text-start">
            {stringToCurrency(props.offer ?? props.quote)}
          </label>
        ) : (
          <Box display={"flex"}>
            <TextField
              name="offer"
              size="small"
              label="Precio nuevo de venta"
              value={newOffer}
              onChange={(e) => setNewOffer(e.target.value)}
              helperText="sin comas"
              sx={{ width: 175 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                )
              }}
            />
            <Box>
              <IconButton
                variant="contained"
                sx={{ mt: 1, ml: 1 }}
                color="primary"
                onClick={updateSellingPrice}
              >
                <UpdateIcon color="inherit" />
              </IconButton>
            </Box>
          </Box>
        )}
      </Grid>
      {success.severity !== "" && (
        <Snackbar open={success.severity !== ""} autoHideDuration={3000}>
          <Alert severity={success.severity} sx={{ width: "100%" }}>
            {success.text}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
};

export default Prices;
