import { Outlet } from "react-router-dom";

import Navbar from "../../components/Navbar";

const CtcContainer = () => {
  return (
    <section className="d-flex flex-column">
      <Navbar />
      <Outlet />
    </section>
  );
};

export default CtcContainer;
