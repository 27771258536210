import { useContext, useState } from "react";
import { useQuery } from "react-query";

import { updateProperties } from "../../api/properties/updateProperties";
import { updateReconversion } from "../../api/reconversion/updateReconversion";
import { getMe } from "../../api/session/getMe";
import { currentUser } from "../../cacheQueries/cacheQueries";
import TokenContext from "../../context/Token";

//PROPS DEL COMPONENTE: id=propertyRef._id, reconversionId=_id, refetchReconversion={props.refetchReconversion}
const NewOffer = (props) => {
  // console.log(props)
  const { token } = useContext(TokenContext);
  const { data } = useQuery([currentUser], () => getMe(token));
  const [percentage, setPercentage] = useState("");

  // console.log(props.id)

  const sendData = async () => {
    try {
      const resNewOffer = await updateReconversion(
        token,
        props.reconversionId,
        { newOffer: true, offerPercentage: percentage }
      );

      if (resNewOffer.status === 200) {
        const resUpdateProperty = await updateProperties(token, props.id, {
          reconversion: false
        });

        if (resUpdateProperty.reconversion === false) {
          props.refetchReconversion();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="d-flex flex-column">
      {data?.data?.admin?.role === "Super" && (
        <select
          className="mx-auto"
          style={{ width: 200 }}
          value={percentage ?? ""}
          onChange={(e) => setPercentage(Number(e.target.value))}
        >
          <option hidden value={""}>
            Oferta nueva
          </option>
          <option value={10}>10%</option>
          <option value={20}>20%</option>
          <option value={30}>30%</option>
          <option value={40}>40%</option>
          <option value={50}>50%</option>
        </select>
      )}
      <button
        className={
          percentage
            ? "btn-fill-orange my-2"
            : "btn-disabled mt-2 py-0 px-1 rounded-pill"
        }
        style={{
          height: !percentage ? 24 : null,
          cursor: !percentage ? "not-allowed" : "pointer"
        }}
        onClick={sendData}
        disabled={!percentage}
      >
        Enviar Nueva Oferta
      </button>
    </div>
  );
};

export default NewOffer;
