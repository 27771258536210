import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ListDates from "./components/SellerProperty/Prospects/ListDates";
import ProspectSearch from "./components/SellerProperty/Prospects/ProspectSearch";
import TokenContext from "./context/Token";
import "./App.css";
import CalendarPage from "./pages/BuyProperty/CalendarPage";
import Historial from "./pages/BuyProperty/Historial";
import BuyProceedings from "./pages/BuyProperty/Proceedings";
import BuyProfiles from "./pages/BuyProperty/Profiles";
import BuyProspects from "./pages/BuyProperty/Prospects";
import BuyReconvert from "./pages/BuyProperty/Reconvert";
import BuySignature from "./pages/BuyProperty/Signatures";
import ChangePassword from "./pages/changePassword/ChangePassword";
import CotizerPage from "./pages/cotizer/CotizerPage";
import BuyDates from "./pages/Dates/BuyDates";
import Deed from "./pages/Deed/Deed";
import HomeInfo from "./pages/homeInfo/HomeInfo";
// import Inspections from "./pages/inspections/inspections";
import Inventory from "./pages/Inventory/Inventories";
import Legal from "./pages/legal/Legal";
import Login from "./pages/login/Login";
// METRICAS
import MapPage from "./pages/MapPage/MapPage";
import Metrics from "./pages/Metrics/Metrics";
// COMPRA DE INMUEBLE
// VENTA DE INMUEBLE
import PropertyData from "./pages/propertyData/PropertyData";
import SellerProceedings from "./pages/SellerProperty/Proceedings";
import SellerProfiles from "./pages/SellerProperty/Profiles";
import SellerProspects from "./pages/SellerProperty/Prospects";
import SellerReconvert from "./pages/SellerProperty/Reconvert";
import SellerSignature from "./pages/SellerProperty/Signature";
// INVENTARIO
// CONFIGURACION
import Settings from "./pages/Settings/Settings";
// INSPECCIONES
// ESCRITURACION
import ComprarTemplate from "./routes/templates/ComprarTemplate";
import CtcContainer from "./routes/templates/CtcContainer";
import DashboardTemplate from "./routes/templates/DashboardTemplate";
import VentaTemplate from "./routes/templates/VentaTemplate";

import { PDFViewer } from "@react-pdf/renderer";

import OfferPdf from "./components/pdfs/Pdf";

function App() {
  const { token, userType } = useContext(TokenContext);

  return (
    <div className="App">
      <Routes>
        <Route
          path="pdf"
          element={
            <div style={{ width: "100vw", height: "100vh" }}>
              <PDFViewer style={{ width: "100vw", height: "100vh" }}>
                <OfferPdf />
              </PDFViewer>
            </div>
          }
        ></Route>
        {/*Contenedor de todas las rutas */}
        <Route
          path="/healthz"
          element={<h1>Hey There!!! The App is Healthy</h1>}
        ></Route>
        <Route
          path="/recuperar-password"
          element={
            token ? (
              <Navigate
                to={
                  userType === "Lawyer"
                    ? "/ctc/dashboard"
                    : userType === "Adviser"
                    ? "/ctc/compra/clientes"
                    : "/ctc/dashboard/metricas"
                }
              />
            ) : (
              <ChangePassword />
            )
          }
        />
        <Route
          path="/log-in"
          element={
            token ? (
              <Navigate
                to={
                  userType === "Lawyer"
                    ? "/ctc/dashboard"
                    : userType === "Adviser"
                    ? "/ctc/compra/clientes"
                    : "/ctc/dashboard/metricas"
                }
              />
            ) : (
              <Login />
            )
          }
        />

        <Route //todas las paginas con banner/header/nav
          path="/ctc"
          element={token ? <CtcContainer /> : <Navigate to="/login" />}
        >
          <Route //todas las paginas con menu de dashboard
            path="dashboard"
            element={<DashboardTemplate />}
          >
            {userType !== "Lawyer" && (
              <Route path="metricas" element={<Metrics />} />
            )}
            {userType !== "Lawyer" && (
              <Route path="inventario" element={<Inventory />} />
            )}
            {userType !== "Lawyer" && (
              <Route path="inventario/:propertyId" element={<HomeInfo />} />
            )}
            <Route path="configuracion" element={<Settings />} />
            {/* <Route path="inspecciones" element={<Inspections />} /> */}
            {/* <Route path="cotizador" element={<CotizerPage />} /> */}
            {userType !== "Lawyer" && (
              <Route path="escrituracion" element={<Deed />} />
            )}
            <Route path="mapa" element={<MapPage />} />
          </Route>

          <Route //todas las paginas con menu de compra
            path="compra"
            element={<ComprarTemplate />}
          >
            <Route
              path="propiedad/:propertyId/:processStatus"
              element={<PropertyData />}
            />
            {userType !== "Lawyer" && (
              <>
                <Route path="prospectos" element={<BuyProfiles />} />
                <Route path="clientes" element={<BuyProspects />} />
                <Route path="cita" element={<BuyDates />} />
                <Route path="expediente" element={<BuyProceedings />} />
              </>
            )}

            <Route path="proceso-de-compra" element={<Legal />} />
            <Route path="firma" element={<BuySignature />} />
            {userType !== "Lawyer" && (
              <>
                <Route path="reconversion" element={<BuyReconvert />} />
                <Route path=":propertyId/historial" element={<Historial />} />
                <Route path="calendario" element={<CalendarPage />} />
              </>
            )}
          </Route>

          <Route //todas las paginas con menu de venta
            path="venta"
            element={<VentaTemplate />}
          >
            <Route path="leads-y-perfilados" element={<SellerProfiles />} />
            <Route path="prospectos" element={<SellerProspects />} />
            <Route path="reconversion" element={<SellerReconvert />} />
            <Route path="expediente" element={<SellerProceedings />} />
            <Route path="firma" element={<SellerSignature />} />
            <Route path="search/prospectos" element={<ProspectSearch />} />
            <Route
              path="historial/agendas/prospectos"
              element={<ListDates />}
            />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            token ? (
              <Navigate
                to={
                  userType === "Lawyer"
                    ? "/ctc/dashboard"
                    : userType === "Adviser"
                    ? "/ctc/compra/clientes"
                    : "/ctc/dashboard/metricas"
                }
              />
            ) : (
              <Navigate to="/log-in" />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
