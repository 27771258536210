import {
  Checkbox,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import "moment/locale/es";
import moment from "moment";
import { useContext, useState } from "react";
import { MdModeEditOutline } from "react-icons/md";

import { updateObservations } from "../../../api/Observations/updateObservations";
import TokenContext from "../../../context/Token";
import { useModal } from "../../../hooks/useModal";
import EditObservation from "./EditObservation";

const ObservationItem = ({ obs, refetch, propertyId }) => {
  moment.locale("es");

  const { token } = useContext(TokenContext);
  const [checked, setChecked] = useState(obs.completed ?? false);
  const { open, onOpenModal, onCloseModal } = useModal();

  const completeTask = async () => {
    try {
      const response = await updateObservations(token, obs._id, {
        propertyRef: propertyId,
        completed: !obs.completed
      });

      // console.log(response?.data);
      refetch();
    } catch (error) {
      console.log(error);
    }

    // console.log(response)
  };

  return (
    <>
      <ListItem
        disablePadding
        alignItems="flex-start"
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="comment"
            color="primary"
            onClick={onOpenModal}
          >
            <MdModeEditOutline />
          </IconButton>
        }
      >
        <ListItemButton
          dense
          role={undefined}
          onClick={() => {
            completeTask();
            setChecked(!checked);
          }}
          disableRipple
        >
          <ListItemIcon>
            <Checkbox
              edge={"start"}
              color="primary"
              checked={checked}
              inputProps={{
                "aria-labelledby": `checkbox-list-label-${obs._id}`
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              //texto
              <>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="secondary"
                  fontSize={18}
                >
                  {obs.observation}
                </Typography>
              </>
            }
            secondary={
              //texto
              <Typography
                variant="body1"
                color="primary"
                fontWeight={500}
                fontSize={13}
              >
                {moment(obs.updatedAt).format("L LT a")}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
      {open && (
        <EditObservation
          open={open}
          onOpen={onOpenModal}
          onClose={onCloseModal}
          obs={obs}
          refetch={refetch}
        />
      )}
      <Divider component="li" />
    </>
  );
};

export default ObservationItem;
