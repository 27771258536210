import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField
} from "@mui/material";
import { grey } from "@mui/material/colors";
import moment from "moment";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { updateSign } from "../../../api/properties/updateSign";
import TokenContext from "../../../context/Token";
import Conditions from "./Conditions";
import SigningConfirmation from "./SigningConfirmation";

const SigningModal = ({ open, onCloseModal, property }) => {
  const { token } = useContext(TokenContext);
  const { propertyId } = useParams();
  const [conditions, setConditions] = useState(false);
  const [checked, setChecked] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    isSigned: property?.sign?.signed !== null ? property?.sign?.signed : "",
    reason: property?.sign?.motive || ""
  });
  const [signData, setSignData] = useState({
    date: property?.sign?.date
      ? moment(new Date(property?.sign?.date)).format("YYYY-MM-DDTHH:mm")
      : moment().format("YYYY-MM-DDTHH:mm"),
    location: property?.sign?.location || ""
  });

  const handleChange = (e) => {
    setSignData({ ...signData, [e.target.name]: e.target.value });
  };

  const sendData = async (conditions) => {
    console.log("SEND DATA");

    const data = {
      ...property.sign,
      date: signData?.date,
      location: signData?.location,
      signed: confirmationData?.isSigned,
      motive: confirmationData?.reason,
      conditions
    };

    try {
      const resUpdateProperty = await updateSign(token, propertyId, data);

      if (resUpdateProperty.status === 200) {
        console.log("OK");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"signing-modal"}
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"signing-modal"}>
        Firma
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: { sx: 300, sm: 500, md: 800 } }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                fullWidth
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                name="date"
                label="Fecha y Hora"
                value={signData.date}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                size="small"
                fullWidth
                name="location"
                label="Ubicación"
                value={signData.location}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <Button variant="contained" color="primary" onClick={sendData}>
              Actualizar
            </Button>
          </Box>
          <Divider sx={{ backgroundColor: grey[900], mb: 3 }} />
          {moment(signData.date).isBefore(moment()) && signData.location && (
            <>
              <SigningConfirmation
                confirmationData={confirmationData}
                setConfirmationData={setConfirmationData}
                sendData={sendData}
              />
              <Divider sx={{ backgroundColor: grey[900], mb: 3 }} />
            </>
          )}
          <FormControlLabel
            labelPlacement="start"
            control={
              <Switch checked={checked} onChange={() => setChecked(!checked)} />
            }
            label="Condicionado"
          />
          {checked === true && (
            <Conditions
              propertyConditions={property?.sign?.conditions}
              setConditions={setConditions}
              sendData={sendData}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {moment(signData.date).isBefore(moment()) &&
          confirmationData.isSigned === true && (
            <Button variant="blue">Firmado</Button>
          )}
        <Button variant="contained">Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SigningModal;
