import { Box } from "@mui/material";
import { useState } from "react";

import errorImg from "../../../../assets/error-image.png";
import { useModal } from "../../../../hooks/useModal";
import ImgModal from "./imgModal";

const ImgComponent = ({ img, id, description }) => {
  const [imageSrc, setImageSrc] = useState(img);
  const { open, onOpenModal, onCloseModal } = useModal();

  const handleImageError = () => {
    setImageSrc(errorImg);
  };

  return (
    <Box
      height={140}
      width={140}
      borderRadius={2}
      mr={2}
      mt={2}
      overflow="hidden"
      sx={{ cursor: "pointer" }}
    >
      {/* <a href={imageSrc} target="_blank" rel="noreferrer"> */}
      <img
        height={"100%"}
        width={"100%"}
        src={imageSrc}
        alt={"img"}
        onError={handleImageError}
        onClick={onOpenModal}
      />
      <ImgModal
        open={open}
        onCloseModal={onCloseModal}
        imageSrc={imageSrc}
        description={description}
      />
      {/* </a> */}
    </Box>
  );
};

export default ImgComponent;
