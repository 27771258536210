import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton } from "@mui/material";
import { useContext } from "react";

import { updateProperties } from "../../api/properties/updateProperties";
import TokenContext from "../../context/Token";

const ReassignAdviser = (props) => {
  const { token } = useContext(TokenContext);

  const sendData = async (adviserRef) => {
    try {
      const response = await updateProperties(token, props.id, {
        adviserRef,
        reconversion: false
      });

      if (response) {
        props.refetchReconversion();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="dropdown">
      <IconButton
        aria-label="houseData"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        sx={{ color: "#0053C5" }}
      >
        <EditIcon color="inherit" />
      </IconButton>
      {/* <Button
        variant="contained"
        sx={{ mt: 0 }}
        type="button"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Reasignar
      </Button> */}
      <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
        {props.advisers?.map((ad) => (
          <li key={ad._id}>
            <button
              className="dropdown-item"
              type="button"
              onClick={() => sendData(ad._id)}
            >
              {ad.name ?? ""}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReassignAdviser;
