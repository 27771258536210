import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getAllHistories = async (token, type, propertyId) => {
  try {
    const response = await axios.get(
      `${REACT_APP_BASE_URL}/histories?type=${type}&reference=${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
