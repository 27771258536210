/* eslint-disable no-prototype-builtins */
import { Box, Button, Grid } from "@mui/material";
import { set } from "date-fns";
import React, { useState } from "react";
import { useContext } from "react";
import { useQuery } from "react-query";

import { getProperties } from "../../api/properties/getProperties";
import { prospectosFirma } from "../../cacheQueries/cacheQueries";
import { defaultFilter } from "../../components/GeneralFilter/defaultFilter";
import GeneralFilter from "../../components/GeneralFilter/GeneralFilter";
import Loading from "../../components/loading/Loading";
import SignatureCard from "../../components/Signature/SignatureCard";
import SignatureCategories from "../../components/Signature/SignatureCategories";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";

const { REACT_APP_BASE_URL } = process.env;

const Signature = () => {
  const { token } = useContext(TokenContext);
  const [filters, setFilters] = useState({ ...defaultFilter });
  const [settlements, setSettlements] = useState([]);
  const [results] = useState(200);
  const [page] = useState(1);

  const { isLoading, isError, data, refetch, isFetching } = useQuery(
    [prospectosFirma, results, page],
    () =>
      getProperties(
        token,
        results,
        page,
        "Firma",
        "",
        "",
        {
          ...filters,
          dateStart: set(new Date(filters.dateStart), {
            hours: 0,
            minutes: 0,
            seconds: 0
          }).toISOString(),
          dateEnd: set(new Date(filters.dateEnd), {
            hours: 23,
            minutes: 59,
            seconds: 59
          }).toISOString()
        },
        true
      ),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  const [value, setValue] = useState({
    selectTab: "" //pestaña seleccionada (general y prioridad alta, media o baja)
  });

  const handleSelect = (type, target, id) => {
    //type puede ser selectTab (pestaña elegida), priority (alta, media o baja), id (de la casa)
    setValue({
      ...value,
      [type]: target,
      id: id
    });
  };
  const filteredList =
    value.selectTab && value.selectTab === "hasFirmDate"
      ? data?.properties?.docs
          ?.filter((property) => property.hasOwnProperty("firmDate"))
          .map((d) => d)
      : data?.properties?.docs
          ?.filter((property) => !property.hasOwnProperty("firmDate"))
          .map((d) => d);

  const signatureFilter =
    value.selectTab === "hasFirmDate"
      ? data?.properties?.docs
          ?.filter((property) => property.hasOwnProperty("firmDate"))
          .map((property) => (
            <SignatureCard
              refetchProperties={refetch}
              key={property._id}
              property={property}
              onSelect={handleSelect}
            />
          ))
      : data?.properties?.docs
          ?.filter((property) => !property.hasOwnProperty("firmDate"))
          .map((property) => (
            <SignatureCard
              refetchProperties={refetch}
              key={property._id}
              property={property}
              onSelect={handleSelect}
            />
          ));

  // if (isLoading) return <Loading />;

  if (isError) {
    return <h2>Error al cargar datos</h2>;
  }

  return (
    <section className="flex-center">
      <SignatureCategories
        onSelect={handleSelect}
        totalLength={data?.properties?.docs?.length}
        noDate={
          data?.properties?.docs.filter((property) => !property.firmDate).length
        }
        withDate={
          data?.properties?.docs.filter((property) => property.firmDate).length
        }
      />
      <a
        className="mt-4"
        href={`${REACT_APP_BASE_URL}/csv/properties?status=Firma`}
      >
        <Button variant="contained">Descargar CSV</Button>
      </a>
      <Box style={{ width: "100%", paddingLeft: "8px" }} display="flex">
        <GeneralFilter
          refetch={refetch}
          filters={filters}
          setFilters={setFilters}
          settlements={settlements}
          setSettlements={setSettlements}
        />
      </Box>
      {data?.properties.length === 0 ? (
        <h2 className="fs-5 margin-element">
          No se encontraron casas en Clientes
        </h2>
      ) : isFetching ? (
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Table
          columnTitles={[
            "ID de la casa",
            "Precio",
            "Informacion del Cliente",
            "Informacion del Inmueble",
            "Fuente",
            "Acciones"
          ]}
          properties={
            value.selectTab ? filteredList : data?.properties?.docs ?? []
          }
          refetchProperties={refetch}
          tableType={"Firma"}
        />
      )}
      {/* {value.selectTab ?
        signatureFilter.length !== 0 ?
          signatureFilter : <h2 className='fs-5 margin-element'>No se encontraron casas con prioridad {value.selectTab.toLowerCase()}</h2> :

        data?.properties?.map(property => (
          <SignatureCard
            key={property._id}
            property={property}
            refetchProperties={refetch}
          />
        ))} */}
    </section>
  );
};

export default Signature;
