import { Box, Button, Grid, Pagination } from "@mui/material";
import { set } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getProperties } from "../../api/properties/getProperties";
import { defaultFilter } from "../../components/GeneralFilter/defaultFilter";
import GeneralFilter from "../../components/GeneralFilter/GeneralFilter";
import Loading from "../../components/loading/Loading";
// import ProspectCard from "../../components/Prospect/ProspectCard";
// import SearchBar from "../../components/SearchBar";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";

const { REACT_APP_BASE_URL } = process.env;

const Prospects = () => {
  const { token } = useContext(TokenContext);
  const [filters, setFilters] = useState({ ...defaultFilter });
  const [settlements, setSettlements] = useState([]);
  const [results, setResults] = useState(20);
  const [page, setPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(1);

  // data && console.log(data)
  const { isError, data, refetch, isFetching } = useQuery(
    ["prospectos-properties"],
    () =>
      getProperties(token, results, page, "Prospectos", "", "", {
        ...filters,
        dateStart: set(new Date(filters.dateStart), {
          hours: 0,
          minutes: 0,
          seconds: 0
        }).toISOString(),
        dateEnd: set(new Date(filters.dateEnd), {
          hours: 23,
          minutes: 59,
          seconds: 59
        }).toISOString()
      }),
    { refetchOnWindowFocus: false, keepPreviousData: true }
  );

  useEffect(() => {
    const getPaginationValue = () => {
      setPaginationValue(Math.ceil(data?.properties?.totalDocs / results));
    };

    getPaginationValue();
  }, [data?.properties?.totalDocs, results]);

  // if (isLoading) {
  //   return (
  //     <section className="flex-center">
  //       <Loading />
  //     </section>
  //   );
  // }

  if (isError) {
    return <h2>Error al cargar datos</h2>;
  }

  return (
    <section className="flex-center">
      <Box mt={2} height={32}>
        {paginationValue > 0 && (
          <Pagination
            color="primary"
            count={paginationValue}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        )}
      </Box>
      <a
        className="mt-4"
        href={`${REACT_APP_BASE_URL}/csv/properties?status=Prospectos`}
      >
        <Button variant="contained">Descargar CSV</Button>
      </a>
      <Box style={{ width: "100%", paddingLeft: "8px" }} display="flex">
        <GeneralFilter
          refetch={refetch}
          filters={filters}
          setFilters={setFilters}
          settlements={settlements}
          setSettlements={setSettlements}
        />
      </Box>
      {/* <SearchBar /> */}
      {data?.properties.length === 0 ? (
        <h2 className="fs-5 margin-element">
          No se encontraron casas en Clientes
        </h2>
      ) : isFetching ? (
        <Grid
          container
          sx={{ flexGrow: 1 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      ) : (
        <Table
          columnTitles={[
            "Tipo de Crédito",
            "Fecha y Hora",
            "Informacion del Cliente",
            "Informacion del Inmueble",
            "Fuente",
            "Oferta",
            "Acciones"
          ]}
          properties={data?.properties?.docs ?? []}
          refetchProperties={refetch}
          tableType={"Prospectos"}
        />
      )}

      {/* {data?.properties?.map(property => (
        <ProspectCard
          key={property._id}
          property={property}
          refetchProperties={refetch}
        />
      ))} */}
    </section>
  );
};

export default Prospects;
