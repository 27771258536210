import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import ClientInfo from "../../components/InfoFields/ClientInfo";
import PropertyInfo from "../../components/InfoFields/PropertyInfo";
import DocumentationButton from "./DocumentationButton";
import ValidateButton from "./ValidateButton";

const LegalTable = ({ properties, refetch }) => {
  return (
    <table
      className="table table-bordered mt-3"
      style={{ borderColor: "#707070" }}
    >
      <thead style={{ backgroundColor: "#F2F2F2", color: "#718195" }}>
        <tr>
          <th>ID de la casa</th>
          <th>Información del Cliente</th>
          <th>Información del Inmueble</th>
          <th>Estatus</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {properties?.map((property) => (
          <tr key={property._id}>
            <td style={{ width: 250 }}>
              <label
                className="value-title mt-4 text-uppercase"
                style={{ fontSize: 14 }}
              >
                {" "}
                {property?._id ?? "N/A"}
              </label>
            </td>
            <td style={{ width: 400 }}>
              <ClientInfo sellerRef={property?.sellerRef} />
            </td>
            <td style={{ width: "450px" }}>
              <PropertyInfo property={property} />
            </td>
            <td style={{ width: "150px" }}>
              <label
                className="value-title mt-4"
                style={{ fontSize: 14, fontWeight: "bold" }}
              >
                {" "}
                {property?.status ?? "N/A"}
              </label>
            </td>
            <td style={{ width: 200 }}>
              <Link
                to={`/ctc/compra/propiedad/${property?._id}/inicio-proceso-de-compra`}
              >
                <Button variant="contained" fullWidth sx={{ mt: 0 }}>
                  Ver Más
                </Button>
              </Link>
              {property?.status === "Gestion" && (
                <DocumentationButton property={property} refetch={refetch} />
              )}
              {property?.status === "Firma" && (
                <ValidateButton
                  propertyId={property?._id}
                  sellerId={property?.sellerRef?._id}
                  legal={property?.legal}
                  refetchProperties={refetch}
                />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LegalTable;
