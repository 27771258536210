import React from "react";

export default function Map({ mapContainer, location }) {
  return (
    <div
      data-cy-location={location}
      ref={mapContainer}
      data-tap-disabled="true"
      style={{
        height: "100%",
        width: "100%"
      }}
    />
  );
}
