import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import { useContext, useState } from "react";

import { createObservations } from "../../../api/Observations/createObservations";
import TokenContext from "../../../context/Token";

const ObservationModal = ({ open, onCloseModal, propertyId, refetch }) => {
  const { token } = useContext(TokenContext);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState({ severity: "", text: "" });
  const [note, setNote] = useState("");
  const sendData = async () => {
    try {
      setIsLoading(true);
      await createObservations(token, {
        propertyRef: propertyId,
        observation: note
      });
      setIsLoading(false);
      setSuccess({ severity: "success", text: "actualizado" });
      refetch();
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
        onCloseModal();
      }, 3000);
    } catch (error) {
      setSuccess({ severity: "error", text: String(error) });
      setTimeout(() => {
        setSuccess({ severity: "", text: "" });
      }, 3000);
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      // onClose={onCloseModal}
      aria-labelledby={"error-success-dialogue"}
      maxWidth="md"
      sx={{ borderRadius: ".5rem" }}
    >
      <DialogTitle id={"error-success-title"}>
        Nueva Observación
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{
            position: "absolute",
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: { xs: 300, md: 500 } }}>
        <TextField
          multiline
          fullWidth
          minRows={{ xs: 5, md: 2 }}
          maxRows={{ xs: 5, md: 3 }}
          id="observation"
          name={"observation"}
          label="Escribe una observación"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions sx={{ px: 4 }}>
        {success.severity !== "" && (
          <Alert severity={success.severity} sx={{ mr: 2 }}>
            {success.text}
          </Alert>
        )}
        <Button
          disabled={isLoading}
          color="primary"
          variant="contained"
          onClick={sendData}
        >
          {isLoading ? "Actualizando..." : "Enviar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObservationModal;
