import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Typography,
  IconButton,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";

import ModalDataHouseV2 from "../../../components/Modals/modalDataHouse/ModalDataHouseV2";
import { useModal } from "../../../hooks/useModal";
import ExtraInfo from "./ExtraInfo";
import GeneralInfo from "./GeneralInfo";

const InfoBuilding = ({ property, refetch }) => {
  const { open, onOpenModal, onCloseModal } = useModal();

  return (
    <>
      <Box display="flex" mt={4}>
        <Typography
          display={"block"}
          sx={{ width: "fit-content" }}
          variant="h4"
          color="primary"
          fontWeight={600}
          textAlign="start"
          my={1}
        >
          Información del inmueble
        </Typography>
        <IconButton
          aria-label="houseData"
          onClick={() => onOpenModal()}
          sx={{ color: "#0053C5" }}
        >
          <EditIcon color="inherit" />
        </IconButton>
        {open && (
          <ModalDataHouseV2
            property={property}
            open={open}
            onCloseModal={onCloseModal}
            refetch={refetch}
          />
        )}
      </Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="info-building-content"
          id="info-building-header"
        ></AccordionSummary>
        <AccordionDetails>
          <GeneralInfo property={property} />
          <ExtraInfo property={property} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default InfoBuilding;
