import { Button } from "@mui/material";

import Loading from "../../components/loading/Loading";
import RehabModal from "../../components/Modals/RehabModal/RehabModal";
import ToHistoryButton from "../../components/ToHistoryButton";
import InspeccionModal from "../propertyData/Inspection/inspeccionModal/InspeccionModal";

const { REACT_APP_BASE_URL } = process.env;

const ButtonSection = (props) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: 10
      }}
    >
      <InspeccionModal
        evidence={props?.property?.inspection?.evidence ?? []}
        generalComment={props?.property?.inspection?.comments ?? ""}
        modalType="rehab"
        title="Rehabilitación"
      />

      <ToHistoryButton
        id={props.property?._id}
        property={props.property ?? {}}
      />

      <a
        href={`${REACT_APP_BASE_URL}/pdf/lead-property?propertyRef=${props.property?._id}`}
      >
        <Button variant="contained" sx={{ mt: 0 }} fullWidth>
          Ver PDF
        </Button>
      </a>
      <a
        href="https://precalificaciones.infonavit.org.mx/wpEstadoSolicitudCreditoWeb/servlet/TramiteMEI"
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="contained" sx={{ mt: 0 }} fullWidth>
          Titulación
        </Button>
      </a>
    </div>
  );
};

export default ButtonSection;
