import CloseIcon from "@mui/icons-material/Close";
import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from "@mui/material";
import { useState } from "react";

import { useModal } from "../../../../hooks/useModal";
import AuthRehabButtons from "./AuthRehabButtons";
import CategoriesList from "./CategoriesList";
import FinishRehabButtom from "./FinishRehabButton";

const InspeccionModal = ({
  evidence = [],
  generalComment = "",
  modalType = "",
  title = "",
  rehabilitationApproved,
  changeRehabilitationApproved,
  propertyId,
  refetch
}) => {
  const { open, onOpenModal, onCloseModal } = useModal();
  const [success, setSuccess] = useState({ severity: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");

  return (
    <>
      {modalType === "inspection" ? (
        <IconButton onClick={onOpenModal} sx={{ color: "#0053C5" }}>
          <PostAddIcon color="inherit" />
        </IconButton>
      ) : (
        <Button variant="contained" sx={{ mt: 0 }} onClick={onOpenModal}>
          Rehabilitacion
        </Button>
      )}
      {open && (
        <Dialog
          open={open}
          // onClose={onCloseModal}
          aria-labelledby={"error-success-dialogue"}
          sx={{ borderRadius: ".5rem" }}
        >
          <DialogTitle id={"error-success-title"}>
            {title}
            <IconButton
              aria-label="close"
              onClick={onCloseModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box component={"form"} mb={4} minWidth={500} height={"60vh"}>
              <CategoriesList
                evidence={evidence}
                modalType={modalType}
                propertyId={propertyId}
                refetch={refetch}
              />
              {modalType === "inspection" && (
                <AuthRehabButtons
                  rehabilitationApproved={rehabilitationApproved}
                  changeRehabilitationApproved={changeRehabilitationApproved}
                />
              )}
              {modalType === "rehab" && <FinishRehabButtom />}
              <Box mt={3}>
                <TextField
                  fullWidth
                  multiline
                  name="comment"
                  label="Comentarios"
                  value={generalComment ?? ""}
                />
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            {success.severity === "success" && (
              <Alert severity={success.severity} sx={{ mr: 2 }}>
                {success.message}
              </Alert>
            )}
            {success.severity === "error" && (
              <Alert severity={success.severity} sx={{ mr: 2 }}>
                {success.message}
              </Alert>
            )}
            <Box display={"flex"}>
              {/* <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{ width: 200, mr: 3, ml: "auto" }}
              >
                Crear
              </Button> */}
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default InspeccionModal;
