import { ThemeProvider } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import es from "date-fns/locale/es";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";
import App from "./App";
import { LoadingProvider } from "./context/Loading";
import { TokenProvider } from "./context/Token";
import theme from "./theme";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://41082cb7ce5adbdf4c2c66f418157b09@o4506656193839104.ingest.sentry.io/4506730179395584",
    integrations: [new BrowserTracing()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: process.env.REACT_APP_APP_ENV
  });
}
setChonkyDefaults({ iconComponent: ChonkyIconFA });

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <TokenProvider>
          <LoadingProvider>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={es}
            >
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </LocalizationProvider>
          </LoadingProvider>
        </TokenProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);
