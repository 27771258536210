import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { updateProperties } from "../../../api/properties/updateProperties";
import { createReconversion } from "../../../api/reconversion/createReconversion";

const FirmConfirmation = (props) => {
  const navigate = useNavigate();
  const [firmState, setFirmState] = useState({
    firm: props.firm ?? null,
    firmText: props.firmText ?? ""
  });

  const handleChange = (e) => {
    setFirmState({
      ...firmState,
      [e.target.name]: e.target.value
    });
  };

  const sendData = async (e) => {
    e.preventDefault();

    if (firmState.firm === "true") {
      try {
        const response = await updateProperties(props.token, props.id, {
          status: "Inventario",
          firm: firmState.firm,
          firmText: firmState.firmText
        });

        if (response._id) {
          props.setSuccessMessage("Estado de Firma Actualizado");
          navigate(0);
          setTimeout(() => {
            props.setSuccessMessage("");
          }, 3000);
        }
        return;
      } catch (error) {
        props.setErrorMessage(error);
        setTimeout(() => {
          props.setErrorMessage("");
        }, 3000);
        return;
      }
    }

    if (firmState.firm === "false") {
      try {
        await updateProperties(props.token, props.id, { reconversion: true });

        const response = await createReconversion(props.token, {
          reasonDetail: firmState.firmText ?? "",
          reason: "No se presento a firma",
          propertyRef: props.id
        });

        if (response.status === 201) {
          props.setSuccessMessage("Estado de Firma Actualizado");
          setTimeout(() => {
            props.setSuccessMessage("");
            navigate(0);
          }, 1500);
        }
      } catch (error) {
        props.setErrorMessage(error);
        setTimeout(() => {
          props.setErrorMessage("");
        }, 3000);
        return;
      }
    }
  };

  return (
    <form className="d-flex flex-column mt-3" onSubmit={sendData}>
      <label className="property-title mt-2">Estado de la firma:</label>
      <select
        name="firm"
        id="firm"
        className="mt-2 p-1 w-25"
        onChange={handleChange}
      >
        <option value="" hidden>
          Seleccionar
        </option>
        <option value={true}>Ya firmó</option>
        <option value={false}>No firmó</option>
      </select>
      <label className="property-title mt-2">Comentario (opcional):</label>
      <textarea
        name="firmText"
        id="firmText"
        cols="30"
        rows="10"
        className="mt-2"
        onChange={handleChange}
      ></textarea>
      <button className="btn-fill-orange mt-2" type="submit">
        Actualizar
      </button>
    </form>
  );
};

export default FirmConfirmation;
