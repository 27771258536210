import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const getDocListSeller = async (token, params) => {
  let url = `${REACT_APP_BASE_URL}/documentations/list?type=`;

  let searchParams = "";

  for (let i = 0; i < params.length; i++) {
    if (params[i] !== false) {
      if (i === 0) {
        searchParams += params[i];
      } else {
        searchParams = searchParams + "&type=" + params[i];
      }
    }
  }

  if (searchParams !== "") url += searchParams;

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return response;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err.errors[0]?.msg
        : err?.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
