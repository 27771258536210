import { Box, Pagination } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getPreInventory } from "../../api/properties/getPreInventory";
import Table from "../../components/Table/Table";
import TokenContext from "../../context/Token";

const PreInventoryContainer = (props) => {
  const { token } = useContext(TokenContext);
  const [results] = useState(200);
  const [page, setPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(1);
  const { data, isLoading } = useQuery(
    ["Pre-Inventory-properties", token, results, page],
    () => getPreInventory(token, results, page)
  );

  useEffect(() => {
    const getPaginationValue = () => {
      setPaginationValue(Math.ceil(data?.properties?.totalDocs / results));
    };

    getPaginationValue();
  }, [data?.properties?.totalDocs, results]);
  // console.log(data ?? "");
  return (
    <>
      <Box mt={2} height={32}>
        {paginationValue > 0 && (
          <Pagination
            color="primary"
            count={paginationValue}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        )}
      </Box>
      <Table
        columnTitles={[
          "Id de la Casa",
          "Status",
          "Precio Compra",
          "Precio Venta",
          "Asesores",
          "Más"
        ]}
        properties={data?.properties ?? []}
        tableType={"Inventario"}
      />
    </>
  );
};

export default PreInventoryContainer;
