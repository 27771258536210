import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdOutlineAddAPhoto } from "react-icons/md";

//PROPS DEL COMPONENTE: imagesInputRef, imagesToUpload, setImagesToUpload, imagesToDisplay, setImagesToDisplay
const AddImages = (props) => {
  const onSelectFiles = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    const previewsArray = selectedFilesArray.map((f) => URL.createObjectURL(f));

    props.setImagesToDisplay((previousImages) =>
      previousImages.concat(previewsArray)
    );
    props.setImagesToUpload((previousImages) =>
      previousImages.concat(selectedFilesArray)
    );
    // console.log(e.target.files)
  };

  const deleteItem = (im, i) => {
    props.setImagesToDisplay(props.imagesToDisplay.filter((e) => e !== im));

    const toDelete = [...props.imagesToUpload].splice(i, 1);

    props.setImagesToUpload(
      props.imagesToUpload.filter((e) => e.name !== toDelete[0].name)
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <div className="py-3 me-4">
        <label className="select-file-box" style={{ cursor: "pointer" }}>
          <input
            ref={props.imagesInputRef}
            type="file"
            accept="image/*"
            name="images"
            id="images"
            onChange={onSelectFiles}
            hidden
            multiple
          />
          <MdOutlineAddAPhoto className="select-icons" />
          <span style={{ userSelect: "none" }}>Agregar fotos</span>
        </label>
      </div>
      <div
        className="d-flex py-3 thumbnails w-75 "
        style={{ overflowX: "auto" }}
      >
        {props.imagesToDisplay?.map((im, i) => (
          <div key={i} className="thumbnail-container">
            <AiOutlineCloseCircle
              className="delete-item"
              onClick={() => deleteItem(im, i)}
            />
            <img src={im} alt={"preview"} className="thumbnail" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddImages;
