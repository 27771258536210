import { useMemo } from "react";
import { useState } from "react";

import { updateCurrentBuyRequest } from "../../api/buyRequest/updateBuyRequest";
import { createCallSale } from "../../api/call/createCallSale";
import { createReconversionSale } from "../../api/reconversion/createReconversionSale";
import Loading from "../../components/loading/Loading";
import { optionsReconversion } from "../../constants/reconversionSaleConstants";
import { useDeedContext } from "../../hooks/useDeedContext";

export const DeedReconversion = (props) => {
  const { token, getBuyRequestInDeed } = useDeedContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    reason: "",
    reasonDetail: "",
    date: "",
    type: ""
  });
  const isDisabled = useMemo(() => Object.values(data).includes(""), [data]);
  const handleChange = (e) =>
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const showError = (error) => {
    console.log(error);
    setError(error);
    setTimeout(() => setError(""), 5500);
  };

  const handleClose = () => {
    props.onClose();
    setData({ reason: "", reasonDetail: "", date: "", type: "" });
  };

  const handleSubmit = async () => {
    setLoading(true);
    updateCurrentBuyRequest(token, props._id, { reconversion: true })
      .then(() =>
        createReconversionSale(token, { ...data, buyRequestRef: props._id })
      )
      .then(() => createCallSale(token, { ...data, buyRequestRef: props._id }))
      .then(() => getBuyRequestInDeed())
      .catch((error) => showError(error))
      .finally(() => setLoading(false));
  };

  return (
    props.open && (
      <td
        className={`custom_modal ${props.open && "isOpen"}`}
        onClick={handleClose}
      >
        <div
          className="custom_modal__content w-75"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="custom_modal__header">
            <h2 className="mt-3">Reconversion</h2>
            <div className="col-3 mt-3">
              <button
                className="btn-close custom_modal_close"
                onClick={handleClose}
              ></button>
            </div>
          </div>

          <div className="my-5 px-3">
            <form>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <div className="mb-3">
                    <label className="form-label text-start w-100">Razón</label>
                    <select
                      className="form-select"
                      value={data.reason}
                      name="reason"
                      onChange={handleChange}
                    >
                      <option disabled value="">
                        Seleccione
                      </option>
                      {optionsReconversion.map((opt) => (
                        <option value={opt} key={opt}>
                          {opt}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-start w-100">
                      Comentario
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                      placeholder="Comentario"
                      value={data.reasonDetail}
                      name="reasonDetail"
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-label text-start w-100">
                        Forma de Contacto
                      </label>
                      <select
                        className="form-select"
                        name="type"
                        value={data.type}
                        onChange={handleChange}
                      >
                        <option value={""} disabled>
                          Seleccione
                        </option>
                        <option value={"sms"}>SMS</option>
                        <option value={"telefono"}>Llamada Telefónica</option>
                      </select>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label text-start w-100">
                        Fecha de Contacto
                      </label>
                      <input
                        type="datetime-local"
                        className="form-control w-100"
                        name="date"
                        value={data.date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>

          <div className="custom_modal__footer">
            {error !== "" && <div className="deed-error">{error}</div>}
            <button
              className="deed-button w-auto px-3 py-1"
              onClick={handleSubmit}
              disabled={isDisabled}
            >
              Enviar a reconversión
            </button>
            <button
              className="deed-button w-auto px-3 py-1"
              onClick={handleClose}
            >
              CERRAR
            </button>
          </div>
        </div>
      </td>
    )
  );
};
