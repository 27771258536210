import { Grid, TextField, Typography } from "@mui/material";

const PropertyExtras = ({ propertyData, setPropertyData }) => {
  return (
    <>
      <Typography
        variant="body1"
        color="primary"
        fontSize={20}
        fontWeight="bold"
        mt={4}
      >
        Extras
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            id="inspectionUrl"
            name="inspectionUrl"
            label="Inspección URL"
            value={propertyData.inspectionUrl}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                inspectionUrl: e.target.value
              })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            id="locationUrl"
            name="locationUrl"
            label="Ubicacación Url"
            value={propertyData.locationUrl}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                locationUrl: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            required
            fullWidth
            id="driveUrl"
            name="driveUrl"
            label="Drive Url"
            value={propertyData.driveUrl}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                driveUrl: e.target.value
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            rows={4}
            required
            fullWidth
            multiline
            id="notes"
            name="notes"
            label="Notas"
            value={propertyData.notes}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                notes: e.target.value
              })
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyExtras;
