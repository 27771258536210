import axios from "axios";

const { REACT_APP_BASE_URL } = process.env;

export const updateDebt = async (token, debtId, data) => {
  const body = {
    debt: {
      detail: data.text ?? "",
      amount: Number(data.amount),
      type: data.type
    }
  };

  try {
    const response = await axios.put(
      `${REACT_APP_BASE_URL}/debts/${debtId}`,
      body,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data;
  } catch (error) {
    const err = error?.response?.data?.error ?? {};
    const response =
      err?.type === "VALIDATION_ERROR"
        ? err?.errors[0]?.msg
        : err.message ?? "Error en la petición";

    return Promise.reject(response);
  }
};
