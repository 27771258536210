import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from "@mui/material";

const CheckBoxes = ({ propertyAddress, setPropertyAddress }) => {
  const handleChange = (e) => {
    setPropertyAddress({
      ...propertyAddress,
      [e.target.name]: e.target.checked
    });
  };

  return (
    <>
      <Typography
        variant="body1"
        color="primary"
        fontSize={20}
        fontWeight="bold"
        mt={4}
      >
        Extras
      </Typography>
      <Grid container>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="kitchen"
                  checked={propertyAddress.kitchen}
                  color="primary"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="¿La cocina tiene tarja?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="rooftop"
                  checked={propertyAddress.rooftop}
                  color="primary"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="¿Tiene zotehuela?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="spaceBetween"
                  checked={propertyAddress.spaceBetween}
                  color="primary"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="¿Tiene espacio para sala/comedor?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="heater"
                  checked={propertyAddress.heater}
                  color="primary"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="¿Tiene calentador?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="doorProtection"
                  checked={propertyAddress.doorProtection}
                  color="primary"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="¿Tiene herreria en las puertas de entrada?"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="accessControl"
                  checked={propertyAddress.accessControl}
                  color="primary"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="¿Tiene control de accesos?"
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="halfBath"
                  checked={propertyAddress.halfBath}
                  color="primary"
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="¿Tiene medio baño?"
            />
          </FormGroup>
        </Grid>
      </Grid>
    </>
  );
};

export default CheckBoxes;
