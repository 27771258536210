import { Outlet } from "react-router-dom";

import SellerSidebar from "../../components/SellerSidebar";

const VentaTemplate = () => {
  return (
    <section className="d-flex">
      <SellerSidebar />
      <Outlet />
    </section>
  );
};

export default VentaTemplate;
