import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  ClickAwayListener,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useState } from "react";

const CreditInfo = ({
  sellerInfo,
  setSellerInfo,
  propertyData,
  setPropertyData
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography
        variant="body1"
        color="primary"
        fontSize={20}
        fontWeight="bold"
        mt={4}
      >
        Crédito
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            required
            select
            id="creditType"
            name="creditType"
            label="Tipo de Crédito"
            value={propertyData.creditType ?? ""}
            onChange={(e) =>
              setPropertyData({ ...propertyData, creditType: e.target.value })
            }
          >
            <MenuItem value="Infonavit">Infonavit</MenuItem>
            <MenuItem value="Fovissste">Fovissste</MenuItem>
            <MenuItem value="Bancario">Bancario</MenuItem>
            <MenuItem value="Otro">Otro</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            id="credit"
            name="credit"
            label="Número de crédito"
            value={sellerInfo.credit ?? ""}
            onChange={(e) =>
              setSellerInfo({ ...sellerInfo, credit: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} display="flex">
          <TextField
            fullWidth
            id="debtCredit"
            name="debtCredit"
            label="Monto pendiente"
            value={propertyData.debtCredit ?? ""}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                [e.target.name]: e.target.value
              })
            }
          />
          {propertyData.creditType === "Infonavit" && (
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              <Tooltip
                PopperProps={{ disablePortal: true }}
                onClose={() => setOpen(false)}
                open={open}
                arrow
                title="Para conocer el monto pendiente, escribe tu Número de 
              Seguridad Social (NSS) o tu número de crédito y envíalo al 
              30900. Selecciona la opción SI"
                sx={{ fontSize: 22 }}
                onClick={() => setOpen(!open)}
              >
                <HelpOutlineIcon color="primary" fontSize={"inherit"} />
              </Tooltip>
            </ClickAwayListener>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            select
            id="hasBacklogCredit"
            name="hasBacklogCredit"
            label="¿El crédito presenta atrasos?"
            value={propertyData.hasBacklogCredit ?? ""}
            onChange={(e) =>
              setPropertyData({
                ...propertyData,
                hasBacklogCredit: e.target.value
              })
            }
          >
            <MenuItem value={true}>Si</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </TextField>
        </Grid>
        {propertyData.hasBacklogCredit === true && (
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              select
              id="monthBacklogs"
              name="monthBacklogs"
              label="¿cuántos meses de atraso presenta?"
              value={propertyData.monthBacklogs ?? ""}
              onChange={(e) =>
                setPropertyData({
                  ...propertyData,
                  monthBacklogs: e.target.value
                })
              }
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
              <MenuItem value="4">4</MenuItem>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="7">7</MenuItem>
              <MenuItem value="8">8</MenuItem>
              <MenuItem value="9">9</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">Más de 1 año</MenuItem>
            </TextField>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CreditInfo;
