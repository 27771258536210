import Typography from "@mui/material/Typography";
import React from "react";

import Loading from "../../components/loading/Loading";
import { useDeedContext } from "../../hooks/useDeedContext";
import { DeedListTable } from "./DeedListTable";

function DeedTable() {
  const { loading, buyRequests, token } = useDeedContext();

  console.log(buyRequests);
  return (
    <section className="flex-center">
      {loading ? (
        <Loading />
      ) : buyRequests.length > 0 ? (
        <DeedListTable buyRequests={buyRequests} token={token} />
      ) : (
        <div>
          <Typography variant="h4" color="primary">
            No hay registros disponibles
          </Typography>
        </div>
      )}
    </section>
  );
}

export default DeedTable;
